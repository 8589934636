
import 'font-awesome/css/font-awesome.min.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

/* added support for both MDI and Fontawsome icons */

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi' || 'fa' || 'fab' || 'fas' || 'far'
    },

});
