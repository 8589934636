<!---------------------------------------------------------------
   
              Mortgage Payment Tools Tab Component

       for use in Mortgage Calculators Main Parent Component

---------------------------------------------------------------->
<template>
  <div>
  <v-card width="100%" elevation="10">
      <v-card-text>
        <v-sheet class="mx-auto" :width="( isMobile ? '95%' : '75%' )">
            <v-form id="mortgagePayment" v-model="formValid" class="ma-5" fast-fail @submit.prevent>
              <!-- Home value field -->
              <v-row dense >
                  <v-col cols="11" >
                      <v-currency-field 
                          :valueAsInteger="true" 
                          :decimalLength=0
                          name="home_value"
                          prepend-icon="fa-solid fa-file-invoice-dollar"                  
                          v-model="homeValue"
                          :rules="homeValueRules"
                          label="Home Price"
                          dense
                          clearable                  
                          required />
                  </v-col>
                  <v-col cols="1">
                      <v-btn x-small fab @click.prevent.stop="showHelpInfo( 1 )"><v-icon>fa-regular fa-circle-question</v-icon></v-btn>
                  </v-col>
              </v-row>
              <!-- down payment field -->
              <v-row dense >
                  <v-col cols="11">
                      <v-currency-field 
                          :valueAsInteger="true" 
                          :decimalLength=0
                          name="down_payment"
                          prepend-icon="fa-solid fa-sack-dollar"                  
                          v-model="downPayment"
                          :rules="downPaymentRules"
                          label="Down Payment"
                          dense
                          clearable                  
                          required />
                  </v-col>
                  <v-col cols="1">
                    <v-btn x-small fab @click.prevent.stop="showHelpInfo( 2 )"><v-icon>fa-regular fa-circle-question</v-icon></v-btn>
                  </v-col>
              </v-row>    
              <!-- loan terms in years -->  
              <v-row dense class="mt-0 pt-0" >
                  <v-col cols="11" >
                    <!-- v-select requires string array  -->
                      <v-select v-model="selectedTerm" prepend-icon="fa-regular fa-calendar-days" 
                                        label="Loan Term (years)" :items="loanYears" :rules="loanTermRules" return-object required>
                      </v-select>
                  </v-col>
                  <v-col class="mt-3" cols="1">
                      <v-btn x-small fab @click.prevent.stop="showHelpInfo( 3 )"><v-icon>fa-regular fa-circle-question</v-icon></v-btn>
                  </v-col>
               </v-row>    
              <!-- interest rate field -->
              <v-row dense >
                  <v-col cols="11">
                    <v-currency-field 
                              :valueAsDecimal="true" 
                              :decimalLength=3
                              name="interest_rate"
                              prepend-icon="fa-solid fa-filter-circle-dollar"   
                              v-model="interestRate" 
                              :rules="interestRateRules"
                              label="Interest Rate (%)"
                              dense
                              clearable                  
                              required />
                  </v-col>
                  <v-col cols="1">
                    <v-btn x-small fab @click.prevent.stop="showHelpInfo( 4 )"><v-icon>fa-regular fa-circle-question</v-icon></v-btn>
                  </v-col>
              </v-row>    
              <v-row dense>
                  <v-col cols="11" class="ma-0 pa-0" >
                        <!--<v-date-picker v-model="startDate" year-icon="mdi-calendar-blank" prev-icon="mdi-skip-previous" next-icon="mdi-skip-next"></v-date-picker>-->
                        <v-menu class="ma-0 pa-0" v-model="startDateMenu" :close-on-content-click="false" transition="scale-transition" min-width="290">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="startDate" @change="updateStartDate" label="Start Date" v-bind="attrs" 
                                    v-on="on" prepend-icon="mdi-calendar" readonly>
                                </v-text-field>
                            </template>
                            <v-date-picker title v-model="startDate" @input="updateStartDate" ></v-date-picker>
                        </v-menu>
                  </v-col>   
                  <v-col cols="1" class="mt-2">
                    <v-btn class="ma-0 pa-0" x-small fab @click.prevent.stop="showHelpInfo( 5 )"><v-icon>fa-regular fa-circle-question</v-icon></v-btn>
                  </v-col>                                   
              </v-row>              
              <!-- property tax field -->
              <v-row dense >
                  <v-col cols="11">
                      <v-currency-field 
                          :valueAsDecimal="true" 
                          :decimalLength=2
                          name="property_tax"
                          prepend-icon="fa-solid fa-house-lock"                  
                          v-model="propertyTax" 
                          :rules="propertyTaxRules"
                          label="Property Tax (Yearly)"
                          dense
                          clearable                  
                          required />
                  </v-col>
                  <v-col cols="1">
                      <v-btn x-small fab @click.prevent.stop="showHelpInfo( 6 )"><v-icon>fa-regular fa-circle-question</v-icon></v-btn>
                  </v-col>
              </v-row>    
              <!-- house insurance field -->
              <v-row dense >
                  <v-col cols="11">
                      <v-currency-field 
                          :valueAsDecimal="true" 
                          :decimalLength=2
                          name="house_insurance"
                          prepend-icon="fa-solid fa-house-tsunami"                  
                          v-model="houseInsurance"
                          :rules="insuranceRules"
                          label="Insurance (Yearly)"
                          dense
                          clearable                  
                          required />
                  </v-col>
                  <v-col cols="1">
                    <v-btn x-small fab @click.prevent.stop="showHelpInfo( 7 )"><v-icon>fa-regular fa-circle-question</v-icon></v-btn>
                  </v-col>
              </v-row>  
              <!-- private mortgage insurance field -->
              <v-row dense >
                  <v-col cols="11">
                      <v-currency-field 
                          :valueAsDecimal="true" 
                          :decimalLength=2
                          name="private_mortgage_insurance"
                          prepend-icon="fa-solid fa-person-shelter"                  
                          v-model="privateMortgageInsurance" 
                          :rules="pmInsuranceRules"
                          label="Private Mortgage Insurance (Yearly)"
                          dense
                          clearable />
                  </v-col>
                  <v-col cols="1">
                    <v-btn x-small fab @click.prevent.stop="showHelpInfo( 8 )"><v-icon>fa-regular fa-circle-question</v-icon></v-btn>
                  </v-col>
              </v-row>   
              <!-- HOA -->                
              <v-row dense >
                  <v-col cols="11">
                      <v-currency-field 
                          :valueAsDecimal="true" 
                          :decimalLength=2
                          name="hoa"
                          prepend-icon="fa-solid fa-people-roof"                  
                          v-model="hoa"
                          :rules="hoaRules"
                          label="HOA (Monthly)"
                          dense
                          clearable />
                  </v-col>
                  <v-col cols="1">
                    <v-btn x-small fab @click.prevent.stop="showHelpInfo( 9 )"><v-icon>fa-regular fa-circle-question</v-icon></v-btn>
                  </v-col>
              </v-row>                                                                      

              <!-- main form control - check for valid form  -->
              <v-row dense >
                 <!-- :disabled="!formValid" -->
                 <v-col cols="4" >
                     <v-btn :disabled="!formValid" type="button" @click.prevent.stop="calculateMortgage">Calculate</v-btn>
                 </v-col>
                 <v-col cols="2" >&nbsp;</v-col>                 
                 <v-col cols="4">
                    <v-btn type="button" @click.prevent.stop="mpFormReset">Reset</v-btn>
                 </v-col>
              </v-row>

            </v-form>
        </v-sheet>
      </v-card-text>
    </v-card>
    <!-- calculate dialog popup showing calcluated monthly and yearly payment break downs  -->
    <!-- https://www.usbank.com/home-loans/mortgage/mortgage-calculators/mortgage-payment-calculator.html 
         https://www.npmjs.com/package/vue-google-charts
    -->
    <v-dialog v-model="dialog" :width="dialogWidth" :height="dialogHeight" >
      <v-card >
          <v-tabs background-color="deep-purple accent-4" dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
              <!-- Monthly calculations -->  
              <v-tab href="#monthly">Monthly <v-icon>fa-solid fa-timeline</v-icon> </v-tab>
              <v-tab-item value="monthly">
                <!-- monthly payment break down card -->
                <v-card >
                    <v-card-title class="text-h5">Monthy Payments</v-card-title>
                    <v-card-text class="ma-0 pa-0">
                        <GChart :resizeDebounce="500" :type="chartType" :data="monthlyChartData" :options="monthlyChartOptions" />
                    </v-card-text>
                </v-card>   
                <v-container fluid >
                    <v-row>
                        <v-col cols="12">
                            <v-responsive class="overflow-y-auto" max-height="calc(90vh - 520px)">
                                <!-- using regular HTML table to format results-->
                                 <template>
                                    <div style="overflow-x:auto;">
                                        <table>
                                            <thead><tr><th>Amount</th><th>Payment</th></tr></thead>
                                            <tbody>
                                                <tr v-for="item in monthlyPayments" :key="item.name">
                                                    <td class="text-left"> {{ item.name }} </td>
                                                    <td class="text-left"> {{ formatNumeric( item.value ) }} </td>                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </template>
                            </v-responsive>
                        </v-col>
                    </v-row>
                </v-container>                             
              </v-tab-item> 
            <!-- yearly payment break down card -->      
            <v-tab href="#yearly">Yearly<v-icon>fa-regular fa-calendar</v-icon> </v-tab>
                <v-tab-item value="yearly">
                    <!-- monthly payment break down card -->
                    <v-card >
                        <v-card-title class="text-h5">Yearly Payments</v-card-title>
                        <v-card-text class="ma-0 pa-0" > 
                            <GChart :resizeDebounce="500" :type="chartType" :data="yearlyChartData" :options="yearlyChartOptions" />
                        </v-card-text>
                    </v-card>  
                    <v-container fluid >
                        <v-row>
                            <v-col cols="12">
                                <v-responsive class="overflow-y-auto" max-height="calc(90vh - 520px)">
                                <!-- using regular HTML table to format results-->
                                <template>
                                    <div style="overflow-x:auto;">
                                        <table>
                                            <thead><tr><th>Amount</th><th>Payment</th></tr></thead>
                                            <tbody>
                                                <tr v-for="item in yearlyPayments" :key="item.name">
                                                    <td class="text-left"> {{ item.name }} </td>
                                                    <td class="text-left"> {{ formatNumeric( item.value ) }} </td>                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </template>
                                </v-responsive>
                            </v-col>
                        </v-row>
                    </v-container>                
                </v-tab-item>               
              <v-spacer></v-spacer>
              <v-btn x-small fab @click.prevent.stop="dialog = false" class="mt-5 mr-5" ><v-icon>fa-regular fa-circle-xmark</v-icon> </v-btn>
          </v-tabs>
          <!-- close placed on outer card -->
          <v-card-actions>
            <v-container fluid >
                <v-row>
                    <v-col cols="3">                    
                        <v-btn elevation="5" outlined color="green darken-1" text @click.prevent.stop="dialog = false" ><v-icon left>fa-regular fa-circle-xmark</v-icon>Close</v-btn>
                    </v-col>
                </v-row>
            </v-container>
          </v-card-actions>
       </v-card>
    </v-dialog>  
  </div>    
</template>

<script>
import { GChart }   from 'vue-google-charts/legacy';

export default {
    components: { GChart },
    data: () => ({
      formValid: false,
      dialog: false,
      dialogWidth:  500,
      dialogHeight: 800,
      isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),   
      homeValue: 100000,
      downPayment: 20000,
      // v-select requires string or object - numbers don't work
      loanYears: ['5','10','15','20','25','30','35','40','45'], 
      selectedTerm: '30',
      loanAmount: 0,
      interestRate: 6.5,
      numberOfPayments: 0,
      startDateMenu: false,
      startDate: new Date().toISOString().substr(0, 10),
      propertyTax: 0,
      houseInsurance: 0,
      privateMortgageInsurance: 0,
      hoa: 0,
      chartType: 'PieChart', 
      monthlyChartData: [ ['Task', 'Monthly Payments'], ['Mortgage', 25.3 ], ['Taxes', 25.3 ], ['Interest', 2],  ['Commute', 2],  ['Watch TV', 2],  ['Sleep', 7] ],
      monthlyChartOptions: { title: 'Payment Breakdown', pieHole: 0.2, is3D: true, chartArea:{ left:15, top:10, width:'100%', height:'100%' } },
      yearlyChartData:  [  ['Task', 'Yearly Payments'], ['Principal', 25.3 ], ['Taxes', 11],  ['Interest', 2],  ['Commute', 2],  ['Watch TV', 2],  ['Sleep', 7] ],
      yearlyChartOptions: { title: 'Yearly Payments', pieHole: 0.2, is3D: true, chartArea:{ left:15, top:10, width:'100%', height:'100%' } },   
      homeValueRules:   [ v => ( !isNaN(parseFloat(v)) && parseFloat(v) > 0 ) || 'Must be a valid home value'],  
      downPaymentRules: [ v => ( !isNaN(parseFloat(v)) && parseFloat(v) > 1 ) || 'Must be a valid down payment amount'],  
      loanTermRules:    [ v => ( !isNaN(parseFloat(v)) && parseFloat(v) > 0 ) || 'Must be a valid loan term'],  
      interestRateRules:[ v => ( !isNaN(parseFloat(v)) && parseFloat(v) > 0 ) || 'Must be a valid interest rate'],  
      propertyTaxRules: [ v => ( !isNaN(parseFloat(v)) && parseFloat(v) > 0 ) || 'Must be a valid property tax amount'], 
      insuranceRules:   [ v => ( !isNaN(parseFloat(v)) && parseFloat(v) > 0 ) || 'Must be a valid home insurance value'], 
      pmInsuranceRules: [ v => ( !isNaN(parseFloat(v))  ) || 'If supplied must be a valid PMI value'],                          
      hoaRules:         [ v => ( !isNaN(parseFloat(v)) && parseFloat(v) >= 0 ) || 'If supplied must be a number'],
      //-- v-data table headers - bottom of Pie chart ----------*
      table_headers:    [ { text: 'Payments',  align: 'center', sortable: false, value: 'name' }, { text: 'Amount',  align: 'center', value: 'value' } ],
      //table_headers:    [ { text: 'Payments', align: 'start', sortable: false, value: 'name' }, { text: 'Amount', value: 'value' } ],      
      // -- pie chart data table arrays -----*
      yearlyPayments:  [ ],
      monthlyPayments: [ ],        
      //--- mortgage payment help message section for each value ---*
      homeValueHelp:   'This is what you pay for the home. It includes both your down payment and the total amount (principal) of your mortgage loan.',
      downPaymentHelp: "This is the amount the buyer has saved and is willing to invest in the property upfront. The average downpayment is around 10%, though the benchmark is 20% to relax PMI requirements.",  
      loanTermHelp:    'The 30-year term is the most common because it has lower monthly payments than the 15-year term does, but the total cost of interest is higher over the course of the mortgage.',
      interestHelp:    "Average mortgage rates vary from day to day, and the rate you're offered will depend on your down payment, credit score, debt and income.",
      startDateHelp:   "Start of your loan.",      
      propertyTaxHelp: "This is a tax that property owners pay to governing authorities. In the U.S., property tax is usually managed by municipal or county governments. All 50 states impose taxes on property at the local level. The annual real estate tax in the U.S. varies by location; on average, Americans pay about 1.1% of their property's value as property tax each year.",
      insuranceHelp:   "Homeowners insurance covers your house and belongings in case of events such as fires, hail, tornadoes and burst pipes.",
      pmiInsuranceHelp:"If you put less than 20% down for a conventional loan, you typically will have to pay for private mortgage insurance, which will be included in your monthly mortgage payment.",
      hoaHelp: "A homeowners association, or HOA, is a resident-run group that governs a neighborhood, condominium complex or other housing development. The association sets rules and collects fees from property owners to pay for amenities, such as pools, parks and walkways. If the home you buy is in an HOA, the fee will count as part of your housing costs.",
      // -- add other default help messages here ...
      noHelpMsg:       'No Help Provided',
      // define search for S3 help files and their positions to update latest text for Mortgage Calculator Component
      helpS3Files: [ [ 'Home_Value.txt',    1 ], 
                     [ 'Down_Payment.txt',  2 ],
                     [ 'Loan_Term.txt',     3 ],
                     [ 'Interest.txt',      4 ],   
                     [ 'Start_Date.txt',    5 ],    
                     [ 'Property_Tax.txt',  6 ],    
                     [ 'Insurance.txt',     7 ],   
                     [ 'PMI_Insurance.txt', 8 ],   
                     [ 'HOA.txt',           9 ],                                                                                        
                   ],
     }),
    //--- to handle completed form - make sure required fields have data -----*
    computed: {
        isFormInValid() {
            //return Object.keys( this.fields ).some( key => this.fields[key].invalid );
            this.isFormInValid = ( this.homeValue > 0 && this.downPayment > 0 && this.propertyTax > 0 );
            console.log( "form is => "+this.isFormInValid )
        },
    },
    beforeMount() {
        // set dialog box to device measurements on init
        this.dialogWidth  = ( this.isMobile ? 350 : 525 );
        this.dialogHeight = ( this.isMobile ? 375 : 575 );  

        // fill in mortgage data elements from previous session data
        this.retrieveSessionMortgageData();        
    },
    mounted() {
        // get help messages from S3 bucket for all mortage field items - for updates
        this.getAllHelpMessages(); 
    },
    //--- method section of data block ---*
    methods: {
        // v-data table header column formatter
        formatNumeric( value ) {
           return "$ " + new Intl.NumberFormat('en-US').format(value);
        },
        // method to calculate user entered data to show graph
        calculateMortgage() {
            this.loanAmount       = ( this.homeValue - this.downPayment );
            //console.log("Loan Amount: "+ this.loanAmount );
            this.numberOfPayments = ( this.selected * 12 );
            //console.log("# of Payments:"+this.numberOfPayments);
            //const loanTerm = parseInt(document.getElementById('loan-term').value);
            //var interestRate = this.interestRate / 100;
            //const propertyTaxYearly = parseFloat(document.getElementById('property-tax').value);
            //const houseInsuranceYearly = parseFloat(document.getElementById('house-insurance').value);
            //const privateMortgageInsuranceMonthly = parseFloat(document.getElementById('private-mortgage-insurance').value);
            //const hoaMonthly = parseFloat(document.getElementById('hoa').value);

            //  after recalculating yearly and monthly from user entered data - show popup dialog
            this.calculateMonthlyAmt();

            this.calculateYearlyAmt();

            // show dialog
            this.dialog = true;

    },
// monthly pie chart settings
calculateMonthlyAmt() {
    try {

    /*
      homeValue: 100000,
      downPayment: 20000,
      selectedTerm: '30',
      loanAmount: 0,
      interestRate: 6.5,
      numberOfPayments: 0,
      startDateMenu: false,
      startDate: new Date().toISOString().substr(0, 10),
      propertyTax: 0,
      houseInsurance: 0,
      privateMortgageInsurance: 0,
      hoa: 0,
   */

//      const homeValue   = parseFloat(document.getElementById('home-value').value);
//      const downPayment = parseFloat(document.getElementById('down-payment').value);
//      const loanTerm    = parseInt(document.getElementById('loan-term').value);
//      const interestRate = parseFloat(document.getElementById('interest-rate').value) / 100;
//      const propertyTaxYearly = parseFloat(document.getElementById('property-tax').value);
//      const houseInsuranceYearly = parseFloat(document.getElementById('house-insurance').value);
//      const privateMortgageInsuranceMonthly = parseFloat(document.getElementById('private-mortgage-insurance').value);
//      const hoaMonthly = parseFloat(document.getElementById('hoa').value);

//      const loanAmount = homeValue - downPayment;
      const monthlyInterestRate   = ( this.interestRate );
        this.numberOfPayments     = ( this.selectedTerm * 12 );
        this.loanAmount           = ( this.homeValue - this.downPayment );
      const monthlyPayment        = ( this.loanAmount * monthlyInterestRate ) /  (1 - Math.pow( 1 + monthlyInterestRate, - this.numberOfPayments ) );
      const propertyTaxMonthly    = ( this.propertyTax / 12 );
      const houseInsuranceMonthly = ( this.houseInsurance / 12 );
      const totalMonthlyExpenses  = ( propertyTaxMonthly + houseInsuranceMonthly + ( this.privateMortgageInsurance / 12 ) + this.hoa );

      const totalMonthlyPayment   = ( monthlyPayment + totalMonthlyExpenses );

      const downPaymentPercentage = ( ( this.downPayment / this.homeValue ) * 100 );

//-------------------------------------------------------------------------

        let monthlyInterest  = ( monthlyInterestRate );
        let monthlyInsurance = ( ( this.privateMortgageInsurance / 12 ) + ( this.houseInsurance / 12 ) );
        let monthlyTax       = ( this.propertyTax / 12 );
        let monthlyHoa       = ( this.hoa );
        let monthlyTotal     = (( parseFloat(monthlyInterest) + parseFloat( monthlyInsurance ) 
                                                              + parseFloat( monthlyTax ) 
                                                              + parseFloat( monthlyHoa )).toFixed(2) );
//const monthlyPayment = (loanAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -numPayments));       

        // --- Pie Chart Data block ----*
        let interest         = ['Interest',  parseFloat(monthlyInterest).toFixed(2) ];
        let insurance        = ['Insurance', parseFloat(monthlyInsurance).toFixed(2)];
        let taxes            = ['Taxes',     parseFloat(monthlyTax).toFixed(2) ];
        let hoa              = ['HOA',       parseFloat(monthlyHoa).toFixed(2) ];
        this.monthlyChartData = [['Task', 'Monthly Payments'], interest, insurance, taxes, hoa ];
        // Data table Block ---*
        var monthlyCosts = [
            { name: 'Mortgage Interest',     value: monthlyInterest },
            { name: 'Insurance',             value: monthlyInsurance },
            { name: 'Property Taxes',        value: monthlyTax },
            { name: 'HOA',                   value: monthlyHoa },
            { name: 'Total Monthly Payment', value: monthlyTotal }
        ];
        // remove any data table elements with 0 amount - since pie chart filters it as well
        this.monthlyPayments = monthlyCosts.filter((obj) => obj.value > 0)

        // save calculated amounts to Session Storage 
        this.updateSessionMortgageData();

    } catch (err) {
        console.log(err);
    }
},
// yearly pie chart settings
calculateYearlyAmt() {
    var yearlyInterestRate = this.interestRate / 100;
    var yearlyPayment = this.loanAmount * (yearlyInterestRate / (1 - Math.pow(1 + yearlyInterestRate, -this.numberOfPayments)));
    //------------------------------------------------
    var yearlyInterest = (this.loanAmount * yearlyInterestRate).toFixed(2);
    var yearlyInsurance = ((this.privateMortgageInsurance + this.houseInsurance));
    var yearlyTax = this.propertyTax;
    var yearlyHoa = (this.hoa * 12);
    var yearlyTotal = ((parseFloat(yearlyInterest) + parseFloat(yearlyInsurance) + parseFloat(yearlyTax) + parseFloat(yearlyHoa)).toFixed(2));
    // --- Pie Chart Data block ----*
    var interest = ['Interest', parseFloat(yearlyInterest)];
    var insurance = ['Insurance', parseFloat(yearlyInsurance)];
    var taxes = ['Taxes', parseFloat(yearlyTax)];
    var hoa = ['HOA', parseFloat(yearlyHoa)];
    this.yearlyChartData = [['Task', 'Yearly Payments'], interest, insurance, taxes, hoa];
    // Data table Block ---*
    var yearlyCosts = [
        { name: 'Mortgage Interest', value: yearlyInterest },
        { name: 'Insurance', value: yearlyInsurance },
        { name: 'Property Taxes', value: yearlyTax },
        { name: 'HOA', value: yearlyHoa },
        { name: 'Total Yearly Payment', value: yearlyTotal }
    ];
    // remove any data table elements with 0 amount - since pie chart filters it as well
    this.yearlyPayments = yearlyCosts.filter((obj) => obj.value > 0)

},
    // save user's entered data to session store - for convienence
    updateSessionMortgageData() {
      sessionStorage.setItem( 'homeValue',      this.homeValue );
      sessionStorage.setItem( 'downPayment',    this.downPayment );
      sessionStorage.setItem( 'selectedTerm',   this.selected );
      sessionStorage.setItem( 'interestRate',   this.interestRate );
      sessionStorage.setItem( 'startDate',      this.startDate  );   
      sessionStorage.setItem( 'propertyTax',    this.propertyTax ),
      sessionStorage.setItem( 'houseInsurance', this.houseInsurance );
      sessionStorage.setItem( 'privateMortgageInsurance', this.privateMortgageInsurance );
      sessionStorage.setItem( 'hoa',            this.hoa );    
    },
    // set mortgage calculator data from Session - called on form load - or reset
    retrieveSessionMortgageData() {
        var homeValue    = sessionStorage.getItem( 'homeValue' )
           if( homeValue )   { this.homeValue  = homeValue }      else { this.homeValue    = 10000 }
        var downPayment  = sessionStorage.getItem( 'downPayment')
           if( downPayment ) { this.downPayment = downPayment }   else { this.downPayment  = 100   }
        var term         = sessionStorage.getItem( 'selectedTerm' )
           if( term )        { this.selected    = term }          else { this.selected     = 30    }
        var interestRate = sessionStorage.getItem( 'interestRate' )
           if( interestRate ){ this.interestRate = interestRate } else { this.interestRate = 6.5   }
        var startDate    = sessionStorage.getItem( 'startDate' )
           if( startDate )   { this.startDate = startDate }       else { this.startDate    = new Date().toISOString().substr(0, 10) }
        var propTax      = sessionStorage.getItem( 'propertyTax' )
           if( propTax )     { this.propertyTax = propTax }       else { this.propertyTax  = 100 }
        var houseIns     = sessionStorage.getItem( 'houseInsurance' )
           if( houseIns )    { this.houseInsurance = houseIns }   else { this.houseInsurance = 100 }
        var privateIns   = sessionStorage.getItem( 'privateMortgageInsurance' );   
           if( privateIns )  { this.privateMortgageInsurance = privateIns } else { this.privateMortgageInsurance = 0 }
        var hoa          = sessionStorage.getItem( 'hoa' );
           if( hoa )         { this.hoa = hoa }                   else { this.hoa = 0 }
    },
    // start date dialog - pass updated date value
    updateStartDate() {
        // close both calendars after calc
        this.startDateMenu = false;
    },
    showPaymentAmount() {
        // show dialog box with updated message depending on field position     
        var hh = "<b>test</b>";     
        this.$swal({
                  position: 'center',
                  allowOutsideClick: true,
                  allowEscapeKey: true,
                  showConfirmButton: true,
                  confirmButtonText: "Ok",
                  icon:  'info',
                  title: "Mortgage Payments",
                  html: "Testno  sporocilo za objekt " + hh + "",  
              }).then((result) => {
                  if( result.isConfirmed ) { }
              })
    },
    // called before mount - pre retrieve files
    getAllHelpMessages() {
        // retrieve all individual help files from S3 document folder
        for( let i = 0; i < this.helpS3Files.length; i++ ) {
            // get file name and data field to make help update
            this.getHelpInfo( "help/"+this.helpS3Files[i][0], this.helpS3Files[i][1] );
            //console.log( "Retrieve-> Help File: "+ this.helpS3Files[i][0] +" Blk: "+ this.helpS3Files[i][1] );
        }
    },
    //--- used in loop to get all mortgage help messages ----*
    getHelpInfo( helpFile, pos ) {
        //this.showCheckToast();   // calendar check message
        this.axios.get('/rs/utils/document?file='+helpFile,{headers:{'api-ref':'000'}}).then((response) => {
                //console.log( response.data  ); // <- later comment out
                let txt = response.data;
                // check for valid txt from server
                if( txt && txt.length > 25 ) {
                    // pass onto update data block
                    this.updateHelpInfo( pos, txt );
                }
          }).catch( err => { 
              // show message - system using old calendar 2023 - not found in S3 bucket
              //this.showErrorToast();   
              console.log( err );
          });
    },
    updateHelpInfo( pos, txt ) {
        switch( pos ) {
            case 1 : { this.homeValueHelp    = txt;  break } 
            case 2 : { this.downPaymentHelp  = txt;  break }  
            case 3 : { this.loanTermHelp     = txt;  break } 
            case 4 : { this.interestHelp     = txt;  break }   
            case 5 : { this.noHelpMsg        = txt;  break }   
            case 6 : { this.propertyTaxHelp  = txt;  break }   
            case 7 : { this.insuranceHelp    = txt;  break }             
            case 8 : { this.pmiInsuranceHelp = txt;  break }    
            case 9 : { this.hoaHelp          = txt;  break }       
            //-- need to add other messages ---*
            default: { helpMsg = this.noHelpMsg }              
        }
    },
    showHelpInfo( pos ) {
        let helpMsg;
        // determine help message by button 
        switch( pos ) {
            case 1 : { helpMsg = this.homeValueHelp;       break } 
            case 2 : { helpMsg = this.downPaymentHelp;     break }  
            case 3 : { helpMsg = this.loanTermHelp;        break } 
            case 4 : { helpMsg = this.interestHelp;        break }  
            case 5 : { helpMsg = this.startDateHelp;       break }   
            case 6 : { helpMsg = this.propertyTaxHelp;     break }  
            case 7 : { helpMsg = this.insuranceHelp;       break }   
            case 8 : { helpMsg = this.pmiInsuranceHelp;    break }    
            case 9 : { helpMsg = this.hoaHelp;             break }                                   
            //-- need to add other messages ---*
            default: { helpMsg = this.noHelpMsg }              
        }
        // show dialog box with updated message depending on field position          
        this.$swal({
                  position: 'center',
                  allowOutsideClick: true,
                  allowEscapeKey: true,
                  showConfirmButton: true,
                  confirmButtonText: "Ok",
                  icon:  'info',
                  title: "Info",
                  text:  helpMsg,
              }).then((result) => {
                  if( result.isConfirmed ) { }
        })
    },
    // called to reset values of form - from previously saved data, or defaults to start over
    mpFormReset() {
        // reset default values from session - if any - or apply default amounts
        this.retrieveSessionMortgageData();  
    }

  }

}
</script>

<style scoped>
    th, td {
        padding-top: 1px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
    }
    table { 
      width: 100%; 
      border-collapse: collapse; 
    }
    /* Zebra striping */
    tr:nth-of-type(odd) { 
       background: #eee; 
    }
    th { 
      background: #333; 
      color: white; 
      font-weight: bold; 
    }
    td, th { 
      padding: 6px; 
      border: 1px solid #ccc; 
      text-align: left; 
    }    
</style>
<!--

      const homeValue = parseFloat(document.getElementById('home-value').value);
      const downPayment = parseFloat(document.getElementById('down-payment').value);

      const loanTerm = parseInt(document.getElementById('loan-term').value);
      const interestRate = parseFloat(document.getElementById('interest-rate').value) / 100;
      const propertyTaxYearly = parseFloat(document.getElementById('property-tax').value);
      const houseInsuranceYearly = parseFloat(document.getElementById('house-insurance').value);
      const privateMortgageInsuranceMonthly = parseFloat(document.getElementById('private-mortgage-insurance').value);
      const hoaMonthly = parseFloat(document.getElementById('hoa').value);

      const loanAmount          = homeValue - downPayment;
      const monthlyInterestRate = interestRate / 12;
      const numPayments         = loanTerm * 12;

      const monthlyPayment = (loanAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -numPayments));

      const propertyTaxMonthly = propertyTaxYearly / 12;
      const houseInsuranceMonthly = houseInsuranceYearly / 12;
      const totalMonthlyExpenses = propertyTaxMonthly + houseInsuranceMonthly + privateMortgageInsuranceMonthly + hoaMonthly;

      const totalMonthlyPayment = monthlyPayment + totalMonthlyExpenses;

      const downPaymentPercentage = (downPayment / homeValue) * 100;



-->
