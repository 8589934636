<!--
     App Bar Left Drawer Menu Options - mobile & small devices only
-->
<template>
    <div class="mt-0 pt-0">
        <v-container class="mt-0 mb-0 ml-0 pl-0 mr-0 pr-0">
            <v-row dense >
                  <v-btn :to="{name: 'home'}" plain class="mr-0 mb-0 pr-0 pb-0" >
                    <v-img src="@/assets/kCalc_logo_64.png" max-height="32" max-width="32"></v-img> 
                  </v-btn>
                  <h3 class="ml-0 pl-0 mb-0 pb-0 white--text">{{ this.app_title }}</h3>  
            </v-row>
            <v-row dense justify="center" class="mt-0 pt-0 mb-0 pb-0">
                <DigitalClock class="mt-0 pt-0 mb-0 pb-0" />
            </v-row>
        </v-container>                   
        <v-divider dark style="height:15px;background-color:aqua" class="ma-0 pa-0" ></v-divider>  
        <!-- Investment tools nav bar group -->
            <!-- show static menu of 'tools' - close Favorites list to force user to click the main group link for updates -->         
            <v-list-group class="ml-0 pl-1 text-left overflow-y-auto" no-action sub-group @click.stop="groupOpened = false">
                <template v-slot:activator> 
                    <v-list-item class="ml-0 pl-0 mr-0 pr-0" @click="groupOpened = false; checkBookmarks( 'Investing Tools' )">
                        <v-icon left color="white" class="pl-0 pr-0 ml-0 mr-0">mdi-tools</v-icon>                       
                        <v-list-item-content>
                            <v-list-item-title class="ListItemClassInActive">&nbsp;Investment Tools</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-list-item v-for="tool in tools" :id="tool.id" :key="tool.id" :to="tool.route" @click.stop="groupOpened = false; checkBookmarks( 'Investing Tools' )">
                    <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-3">
                        <v-icon left v-text="tool.icon"></v-icon>
                    </v-list-item-icon>                    
                    <v-list-item-title class="ListItemClassInActive subListItem" v-text="tool.title" ></v-list-item-title>
                </v-list-item>
            </v-list-group>
            <!-- Live Data Charts Group-->
            <!-- show static menu of 'Live Charts' - close Favorites list to force user to click the main group link for updates -->             
            <v-list-group class="text-left ml-0 pl-1" no-action sub-group @click.stop="groupOpened = false; checkBookmarks( 'Live Data Charts' )">
                <template v-slot:activator>
                    <v-list-item class="ml-0 pl-0 mr-0 pr-0" @click="groupOpened = false">
                        <v-icon left color="white" class="pl-0 pr-0 ml-0 mr-0" >mdi-chart-multiple</v-icon>                       
                        <v-list-item-content>
                            <v-list-item-title class="ListItemClassInActive">&nbsp;Live Data Charts</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                    <v-list-item v-for="chart in charts" :id="chart.id" :key="chart.id" :to="chart.route" @click="groupOpened = false; checkBookmarks( 'Live Data Charts' )">
                        <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">
                            <v-icon left v-text="chart.icon"></v-icon>
                        </v-list-item-icon>                    
                        <v-list-item-title class="ListItemClassInActive subListItem" v-text="chart.title" ></v-list-item-title>
                    </v-list-item>
            </v-list-group>            
            <!-- News Options RSS etc -->
            <!-- show static menu of 'News' - close Favorites list to force user to click the main group link for updates -->             
            <v-list-group class="text-left ml-0 pl-1" no-action sub-group @click.stop="groupOpened = false; checkBookmarks( 'Latest News' )">
                <template v-slot:activator>
                    <v-list-item class="ml-0 pl-0 mr-0 pr-0" @click="groupOpened = false">
                        <v-icon left color="white" class="pl-0 pr-0 ml-0 mr-0" >mdi-newspaper-variant-multiple-outline</v-icon>                       
                        <v-list-item-content>
                            <v-list-item-title class="ListItemClassInActive">&nbsp;Investing News</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-list-item v-for="n in news" :id="n.id" :key="n.id" :to="n.route" @click="groupOpened = false; checkBookmarks( 'Latest News' )">
                    <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">
                            <v-icon left v-text="n.icon"></v-icon>
                    </v-list-item-icon>                    
                    <v-list-item-title class="ListItemClassInActive subListItem" v-text="n.title" ></v-list-item-title>
                </v-list-item>
            </v-list-group> 
            <!-- VIDEO -->
            <!-- show static menu of 'Video Clips' - close Favorites list to force user to click the main group link for updates -->              
            <v-list-group class="text-left ml-0 pl-1 " no-action sub-group @click.stop="groupOpened = false; checkBookmarks( 'Latest Videos' )">
                <template v-slot:activator>
                    <v-list-item class="ml-0 pl-0 mr-0 pr-0" @click="groupOpened = false">
                        <v-icon left color="white" class="pl-0 pr-0 ml-1 mr-1" >fa-regular fa-file-video</v-icon>                       
                        <v-list-item-content>
                            <v-list-item-title class="ListItemClassInActive">&nbsp;Video</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-list-item v-for="n in video" :id="n.id" :key="n.id" :to="n.route" @click="groupOpened = false; checkBookmarks( 'Latest Videos' )">
                    <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">
                        <v-icon left v-text="n.icon"></v-icon>
                    </v-list-item-icon>                    
                    <v-list-item-title class="ListItemClassInActive subListItem" v-text="n.title" ></v-list-item-title>
                </v-list-item>
            </v-list-group>               
            <!-- PODCASTS -->
            <!-- show static menu of 'Podcasts' - close Favorites list to force user to click the main group link -->            
            <v-list-group class="text-left ml-0 pl-1 " no-action sub-group @click.stop="groupOpened = false; checkBookmarks( 'Investing Podcasts' )">
                <template v-slot:activator>
                    <v-list-item class="ml-0 pl-0 mr-0 pr-0" @click="groupOpened = false">
                        <v-icon left color="white" class="pl-0 pr-0 ml-0 mr-0" >mdi-podcast</v-icon>                       
                        <v-list-item-content>
                            <v-list-item-title class="ListItemClassInActive">&nbsp;Podcasts</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-list-item v-for="n in podcasts" :id="n.id" :key="n.id" :to="n.route" @click="groupOpened = false; checkBookmarks( 'Investing Podcasts' )">
                    <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">
                        <v-icon left v-text="n.icon"></v-icon>
                    </v-list-item-icon>                    
                    <v-list-item-title class="ListItemClassInActive subListItem" v-text="n.title" ></v-list-item-title>
                </v-list-item>
            </v-list-group> 
            <!-- TRENDS Removed 03-20-24 Data Issue API Key PM -->
        <!-- show static menu of 'Trends' tables - close Favorites list to force user to click the main group link 
            <v-list-group class="text-left ml-0 pl-1 mb-0 pb-0" no-action sub-group @click.stop="groupOpened = false">
                <template v-slot:activator>
                    <v-list-item class="ml-0 pl-0 mr-0 pr-0" @click="groupOpened = false">
                        <v-icon left color="white" class="pl-0 pr-0 ml-0 mr-0" >mdi-chart-bar-stacked</v-icon>                       
                        <v-list-item-content>
                            <v-list-item-title class="ListItemClassInActive">&nbsp;Market Trends</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-list-item v-for="n in trends" :key="n.id" :to="n.route" @click="groupOpened = false">
                    <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">
                        <v-icon left v-text="n.icon"></v-icon>
                    </v-list-item-icon>                    
                    <v-list-item-title class="ListItemClassInActive subListItem" v-text="n.title" ></v-list-item-title>
                </v-list-item>
            </v-list-group> 
        -->
             <!-- Favorites / with or without bookmarks broken out - added v-model to close 'v-list-group' after selection
              as outlined here [ https://stackoverflow.com/questions/71677079/v-list-close-group-when-list-item-is-clicked  ]
            -->
             <div v-if="hasBookmarks" >
                <!-- check for any new bookmarks stored in localStorage - dynamic listing -->
                <v-list-group v-model="groupOpened" class="text-left ml-0 pl-1" @click.stop="getBookMarks" no-action sub-group>
                    <template v-slot:activator>
                        <v-list-item class="ml-0 pl-0 mr-0 pr-0">
                            <v-icon left color="white" class="pl-0 pr-0 ml-0 mr-0" >fa-solid fa-heart-circle-check</v-icon>                       
                            <v-list-item-content>
                                <v-list-item-title class="ListItemClassInActive">&nbsp;Favorites</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-list-item v-for="n in favorites" :key="n.id" :to="n.route" @click="groupOpened = false">
                        <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">
                            <v-icon left v-text="n.icon"></v-icon>
                        </v-list-item-icon>                    
                        <v-list-item-title class="ListItemClassInActive subListItem" v-text="n.title" ></v-list-item-title>
                    </v-list-item>
                </v-list-group>  
            </div>
            <div v-else >
                <!-- empty Favorites - display main button only -->
                <v-list-group v-model="groupOpened" class="text-left ml-0 pl-1" @click.stop="getBookMarks" no-action sub-group>
                    <template v-slot:activator>
                        <v-list-item class="ml-0 pl-0 mr-0 pr-0" >
                            <v-icon left color="white" class="pl-0 pr-0 ml-0 mr-0" >fa-solid fa-heart-circle-check</v-icon>                       
                            <v-list-item-content>
                                <v-list-item-title class="ListItemClassInActive">&nbsp;Favorites</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>  
                </v-list-group>              
            </div>
            <!-- Social Media Section instead of Footer component - HARD CODED not using arrays -->
            <v-list-group class="text-left ml-0 pl-1 mb-10 pb-10" @click.stop="groupOpened = false"  no-action sub-group >
                <!-- Facebook hard coded for Left Drawer Menu -->
                <template v-slot:activator>
                    <v-list-item class="ml-0 pl-0 mr-0 pr-0" @click="groupOpened = false">
                        <v-icon left color="white" class="pl-0 pr-0 ml-0 mr-0" >fa-solid fa-person-walking-dashed-line-arrow-right</v-icon>                       
                        <v-list-item-content>
                            <v-list-item-title class="ListItemClassInActive">&nbsp;Follow Us ...</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <!-- FaceBook X -->
                <v-list-item @click.prevent.stop="openFacebook">
                    <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">
                        <img src="@/assets/facebook_24icon.png" />
                    </v-list-item-icon>   
                    <v-list-item-content>                 
                       <v-list-item-title class="ListItemClassInActive subListItem">&nbsp;FaceBook / Meta</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- Instagram -->
                <v-list-item @click.prevent.stop="openInstagram">
                    <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">
                        <img src="@/assets/instagram_24icon.png" />
                    </v-list-item-icon>   
                    <v-list-item-content>                 
                       <v-list-item-title class="ListItemClassInActive subListItem">&nbsp;Instagram</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- Pinterest add 10 to mb and pb to leave space at end of drawer -->
                <v-list-item @click.prevent.stop="openPinterest" >
                    <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">
                        <img src="@/assets/pinterest_24icon.png" />
                    </v-list-item-icon>   
                    <v-list-item-content>                 
                       <v-list-item-title class="ListItemClassInActive subListItem">&nbsp;Pinterest</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>     
                <!-- Threads -->
                <v-list-item @click.prevent.stop="openThreads" class="mb-10 pb-10">
                    <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">
                        <img src="@/assets/threads_24icon.png" />
                    </v-list-item-icon>   
                    <v-list-item-content>                 
                       <v-list-item-title class="ListItemClassInActive subListItem">&nbsp;Threads</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>                                                  
            </v-list-group> 
    </div>
    <!-- end of drawer navigation element --> 
</template>
<script>
  import { eventBus }      from '@/main'  // event bus communication for registered components
  /* using i18n to pull from locale settings to load 'src/locals/xx.json' file for label settings */
  //import i18n from "@/i18n"
  // digital clock
  import DigitalClock      from "@/components/DigitalClock.vue"
  
  // apply bookmark CSS
  import $ from 'jquery';

  /* very important to load menu items from javascript external data file - 1 place  */
  import toolsMenuItems    from '@/components/menus/Data_Arrays/tools_menu'
  import chartsMenuItems   from '@/components/menus/Data_Arrays/charts_menu'
  import newsMenuItems     from '@/components/menus/Data_Arrays/news_menu'  
  import videoMenuItems    from '@/components/menus/Data_Arrays/video_menu'    
  import podcastsMenuItems from '@/components/menus/Data_Arrays/podcasts_menu' 
  import trendsMenuItems   from '@/components/menus/Data_Arrays/trends_menu' 
  //----------------------------------------
  //import socialMediaLinks  from '@/components/widgets/socialMediaLinks.vue' 

  export default {
    head: {
      script: [
        { src: '@/components/menus/Data_Arrays/tools_menu.js',          async: true, defer: true },
        { src: '@/components/menus/Data_Arrays/charts_menu.js',         async: true, defer: true },
        { src: '@/components/menus/Data_Arrays/news_menu.js',           async: true, defer: true },  
        { src: '@/components/menus/Data_Arrays/video_menu.js',          async: true, defer: true },
        { src: '@/components/menus/Data_Arrays/podcasts_menu.js',       async: true, defer: true },
        { src: '@/components/menus/Data_Arrays/trends_menu.js',         async: true, defer: true },      
      ],
    },
    components: { DigitalClock },
    name: "AppBarLeftDrawer",
    data: () => ({
          drawer: false,
          group: null,
          tabs: null,
          hasBookmarks: false,
          groupOpened:  false,  // track menu group
          app_title: process.env.VUE_APP_TITLE,
          company: process.env.VUE_APP_LLC,          
          // determines on object creation if running on mobile device true or false - although hard coded init
          isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),            
          // this array is used only on the left drawer menu for mobile devices
          // *** see 'AppBarToolsMenu' and 'AppBarReportsMenu' for desktop machines menu options
          tools:       toolsMenuItems,
          charts:      chartsMenuItems,
          news:        newsMenuItems,
          video:       videoMenuItems,
          podcasts:    podcastsMenuItems,
          trends:      trendsMenuItems,  
          // get user selected bookmarks ( menu item )
          favorites:    [],
          facebookSite:   [ { count:0,title:"Facebook",text:"Follow Us ... on Facebook/Meta?",
                url:'https://www.facebook.com/profile.php?id=100093600655683'},
          ],
          instragramSite: [ { count:0,title:"Instagram",text:"Follow Us ... on Instagram?",
                url:'https://www.instagram.com/insightsoftwarellc/?fbclid=IwAR3WGkqiaDWKHAeXN71uoia5HjkDceOiHDsJCVwq5eStxPJyxJ8QyJaWqB0'},
          ],
          threadsSite:    [ { count:0,title:"Threads",text:"Follow Us ... on Threads?", url:'https://www.threads.net/@insightsoftwarellc'}, 
          ],
          pintrestSite:   [ { count:0,title:"Pinterest",text:"Follow Us ... on Pinterest?", url:'https://www.pinterest.com/insightsoftwarellc'}, 
          ],
          redditSite:     [ { count:0,title:"Reddit",text:"Follow Us ... on Reddit?", url:''}, ]         
    }),
    mounted: function() {
       // setup popup dialog listener when component is added to footer template
       eventBus.$on( "open-drawer", this.closeFavorites );
    },    
    methods: {
        getBookMarks() {
            // get localStorage Bookmark list - if any
            this.favorites  = JSON.parse( localStorage.getItem("bookmarks") || "[]" );
            if( this.favorites && this.favorites.length > 0 ) {
                this.hasBookmarks = true;
            } else {
                this.hasBookmarks = false;
            }
          return( this.hasBookmarks );
        },
        closeFavorites() {
            this.groupOpened = false;
            //console.log( "groupFavorites called ..." );
        },
        openFacebook() {
            // update site count so confirm question is asked only 1x - use javascript 'spread' ...
            this.confirmOpenSite( ...this.facebookSite ) 
            //this.groupOpened = false;
        },          
        openInstagram() {
            // update site count so confirm question is asked only 1x - use javascript 'spread' ...
            this.confirmOpenSite( ...this.instragramSite ) 
            //this.groupOpened = false;
        },
        openThreads() {
            // update site count so confirm question is asked only 1x - use javascript 'spread' ...
            this.confirmOpenSite( ...this.threadsSite ) 
            //this.groupOpened = false;            
        },
        openPinterest() {
            // update site count so confirm question is asked only 1x - use javascript 'spread' ...
            this.confirmOpenSite( ...this.pintrestSite ) 
            //this.groupOpened = false;           
        },            
        openReddit() {
            // update site count so confirm question is asked only 1x - use javascript 'spread' ...
            this.confirmOpenSite( ...this.redditSite ) 
            //this.groupOpened = false;       
        },
        // asked 1x for every defined social media site 
        confirmOpenSite( siteArray ) {
            // ask to confirm only 1x
            if( siteArray.count < 1 ) {
                this.$swal({
                    position: 'center',
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    confirmButtonText: "Yes",
                    showCloseButton: true,
                    showCancelButton: true,
                    cancelButtonText: "No",
                    icon: 'question',
                    title: siteArray.title,
                    text:  siteArray.text,
                    footer: "This will open another 'tab' for [ "+siteArray.title+" ]",
                }).then((result) => {
                    if( result.isConfirmed ) { 
                        siteArray.count += 1;
                        window.open( siteArray.url,'_blank').focus();
                    }
                })
            } else {
                // open site without dialog - asked previously and confirmed
                siteArray.count += 1;
                window.open( siteArray.url,'_blank').focus();
            }  
        },
        //*** ---- highlight menu items with bookmarks - dynamic  ---------*
        checkBookmarks( tGroup ) {
            //------------------------------------------------------------------------*       
            var bookMarks  = JSON.parse( localStorage.getItem('bookmarks') || "[]" );
            setTimeout(function() {
                bookMarks.forEach((bMark) => {
                    // filter only check - Investing Tools group here
                    if( bMark.group === tGroup ) {
                        // filter through the keys to re-mark bookmark
                        Object.entries(bMark).forEach(([ key, value ]) => {
                            if( key === 'id' ) { //v-list-item v-list-item--link //element.classList.toggle(className)
                                $("#"+value ).addClass("bookmarked");
                            }
                        });  
                    }
                })
            }, 250 );
          }        
               
    },
    beforeMount() {
       this.getBookMarks();
    }
 }
</script>

<style scoped>
  .bookmarked {
    border-right: 5px solid rgb(76, 226, 76);
    z-index: 99999;
  }
  .v-list-group__header .v-list-item .v-list-item--link .theme--light  {
    margin-left: 0;
    margin-inline-start: 0;
    padding-left: 0;
    padding-inline-start: 0;
  }
  .v-list-item__icon .v-list-group__header__prepend-icon {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
  } 
  .v-icon .notranslate .mdi .mdi-menu-down {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
  }
  .ListItemClassActive {
    color: #0755f3;
  }
  .ListItemClassInActive {
    color: #95d4e7;
   }
  
  .v-list-item {
     /*background: lightgray;*/
     color: rgb(141, 141, 242);
     font-size: 14px;
  }
  
  .v-list-item--active .subListIcon .v-icon {
     background-color: lightgreen;
  }
  .v-list-item--active .v-list-item__title {
     color: rgb(253, 250, 250);
     font-weight: bold;
  } 
  .subListIcon {
    font-size: 12px;
  }  
  .subListItem {
    font-size: 14px;
  }   
  .v-list-group__header .v-list-item .v-list-item--active .v-list-item--link .theme--light {
    margin-left:  0;
    padding-left: 0;
  }
</style>