<!-- 
    popup Contact Us dialog

    limited to 500 character message
-->
<template>
  <v-dialog v-model="dialog" persistent max-width="600px" :key="componentKey" >
    <v-form ref="contactForm" @submit.prevent.stop v-model="valid" >
      <v-card>
        <v-card-title class="ma-0 pa-0">
            <v-img class="white--text align-center" width="auto" height="75px" 
              src="@/assets/contact-us.jpg" >        
                <v-card-title>
                  <span class="text-h5">{{$t("footer.contact")}}</span>
                </v-card-title>
            </v-img>    
        </v-card-title>        
        <v-card-text class="mt-2">
            <v-card-subtitle class="hidden-md-and-down" >{{$t('contact_dialog.dialog_subtitle')}}</v-card-subtitle>
              <v-row dense>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-text-field 
                        name="contact_firstName"
                        prepend-icon="mdi-face-man" 
                        :label="$t('contact_dialog.first_nm_label')"
                        v-model="form.contact_firstName"
                        :rules="contact_firstNameRules"
                        clearable
                        :counter="35"
                        maxlength="35"
                        required >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-text-field prepend-icon="fa-id-badge"
                      name="contact_lastName"
                      :label="$t('contact_dialog.last_nm_label')"
                      v-model="form.contact_lastName"
                      :rules="contact_lastNameRules"                    
                      :counter="50"
                      maxlength="50"
                      persistent-hint
                      clearable
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field prepend-icon="fa-at"
                      name="contact_email"
                      :label="$t('contact_dialog.email_label')"
                      type="email"
                      v-model="form.contact_email"
                      :rules="contact_emailRules"                    
                      :counter="100"
                      maxlength="100"
                      clearable
                      required
                    ></v-text-field> 
                  </v-col>
                  <v-col cols="12">
                    <v-text-field prepend-icon="fa-mobile-screen"
                      name="contact_phone_no"
                      :label="$t('contact_dialog.phone_label')"
                      v-mask="'##-###-###-####'"
                      hint="optional"
                      type="phone"
                      clearable
                      v-model="form.contact_cellPhone"
                      :counter="15"
                      maxlength="15"
                    ></v-text-field>
                  </v-col> 
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-textarea outlined 
                        clearable
                        name="contact_message"
                        :label="$t('contact_dialog.message_label')"
                        v-model="form.contact_message"
                        :counter="500"
                        :hint="$t('contact_dialog.message_req')"
                        :rules="contact_messageRules"
                        maxlength="501"
                        required  >
                    </v-textarea>
                  </v-col>                 
              </v-row>
          <small>{{$t("contact_dialog.indicate_msg")}}</small>
        </v-card-text>
        <v-divider class="ma-0 pa-0"></v-divider>
        <v-card-actions>
          <v-btn small color="blue darken-1" outlined text-sm :disabled="!valid" @click.stop="submitForm" >
              <v-icon left dark>fa-solid fa-upload</v-icon>
              <!-- set size of font to 8px to allow spanish to fit size-->
              <span style="font-size: 8px">{{$t("contact_dialog.button_send")}}</span>
          </v-btn>          
          <v-btn small color="red darken-1" outlined text-sm @click.stop="cancelDialog">
              <v-icon left dark>fa-solid fa-ban</v-icon>          
              <span style="font-size: 8px">{{$t("contact_dialog.button_cancel")}}</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn small color="orange" outlined text-sm @click.stop="resetContactForm" >
             <v-icon left dark>fa-solid fa-rotate-right</v-icon>
             <span style="font-size: 8px">{{$t("contact_dialog.button_reset")}}</span>
          </v-btn>                    
        </v-card-actions>
      </v-card>
    </v-form>      
  </v-dialog>
</template>

<script>
  // listen for global event calls called from FooterBar - or from where ever
  import { eventBus } from '@/main'  // event bus communication for registered components
  import i18n from "@/i18n"          // i18n international language support for labels
  // axios api services
  import apiService from '@/services/apiServices.js'

  export default {
    name: "ContactInfo",
    data: () => ({
      componentKey: 0, // used to refresh - rerender component
      dialog: false,   // shows'true' or closes dialog 'false'
      valid: false,    // form validation set to false as default
      form: {
        contact_firstName: '',
        contact_lastName: '',
        contact_email: '',
        contact_cellPhone: '01',
        contact_message: '',
      },
      errors: {},
      contact_firstNameRules: [
        v => !!v || i18n.t('contact_dialog.first_nm_req'),
        v => (v && v.length <= 35) || i18n.t('contact_dialog.first_nm_error'),
      ],
      contact_lastNameRules: [
        v => !!v || i18n.t('contact_dialog.last_nm_req'),
        v => (v && v.length <= 50) || i18n.t('contact_dialog.last_nm_error'),
      ],
      contact_emailRules: [
        v => !!v || i18n.t('contact_dialog.email_req'),
        v => /.+@.+/.test(v) || i18n.t('contact_dialog.email_invalid'),
        v => (v && v.length <= 100 ) || i18n.t('contact_dialog.email_error'),
      ],     
      contact_phoneRules: [
        v => !!v || i18n.t('contact_dialog.phone_req'),
        v => (v && v.length <= 25 ) || i18n.t('contact_dialog.phone_error'),
      ],
      contact_messageRules: [
        v => !!v || i18n.t('contact_dialog.message_req'),
        v => (v && v.length > 10)  || i18n.t('contact_dialog.message_req'),
        v => (v && v.length <= 500) || i18n.t('contact_dialog.message_error'),
      ],                     
    }),
    created: function() {
      // setup popup dialog listener when component is added to footer template
        eventBus.$on("show-contact", this.showContactInfo );
    },
    destroyed() {
      // Removes Event Bus listener upon removal of component
        eventBus.$off("show-contact");
      },
    methods: {
      //-----------------------------------------------
      submitForm() {
            // check for any XSS tags inserted in form data using - from utils.js
            if( checkForSpecialChar( this.form.contact_firstName ) 
                 || checkForSpecialChar( this.form.contact_lastName ) 
                    || checkForSpecialChar( this.form.contact_email ) 
                       || checkForSpecialChar( this.form.contact_message ) ) {
                // alert message - invalid data XSS in form element
                this.$swal({ icon: "error", title: "Oops...", text: "Invalid Contact Information!", footer: 'Only Valid Contact Information is Accepted' });
                // reset form - remove data
                this.resetContactForm();
            } else {
              this.$swal({
                  position: 'center',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showCancelButton: true,  
                  cancelButtonText: i18n.t('contact_dialog.send_cancel_button'),              
                  showConfirmButton: true,
                  confirmButtonText: i18n.t('contact_dialog.send_ok_button'),
                  icon: 'question',
                  title: i18n.t('contact_dialog.send_info_title'),
                  text: i18n.t('contact_dialog.send_info_subtitle'),
                  footer: i18n.t('contact_dialog.send_footer'),
              }).then((result) => {
                  if( result.isConfirmed ) {
                      // *** submit email contact info from object form section *** 
                      var pkg = JSON.stringify( this.form );  
                      // -- call service and pass json package to server --*                
                      apiService.sendContactInfo( pkg )
                          .then((res) => {
                              // Show success message
                              this.$swal.fire({
                                icon: 'success',
                                title: i18n.t('contact_dialog.sent_title'),
                                text: i18n.t('contact_dialog.sent_subtitle'),                        
                                footer: i18n.t('contact_dialog.sent_footer')
                              }).then((result) => {
                                if( result ) {
                                  // reset contact form - clear values
                                  this.resetContactForm();
                                  // close contact dialog to stop multiple submits
                                  this.dialog = false;
                                }
                              }); 
                              console.log(res);   
                        }).catch((err) => {
                              this.$swal.fire({
                                icon: 'error',
                                title: i18n.t('contact_dialog.sent_err_title'),
                                text: err,                        
                                footer: i18n.t('contact_dialog.sent_err_footer')
                              }).then((result) => {
                                if( result ) {
                                    console.log( err );
                                }
                              });    
                        }).finally(() => {
                          // any other clean up can go here
                        })  
                  }                
              })
          }            
      },
      //-----------------------------------------------
      showContactInfo() {
        // basically sets title from international locale files
        this.dialog_title=i18n.t("footer.contact");
        // basically just sets show = true to show dialog
        this.dialog=true;
      },
      resetContactForm() {
        this.$refs.contactForm.reset();
        // reset country code back to US - 01
        this.form.contact_cellPhone ="01";
      },
      cancelDialog() {
        this.resetContactForm();
        this.dialog = false;  // close dialog
        this.forceRerender(); // clear values
      },
      // -- method to tell vue to re-render component - clear values ***
      // as noted on [ https://michaelnthiessen.com/force-re-render/  ]
      forceRerender() {
         this.componentKey += 1;
      }      
    }
  }
</script>

