<!--
           FROM / TO DATE COMPONENT TAB
        hosted in the DateTimeView.vue parent view
-->
<template>
    <v-card color="#D6EAF8" style="position: relative" >
        <v-card-title class="text-h6 justify-center">(From - To) Days</v-card-title>
            <v-card-text style="height:100%;width:100%">
                <v-row justify="center" >                      
                    <v-col cols="8" sm="8" md="4" lg="4" >
                        <v-menu v-model="fmtoStartMenu" :close-on-content-click="false" transition="scale-transition" min-width="290">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field 
                                    v-model="fmtoStartDate" 
                                    @change="calculateDays"
                                    :label="$t('date_time_page.range_start_date_lbl')"                                     
                                    v-bind="attrs" 
                                    v-on="on" prepend-icon="mdi-calendar" readonly>
                                </v-text-field>
                            </template>
                            <v-date-picker title v-model="fmtoStartDate" @input="calculateDays" :locale="currentLocale" ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" >
                        <v-menu v-model="fmtoEndMenu" :close-on-content-click="false" transition="scale-transition" min-width="290">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-model="fmtoEndDate" 
                                @change="calculateDays"
                                :label="$t('date_time_page.range_end_date_lbl')"                                 
                                locale="en"
                                v-bind="attrs" 
                                v-on="on" prepend-icon="mdi-calendar" readonly>
                            </v-text-field>
                            </template>
                            <v-date-picker title v-model="fmtoEndDate" @input="calculateDays" :locale="currentLocale"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" >
                        <v-currency-field 
                            name="days" 
                            :label="$t('date_time_page.range_days_lbl')" 
                            :allow-negative="true"                
                            :decimalLength=0
                            :value-as-integer="true"                              
                            prepend-icon="mdi-calculator-variant-outline"
                            v-model="daysFromTo"
                            readonly
                            required />
                    </v-col>
                </v-row>                   
            </v-card-text>
    </v-card> 
</template>

<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    //import i18n from "@/i18n"   
    //import DatePicker from "@/components/DatePicker"; - template component not used
    import dayjs from "dayjs";       

    export default {
      data: () => ({
        currentLocale: '',
        fmtoStartMenu: false,
        fmtoEndMenu: false,
        fmtoStartDate: new Date().toISOString().substr(0, 10),
        fmtoEndDate: new Date().toISOString().substr(0, 10),
        daysFromTo : '', 
      }),
      // set locale when the object is mounted so date is displayed in correct language
      beforeMount: function() {
        this.currentLocale = this.$i18n.locale;
        //console.log('Before mount try to change language from setting: '+this.currentLocale );                
      },      
      mounted() {
        // make sure library 'days.js' calculations match current locale settings
        dayjs.locale( this.$i18n.locale );
      },      
      methods: {
        calculateDays() {
            // using dayjs plugin library for calculations
            if( this.fmtoStartDate != null && this.fmtoEndDate != null ) {
                var start       = dayjs( this.fmtoStartDate );    
                var end         = dayjs( this.fmtoEndDate );                
                this.daysFromTo = ( end.diff( start ) / 86400000 );
                //console.log( "Day difference = "+ end.diff( start ) / 86400000 );
            } else {
                this.daysFromTo = '';
            }
            // close both calendars after calc
            this.fmtoStartMenu = false;
            this.fmtoEndMenu   = false;
        },                       
      },
    
    }

</script>