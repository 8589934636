import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticStyle:{"position":"relative"},attrs:{"color":"#C9F9F7"}},[_c(VCardTitle,{staticClass:"text-h6 justify-center"},[_vm._v(_vm._s(_vm.$t("stock_prct_prce_page.prct_tab_subtitle")))]),_c(VCardText,{staticStyle:{"height":"100%","width":"100%"}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"8","sm":"8","md":"6","lg":"4"}},[_c('v-currency-field',{attrs:{"name":"currentPrice","label":_vm.$t('stock_prct_prce_page.prct_tab_crnt_prce_lbl'),"allow-negative":false,"decimalLength":2,"value-as-integer":false,"prepend-icon":"mdi-currency-usd","clearable":"","rules":_vm.currentPrice_Rules,"required":""},on:{"blur":_vm.calculateGainLoss},model:{value:(_vm.currentPrice),callback:function ($$v) {_vm.currentPrice=$$v},expression:"currentPrice"}})],1),_c(VCol,{attrs:{"cols":"8","sm":"8","md":"6","lg":"4"}},[_c('v-currency-field',{attrs:{"name":"expectedPrice","label":_vm.$t('stock_prct_prce_page.prct_tab_expt_prce_lbl'),"allow-negative":false,"decimalLength":2,"value-as-integer":false,"prepend-icon":"mdi-hand-heart-outline","clearable":"","rules":_vm.expectedPrice_Rules,"required":""},on:{"blur":_vm.calculateGainLoss},model:{value:(_vm.expectedPrice),callback:function ($$v) {_vm.expectedPrice=$$v},expression:"expectedPrice"}})],1),_c(VCol,{attrs:{"cols":"8","sm":"8","md":"6","lg":"4"}},[_c('v-currency-field',{attrs:{"name":"percentGainLoss","label":_vm.$t('stock_prct_prce_page.prct_tab_gain_loss_lbl'),"allow-negative":true,"decimalLength":2,"value-as-integer":false,"prepend-icon":"mdi-percent-outline","readonly":"","required":""},model:{value:(_vm.percentGainLoss),callback:function ($$v) {_vm.percentGainLoss=$$v},expression:"percentGainLoss"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }