<!--
    Stock Price Calculator Tab hosted in '/views/StockPercentagePriceView.vue'
-->
<template>
    <v-card color="#D7FCFB" style="position: relative" >
        <v-card-title class="text-h6 justify-center">{{$t("stock_prct_prce_page.prce_tab_subtitle")}}</v-card-title>
            <v-card-text style="height:100%;width:100%">
                <v-row justify="center" >                      
                    <v-col cols="8" sm="6" md="6" lg="4" >
                        <v-currency-field 
                            name="currentPrice" 
                            :label="$t('stock_prct_prce_page.prce_tab_crnt_prce_lbl')" 
                            :allow-negative="true"                
                            :decimalLength=2
                            :value-as-integer="false"                              
                            prepend-icon="mdi-currency-usd"
                            v-model="currentPrice"
                            clearable
                            :rules="currentPrice_Rules"                              
                            @blur="calculateGainLoss"
                            required />
                    </v-col>
                    <v-col cols="8" sm="6" md="6" lg="4" >
                        <v-container fluid class="ma-0 pa-0" >
                            <v-layout align-content-start >
                                <v-row no-gutters dense>
                                    <v-col cols="3">   
                                        <v-btn x-small class="ml-0 mr-1 mt-4 mb-0" fab @click="togglePlus" >
                                            <div v-if="this.isPositive"><v-icon color="green" dense>mdi-plus</v-icon> </div>
                                            <div v-else><v-icon color="red" dense>mdi-minus</v-icon></div>               
                                        </v-btn>  
                                    </v-col>
                                    <v-col cols="9"> 
                                        <v-currency-field 
                                            name="percentGainLoss"
                                            :label="$t('stock_prct_prce_page.prce_tab_gain_loss_lbl')"                              
                                            :allow-negative="false"                
                                            :decimalLength=2
                                            :value-as-integer="false"  
                                            append-icon="mdi-percent-outline"
                                            v-model="percentGainLoss"
                                            @blur="calculateGainLoss" 
                                            :rules="percentGainLoss_Rules"
                                            :color= "(this.isPositive ? 'green':'red')"   
                                            :class= "(this.isPositive ? 'text-green':'text-red')"   
                                            clearable                                                                
                                            required />
                                    </v-col>
                                </v-row>
                            </v-layout>
                        </v-container>
                    </v-col>
                    <v-col cols="8" sm="6" md="6" lg="4" >
                        <v-currency-field 
                            name="expectedPrice" 
                            :label="$t('stock_prct_prce_page.prce_tab_crnt_xptd_prce_lbl')"                               
                            :allow-negative="true"                
                            :decimalLength=3
                            :value-as-integer="false"                              
                            prepend-icon="mdi-hand-heart-outline"
                            v-model="expectedPrice"
                            readonly />
                    </v-col>                    
                </v-row>                   
            </v-card-text>
    </v-card> 
</template>

<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"   

    export default {
      data: () => ({
        currentPrice: null,
        expectedPrice: null,
        percentGainLoss: null,  
        isPositive: true,        // default to true - user can press to get - sign
        errors: {}, 
        locale: i18n.locale,
        // determines on object creation if running on mobile device true or false
        isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))), 
        currentPrice_Rules: [
            v => !!v || i18n.t('stock_prct_prce_page.prct_tab_crnt_prce_req_lbl'), 
            v => (v && parseFloat(v.replace(/,/g,'')) > 0.00 && parseFloat(v.replace(/,/g,'')) <= 10000000) || i18n.t('stock_prct_prce_page.prct_tab_expt_prce_err_lbl'),
        ],                           
        percentGainLoss_Rules: [
            v => !!v || i18n.t('stock_prct_prce_page.prce_tab_gain_loss_req_lbl'), 
            v => (v && parseFloat(v.replace(/,/g,'')) > (-50) ) || i18n.t('stock_prct_prce_page.prce_tab_gain_loss_err_lbl'),
        ],     
      }),
      // set locale when the object is mounted so date is displayed in correct language
      beforeMount: function() {
        //this.currentLocale = this.$i18n.locale;
        //console.log('Before mount try to change language from setting: '+this.currentLocale );                
      },      
      mounted() {
        // make sure library 'days.js' calculations match current locale settings
        //dayjs.locale( this.$i18n.locale );
      },      
      methods: {
        togglePlus() {
            if( !this.isPositive ) {
                this.isPositive = true;
            } else {
                this.isPositive = false;
            }
            // recalculate gain/loss
            this.calculateGainLoss();
        },
        calculateGainLoss() {
            // if currentPrice not null && percentage gain loss is not null
            if( this.currentPrice && this.percentGainLoss ) {
                // *** first get form field values - individually used in calculated sections***
                var currentPrc      = Number.parseFloat(this.currentPrice.toLocaleString(this.$i18n.locale).replace(/,/g,''));
                var gainLoss        = Number.parseFloat(this.percentGainLoss.toLocaleString(this.$i18n.locale).replace(/,/g,''));
                var percentGainLoss = ((this.isPositive)? gainLoss : -gainLoss );
                //
                if( currentPrc != 0 && percentGainLoss != 0 ) {
                    this.expectedPrice = (((currentPrc*percentGainLoss)/100) + currentPrc );
                } else {
                    this.expectedPrice = 0;
                }
            }
        },
        // formats numbers with commas, negative number wrapped with params
        numberWithCommas(num) {
             //return num < 0 ? '( '+num.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',').replace(/-/g,'')+' )' : num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
             var retVal;
             if( num < 0 ) {
                // remove - negative sign and surround with parans () to signify negative number
                retVal = '( '+new Intl.NumberFormat( this.$i18n.locale ).format(num).replace(/-/g,'')+' )'
             } else {
                retVal = new Intl.NumberFormat( this.$i18n.locale ).format(num)
             }
             return retVal;             
        },                                
      },
    
    }
</script>

<style scoped>
    .text-green input {
      color: green !important;
    }
    .text-red input {
      color: rgb(175, 9, 73) !important;
    }    
</style>