<!-- **********************************************************************************
   main application footer bar which listens for registered events to display dialogs

   note: '11-17-2022' moved the conent of the original footer bar to a seperate file
       called 'FooterBarConent' - reason being when the app is running on a mobile device
       the footer with 'app' property gets fixed to the bottom of the screen. Limiting the screen
       view, without that attribute the screen functions correctly. 
       The'app' attribute applied to the Vuetify <footer> component gets positioned at the bottom
       of the internal 'card' so its positioned at the very bottom of the component. Desktop default.
************************************************************************************* -->
<template>
  <!-- special check before applying footer to the app -->
  <!-- no 'app' is added to the component allowing larger screen for mobile -->
  <div>
    <div v-if="this.screenWidth <= 800" >
        <!-- MOBILE / SMALL TABLET - FOOTER SECTION-->
        <v-footer class="mb-0 mt-2" color="primary lighten-2"> 
          <v-container fluid class="ma-0 pa-0" >
            <v-row  class="ma-0 pa-0 lighten-2 text-center white--text" dense justify="center"> 
                <v-btn x-small class="ml-0 mr-1 mt-0 mb-0" fab outlined color="indigo" :to="{name:'home'}" >
                    <v-icon>mdi-home</v-icon> 
                </v-btn> 
            <!--
                <v-btn x-small class="ml-1 mr-1 mt-0 mb-0" fab outlined color="indigo" @click="showPrivatePolicy"   >
                    <v-icon>fa-solid fa-user-shield</v-icon> 
                </v-btn>   
            -->                
                <v-btn x-small class="ml-1 mr-1 mt-0 mb-0" fab outlined color="indigo" @click="showAboutSite" >
                    <v-icon>fa fa-list-ul</v-icon> 
                </v-btn>   
                <v-btn x-small class="ml-1 mr-1 mt-0 mb-0" fab outlined color="indigo" @click="showDisclaimer"  >
                    <v-icon>mdi-account-tie-hat</v-icon> 
                </v-btn>
                <v-btn x-small class="ml-1 mr-1 mt-0 mb-0" fab outlined color="indigo" @click="showTerms"  >
                    <v-icon>mdi-seal</v-icon> 
                </v-btn>  
                <v-btn x-small class="ml-1 mr-0 mt-0 mb-0" fab outlined color="indigo" @click="showContactInfo"  >
                    <v-icon>mdi-card-account-mail</v-icon>                
                </v-btn>  
            </v-row>
            <v-row dense justify="center" class="ma-0 pa-0 lighten-2 white--text"> 
                <v-col cols="12">
                  {{ new Date().getFullYear() }} &nbsp;&nbsp;<strong>{{ this.company }}</strong>
                </v-col>
            </v-row>       
          </v-container>
        </v-footer>
    </div>  
    <div v-else-if="this.isMobile || this.screenWidth <= 1180">
       <!-- iPhone or BIG TABLET FOOTER Match AppBar.vue Left Drawer settings -->  
        <v-footer class="mb-0 mt-5" color="primary lighten-2">
          <v-container fluid class="ma-0 pa-0" > 
            <v-row dense justify="center" class="ma-0 pa-0 lighten-2 text-center white--text" > 
               <v-col cols="2" ></v-col>
               <v-col cols="8">
                  <v-btn x-small class="ml-0 mr-1 mt-0 mb-0" fab outlined color="indigo" :to="{name:'home'}" >
                      <v-icon>mdi-home</v-icon> 
                  </v-btn>
    
                  <v-btn x-small class="ml-1 mr-1 mt-0 mb-0" fab outlined color="indigo" @click="showAboutSite"  >
                      <v-icon>fa fa-list-ul</v-icon> 
                  </v-btn>                                  
                  <v-btn x-small class="ml-1 mr-1 mt-0 mb-0" fab outlined color="indigo" @click="showDisclaimer"  >
                      <v-icon>mdi-account-tie-hat</v-icon> 
                  </v-btn>
                  <v-btn x-small class="ml-1 mr-1 mt-0 mb-0" fab outlined color="indigo" @click="showTerms"  >
                      <v-icon>mdi-seal</v-icon> 
                  </v-btn>  
                  <v-btn x-small class="ml-1 mr-0 mt-0 mb-0" fab outlined color="indigo" @click="showContactInfo"  >
                      <v-icon>mdi-card-account-mail</v-icon>                
                  </v-btn>  
               </v-col>
               <v-col cols="2">
                  <!-- add social media for tablets -->
                  <socialMediaLinks/> 
               </v-col>               
            </v-row>
            <v-row dense justify="center" class="ma-0 pa-0 lighten-2 white--text"> 
                <v-col cols="12">
                  {{ new Date().getFullYear() }} &nbsp;&nbsp;<strong>{{ this.company }}</strong>
                </v-col>
            </v-row>    
          </v-container>
        </v-footer>
    </div>
    <div v-else >
      <!-- DESKTOP - LARGE SCREEN - FOOTER -->  
      <v-footer class="mb-0 mt-5" color="primary lighten-2">
          <v-container fluid class="ma-0 pa-0" > 
              <v-row class="ma-0 pa-0 lighten-2 text-center white--text" dense > 
                  <v-col cols="2">&nbsp;</v-col>
                  <v-col cols="8">
                      <v-btn small class="ml-1 mr-1 mt-0 mb-0" outlined color="indigo" :to="{name:'home'}" >
                          <v-icon left>mdi-home</v-icon>{{$t("footer.home")}}
                      </v-btn>
                      <v-btn small class="ml-1 mr-1 mt-0 mb-0" outlined color="indigo" @click="showAboutSite" >
                          <v-icon left>fa fa-list-ul</v-icon> Menu
                      </v-btn>                             
                      <v-btn small class="ml-1 mr-1 mt-0 mb-0" outlined color="indigo" @click="showDisclaimer" >
                          <v-icon left>mdi-account-tie-hat</v-icon> {{$t("footer.disclaimer")}}
                      </v-btn>                     
                      <v-btn small class="ml-1 mr-1 mt-0 mb-0" outlined color="indigo" @click="showTerms" >
                          <v-icon left>mdi-seal</v-icon>  {{$t("footer.terms")}}
                      </v-btn>  
                      <v-btn small class="ml-1 mr-1 mt-0 mb-0" outlined color="indigo" @click="showContactInfo" >
                          <v-icon left>mdi-card-account-mail</v-icon> {{$t("footer.contact")}}
                      </v-btn> 
                  </v-col>
                  <v-col cols="2">
                     <socialMediaLinks/>
                  </v-col> 
              </v-row> 
              <v-row dense justify="center" class="ma-0 pa-0 lighten-2 white--text"> 
                    {{ new Date().getFullYear() }} &nbsp;&nbsp;<strong>{{ this.company }}</strong>
              </v-row>              
          </v-container>
       </v-footer>    
    </div>
    <!-- popup dialogs placed here -->
    <AboutTheSite/>    
    <DisClaimer/>
    <TermsOfUse/>
    <ContactInfo/> 
    <!-- <BottomSheetAds/> -->       
  </div>
</template>

<script>
  import { eventBus }  from '@/main'    // event bus communication for registered components
  //import i18n         from "@/i18n"  // i18n international language support for labels
  import DisClaimer    from '@/components/DisClaimer.vue';
  import TermsOfUse    from '@/components/TermsOfUse.vue';
  import ContactInfo   from '@/components/ContactUs.vue';
  import AboutTheSite  from '@/components/AboutTheSite.vue' 
  //import PrivatePolicy from '@/components/PrivatePolicy.vue' 
  //import AboutUs    from '@/components/AboutUs.vue';
  // ads bottom sheet - uses broadcast message to show ad
  //import BottomSheetAds from '@/components/ads/BottomSheetAds.vue'   
  //----------------------------------------
  import socialMediaLinks  from '@/components/widgets/socialMediaLinks.vue'  
 
  export default {
    name: "FooterBar",
    components: { AboutTheSite, DisClaimer, TermsOfUse, ContactInfo, socialMediaLinks },
    data: () => ({
        pgm_mode: process.env.VUE_APP_MODE,
        company: process.env.VUE_APP_LLC,
        // determines on object creation if running on mobile device true or false - although hard coded init
        isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),
        screenHeight: screen.height,
        screenWidth:  screen.width,
    }),
    mounted() {
        //console.log(  "IS Mobile ? "+this.isMobile+"  ---->  device type ? "+this.deviceType ); 
        //window.addEventListener("deviceorientation", this.handleOrientation);
    },
    methods: {
      showDisclaimer() {
        // emits global event for component - to open snackbar
        eventBus.$emit("show-disclaimer", "showDisclaimer");
        //console.log("emitter called to show disclaimer");
      },
      showTerms() {
        eventBus.$emit("show-terms", "showTerms");
        //console.log("emitter called to show terms");
      },
      showContactInfo() {
        eventBus.$emit("show-contact", "showContactInfo");        
        //console.log("emitter called to show contact info");
      },
      showAboutSite() {
          // emits global event for component - to open about site document
          eventBus.$emit("show-about-site", "showAboutSite");
      },   
    },
  }
</script>
