<!--   DATE/TIME CALCULATOR TOOL ---------------

   DATE - TIME CALCULATOR with sub date components
   
   Main Date and Time View which loads a FromToDate Calculator and a FuturePast Calculator components 
   for use within a set of tabs   

-->
<template>
  <v-container class="fill-height printable" > 
    <v-layout justify-center align-center>    
        <v-card class="mx-auto my-auto" min-width="225" max-width="600" hover >
            <!-- check for mobile device - different size background image and text --->
            <v-img class="white--text align-center" width="auto" height="75px" src="@/assets/agenda_1280.jpg" cover >
                <v-card-title class="justify-center" >
                    <v-row dense>
                        <v-col cols="1">
                            <!-- user help button -->
                            <v-btn class="mx-1" fab small dark color="green" @click.prevent.stop="showDateHelp" >
                                <v-icon dark>fa-solid fa-question</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="pt-2 d-flex justify-center" cols="9">
                          <span class="text-h5 font-weight-bold">&nbsp;&nbsp;{{$t("date_time_page.date_tools_title")}}</span>
                        </v-col>
                        <v-col cols="2">
                            <!-- track bookmarks -->
                            <Bookmark link="datetime"/>                          
                        </v-col>                    
                    </v-row>
                    <!-- create help wiki here -->
                    <DateHelpWiki />
                </v-card-title>     
            </v-img>                
            <v-card-text class="text--primary">
                  <v-container fill-height >
                    <v-flex fill-height >  
                      <!-- -->        
                      <v-card >
                        <v-tabs v-model="tab" background-color="primary" >
                            <v-tab v-for="item in items" :key="item.tab">
                              <v-icon left>{{item.icon}}</v-icon>
                              {{ item.tab }}
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item v-for="item in items" :key="item.tab">
                              <v-card flat hover >
                                <v-card-text class="ma-0 pa-0">
                                  <component v-bind:is="item.content"></component>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                      </v-card>
                      <v-divider></v-divider>
                        <v-row justify="center">
                          <v-col cols="12" >
                              <ToolsBottomAds/>          
                          </v-col>
                        </v-row>    
                        <v-divider></v-divider>                                                                                                       
                        <v-row dense justify="center">
                          <v-col cols="12">
                             <SideDisclaimerNotice/>
                          </v-col>
                        </v-row>                         
                    </v-flex>                 
                  </v-container>
              </v-card-text>
          </v-card>
    </v-layout>
  </v-container>
</template>

<script>
    // listen for and allow broadcasts to components
    import { eventBus } from '@/main'  // event bus communication for registered components

    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"      

    // import tab component cards
    import FromToDateCalculator from "@/components/tools/FromToDateCalculator.vue";  // tab 1
    import FuturePastCalculator from "@/components/tools/FuturePastCalculator.vue";  // tab 2
    import SideDisclaimerNotice from '@/components/SideDisclaimerNotice.vue'
    import ToolsBottomAds       from '@/components/ads/ToolsBottomAds.vue';
    // wiki tool document used when user presses 'help' button
    import DateHelpWiki         from '@/components/AboutDateWiki.vue'  
    // user bookmarking component
    import Bookmark             from '@/components/AddToFavorites.vue'    

    // ads bottom sheet - uses broadcast message to show ad
    //import BottomSheetAds from '@/components/BottomSheetAds.vue'

    export default {
      components: { FromToDateCalculator, FuturePastCalculator, SideDisclaimerNotice, ToolsBottomAds, DateHelpWiki, Bookmark },
      data: () => ({
        valid: false,
        tab: null,
        locale: i18n.locale,
        isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),             
        items: [
          { tab: i18n.t('date_time_page.range_tab'), icon:'mdi-arrange-send-backward', content: 'FromToDateCalculator' },
          { tab: i18n.t('date_time_page.future_tab'), icon:'mdi-update',content: 'FuturePastCalculator' }
        ],
        value: null,
        errors: {},
      }),
      // user defined methods
      methods: {
        disablePastDates(val) {
           return val >= new Date( this.startDate ).toISOString().substr(0, 10)
        },
        showDateHelp() {
            // emits global event for component - to open help document from button
            eventBus.$emit("show-date-wiki", "showDateHelp");
        },           
      },      
      // life-cycle - looks for mount
      beforeMount() {
          // show loading spinner - just before component is mounted to app
          this.$loading(true)
      },      
      mounted() {
        // .5 sec delay - then remove loading indicater       
        setTimeout( ()=> this.$loading(false), 500 );

        // show ads - if enabled in .env file - defined in BottomSheetAds component
        //setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), 2500 );   
      },
      unmounted() {
        console.log('DateTimeView Unmounted ???');
      },
      metaInfo() {
        return {
            title: "Stock-Insight's Date/Time Calculator",
            meta: [
              { name: 'robots', content: 'index,follow'}, 
              { name: 'description', content: 'Stock Date Time Calculator' },
              { name: 'keywords', content: 'date range, time, stock calculator' },                                 
              { property: 'og:type', content: 'website'}, 
              { property: 'og:site_name', content: 'www.Stock-Insight.net'},
              { property: 'og:title', content: "Stock-Insight's Date/Time Calculator"},                             
              { vmid: "description", name: "description",
                content: "A Stock Option 'Date/Time' Calculator for Helping to Calulate Experations"
              }
            ],
            link: [
              {
                rel: 'canonical',
                href: '<https://www.stock-insight.net/>'
              }
            ]
        }
      }                         
    }
  </script>

  <!-- added to highlight tab icon when active - like the AppBar.vue icons -->
  <style scoped>
  .v-tab--active .v-icon, .v-tab--active .v-btn.v-btn--flat {
    color: red;
  }
</style>