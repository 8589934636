<!-- 
     WSJ Podcast Feeds [ https://video-api.wsj.com/podcast/rss/ ]  
-->
<template>
  <v-layout class="mt-5" align-center justify-center column>
    <v-flex row align-center>  
      <!-- START DESKTOP CARD - Toolbar and Tabs for report selection -->      
      <v-card class="mx-auto mb-15 flexcard" :min-width="this.cardMinWidth" :width="this.cardWidth" :height="this.cardHeight" hover>
        <div v-if="this.isMobile">
              <!-- appears on mobile only devices -->
              <v-toolbar color="#757575" dark flat width="auto" height="65px" >              
                <v-toolbar-title class="text-left text-h3 mt-1 white--text" background >WSJ</v-toolbar-title>  
                <div style="margin-left: auto; display: block;">
                    <Bookmark link="WSJPodcasts"/>
                </div> 
                <!-- TABS Section displayed at bottom of toolbar -->
                <template v-slot:extension >
                    <!-- mobile report bar -->
                    <v-btn block v-if="isMobile === true" @click.stop="drawer = !drawer" >
                       <v-icon left >{{ listIcon }}</v-icon> {{ mobileRptBar }}
                    </v-btn>              
                </template>                        
              </v-toolbar> 
              <div class="text-left">               
                <v-chip style="margin-left: 15px" x-small text-color="white" color="blue" >{{ this.getformattedDate() }}</v-chip>  
              </div>                       
          </div>
          <div v-else>   
             <v-toolbar color="#757575" dark flat width="auto" height="65px" >  
                <v-toolbar-title class="text-left text-h4 mt-2 white--text" >Wall Street Journal</v-toolbar-title>          
                <div style="margin-left: auto; margin-top: 3px; display: block;">
                    <Bookmark link="WSJPodcasts"/>
                </div>   
             </v-toolbar> 
             <div class="text-left">
                 <v-chip style="margin-left: 20px;" x-small text-color="white" color="blue" >{{ this.getformattedDate() }}</v-chip>    
             </div>                         
        </div>
        <!-- feed viewing component - where feeds are available for viewing on desktop -->
        <v-container>
          <v-row dense no-gutters class="ma-0 pa-0" v-if="isMobile === false"> 
              <!-- desktop button bar options list 1 -->
              <!-- loop through both feed lists to generate menu for top menu -->
              <v-col cols="12" class="ma-0 pa-0" >
                <v-btn-toggle v-model="toggle_list1" borderless dark rounded background-color="blue">
                  <v-btn v-for="item in items1" :value="item.id" :key="item.id" small rounded active-class="aa" elevation="3" @click.prevent="getFeedReport(item.id,item.rpt)">
                    <v-icon left >{{ item.icon }}</v-icon> {{ item.name }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>             
            <!-- report section -->
            <v-row>  
              <v-col cols="12">
                  <!-- *** feed display section - all rss data will be displayed in the RssFeedViewer *** -->  
                  <!-- height adjusted to fit within the host parent card; desktop or mobile -->                
                  <v-card v-scroll.self="onScroll" class="overflow-y-auto" ref="rssViewer" :height="this.cardHeight-(this.isMobile?160:175)" >    
                      <RssFeedViewer :rssFeed="reportData" />                          
                  </v-card>                  
              </v-col>
            </v-row>
        </v-container>
      </v-card>
      <!-- *** START Left Drawer for small screens - uses tab v-card section to display report *** -->
      <v-navigation-drawer app v-model="drawer" color="blue" theme="dark" class="mt-0 pt-0 pb-15 mb-15" absolute temporary >
          <v-row dense no-gutters class="mb-0 pb-0">
              <v-col class="text-md-center" cols="12">               
                  <h4 class="ml-auto mb-0 pb-0 white--text">WSJ Podcasts</h4>  
              </v-col>
          </v-row>
          <v-divider dark style="height:3px;background-color:aqua" class="ma-0 pa-0" ></v-divider>             
          <v-list nav dense class="ma-0 pa-0">
            <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
              <!-- loop through both feed lists to generate menu for drawer -->
              <v-list-item v-for="item in items1" :key="item.id"  @click.prevent="getFeedReport(item.id,item.rpt)" >
                  <!-- highlight first report id: 0 as 'active' link -->
                  <div v-if="item.id === 0">
                      <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">                
                         <v-icon id="fIcon" left class="initHighlighted" >{{ item.icon }}</v-icon>
                      </v-list-item-icon>     
                  </div>
                  <div v-else>
                      <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">                
                        <v-icon left >{{ item.icon }}</v-icon> 
                      </v-list-item-icon>     
                  </div>                                    
                  <v-list-item-content :style="{'align-items':'left'}">
                      <v-list-item-title id="listFont" class="ListItemClassInActive">{{ item.name }}</v-list-item-title>
                  </v-list-item-content>                      
              </v-list-item> 
            </v-list-item-group>
          </v-list>
      </v-navigation-drawer>  
      <!-- *** END Left Drawer Section *** -->                
    </v-flex>
  </v-layout>         
</template>
  
<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"  

    // date js utility for formatting current NYSE date
    import dayjs from "dayjs";    
    
    // listen for and allow broadcasts to components
    import { eventBus } from '@/main'  // event bus communication for registered components

    // user bookmarking component
    import Bookmark     from '@/components/AddToFavorites.vue'                 

    // add embedded feed viewer for customizing feed
    import RssFeedViewer from '@/components/views/RssFeedViewer.vue'

    // use NY Date info for unigue key to sync with server - basic security
    var tNYDate = new Date(new Date().toLocaleString('en', {timeZone: 'America/New_York'}));  

    export default {
      components: { RssFeedViewer, Bookmark },
      data: () => ({
        scrollInvoked: 0,
        timestamp: new Date(new Date().toLocaleString('en',{timeZone:'America/New_York'})),        
        toggle_list1: 0,    
        valid: false,
        drawer: false, 
        group: null,               
        // grabs locale from environment setup in start of app
        locale: i18n.locale,        
        // determines on object creation if running on mobile device true or false
        isMobile: ((/Mobile/i.test(navigator.userAgent))),
        // *** following settings are designed for desktop - flipped if 'mobile' before mount ***              
        cardMinWidth: 650,
        cardWidth: "75%",
        cardMaxWidth: "80%",
        cardMinHeight:850,
        cardHeight: 900,
        feedName: '',
        tab: null,
        show: false,
        listIcon: 'fa-solid fa-list',
        mode: sessionStorage.getItem('mode'),
        mobileRptBar: i18n.t('podcast_mobile_reportbar'),  
        showAdsID : 0,
        hideAdsID : 0,  
        time_delay: Number( process.env.VUE_APP_ADS_DELAY ),                    
        //----------SECURITY CHECKS SENT to SERVER --------------------------------*
           xKey: tNYDate.getFullYear()+(tNYDate.getMonth()+1)+(tNYDate.getDate()),
          x2Key: tNYDate.getFullYear()*(tNYDate.getMonth()+1)*(tNYDate.getDate()),          
        //-------------------------------------------------------------------------*  
        reportData: 'Report Viewer Section', 
        // menu items for tabs and left drawer component on mobile  
        // { id:1,name:'News',rpt:'Nasdaq_news',title:'General News',icon:'mdi-newspaper-variant-multiple'},
        items1: [
              { id:0,name:'News',rpt:'WSJ_news',title:'WSJ News',icon:'fa-radio'}, 
              { id:1,name:'Briefing',rpt:'WSJ_briefing',title:'Briefing',icon:'fa-person-chalkboard'},                            
              { id:2,name:'Your Money',rpt:'WSJ_your-money',title:'Your Money',icon:'fa-hand-holding-dollar'},                                                                                   
              { id:3,name:'The Journal',rpt:'WSJ_the-journal',title:'The Journal',icon:'fa-landmark'},             
        ],                     
      }),
      beforeMount() {
        // show loading spinner - just before component is mounted to app
        this.$loading(true);
        // remove any Ad listeners 
        this.adCleanUp()    
        //*** re-set card/widget measurements if running on mobile device before mount ***
        if( this.isMobile ) {
            this.cardWidth="90%";
            this.cardMinWidth=325;
            this.cardMaxWidth="99%";
            this.cardHeight=1000;
        }
        //*****************************************************/            
      },
      mounted() {
        // 1/2 sec delay - then remove loading indicater       
        setTimeout( ()=> this.$loading(false), 1000 ); 

        // check for setting - time out is set in .env file for ads 
        if( this.time_delay <= 0 ) { this.time_delay = 2500; }       

        // remove any Ad listeners 
        this.adCleanUp()     

        // clear any previous data - to start on first story
        this.reportData = '';        

        // *** get first report on mount - Commodities is the first button ***
        this.getFeedReport( 0, this.items1[0].rpt );

        // ads here after checking online status
        setTimeout( ()=> this.setupAudios(), 1000 );           
        // show ads - if enabled in .env file - defined in BottomSheetAds component
        setTimeout( ()=> eventBus.$emit("show-ads","showAds"), 1500 ); 
      },
      beforeUnmount() {
         // remove Ad listeners
         this.adCleanUp()
      },         
      // called when component is removed
      unmounted() {
        // clear any 'refresh' data attempts before exiting component
        sessionStorage.removeItem("refresh");
      },            
      methods: {
        setupAudios() {
            // Get all the audio elements on the page
            const audios = document.querySelectorAll('audio');          
            // Loop through each audio element and add an event listener for the play event
            audios.forEach( audio => {
                  audio.addEventListener('play', () => {
                        // Pause any other audio element that is not the current one
                        audios.forEach(otherAudio => {
                            if( otherAudio !== audio ) {
                                otherAudio.pause();
                            }
                        });
                  });
            });
        },                
        onScroll () {
           // to track scrolling of card window
           this.scrollInvoked++
        },
        getformattedDate() {
           var day = dayjs( this.timestamp );
           return( "Current as of: "+day.format( 'MM-DD-YYYY HH:mm' ) );           
        }, 
        // display wait timer message
        getWaitForAudiosMsg() {
            let timerInterval;
            this.$swal.fire({
                title: 'Audios Loading!',
                html: 'Please Wait for Setup ...',
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  this.$swal.showLoading()
                  //const b = this.$swal.getHtmlContainer().querySelector('b')
                  //timerInterval = setInterval(() => {
                  //   b.textContent = this.$swal.getTimerLeft()
                  //}, 100 )
                },
                willClose: () => { clearInterval( timerInterval ) }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if( result.dismiss === this.$swal.DismissReason.timer) {
                    //console.log('I was closed by the timer')
                }
            });
        },    
        adCleanUp() {
          // close right drawer
          eventBus.$emit("hide-side-ads","hideSideAds");  
          // stop any timeout events by assigned ID - before creating any new ones
          clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );
        },
        showHideAds() {
          this.adCleanUp();
          var delay = this.time_delay;
          // show ads - if enabled in .env file - defined in BottomSheetAds component - record id for clearing
          this.showAdsID = setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), delay );  // record timeout ID -  for clearing
          //console.log("showAdsID = "+this.showAdsID);    
          delay = delay * 3;
          // send event to parent (AppBar.vue) to close Ads right drawer x3 delay
          this.hideAdsID = setTimeout( ()=> eventBus.$emit("hide-side-ads","hideSideAds"), delay );   // record timeout ID - for clearing  
          //console.log("shideAdsID = "+this.hideAdsID);    
        },                
        refreshMsg() {
            this.$swal({
                title: 'New Podcasts Available',
                text:  'Refreshing Data List, Please Wait ...',
                icon:  'info',
                showCloseButton: false,
                showLoaderOnConfirm: false
              }).then(() => {  });
        },                                    
        /**************************************************************************************
         * this method is assigned to each button generated by looping through List1 and List2
         * when clicked it will make an axios call to retrieve the requested Rss feed from
         * the back end servlet which will pull it from the S3 bucket.
         * 
         * ListNo is passed to clear other button list so it is not highlighted/selected
         * no button group is cleared if zero 0 is passed
         *************************************************************************************/      
        getFeedReport( btnId, rptName ) {
              this.$loading(true);  
              // clear any previous data - to start on first story
                 this.reportData = '';   
              // add timeout to reselect button if clicked multiple times
              setTimeout( ()=> { this.toggle_list1 = btnId; }, 150 );                 
                 
              // request report from back-end to retrieve selected Rss Feed
              this.axios.get('/podcast?report='+rptName,{timeout:5000,headers:{'api-ref':this.xKey,'sc-ch-nx':this.x2Key,'mode':this.mode}}).then((response) => {
                    // clear any existing data - reset scroll window position
                    this.scrollInvoked=0;                    
                    //console.log( response.data  ); // <- later comment out
                    var rssFeedData = response.data;
                    // **** throw error to popup message - invalid symbol ****
                    if( rssFeedData === null || rssFeedData.length <= 10 ) {
                          throw new Error('WSJ Podcast Error');
                    } else {
                        // store feed info to data element 'text' for display - which
                        // is created under a tab section ... reference back to that tab first
                        //   this.$refs[rptName];
                        // assign report to the RssFeedViewer - here
                        this.reportData = rssFeedData;
                        // set scroll back to top
                        this.scrollInvoked=0;
                        // setup audio 
                        setTimeout( ()=> this.setupAudios(), 750 );  
                        // clear loading, then show wait popup message after 250 mil delay
                        setTimeout( ()=> { this.$loading(false); }, 1000 ); 
                        setTimeout( ()=> { this.getWaitForAudiosMsg(); sessionStorage.removeItem("refresh"); }, 1250 );  
                        // call to slide-out ads - show ads - if enabled in .env file 
                        setTimeout( ()=> this.showHideAds(), 1500 );                                     
                    }
              }).catch( err => { 
                  this.$loading(false);
                  var xErr = "<div class='w3-row w3-margin-bottom'>"+    
                              "<div class='w3-card-4' style='width:100%'>"+
                              "<header class='w3-container w3-red w3-left-align'>"+
                              "<h3>Updated WSJ Podcasts Available</h3>"+
                              "<h6>Downloading Please Wait ...</h6></header>"+
                              "<div class='w3-container w3-left-align'>"+
                              "<p></p><p>"+i18n.t('rss_err_msg.action') +"</p>"+
                              "</div></div></div>";
                               console.log( err );
                    // show user by udating - report data for the view
                    this.reportData = xErr;
                    // reload application - probably due to connection timeout or stale data list
                    // *** check for previous try attempt to refresh in session storage ***
                    var refresh = sessionStorage.getItem("refresh");
                    // loader
                    // setTimeout( ()=> { this.$loading(true); }, 2500 );    
                    // after previous attempt - to reload the list - direct to 'home'
                    if( refresh != null ) {
                        // first remove 'reload flag' from sessionStorage - redirect 'home'
                        sessionStorage.removeItem("refresh");
                        setTimeout( ()=> { this.$loading(true); }, 2500 );   
                        setTimeout( ()=> { window.location.href="/"; }, 3500 ); 
                        //console.log( "refresh found & removed - redirected HOME ... " );
                    } else {
                        // **** try refreshing this page data on a reload  1x only ****
                        this.refreshMsg();                        
                        // set storage item - 'refresh' before reloading this page
                        sessionStorage.setItem( "refresh", 'true' );
                        //console.log( "refresh SET - reload page .. " );                          
                        setTimeout( ()=> { window.location.reload(); }, 1500 );  
                    }                              
              });
              // remove highlighed default mobile report icon - must be a better way ...
              if( this.isMobile && btnId != 0 ) {
                  var icon = document.getElementById("fIcon");
                  icon.classList.remove("initHighlighted");
              }                        
          },
      },
      watch: {
          group () {
             this.drawer = false
          },
      },
      metaInfo() {
        return {
          title: "Stock-Insight's Latest Podcasts From the Wall Street Journal",
          meta: [
            { name: 'robots', content: 'index,follow'},
            { name: 'description', content: 'Financial Podcasts from The Wall Street Journal WSJ' },
            { name: 'keywords', content: 'financial podcasts investing, news, briefing, your money, The Journal, wsj' },            
            { property: 'og:type', content: 'website'}, 
            { property: 'og:site_name', content: 'www.Stock-Insight.net'},
            { property: 'og:title', content: "Stock-Insight's Latest Podcasts From the Wall Street Journal"},                             
            {
              vmid: "description",
              name: "description",
              content: "Financial Podcasts from the Wall Street Journal"
            }
          ],
          link: [
            {
              rel: 'canonical',
              href: '<https://www.stock-insight.net/>'
            } 
          ]
        }
      }                      
    }
</script>

<style scoped>
 .v-tab--active {
    background-color:rgba(22, 174, 22, 0.3); 
 }
 .v-list-item__content {
     text-align: left;
     color: white; 
     font-weight: bold;     
 }
 .v-list-item__title {
     padding-left: 10px;
     font-weight: bold;        
 }
 
  .aa {
    background: rgb(25, 0, 255) !important;
  }
  .ListItemClassActive {
    color: #0556f9;
  }
  .ListItemClassInActive {
    color: #f1f8fa;
   }
  .v-list-item__icon v-list-group__header__prepend-icon {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
  } 
  .v-list-item--active .subListIcon .v-icon {
     background-color: lightgreen;
  }
  .v-list-item--active .v-list-item__title {
     color: rgb(253, 250, 250);
     font-weight: bold;
  } 
  .subListIcon {
    font-size: 16px;
    width: 40px;        
  }  
  .subListItem {
    font-size: 18px;
  }     
  .initHighlighted {
    background-color: lightgreen;
  }  
  #listFont {
     font-size: 16px;
     padding-top: 5px;
  }  
</style>

