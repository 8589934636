import { render, staticRenderFns } from "./AboutMarginWiki.vue?vue&type=template&id=5c98d706&scoped=true&"
import script from "./AboutMarginWiki.vue?vue&type=script&lang=js&"
export * from "./AboutMarginWiki.vue?vue&type=script&lang=js&"
import style0 from "./AboutMarginWiki.vue?vue&type=style&index=0&id=5c98d706&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c98d706",
  null
  
)

export default component.exports