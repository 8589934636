import { render, staticRenderFns } from "./WSJ_RssFeeds.vue?vue&type=template&id=4dd10632&scoped=true&"
import script from "./WSJ_RssFeeds.vue?vue&type=script&lang=js&"
export * from "./WSJ_RssFeeds.vue?vue&type=script&lang=js&"
import style0 from "./WSJ_RssFeeds.vue?vue&type=style&index=0&id=4dd10632&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dd10632",
  null
  
)

export default component.exports