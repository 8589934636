<!--
           FUTURE / PAST COMPONENT TAB
        hosted in the DateTimeView.vue parent view page
-->
<template>
    <v-card color="#D1F2EB" style="position: relative" >
        <v-card-title class="text-h6 justify-center">{{$t("date_time_page.future_tab_subtitle")}}</v-card-title>
            <v-card-text style="height:100%;width:100%">                 
                <v-row dense justify="center">
                <v-col cols="8" sm="8" md="4" lg="4" >
                    <v-menu v-model="fPastStartMenu" :close-on-content-click="false" transition="scale-transition" min-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-model="fPastStartDate" 
                                @change="calculateDays"
                                :label="$t('date_time_page.future_start_date_lbl')"                                
                                v-bind="attrs" 
                                v-on="on" prepend-icon="mdi-calendar" readonly>
                            </v-text-field>
                        </template>
                        <v-date-picker title v-model="fPastStartDate" @input="calculateDays" :locale="currentLocale" ></v-date-picker>
                    </v-menu>
                    </v-col> 
                    <!-- built from data object arrays -->
                    <v-col cols="8" sm="8" md="2" lg="2">
                        <v-combobox v-model="selectedItem" @change="calculateDays"
                                    :items="items" 
                                    :label="$t('date_time_page.future_options_lbl')" >
                        </v-combobox>
                    </v-col> 
                    <v-col cols="8" sm="8" md="2" lg="2" >
                    <v-currency-field 
                        name="days" 
                        :label="$t('date_time_page.future_days_lbl')"
                        @change="calculateDays"   
                        :allow-negative="true"                
                        :decimalLength=0
                        :value-as-integer="true"                              
                        prepend-icon="mdi-calculator-variant-outline"
                        v-model="fpDays"
                        required />
                </v-col>  
                <v-col cols="8" sm="8" md="4" lg="4" >
                    <v-menu v-model="fPastEndMenu" :close-on-content-click="true" transition="scale-transition" min-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-model="fPastEndDate" 
                                label="Calculated Date" 
                                v-bind="attrs" 
                                v-on="on" prepend-icon="mdi-calendar" readonly>
                            </v-text-field>
                        </template>
                        <!-- readonly popup does not allow editing - but user can view calendar -->
                        <v-date-picker title :readonly='true' :value="fPastEndDate" :locale="currentLocale" ></v-date-picker>
                    </v-menu>
                    </v-col>                         
                </v-row>
            </v-card-text>
    </v-card>    
</template>

<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"   
    //import DatePicker from "@/components/DatePicker"; - template not used here
    import dayjs from "dayjs";

    export default {
      components: {  },
      data: () => ({
        valid: false,
        currentLocale: '',
        fPastStartMenu: false,
        fPastEndMenu: false,        
        fPastStartDate: new Date().toISOString().substr(0, 10),
        fPastEndDate: '',
        fpDays: 0,
        // sets 'Future' as default item in the combo box 
        selectedItem: { id: 0, text: i18n.t('date_time_page.future_opt_future_lbl') },
        // option list of combobox - 'Future' or 'Past'...
        items: [
            { id: 0, text: i18n.t('date_time_page.future_opt_future_lbl') },
            { id: 1, text: i18n.t('date_time_page.future_opt_past_lbl') },
        ],               
        value: null,
        errors: {},
            // FORM RULES SECTION - called by form fields 
      }),
      beforeMount: function() {
         // set locale when the object is mounted so date is displayed in correct language        
         this.currentLocale = this.$i18n.locale;
      },
      mounted() {
         // make sure library 'days.js' calculations match current locale settings        
         //dayjs.locale( this.$i18n.locale );  
      },
      methods: {
        calculateDays() {
            // using dayjs plugin library for calculations - a little crazy - need to check these links 
            // good notes here: [ https://zetcode.com/javascript/dayjs/ ]
            // and [https://stackoverflow.com/questions/2388115/get-locale-short-date-format-using-javascript]
            // check for user changing data - called from form items
            if( this.fPastStartDate != null && this.fpDays > 0 ) {
                // starting date calc object - clone is generated since original object does not change
                let now = dayjs( this.fPastStartDate );

                // check for either 'Future' or 'Past' selection by user - either 0 or 1
                var selectedOption = this.selectedItem.id

                // working objects
                let x; let xDate;

                // if 'Future' is selected
                if( selectedOption === 0 ) {
                    // ADD number of days - since 'Future' was selected
                    x = now.add( this.fpDays,'day'); // actually returns a clone of original object
                } else {
                    // SUBTRACT number of days - since 'Past' was selected
                    x = now.subtract( this.fpDays,'day'); 
                    // actually returns a clone of original object - original object 'now' does not change
                }

                // update 'Calculated Date' by setting data object to update date component on the screen
                // since it was modified by user selections
                // get date format
                xDate = x.format('YYYY-MM-DD');

                // update data object tied to 2nd calendar 'fPastEndDate'
                this.fPastEndDate = xDate; 
                //console.log("Data - Date Set to: "+ this.fPastEndDate );
            }
            // make sure both calendars after calc are closed to reflect changes - if opened
            this.fPastStartMenu = false;
            this.fPastEndMenu   = false;
        },                               
      }
    }                
</script>