<!--
       ABOUT-SITE DIALOG WINDOW - popup - from button on footer bar
-->
<template>
  <div class="text-center mx-auto">
    <v-dialog persistent v-model="dialog" :width="(this.isMobile? 350: 550)">
      <v-card height="550px" >
        <v-card-title class="ma-0 pa-0" >  
          <v-container fluid >               
              <v-toolbar color="blue accent-1" flat width="auto" height="45px">
                  <!-- toggle button list1 only appear on desktop  -->
                  <v-row dense no-gutters class="ma-0 pa-0"> 
                    <!-- desktop button bar options list 1 -->
                    <!-- loop through both feed lists to generate menu for top menu -->
                    <v-col cols="12"  >
                      <v-btn-toggle v-model="toggle_list1" borderless dark rounded background-color="blue">
                          <v-btn active-class="aa" elevation="3" small v-for="item in items" :key="item.id" rounded @click.prevent="resetToggle" >
                            <v-icon left>{{item.icon}}</v-icon>{{ item.title }}
                          </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
              </v-toolbar>
            </v-container>
          </v-card-title>
          <v-card-text scrollable class="ma-0 pa-0">
            <v-container fill-height >
              <v-flex fill-height >
                <v-row>  
                    <v-col cols="12">
                      <!-- *** terms display section - all service cookies ... data will be displayed in the termsViewer *** -->  
                      <!-- height adjusted to fit within the host parent card; desktop or mobile -->                
                      <!--<div ref="termsViewer" v-html="this.items[0].content"></div>-->
                      <div ref="aboutSiteViewer" v-html="this.text"></div>
                  </v-col>
                </v-row>                
              </v-flex>            
            </v-container>
          </v-card-text>
        <v-divider class="ma-0 pa-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" text @click.prevent="closeDialog">
            <span style="font-size: 18px">{{$t("disclaimer_notice.button_txt")}}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  // listen for global event calls called from FooterBar - or where ever
  import { eventBus } from '@/main'  // event bus communication for registered components
  import i18n from "@/i18n"          // i18n international language support for labels

  export default {
    name: "about-site",
    data: () => ({
        dialog: false,
        scrollInvoked: 0,
        toggle_list1: 0,    
        dialog_title: i18n.t('about_site_title'),
        value: 1,
        key: 111,
        text: '',
        isMobile: ( (/Mobile/i.test(navigator.userAgent)) ), 
        items: [ // loads default content from locales  en.json file
          { id:0, title: i18n.t('about_site_title'), icon:'fa-solid fa-globe', content: i18n.t('about_site_txt') },
        ],        
    }),
    // life cycle methods
    // when this component is added to page - it listens for a specific event
    mounted: function() {
       // setup popup dialog listener when component is added to footer template
       eventBus.$on("show-about-site", this.showAboutSite);
    },
    // user defined methods go here
    methods: {
      // load content depending on which toggle but is clicked. 
      // defaults to the first item id=0
      getContent( ) {
         // re-load contents
         this.showAbout();
      },
      // re-highlight toggle button 0 - since it operates as a 'on/off' process
      resetToggle() {
         setTimeout(()=>{ this.toggle_list1=0; }, 150 );
      },      
      showAboutSite() {
        // basically just sets show = true to show dialog  
          this.$loading(true);
             this.text = '';  // remove any content to reset scroll
            // request report from back-end to retrieve selected Rss Feed
            this.axios.get('/rs/utils/document?file='+'AboutSite.html',{headers:{'api-ref':this.xKey}}).then((response) => {
                  // clear any existing data - reset scroll window position
                  //console.log( response.data  ); // <- later comment out
                  var txt = response.data;
                  // **** just display default vaule from locales/en.json file ****
                  if( txt === null || txt.length <= 10 ) {
                      throw new Error('About Site Document Not Found - using default i18n !');
                  } else {
                      // assign report to the RssFeedViewer - here
                      this.text = txt;
                      // set scroll back to top
                  }
            }).catch( err => { 
                // show user default Services in en.json file - but this is old
                this.text = i18n.t('about_site_txt');                
                console.log( err );
            });
        // stop loading spinner
        this.$loading(false);
        // show popup dialog with Services and Cookie documents
        this.dialog=true;
        //console.log( i18n.t("footer.terms")  );
      },
      closeDialog() {
        // close dialog window
        this.dialog = false
      },
      onScroll () {
        // to track scrolling of card window
        this.scrollInvoked++
      },      
    },
    metaInfo() {
        return {
          title: "Stock-Insight - About Our Site Tools, Charts & News",
          meta: [
            { name: 'robots', content: 'index,follow'},
            { property: 'og:type', content: 'website'}, 
            { property: 'og:site_name', content: 'www.Stock-Insight.net'},
            { property: 'og:title', content: "Stock-Insight - About Our Site Tools, Charts & News"},                             
            {
              vmid: "description",
              name: "description",
              content: "Stock-Insight - About Our Site Tools, Charts, & News"
            }
          ],
          link: [
            {
              rel: 'canonical',
              href: '<https://www.stock-insight.net/>'
            }
          ]
        }
      }            
  }
</script>

<style scoped>
  .aa {
    background: rgb(25, 0, 255) !important;
  } 
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow-y: scroll;
}  
</style>