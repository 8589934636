<!--------------------------------------------------------------------------------

    Add to Favorites button component - also removes if already saved

    all functionality is contained here - to store menu links to bookmarks array for later display

----------------------------------------------------------------------------------->

<template>
    <div class="ma-0 pa-0">
        <v-row dense class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="12">
               <v-btn small class="ma-0 pa-0" @click.stop="checkBookMarks" rounded fab :color="this.isBookmarked? 'green':'grey'"> <v-icon>fa-solid fa-book-bookmark</v-icon></v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
// listen for and allow broadcasts to components
//import { eventBus } from '@/main'  // event bus communication for registered components

/* very important to load menu items from javascript external data file - 1 place  */
import toolsMenuItems    from '@/components/menus/Data_Arrays/tools_menu'
import chartsMenuItems   from '@/components/menus/Data_Arrays/charts_menu'
import newsMenuItems     from '@/components/menus/Data_Arrays/news_menu'  
import videoMenuItems    from '@/components/menus/Data_Arrays/video_menu'    
import trendsMenuItems   from '@/components/menus/Data_Arrays/trends_menu'  
import podcastsMenuItems from '@/components/menus/Data_Arrays/podcasts_menu'    

// link used for re-freshing App
const url = new URL( '/', window.location.origin )

export default {
    name: "Book-mark",
    components: { },
    // track link when placed on a component 
    props: {
        // track actual html link passed when 
       link: String
    },
    head: {
      // grab all menu item arrays - track bookmarked items
      script: [
        { src: '@/components/menus/Data_Arrays/tools_menu.js',    async: true, defer: true },
        { src: '@/components/menus/Data_Arrays/charts_menu.js',   async: true, defer: true },
        { src: '@/components/menus/Data_Arrays/news_menu.js',     async: true, defer: true },  
        { src: '@/components/menus/Data_Arrays/video_menu.js',    async: true, defer: true },
        { src: '@/components/menus/Data_Arrays/podcasts_menu.js', async: true, defer: true },
        { src: '@/components/menus/Data_Arrays/trends_menu.js',   async: true, defer: true }                                         
      ],
    },    
    data: () => ({
        // determines on object creation if running on mobile device true or false - although hard coded init
        isMobile: ( (/Mobile/i.test(navigator.userAgent) ) ),
        isBookmarked : false,
        // *** see 'AppBarToolsMenu' and 'AppBarReportsMenu' for desktop machines menu options
        tools:    toolsMenuItems,
        charts:   chartsMenuItems,
        news:     newsMenuItems,
        video:    videoMenuItems,
        podcasts: podcastsMenuItems,
        trends:   trendsMenuItems,        
    }),
    // check for component mount
    mounted() {
        this.checkIfBookMarked();
    },
    methods: {
         // called once component is mounted for view
         checkIfBookMarked() {
            var bookmarks = JSON.parse( localStorage.getItem("bookmarks") || "[]" ); 
            try {            
                if( bookmarks && !bookmarks.find( o => o.route.name === this.link ) ) {
                    this.isBookmarked = false;
                } else {
                    this.isBookmarked = true;
                }
            } catch(err) {
                this.$swal({ title: "Shortcut Problem!", text: "Link Issue: [Remove All] to Reset.", icon: "error", footer: err });
            }
         },
         // button clicked calls this method
         checkBookMarks() {
            var bookmarks = JSON.parse( localStorage.getItem("bookmarks") || "[]" ); 
            try {
                if( bookmarks && !bookmarks.find( o => o.route.name === this.link ) ) {
                    // link not in list - give user chance to add
                    this.addToFavorites();
                } else {
                    // link already recoded - give user chance to remove bookmark
                    this.removeFromFavorites();
                }
            } catch(err) {
                this.$swal({ title: "Shortcut Problem!", text: "Link Issue: [Remove All] to Reset.", icon: "error", footer: err });
            }
         },
         addToFavorites() {
            this.$swal({
                position: 'center',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showCancelButton: true,  
                cancelButtonText: "No",              
                showConfirmButton: true,
                confirmButtonText: "Yes",
                icon: 'question',
                title: "Add Shortcut",
                text: "Add this to your 'Favorites' List",
                footer: "Save to 'Favorites' for quicker viewing",
            }).then((result) => {
                if( result.isConfirmed ) {
                    // get current localStorage bookmarks
                    var bookmarks = JSON.parse( localStorage.getItem("bookmarks") || "[]" ); 
                    try {
                        //--- check tools menu - first -- then the others
                        var menuItem = toolsMenuItems.find( o => o.route.name === this.link );
                        if( !menuItem ) {
                            // if not tools - check Charts Menu
                            menuItem = chartsMenuItems.find( o => o.route.name === this.link );
                        } 
                        if( !menuItem ) {
                            // if not Charts Menu item - check News Menu
                            menuItem = newsMenuItems.find( o => o.route.name === this.link );
                        }                     
                        if( !menuItem ) {
                            // if not News menu item - check Video Menu
                            menuItem = videoMenuItems.find( o => o.route.name === this.link );
                        }  
                        if( !menuItem ) {
                            // if not Video menu item - check Podcasts
                            menuItem = podcastsMenuItems.find( o => o.route.name === this.link );
                        }  
                        if( !menuItem ) {
                            // if not Podcast Menu item - check Trends Menu Item
                            menuItem = trendsMenuItems.find( o => o.route.name === this.link );
                        }   
                        // - should be one of the defined menu items ... else problem                   
                        if( menuItem ) {
                            var bookmarked = bookmarks.find( o => o.route.name === this.link );
                            if( ! bookmarked ) {
                                // if not in bookmarks -> store to bookmarks
                                bookmarks.push( menuItem );
                                //console.log( menuItem );
                                // re-store bookmarks to localStorage
                                localStorage.setItem( "bookmarks", JSON.stringify( bookmarks ) );                        
                            }
                            // reset color of icon
                            this.checkIfBookMarked();
                        }   
                    } catch(err) {
                        this.$swal({ title: "Shortcut Problem!", text: "Link Issue: [Remove All] to Reset.", icon: "error", footer: err });
                    } 
                } 
            })           
        },
        removeFromFavorites() {
            this.$swal({
                position: 'center',
                allowOutsideClick: false,
                allowEscapeKey: false,
//                showCancelButton: true,  
//                cancelButtonText: "Remove All",              
                showConfirmButton: true,
                confirmButtonText: "Yes",
                showDenyButton: true,
                denyButtonText: 'No',
                icon  : 'question',
                title : "Remove Shortcut",
                text  : "Remove from 'Favorites' List",
                footer: "Delete Saved 'Favorites' Shortcut",
                customClass: {
                    actions: 'bookmk-actions',
                    confirmButton: 'order-1',
                    denyButton:    'order-2',                            
                //    cancelButton:  'order-3 left-gap', 
                },
            }).then((result) => {
                var bookmarks = JSON.parse( localStorage.getItem("bookmarks") || "[]" );                 
                if( result.isConfirmed ) {
                    // localStorage.setItem("lastname", "Smith");
                    try {
                       if( bookmarks && bookmarks.find( o => o.route.name === this.link ) ) {
                          // filter out saved link
                          var newBookmarks = bookmarks.filter( e => e.route.name !== this.link );
                          localStorage.setItem("bookmarks", JSON.stringify( newBookmarks ));
                          this.isBookmarked = false;
                        } 
                    } catch( err ) { 
                       this.$swal({ title: "Shortcut Problem!", text: "Link Issue: [Remove All] to Reset.", icon: "error", footer: err });
                    }
                    // reset color of icon
                    this.checkIfBookMarked();
                } else if( result.isDenied ) {
                    // cancelled - Do nothing - user said NO
                } else if( result.isDismissed ) {
                    // remove all confirmation dialog - user must confirm
                    this.$swal({
                            title : "Remove All Shortcuts?",
                            text  : "Are you sure? You Won't be able to revert this!",
                            icon  : "warning",
                            showCancelButton  : true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor :  "#d33",
                            confirmButtonText :  "Yes, delete!",
                            footer:'If confirmed, this action will remove all your shortcuts.'
                            }).then((result) => {
                               if(result.isConfirmed ) {
                                   // wipe out all bookmarks
                                   localStorage.setItem("bookmarks", "[]"); 
                                   this.isBookmarked = false;
                                   // reset color of icon
                                   this.checkIfBookMarked();
                                   this.$swal({ title: "Deleted!", text: "All Shortcuts Removed.", icon: "success" });
                                   // refresh App - to show default splash screen
                                   // call to re-check bookmarks from S3 - error will set default bookmarks - start over - in 2 secs
                                   setTimeout( ()=>  window.location.href = url.toString(), 3500 );
                               }
                     });
                }            
            })            
        }
    },
   
}
</script>