<!--
   Displays Rss Feed Data from Rss Feed Parent ie: Nasdaq  <span v-html="rssFeed"></span>
-->
<template>
    <v-container >
        <div v-if="showSearch">
            <v-toolbar color="white" dark flat width="auto" height="25px">
                    <!-- appears on mobile only devices -->
                    <v-toolbar-title  class="text-left text-h6" >
                        <!-- *** show search button for only SEC filings *** -->
                        <v-btn @click.prevent.stop="searchRecords" class="ml-1" rounded small dark color="blue">
                            <v-icon left dark>fa-solid fa-magnifying-glass-location</v-icon>Search Filings
                        </v-btn> 
                    </v-toolbar-title>
            </v-toolbar> 
        </div>
        <span v-html="rssFeed"></span>
    </v-container>
</template>

<script>
    export default {
        name: "RssFeedViewer",
        // listen from parent to update data and pass feed as 'props' to this component for showing
        //props: [ 'rssFeed' ], <- old setting just feed data passed to this component
        props: { rssFeed: String, showSearch: Boolean },
        data() {
            return {
               isMobile: (/Mobile/i.test(navigator.userAgent) ),
            }    
        },
        methods: {
          searchRecords() {
            this.$swal({
                position: 'center',
                allowOutsideClick: true,
                allowEscapeKey: true,
                showCancelButton: true,  
                cancelButtonText: "Remove All",              
                showConfirmButton: true,
                confirmButtonText: "Yes",
                icon: 'question',
                title: "Search",
                text: "Find Records",
                footer: "Search Current Records on File",
                customClass: {
                    actions: 'bookmk-actions',
                    confirmButton: 'order-1',
                    denyButton:    'order-2',                            
                },
            }).then((result) => {
                    if( result.isConfirmed ) {
                        this.$swal({
                        title: 'Record Search Tool',
                        text:  'Enter Any Valid Record ID',
                        id:    "rssRecordSearch",
                        inputAutoFocus: true,                
                        inputAutoTrim: true,
                        input: 'text',
                        inputPlaceholder: 'File Number',
                        inputAutoFocus: true,
                        inputAttributes: { autocapitalize:"on", minlength: 1, maxlength: 35 },   
                        showLoaderOnConfirm: true,
                        confirmButtonText: 'Search',
                        showCancelButton: true,
                        //footer: "Exchanges: NYSE NASDAQ AMEX",
                    }).then((result) => {
                        if( result.value && result.value != "" ) {
                            this.$loading(true);
                            try {
                                // check for any XSS tags inserted in form - from utils.js
                                if( checkForSpecialChar( result.value ) ) {
                                  // ignore if any special characters are found in input
                                  //console.log('Ignored XSS Attack: '+ result.value );
                                  this.$swal.close();
                                  this.$swal({ icon: "error", title: "Oops...", text: "Invalid Data Entered!", footer: 'Only Valid Record IDs are Accepted' });                          
                                } else {
                                    var id = result.value.trim();
                                    //console.log("Symbol parsed returned: -> "+ticker);
                                    /* enter possible ticker submitted by user
                                    if( id ) {
                                        this.symbol = ticker;
                                        // *** post update to all listening sub components **********************
                                        eventBus.$emit("ticker-update", this.symbol );  
                                        // save to localStorage to bring up on a 'refresh' or restart of App
                                        localStorage.setItem( "lastSeachedSymbol", this.symbol );
                                        // update statistics from TV
                                        setTimeout( ()=> this.updateSelection( this.symbol ), 1000 );                                       
                                        //***********************************************************************                           
                                    }
                                    */
                                    // close first popup search
                                    this.$swal.close();                            
                                }
                            } catch(err) { console.log( err ); }
                            // 1 sec delay - then remove loading indicater       
                            setTimeout( ()=> this.$loading(false), 1250 );
                            //console.log( "Selected: "+ result.value   showModal:true, ); 
                        }

                    })                
                
                }

            })            
        }            
        }
    }
</script>

<style>
   /* uses styles defined in top index.html [ css/w34.css ] */
</style>