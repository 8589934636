<!-- 
     CNBC Feeds [  ]  
-->
<template>
  <v-layout class="mt-5" align-center justify-center column >
    <v-flex row align-center>  
      <!-- START DESKTOP CARD - Toolbar and Tabs for report selection -->      
      <v-card class="mx-auto mb-15 flexcard" :min-width="this.cardMinWidth" :width="this.cardWidth" :height="this.cardHeight" hover>
        <div v-if="this.isMobile">
            <v-toolbar color="cyan" dark flat width="auto" height="75px" src="@/assets/cnbc_title_background.png">
                <!-- appears on mobile only devices -->
                <v-toolbar-title class="text-left text-h4 mt-2" >CNBC
                   <br/><v-chip x-small text-color="white" color="blue" >{{ this.getformattedDate() }}</v-chip>
                </v-toolbar-title>
                <div style="margin-left: auto; display: block;">
                    <Bookmark link="cnbcNews"/>
                </div>
            </v-toolbar> 
          </div>
          <div v-else>    
            <v-toolbar color="cyan" dark flat width="auto" height="45px" src="@/assets/cnbc_title_background.png">     
                  <v-toolbar-title class="text-left text-h4 mt-14" >CNBC
                    <br/><v-chip x-small text-color="white" color="blue" >{{ this.getformattedDate() }}</v-chip>
                  </v-toolbar-title>
                  <div style="margin-left: auto; margin-top: 15px; display: block;">
                      <Bookmark link="cnbcNews"/>
                  </div>
                <!-- TABS Section displayed at bottom of toolbar -->
                <template v-slot:extension >
                    <!-- mobile report bar -->
                    <v-btn color="blue" block v-if="isMobile == true" @click.stop="drawer = !drawer" >
                      <v-icon left >{{ listIcon }}</v-icon> {{ mobileRptBar }}
                    </v-btn>              
                </template>    
            </v-toolbar>   
        </div>       
        <!-- feed viewing component - where feeds are available for viewing on desktop -->
        <v-container fluid>
            <!-- toggle button list1 & list2 only appear on desktop  -->
            <v-row dense no-gutters class="ma-1 pa-0" v-if="isMobile === false"> 
              <!-- desktop button bar options list 1 -->
              <!-- loop through both feed lists to generate menu for top menu -->
              <v-col cols="12" class="ma-0 pa-0" >
                <v-btn-toggle v-model="toggle_list1" borderless dark rounded background-color="blue">
                  <v-btn v-for="item in items_1" active-class="aa" elevation="3" small :key="item.id" rounded @click.stop="getFeedReport(1,item.id,item.rpt)">
                     <v-icon left >{{ item.icon }}</v-icon>{{ item.name }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row dense no-gutters class="ma-1 pa-0" v-if="isMobile === false">              
                <v-col cols="12" class="ma-0 pa-0" >
                    <v-btn-toggle v-model="toggle_list2" borderless dark rounded background-color="blue">
                      <v-btn v-for="item in items_2" active-class="aa" elevation="3" small :value="item.id" :key="item.id" rounded @click.stop="getFeedReport(2,item.id,item.rpt)">
                        <v-icon left >{{ item.icon }}</v-icon>{{ item.name }}
                      </v-btn>
                    </v-btn-toggle>
                </v-col>   
            </v-row>   
            <!-- mobile report bar -->
            <v-btn color="#84FFFF" block v-if="isMobile === true" @click.stop="drawer = !drawer" >
                  <v-icon left >{{ listIcon }}</v-icon> {{ mobileRptBar }}
            </v-btn>       
            <!-- report section -->
            <v-row dense>  
              <v-col cols="12">
                  <!-- *** feed display section - all rss data will be displayed in the RssFeedViewer *** -->  
                  <!-- height adjusted to fit within the host parent card; desktop or mobile -->                
                  <v-card v-scroll.self="onScroll" class="overflow-y-auto" ref="rssViewer" :height="this.cardHeight-(this.isMobile?145:185)" >    
                      <RssFeedViewer :rssFeed="reportData" />                          
                  </v-card>                  
              </v-col>
            </v-row>
        </v-container>
      </v-card>
      <!-- *** START Left Drawer for small screens - uses tab v-card section to display report *** -->
      <v-navigation-drawer app v-model="drawer" color="blue" theme="dark" class="mt-0 pt-0 pb-15 mb-15" absolute temporary >
          <v-row dense no-gutters class="mb-0 pb-0">
              <v-col class="text-md-center" cols="12">               
                  <h4 class="ml-auto mb-0 pb-0 white--text">CNBC News</h4>  
              </v-col>
          </v-row>
          <v-divider dark style="height:3px;background-color:aqua" class="ma-0 pa-0" ></v-divider>             
          <v-list nav dense class="ma-0 pa-0">
            <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
              <!-- loop through both feed lists to generate menu for drawer -->
              <v-list-item v-for="item in items_1" :key="item.id" @click.prevent="getFeedReport( 1, item.id, item.rpt )" >
                   <!-- highlight first report id: 0 as 'active' link -->
                   <div v-if="item.id === 0">
                      <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">                
                         <v-icon id="fIcon" left class="initHighlighted" >{{ item.icon }}</v-icon>
                      </v-list-item-icon>     
                  </div>
                  <div v-else>
                      <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">                
                         <v-icon left >{{ item.icon }}</v-icon> 
                      </v-list-item-icon>     
                  </div>                  
                  <v-list-item-content class="ma-0 pa-0" :style="{'align-items':'left'}">
                      <v-list-item-title id="listFont" class="ListItemClassInActive">{{ item.name }}</v-list-item-title>
                  </v-list-item-content>  
              </v-list-item> 
              <v-list-item v-for="item in items_2" :key="item.id" @click.prevent="getFeedReport( 2, item.id, item.rpt )">
                  <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">
                     <v-icon id="fIcon" left >{{ item.icon }}</v-icon> 
                  </v-list-item-icon>                      
                  <v-list-item-content :style="{'align-items':'left'}">
                      <v-list-item-title id="listFont" class="ListItemClassInActive" >{{ item.name }}</v-list-item-title>
                  </v-list-item-content>                      
              </v-list-item>      
            </v-list-item-group>
          </v-list>
      </v-navigation-drawer>  
    </v-flex>
  </v-layout>         
</template>
  
<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"  

    // date js utility for formatting current NYSE date
    import dayjs from "dayjs"      
    
    // listen for and allow broadcasts to components
    import { eventBus }  from '@/main'  // event bus communication for registered components

    // user bookmarking component
    import Bookmark      from '@/components/AddToFavorites.vue'             

    // add embedded feed viewer for customizing feed
    import RssFeedViewer from '@/components/views/RssFeedViewer.vue'

    // use NY Date info for unigue key to sync with server - basic security
    var tNYDate = new Date(new Date().toLocaleString('en', {timeZone:'America/New_York'}));  

    export default {
      components: { RssFeedViewer, Bookmark },
      data: () => ({
        scrollInvoked: 0,
        showAdsID : 0,
        hideAdsID : 0,  
        time_delay: Number( process.env.VUE_APP_ADS_DELAY ), 
        timestamp: new Date(new Date().toLocaleString('en',{timeZone:'America/New_York'})),        
        toggle_list1: 0,    
        toggle_list2: null,        
        valid: false,
        drawer: false, 
        group: null,               
        // grabs locale from environment setup in start of app
        locale: i18n.locale,        
        // determines on object creation if running on mobile device true or false
        isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),
        // *** following settings are designed for desktop - flipped if 'mobile' before mount ***              
        cardMinWidth: 650,
        //cardWidth: 900,
        cardWidth: "80%",
        cardMaxWidth: "95%",
        cardMinHeight:850,
        cardHeight: 1000,
        feedName: '',
        tab: null,
        show: false,
        listIcon: 'fa-solid fa-list',
        mobileRptBar: i18n.t('rss_mobile_reportbar'),
        mode: sessionStorage.getItem('mode'),
        //---------- SECURITY CHECKS SENT to SERVER -------------------------------*
           xKey: tNYDate.getFullYear()+(tNYDate.getMonth()+1)+(tNYDate.getDate()),
          x2Key: tNYDate.getFullYear()*(tNYDate.getMonth()+1)*(tNYDate.getDate()),          
        //-------------------------------------------------------------------------*  
        reportData: 'Report Viewer Section', 
        // menu items for tabs and left drawer component on mobile  
        // { id:1,name:'News',rpt:'Nasdaq_news',title:'General News',icon:'mdi-newspaper-variant-multiple'},
        items_1: [
              { id:0, name:'Earnings', rpt:'CNBC_earnings', title:'CNBC Earnings',icon:'mdi-cash-multiple'},           
              { id:1, name:'News',     rpt:'CNBC_news',     title:'CNBC Top News',icon:'mdi-newspaper-variant-multiple'},           
              { id:2, name:'Finance',  rpt:'CNBC_finance',  title:'CNBC Finance',icon:'mdi-finance'},                            
              { id:3, name:'Investing',rpt:'CNBC_investing',title:'CNBC Investing',icon:'mdi-cash-sync'},                                                                                   
              { id:4, name:'MadMoney', rpt:'CNBC_madmoney', title:'CNBC MadMoney',icon:'mdi-account-tie-voice-outline'},             
        ],  
        items_2: [
              { id:5, name:'Insider',  rpt:'CNBC_insider',   title:'CNBC Insider',    icon:'fa-solid fa-person-shelter'},           
              { id:6, name:'Trader',   rpt:'CNBC_traderTalk',title:'CNBC Trader Talk',icon:'fa-solid fa-comments-dollar'},           
              { id:7, name:'Economy',  rpt:'CNBC_economy',   title:'CNBC Economy',    icon:'fa-solid fa-circle-dollar-to-slot'},                            
              { id:8, name:'Business', rpt:'CNBC_business',  title:'CNBC Business',   icon:'fa-solid fa-business-time'},                                                                                   
              { id:9, name:'Wealth',   rpt:'CNBC_wealth',    title:'CNBC Wealth',     icon:'fa-solid fa-sack-dollar'},             
        ],                               
      }),
      beforeMount() {
        // show loading spinner - just before component is mounted to app
        this.$loading(true)

        // remove any Ad listeners 
        this.adCleanUp()

        //*** re-set card/widget measurements if running on mobile device before mount ***
        if( this.isMobile ) {
            //this.cardWidth=375;
            this.cardWidth="90%"
            this.cardMinWidth=325;
            this.cardMaxWidth="95%";
            this.cardHeight=1000;
        }
        //*****************************************************/            
      },
      mounted() {
        // 1 sec delay - then remove loading indicater       
        setTimeout( ()=> this.$loading(false), 1000 ); 

        // check for setting - time out is set in .env file for ads 
        if( this.time_delay <= 0 ) { this.time_delay = 2500; }

        // clear any previous data - to start on first story
        this.reportData = '';

        // *** get first report on mount - Commodities is the first button ***
        this.getFeedReport( 1, 0, this.items_1[0].rpt );

      },
      beforeUnmount() {
         // remove Ad listeners
         this.adCleanUp()
      },           
      // called when component is removed
      unmounted() {
        // clear any 'refresh' data attempts before exiting component
        sessionStorage.removeItem("refresh");
      },
      methods: {
        onScroll () {
           // to track scrolling of card window
           this.scrollInvoked++
        },
        getformattedDate() {
           var day = dayjs( this.timestamp );
           return( "Current as of: "+day.format( 'MM-DD-YYYY HH:mm' ) );           
        }, 
        adCleanUp() {
          // close right drawer
          eventBus.$emit("hide-side-ads","hideSideAds");  
          // stop any timeout events by assigned ID - before creating any new ones
          clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );
        },
        showHideAds() {
          this.adCleanUp();
          var delay = this.time_delay;
          // show ads - if enabled in .env file - defined in BottomSheetAds component - record id for clearing
          this.showAdsID = setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), delay );  // record timeout ID -  for clearing
          //console.log("showAdsID = "+this.showAdsID);    
          delay = delay * 3;
          // send event to parent (AppBar.vue) to close Ads right drawer x3 delay
          this.hideAdsID = setTimeout( ()=> eventBus.$emit("hide-side-ads","hideSideAds"), delay );   // record timeout ID - for clearing  
          //console.log("shideAdsID = "+this.hideAdsID);    
        },               
        refreshMsg() {
            this.$swal({
                title: 'New Data is Available',
                text:  'Refreshing Data List, Please Wait ...',
                icon:  'info',
                showCloseButton: false,
                showLoaderOnConfirm: false
            }).then(() => {  });
        },                  
        /**************************************************************************************
         * this method is assigned to each button generated by looping through List1 and List2
         * when clicked it will make an axios call to retrieve the requested Rss feed from
         * the back end servlet which will pull it from the S3 bucket.
         * 
         * ListNo is passed to clear other button list so it is not highlighted/selected
         * no button group is cleared if zero 0 is passed
         *************************************************************************************/      
         getFeedReport( listNo, btnId, rptName ) {          
              this.$loading(true); 
              // add timeout to reselect button if clicked multiple times
              setTimeout( ()=> { this.toggle_list1 = btnId; }, 150 );
              // clear any previous data - to start on first story
              this.reportData = '';   
              // side drawer group is assigned 0 during creation - so ignored
              if( listNo === 1 ) {
                  //clear other row of buttons
                  this.toggle_list2 = null;
                  this.toggle_list1 = btnId;
                  // add timeout to reselect button if clicked multiple times
                  setTimeout( ()=> { this.toggle_list1 = btnId; }, 150 );
                  //console.log( "TL_1 ="+this.toggle_list1+" | TL_2 ="+this.toggle_list2 );
              } else {
                  //clear other row of buttons
                  this.toggle_list1 = null;
                  this.toggle_list2 = btnId;
                  // add timeout to reselect button if clicked multiple times                   
                  setTimeout( ()=> { this.toggle_list2 = btnId; }, 150 );
                  //console.log( "TL_1 ="+this.toggle_list1+" | TL_2 ="+this.toggle_list2 ); 
              }
              // add timeout to reselect button if clicked multiple times
              setTimeout( ()=> { this.toggle_list1 = btnId; }, 150 );             
              // request report from back-end to retrieve selected Rss Feed
                this.axios.get('/rss?report='+rptName,{timeout:5000,headers:{'api-ref':this.xKey,'sc-ch-nx':this.x2Key,'mode':this.mode}}).then((response) => {
                      // clear any existing data - reset scroll window position
                      this.scrollInvoked=0;                    
                      //console.log( response.data  ); // <- later comment out
                      var rssFeedData = response.data;
                      // **** throw error to popup message - invalid symbol ****
                      if( rssFeedData === null || rssFeedData.length <= 10 ) {
                          throw new Error('CNBC News Error');
                      } else {
                          // store feed info to data element 'text' for display - which
                          // is created under a tab section ... reference back to that tab first
                          //   this.$refs[rptName];
                          // assign report to the RssFeedViewer - here
                          this.reportData = rssFeedData;
                          // set scroll back to top
                          this.scrollInvoked=0;
                          // slight delay to show screen - remove any refresh data tries
                          setTimeout( ()=> { this.$loading(false); sessionStorage.removeItem("refresh"); }, 1000 );
                          // recall to slide-out ads - show ads - if enabled in .env file 
                          this.showHideAds();                               
                      }
                }).catch( (err) => { 
                    this.$loading(false);
                    var xErr = "<div class='w3-row w3-margin-bottom'>"+    
                                "<div class='w3-card-4' style='width:100%'>"+
                                "<header class='w3-container w3-red w3-left-align'>"+
                                "<h3>Updated CNBC Data Available</h3>"+
                                "<h6>Downloading Please Wait ...</h6></header>"+
                                "<div class='w3-container w3-left-align'>"+
                                "<p></p><p>"+i18n.t('rss_err_msg.action') +"</p>"+
                                "</div></div></div>";
                          console.log( err );
                      // show user by udating - report data for the view
                      this.reportData = xErr;
                      // reload application - probably due to connection timeout or stale data list
                      // *** check for previous try attempt to refresh in session storage ***
                      var refresh = sessionStorage.getItem("refresh");
                      // loader
                      //setTimeout( ()=> { this.$loading(true); }, 2500 );    
                      // after previous attempt - to reload the list - direct to 'home'
                      if( refresh != null ) {
                          // first remove 'reload flag' from sessionStorage - redirect 'home'
                          sessionStorage.removeItem("refresh");
                          setTimeout( ()=> { this.$loading(true); }, 2500 );   
                          setTimeout( ()=> { window.location.href="/"; }, 3500 ); 
                          //console.log( "refresh found & removed - redirected HOME ... " );
                      } else {
                          // **** try refreshing this page data on a reload  1x only ****
                          this.refreshMsg();                                 
                          // set storage item - 'refresh' before reloading this page
                          sessionStorage.setItem( "refresh", 'true' );
                          //console.log( "refresh SET - reload page .. " );                          
                          setTimeout( ()=> { window.location.reload(); }, 1500 );  
                      }
                });
                // remove highlighed default mobile report icon on first load - 'must be a better way' ...
                if( this.isMobile && btnId != 0 ) {
                    var icon = document.getElementById("fIcon");
                    icon.classList.remove("initHighlighted");
                }
          },
      },
      watch: {
          group () { this.drawer = false },
      },
      metaInfo() {
        return {
          title: "Stock-Insight's CNBC Latest News Feed",
          meta: [
            { name: 'robots', content: 'index,follow'},
            { name: 'description', content: 'CNBC Latest News Feeds' },
            { name: 'keywords', content: 'cnbc, stock, financial, news, feed' },                  
            { property: 'og:type', content: 'website'}, 
            { property: 'og:site_name', content: 'www.Stock-Insight.net'},
            { property: 'og:title', content: "Stock-Insight's CNBC Latest News Updates"},                             
            {
              vmid: "description",
              name: "description",
              content: "A Stock Related News Feed from CNBC News"
            }
          ],
          link: [
            {
              rel: 'canonical',
              href: '<https://www.stock-insight.net/>'
            }
          ]
        }
      }                        
    }
</script>

<style scoped>
  .v-tab--active {
      background-color:rgba(22, 174, 22, 0.3); 
  }
  .v-list-item__content {
      text-align: left;
      color: white; 
  }
  .v-list-item__title {
      padding-left: 10px;
      font-weight: bold; 
      height: 24px;
  }
 
  .aa {
    background: rgb(25, 0, 255) !important;
  }
  .ListItemClassActive {
    color: #0556f9;
  }
  .ListItemClassInActive {
    color: #f1f8fa;
   }
  .v-list-item__icon v-list-group__header__prepend-icon {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
  } 

  .v-list-item--active .subListIcon .v-icon {
     background-color: lightgreen;
  }
  .v-list-item--active .v-list-item__title {
     color: rgb(253, 250, 250);
     font-weight: bold;
  } 
  .subListIcon {
    font-size: 16px;
  }  
  .subListItem {
    font-size: 24px;
  }    
  .initHighlighted {
    background-color: lightgreen;
  }  

  #listFont {
     font-size: 16px;
     padding-top: 5px;
  }
</style>

