<!------------------------------------------------------------------------------------------

    Advanced WatchList TradingView Chart - with :key to trigger reload component - refresh
    used on 'Real-Time' Charts component tab  :key="this.symbol"
   :key="this.symbol"
------------------------------------------------------------------------------------------->
<template>
    <v-container fluid class="fill-height" :key="this.symbol" > 
        <v-layout justify-center fluid align-center>    <!-- min-width="225" width="550" max-width="600" -->   
            <!-- TradingView Widget BEGIN -->
            <div class="tradingview-widget-container" style="height:100%;width:100%">
                <div class="tradingview-widget-container__widget" style="height:calc(100% - 32px);width:100%"></div>
                <div class="tradingview-widget-copyright"><span class="blue-text">Watch List Chart</span> by TradingView</div>
                <script type="application/javascript" defer src="https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js" async>
                    {
                        "container_id": "watchlist-chart-demo",
                        "width": "{{ this.widgetWidth }}",
                        "height": "{{ this.widgetHeight }}",
                        "autosize": true,
                        "symbol": "{{ this.symbol }}",
                        "interval": "D",
                        "timezone": "exchange",
                        "theme": "light",
                        "style": "1",
                        "withdateranges": true,
                        "allow_symbol_change": false,
                        "save_image": false,
                        "watchlist": [{{this.selectedTickers}}]
                    }
                </script>
            </div>
            <!-- TradingView Widget END -->
        </v-layout>
    </v-container>        
</template>

<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    // "watchlist": [ "AAPL", "IBM", "TSLA", "AMD", "MSFT", "GOOG" ]
    import i18n from "@/i18n"  
   
    import { eventBus } from '@/main'  // event bus communication for registered components

     export default {
        // need to pass ticker in props to sync all child components 
        props: { ticker: { type: String, default: '' } },
        data: () => ({
                locale: i18n.locale,
                value: null,
                // determines on object creation if running on mobile device true or false
                isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))),
                // *** following settings are designed for desktop - flipped if 'mobile' before mount ***   650           
                cardWidth: "100%",
                widgetWidth: "100%",
                widgetHeight: 695,  
                symbol: "AAPL",                // ticker feed passes 'tvwidgetsymbol' params to this component
                watchlist: ["AAPL"],           // default settings
                showAdsID : 0,
                hideAdsID : 0, 
                selectedTickers: '["AAPL"]' ,  // displayed in Watchlist of chart
                time_delay: Number( process.env.VUE_APP_ADS_DELAY ),  // retrieve setting from .env file   
                errors: {},                   
            }),
/*           
        watch: {
               // whenever selectedTickers are changed or updated from watchlist
               selectedTickers( newValue, oldValue ) {
                  // this.$forceUpdate();
                  console.log( 'Org: '+oldValue+' New: '+newValue );
               }
        },   
*/                
        methods: {
            forceRerender( ticker ) {
               // when component is updated -- also update selected ticker list - watchlist from localstorage
               //********* UPDATE Current WatchList from each tab click event ***********/  
                // set spinner - to hide screen update
                   this.$loading(true);
                // update watchlist
                this.watchlist  = JSON.parse( localStorage.getItem("watchlist") );
                var lsTicker    = localStorage.getItem("lastSeachedSymbol");

                if( lsTicker && this.watchlist.includes( lsTicker ) ) {
                    // do nothing already in watchlist
                    console.log('watchlist - includes ticker skipping ... ['+lsTicker+']');
                } else {
                    // if last searched ticker object exists - add to watchlist - here ***
                    if( lsTicker ) {
                        // step 1 get current watchlist
                        var wList = this.watchlist;

                        // step 2 *** update watchlist with new ticker - since not in list
                        wList.push( lsTicker );

                        // step 3 important for WatchList Graph to be constructed **** from parsed watchlist - as in the following
                        this.selectedTickers  = JSON.stringify( wList ).replace(/[\])}[{(]/g,''); 
                        //console.log("Updated Selected Tickers for WL "+this.selectedTickers);                        

                        // step 4 *** update localstorage watchlist *** re-stamp values
                        localStorage.setItem('watchlist', JSON.stringify( wList ) );

                        // step 5 get updated list from storage to update current data object
                        this.watchlist  = JSON.parse( localStorage.getItem("watchlist") );

                        // **** temporary reset data object symbol to trigger component to refresh it's self ****
                        this.symbol = "";

                        // trigger update - from event passed ticker symbol for this chart - by changing key
                        setTimeout( ()=> { this.symbol = ticker }, 250 );   
                    }
                }
                // hide spinner
                setTimeout( ()=> { this.$loading(false); }, 1000 ); 
               //************************************************************************/               
               //   this.componentKey = this.symbol;  
            },            
            tickerUpdate( ticker ) {
                // update component symbol from 'emitted' event call from parent
                this.forceRerender( ticker );
                //console.log('watchlist - recieved event from parent to update list ... ['+ticker+']');
            },
            // called from watchlist Dialog to remove tickers from selected tickers data
            tickerRemovals( tickers ) {
                var xSymbol = this.symbol;  // record current symbol 

                // 1st update watchlist - get from local storage latest update
                this.watchlist        = JSON.parse( localStorage.getItem("watchlist") );

                // step 2 important for WatchList Graph to be constructed **** from parsed watchlist - as in the following
                this.selectedTickers  = JSON.stringify( this.watchlist ).replace(/[\])}[{(]/g,''); 

                // **** temporary reset data object symbol to trigger component to refresh it's self ****
                this.symbol = "";

                // trigger update - from event passed ticker symbol for this chart - by changing key
                setTimeout( ()=> { this.symbol = xSymbol }, 250 );   

                console.log( "Watchlist Ticker Removal Event Called: "+tickers );
            },
            chkLastSearchedSymbol() {
                return( this.lastSeachedSymbol && this.lastSeachedSymbol != "" );
            }   
        },
        beforeMount() {
            // set spinner
            this.$loading(true);
            var symbol = localStorage.getItem( "lastSeachedSymbol" ); 
            // update symbol if in local storage - set default to Apple
            if( symbol ) { this.symbol = symbol } else { this.symbol = "AAPL"}
            // retrieve saved tickers for watchlist
            var wlTickers = localStorage.getItem("watchlist")
            if( wlTickers ) {
               this.watchlist       = JSON.parse( localStorage.getItem("watchlist") );
               // important for WatchList Graph to be constructed **** from parsed watchlist
               this.selectedTickers = JSON.stringify( this.watchlist ).replace(/[\])}[{(]/g,'');
               //console.log( "PRE_SELECTED = "+this.selectedTickers );
            } else {
               // *** set default ticker to AAPL - if not found in localstorage ***
               this.watchlist = "AAPL";

               //localStorage.setItem('watchlist','["AAPL"]');
               this.selectedTickers = JSON.stringify( this.watchlist ).replace(/[\])}[{(]/g,'');
            }
        },
        mounted() {
            // *** listen for Ticker Change - from parent component sent as an event *** 1x only
            //eventBus.$once("ticker-update", this.tickerUpdate, 'ticker' );
            eventBus.$on("ticker-update", this.tickerUpdate, 'ticker' );

            // -- listen for ticker removals from 'watchlistDialog'
            eventBus.$on("ticker-deletes", this.tickerRemovals, 'tickers' );

            // 1.5 sec delay - then remove loading indicater       
            // clear wait spinner             
            setTimeout( ()=> { this.$loading(false); }, 1500 );
        },
        beforeDestroy() {
            // remove event when component is removed
            eventBus.$off("ticker-update");
        }
    }
</script>

<style scoped>

</style>