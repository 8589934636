
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// both Calls and Puts data store
export default new Vuex.Store({
  state: {
    call_expiration: '',
    call_strikePrice: '',
    call_contracts: '',
    call_optionPrice: '',
    call_currentPrice: '',  
    call_expectedStockPrice: '',
    puts_expiration: '',
    puts_strikePrice: '',
    puts_contracts: '',
    puts_optionPrice: '',
    puts_currentPrice: '',  
    puts_expectedStockPrice: '', 
    margin_days_borrowed: '',
    margin_amount_borrowed: '',
    margin_rate: ''   
  },
  getters: {
    // getter CALLS section
    call_expiration: state => state.call_expiration,
    call_strikePrice: state => state.call_strikePrice,
    call_contracts: state => state.call_contracts,
    call_optionPrice: state => state.call_optionPrice,
    call_currentPrice: state => state.call_currentPrice,
    call_expectedStockPrice: state => state.call_expectedStockPrice,
    // getter PUTS Section    
    puts_expiration: state => state.puts_expiration,
    puts_strikePrice: state => state.puts_strikePrice,
    puts_contracts: state => state.puts_contracts,
    puts_optionPrice: state => state.puts_optionPrice,
    puts_currentPrice: state => state.puts_currentPrice,
    puts_expectedStockPrice: state => state.puts_expectedStockPrice,  
    // getter MARGIN Section
    margin_days_borrowed: state => state.margin_days_borrowed,
    margin_rate: state => state.margin_rate,
    margin_amount_borrowed: state => state.margin_amount_borrowed,            
  },
  mutations: {
    // Call related method mutations ----------*
    CALL_EXPIRATION(state, expiration) {
       this.state.call_expiration = expiration
    },
    CALL_STRIKEPRICE(state, price) {
       this.state.call_strikePrice = price
    },  
    CALL_CONTRACTS(state, contracts) {
      this.state.call_contracts = contracts
    }, 
    CALL_OPTIONPRICE(state, price) {
      this.state.call_optionPrice = price
    },  
    CALL_CURRENTPRICE(state, price) {
      this.state.call_currentPrice = price
    },   
    CALL_EXPECTEDSTOCKPRICE(state, price) {
      this.state.call_expectedStockPrice = price
    },
    // clear call data called from reset button on GUI
    CLEAR_CALLS_DATA(state) {
      state.call_expiration = ''
      state.call_strikePrice = ''
      state.call_contracts = ''
      state.call_optionPrice = ''
      state.call_currentPrice = ''
      state.call_expectedStockPrice = ''       
    },
    // PUTS SECTION ---------------------*
    PUTS_EXPIRATION(state, expiration) {
      this.state.puts_expiration = expiration
    },
    PUTS_STRIKEPRICE(state, price) {
      this.state.puts_strikePrice = price
    },  
    PUTS_CONTRACTS(state, contracts) {
     this.state.puts_contracts = contracts
    }, 
    PUTS_OPTIONPRICE(state, price) {
     this.state.puts_optionPrice = price
    },  
    PUTS_CURRENTPRICE(state, price) {
     this.state.puts_currentPrice = price
    },   
    PUTS_EXPECTEDSTOCKPRICE(state, price) {
      this.state.puts_expectedStockPrice = price
    },
    // clear puts data called from reset button on GUI
    CLEAR_PUTS_DATA(state) {
      state.puts_expiration = ''
      state.puts_strikePrice = ''
      state.puts_contracts = ''
      state.puts_optionPrice = ''
      state.puts_currentPrice = ''
      state.puts_expectedStockPrice = ''       
    }, 
    // MARGIN SECTION ---------------------*
    MARGIN_DAYS_BORROWED(state, days) {
      this.state.margin_days_borrowed = days
    },
    MARGIN_AMOUNT_BORROWED(state, amount) {
      this.state.margin_amount_borrowed = amount
    },
    MARGIN_RATE(state, rate) {
      this.state.margin_rate = rate
    },
    // Clear data
    CLEAR_MARGIN_DATA(state) {
      state.margin_days_borrowed = ''
      state.margin_amount_borrowed = ''
      state.margin_rate = ''      
    }
  },
  actions: {

  },
  modules: {
  }
})
