<!-------------------------------------------------------
    social media links - button group component   class="ml-1 mr-1 pa-0"
-------------------------------------------------------->
<template>
    <v-row dense justify="center">
      <v-col cols="3">
        <v-btn fab x-small @click.prevent.stop="openFacebook" >
            <img src="@/assets/facebook_24icon.png" />
        </v-btn>
      </v-col>
      <v-col cols="3">      
        <v-btn fab x-small @click.prevent.stop="openInstagram" >
          <img src="@/assets/instagram_24icon.png"  />
        </v-btn>
      </v-col>
      <v-col cols="3">      
        <v-btn fab x-small @click.prevent.stop="openThreads" >
          <img src="@/assets/threads_24icon.png" />
        </v-btn>
      </v-col>
      <v-col cols="3">        
        <v-btn fab x-small @click.prevent.stop="openPinterest">
          <img src="@/assets/pinterest_24icon.png" />
        </v-btn>
      </v-col>
     </v-row>
  </template>
  
  <script>
      // 
      export default {
        data: () => ({
            // determines on object creation if running on mobile device true or false - although hard coded init
            isMobile: (/Mobile/i.test(navigator.userAgent)),
            facebookSite: [
              { count:0,title:"Facebook",text:"Follow Us ... on Facebook/Meta?",
                    url:'https://www.facebook.com/profile.php?id=100093600655683'}, 
            ],
            instragramSite: [
              { count:0,title:"Instagram",text:"Follow Us ... on Instagram?",
                    url:'https://www.instagram.com/insightsoftwarellc/?fbclid=IwAR3WGkqiaDWKHAeXN71uoia5HjkDceOiHDsJCVwq5eStxPJyxJ8QyJaWqB0'}, 
            ],
            threadsSite: [
              { count:0,title:"Threads",text:"Follow Us ... on Threads?", url:'https://www.threads.net/@insightsoftwarellc'}, 
            ],
            pintrestSite: [
              { count:0,title:"Pinterest",text:"Follow Us ... on Pinterest?", url:'https://www.pinterest.com/insightsoftwarellc'}, 
            ],
            redditSite: [
              { count:0,title:"Reddit",text:"Follow Us ... on Reddit?", url:''}, 
            ]                                                 
        }),
        methods: {
            openFacebook() {
                // update site count so confirm question is asked only 1x - use javascript 'spread' ...
                this.confirmOpenSite( ...this.facebookSite ) 
            },          
            openInstagram() {
                // update site count so confirm question is asked only 1x - use javascript 'spread' ...
                this.confirmOpenSite( ...this.instragramSite ) 
            },
            openThreads() {
                // update site count so confirm question is asked only 1x - use javascript 'spread' ...
                this.confirmOpenSite( ...this.threadsSite ) 
            },
            openPinterest() {
                // update site count so confirm question is asked only 1x - use javascript 'spread' ...
                this.confirmOpenSite( ...this.pintrestSite ) 
            },            
            openReddit() {
                // update site count so confirm question is asked only 1x - use javascript 'spread' ...
                this.confirmOpenSite( ...this.redditSite ) 
            },
            // asked 1x for every defined social media site 
            confirmOpenSite( siteArray ) {
                // ask to confirm only 1x
                if( siteArray.count < 1 ) {
                  this.$swal({
                      position: 'center',
                      allowOutsideClick: true,
                      allowEscapeKey: true,
                      confirmButtonText: "Yes",
                      showCloseButton: true,
                      showCancelButton: true,
                      cancelButtonText: "No",
                      icon: 'question',
                      title: siteArray.title,
                      text:  siteArray.text,
                      footer: "This will open another 'tab' for [ "+siteArray.title+" ]",
                    }).then((result) => {
                        if( result.isConfirmed ) { 
                           siteArray.count += 1;
                           window.open( siteArray.url,'_blank').focus();
                        }
                    })
                } else {
                   // open site without dialog - asked previously and confirmed
                   siteArray.count += 1;
                   window.open( siteArray.url,'_blank').focus();
                }  
              }            
          }
      }
  </script>
  
  <style scoped>

  </style>
  