<!------------------------------------------------------------------------------------------

    Real-Time Chart from TradingView - specifically designed for mobile ONLY

-------------------------------------------------------------------------------------------->
<template>
    <v-container fluid class="fill-height ma-0 pa-0" > 
        <v-layout justify-center align-center>  
            <v-container fill-height fluid class="ma-0 pa-0" >
                <!-- TradingView Widget BEGIN -->
                <div class="tradingview-widget-container" style="height:100%;width:100%">
                   <div class="tradingview-widget-container__widget" style="height:calc(100% - 32px);width:100%"></div>
                    <div class="tradingview-widget-copyright"><span class="blue-text">Real-Time Charts</span> by TradingView</div>
                        <script type="application/javascript" defer src="https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js" async>
                            {
                                "width": "{{this.widgetWidth}}",
                                "height": "{{this.widgetHeight}}",
                                "symbol": "{{this.symbol}}",
                                "interval": "D",
                                "timezone": "America/New_York",
                                "theme": "light",
                                "style": "0",
                                "locale": "{{this.locale}}",
                                "enable_publishing": false,
                                "hide_legend": true,
                                "withdateranges": true,
                                "allow_symbol_change": true,
                                "save_image": false,
                                "support_host": "https://www.stock-insight.net"
                            }
                        </script>
                </div>
                <!-- TradingView Widget Mobile END -->
            </v-container>
        </v-layout>
    </v-container>        
</template>

<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"  

    export default {
        // need to pass ticker in props to sync all child components 
        props: { ticker: { type: String, default: '' } },
        data: () => ({
                locale: i18n.locale,
                value: null,
                // determines on object creation if running on mobile device true or false
                isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))),
                // *** following settings are designed for desktop - flipped if 'mobile' before mount ***   650           
                cardWidth: "95%",
                widgetWidth: "100%",
                widgetHeight: 1000,                 
                symbol: "AAPL", // ticker feed passes 'tvwidgetsymbol' params to this component
                showAdsID : 0,
                hideAdsID : 0,    
                time_delay: Number( process.env.VUE_APP_ADS_DELAY ),  // retrieve setting from .env file   
                errors: {},                   
            }),
        methods: {

        },
        beforeMount() {
            // set spinner
            this.$loading(true);
            // get last searched ticker from localstorage - if any
            let ticker = localStorage.getItem( "lastSeachedSymbol" );
            // make sure ticker matches last searched
            if( ticker ) { this.symbol = ticker; }            
        },
        mounted() {
            /* get localStore Last Searched Symbol - if set to stay in sync */
            // clear wait spinner 
            setTimeout( ()=> { this.$loading(false); }, 1500 );
        },
    }
</script>

<style scoped>

</style>