<!--  TILE LAYOUT -----------------------------------------------------------
     
    Tile Menu - built by parsing local storage bookmarks - if any

    Added to HomePage.vue component - if bookmarks exist

----------------------------------------------------------------------------->
<template>
    <!------------------------------------------------------------------------------------------------------
     Current settings: this block will replace the default home page comppent if there are more than 1
     saved Bookmarks - 'Tile' type of display walking all the locally stored bookmarks for easier access  xs12 sm8 md4 ld2
      <img :src="`https://randomuser.me/api/portraits/men/${i + 20}.jpg`" class="image" alt="lorem" width="100%" height="100%"> 
      <v-container fluid>
        <v-layout class="mt-10" fill-height justify-center align-center row wrap>
           // <img :src="`https://randomuser.me/api/portraits/men/${i + 20}.jpg`" class="image" alt="lorem" width="100%" height="100%"> 
            <v-flex v-for="(item, index) in JSON.parse( bookmarks )" :key="index" xs12 sm8 md6 lg4 xl2>  xs10 sm8 md6 lg4 xl2> 
                <v-card @dblclick.stop.prevent="loadTileComponent( item.route.name )" class="ma-1 pa-1" min-width="50" max-width="325" outlined>    
                    
     <v-container class="grey lighten-5">
            <v-row class="mb-6" no-gutters>
                <v-col v-for="n in 4" :key="n" cols="12" md="3">
                    <v-card class="pa-2" tile outlined> col </v-card>
                </v-col>
            </v-row>
     <v-container>       xl2 lg4 md6 sm8 xs10 - xl2 lg4 md6 sm9 xs10    flex xl2 lg3 md5 sm7 xs10  flex xl2 lg3 md3 sm5 xs8     

         -- made change to mobile
                <div v-if="isMobile === true">
                    <v-layout row wrap align-center>
                        <v-flex>
                            <div class="pa-5" style="background-image: url('rs/utils/document?file=tiles/tile_menu_msg_background.jpg'); background-size: cover; ">
                                <div style="background: linear-gradient(to right, #ff7e5f, #feb47b); padding: 10px; border-radius: 10px;  margin: auto;">
                                    <h6 style="color: white; font-weight: bold; font-size: 1em;">
                                        <span> {{ messageTxt }} </span>
                                    </h6>
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
                <div v-else >
                    <v-alert @click.stop.prevent="checkAlert" text--white prominent dense v-model="messageBar" border="left" icon="mdi-school"  color="deep-purple accent-5" dark dismissible >
                         <span> {{ messageTxt }} </span>
                    </v-alert>
                </div>
    ---------------------------------------------------------------------------------------------------------------------------------------------->
    <v-container fill-height >
        <v-row dense no-gutters>
            <div v-if="isMobile" >
               <div v-show="alert">
                    <v-card class="mx-auto" flat color="transparent" >
                        <v-card-text>
                            <v-layout row wrap align-center>
                                <v-flex>
                                    <div class="pa-5" style="background-image: url('rs/utils/document?file=tiles/tile_menu_msg_background.jpg'); background-size: cover; ">
                                        <div style="background: linear-gradient(to right, #ff7e5f, #feb47b); padding: 10px; border-radius: 10px;  margin: auto;">
                                            <h6 style="color: white; font-weight: bold; font-size: 1em;">
                                                <span> {{ messageTxt }} </span>
                                            </h6>
                                        </div>
                                    </div>
                                </v-flex>
                            </v-layout>                            
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="mx-2" color="pink" x-small fab @click="closeAlert(toggle)"><i class="fa-solid fa-xmark"></i></v-btn>
                        </v-card-actions>
                    </v-card>
                </div>
            </div>
            <!-- no bookmarks found - default splash screen displayed  -->
            <div v-else >
                <v-alert v-model="alert" text--white prominent dense border="left" icon="mdi-school" color="deep-purple accent-5" dark transition="scale-transition" >
                    <span> {{ messageTxt }} </span>
                    <template v-slot:close="{ toggle }">
                    <v-btn class="mx-2" color="pink" small fab @click="closeAlert(toggle)"><i class="fa-solid fa-xmark"></i></v-btn>
                    </template>
                </v-alert> 
            </div>                        
            
        </v-row>
        <v-row dense no-gutters>
            <v-col>
                <!-- loop all bookmarks - create cards - for home page class="ma-1 pa-1" @dragover.prevent @dragenter.prevent -->
                <v-layout class="mt-5" justify-center align-center row wrap>
                    <v-flex @drop="onDrop( $event, item )" @dragend="onDrop( $event, item )" v-for="( item, index ) in JSON.parse( bookmarks )" :key="index" xl2 lg3 md3 sm5 xs9 > 
                        <!-- drag over records the card's drag postion of the underneath card -->
                        <v-card class="ma-1 pa-1" @dblclick.stop.prevent="loadTileComponent( item.route.name )" draggable="true" @dragover="overItem( $event, item )" min-width="25" max-width="300" outlined>
                            <!-- <v-img :src="require( getTileImageFromS3( item.tile ) )"></v-img> -->
                            <!-- <v-card-subtitle class="font-weight-light text-center" >{{ item.group }} </v-card-subtitle> -->
                            <!-- image not found error template  aspect-ratio="16/9"  v-on:error="getTileImageFromS3Url( 'Image404Error.png' )" -->
                            <!-- <v-img :src=item.tile v-on:error="'/rs/utils/document?file=tiles/Image404Error.png'"/> -->
                            <!-- <v-img :src=item.tile v-on:error="getTileImageFromS3Url( 'Image404Error.png' )"/> -->
                            <v-img :src=item.tile />
                            <!--<v-img :src="storeTileToLocalStorage("+item+")" /> -->
                            <v-card-subtitle class="font-weight-light text-center ma-0 pa-0" >{{ item.title }} </v-card-subtitle>
                            <v-card-actions class="ma-0 pa-1"> 
                                <v-btn @click.stop.prevent="loadTileComponent( item.route.name )" x-small outlined rounded text><i class="fa fa-check fa-2x"></i></v-btn>
                                <div v-show="isMobile" class="ml-10">
                                    <v-btn :disabled="index <= 0" @click.stop.prevent="moveBookmarkUp( item )" class="mr-5" x-small outlined rounded text><i class="fa-solid fa-arrow-up"></i></v-btn>
                                    <v-btn :disabled="(index +1) >= { bookMksLength }" @click.stop.prevent="moveBookmarkDown( item )" x-small outlined rounded text><i class="fa-solid fa-arrow-down"></i></v-btn>
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn @click.stop.prevent="deleteBookmark( item.route, item.title )" x-small rounded outlined text><i class="fa-solid fa-xmark fa-lg"></i></v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-col>
        </v-row>                
    </v-container>
</template>

<script>
//import { ref, computed } from 'vue'

export default { 
    data() {
       return {
            isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),                  
            screenHeight: window.screen.height,
            screenWidth:  window.screen.width,
            bookmarks:    localStorage.getItem("bookmarks"),
            bookMksLength: 0,
            vertical:     true,
            multiLine:    true,
            timeout:      10000,   // alert message timeout in secs
            hasBookmarks: false,
            alert:        false,    // by default to show first time on start up
            dragOverPos:   0,      // track drag over position of draggable card - before dropping
            messageTxt: "Stock-Insight.net offers a comprehensive suite of financial resources, including news, podcasts, charts, tables, calculators, and tools, designed to equip investors with the insights they need to make informed decisions. It is your one-stop destination for staying ahead in the world of finance, providing dynamic market analysis and user-friendly tools."
        }
    },
    beforeMount() {
        // show spinner
        this.$loading( true )

        // display message check
        //if( this.isMobile ) { this.snackbar = true } else { this.messageBar = true }
    },  
    mounted() {
        // both splash pages load - just hidden depending on bookmarks
        // 1 & 1/2 sec delay - then remove loading indicater       
        setTimeout( ()=> this.$loading(false), 1250 );

        // check if alert opened - then closed by user - session
        this.checkAlert()

        // shut alert off after 5 seconds
        //setTimeout( ()=>{ this.vAlert = false }, 5000 );
        console.log( "alert status: "+Boolean( sessionStorage.getItem( 'alert' ) ) );
    },
    methods: {
            closeAlert( toggle ) {
                this.alert = false;
                sessionStorage.setItem('alert', 'false' );
            },
            checkAlert() {
                let alert  = sessionStorage.getItem( 'alert' )
                 if( alert && alert === 'false' ) {
                    // do nothing already set false for session
                } else {
                    // show alert
                    this.alert = true;
                }
                console.log( "check alert status: "+Boolean( sessionStorage.getItem( 'alert' ) ) );
                //if( alert ) {
                    // if exists - reset status from session setting - to show or hide alert message
                //    this.alert = Boolean( alert ); // leave off for entire user session if closed by user
                //} else {
                //    this.alert = true;
                //}
            },
            // used to find object position in array 
            getBookmarkPositionInArray( item ) {
                var pos        = 0; // starting position at top of json object
                try {
                    //var bookmarks = JSON.parse( localStorage.getItem("bookmarks") || "[]" );
                    var bookmarks = JSON.parse( this.bookmarks );
                    if( bookmarks && bookmarks.length > 0 ) {
                        // loop through bookmarks to find ordinal position
                        for( var i = 0; i < bookmarks.length; i += 1 ) {
                            let obj = bookmarks[i];
                            if( obj && obj.id === item.id ) {
                                pos = i;
                                break;
                            }
                            //console.log( item.id );
                        } 
                    }
                } catch( err ) { console.log ( " bookmark parse issue" ) }
                //console.log( "Bookmark Index = "+pos )
                return( pos )
            },
            /********* mobile only ******************************************
             * ability to adjust bookmark position up or down in JSON Array 
             * without having to remove then add back in
             ****************************************************************/
            moveBookmark( bkm, from, to ) {
                return bkm.map(( item, index ) => {
                    if ( index === to   ) return bkm[ from ];
                    if ( index === from ) return bkm[ to   ];
                    return item;
                })
            },
            /*******************************************************
             * load component from Tile Menu selection when clicked
             *******************************************************/
            loadTileComponent( path ) {
                // loads component dynamically by using the named path in router.js -just as if it was clicke
                this.$router.push({ name: path });
                // *** scroll screen to top - since some tiles will be at the bottom ***
                setTimeout( ()=> window.scrollTo( { top: 0, left: 0, behavior: 'smooth'} ), 1750 );      
            },
             /******************************************************
             * called from Tile Home Page Menu to remove bookmark
             ******************************************************/
             deleteBookmark( route, title ) {
                this.$swal({
                    position         : 'center',
                    allowOutsideClick: true,
                    allowEscapeKey   : true,
                    showConfirmButton: true,
                    confirmButtonText: "Yes",
                    showDenyButton   : true,
                    denyButtonText   : 'No',
                    icon  : 'question',
                    title : "Remove </br>[ "+title+" ]",
                    text  : "Remove from 'Home Page'?",
                    footer: "This will remove shortcut from 'Favorites' list",
                    customClass: { actions: 'bookmk-actions', confirmButton: 'order-1', denyButton: 'order-2' },
                }).then((result) => {
                    if( result.isConfirmed ) {
                        // get current localStorage 'bookmarks' list
                        try {
                            //var bookmarks = JSON.parse( localStorage.getItem("bookmarks") || "[]" ); 
                            var bookmarks = JSON.parse( this.bookmarks );     
                            if( bookmarks && bookmarks.length > 0 ) {
                                // check existing route
                                if( bookmarks.find( o => o.route.name === route.name ) ) {
                                    // filter out all but remove link to create new listing
                                    var newBookmarks   = bookmarks.filter( e => e.route.name !== route.name );
                                    // update localStorage - long term storage with updated bookmark list
                                    localStorage.setItem( "bookmarks", JSON.stringify( newBookmarks ) );
                                    this.bookmarks     = JSON.stringify( newBookmarks ); // JSON.parse( localStorage.getItem("bookmarks") );
                                    var bookmks        = JSON.parse( this.bookmarks ); 
                                    // track length to enable/disable move buttons - and re-adjust tile layout
                                    this.bookMksLength = bookmks.length;
                                } else {
                                    this.$swal({ title: "Shortcut Problem!", text: "Route Issue: [Reset Called]", icon: "error", footer: err });
                                    const url = new URL( '/', window.location.origin )
                                    setTimeout( ()=> ( window.location.href = url.toString(), 2500 ) );       
                                }
                                // re-draw home page tile screen - by checking bookmarks - needs work, not smooth
                                // doesn't refresh calling this -> this.$router.push({ name: 'home' });
                                // hard re-fresh on delete -- reloads home page - needs work 
 
                                //-- if no bookmarks - get new list from S3 or reset bookmark defaults ---*
                                if( this.bookMksLength <= 0 ) {
                                    //this.compareBookmarks()
                                    // wait for 1 second to check S3 for default list - need to refresh App?
                                    const url = new URL( '/', window.location.origin )
                                    setTimeout( ()=> ( window.location.href = url.toString(), 500 ) );                                       
                                }
                                // *** need to check length of bookmark list
                            } else {
                                // re-fresh screen - show default splash screen
                                // due complete reset
                                const url = new URL( '/', window.location.origin )
                                // call to re-check bookmarks from S3 - error will set default bookmarks - start over - in 2 secs
                                setTimeout( ()=> ( window.location.href = url.toString() ), 500 );                                   
                            } 
                        } catch( err ) { 
                            // refresh app called because of error
                            const url = new URL( '/', window.location.origin )
                            this.$swal({ title: "Shortcut Problem!", text: "Link Issue: [Reset Called]", icon: "error", footer: err });
                            // call to re-check bookmarks from S3 - error will set default bookmarks - start over - in 2 secs
                            setTimeout( ()=> ( this.compareBookmarks(), window.location.href = url.toString() ), 3500 );   
                        }
                    }            
                })  
            },
            /*****************************************************************************
             * mobile only -> called from 'Mobile' Tile Home Page Menu to move bookmark up
             *****************************************************************************/
             moveBookmarkUp( item ) {
                this.$swal({
                    position         : 'center',
                    allowOutsideClick: true,
                    allowEscapeKey   : true,
                    showConfirmButton: true,
                    confirmButtonText: "Yes",
                    showDenyButton   : true,
                    denyButtonText   : 'No',
                    icon:   'question',
                    title:  "MOVE UP </br>[ "+item.title+" ]",
                    text:   "Move Shortcut Up ?",
                    footer: "Move Up 1 Position from ["+this.getBookmarkPositionInArray( item )+"]",
                    customClass: { actions: 'bookmk-actions', confirmButton: 'order-1', denyButton: 'order-2' },
                }).then((result) => {
                    if( result.isConfirmed ) {
                        // get stored bookmarks
                        //var bookmarks = JSON.parse( localStorage.getItem("bookmarks") || "[]" );   
                        try {
                            var bookmarks = JSON.parse( this.bookmarks );      
                            if( bookmarks && bookmarks.find( o => o.id === item.id ) ) {
                                // get position in JSON array
                                var pos = this.getBookmarkPositionInArray( item );
                                if( pos > 0 ) {
                                    var newBookmarks   = this.moveBookmark( bookmarks, pos, (pos -1) );
                                    // update localStorage with adjusted JSON array
                                    localStorage.setItem( "bookmarks", JSON.stringify( newBookmarks ));
                                    // update data block - to mirror local storage bookmarks for component
                                    this.bookmarks     = localStorage.getItem( "bookmarks" );  
                                    // track length to enable/disable move buttons
                                    var bookmks        = JSON.parse( localStorage.getItem("bookmarks") || "[]" );      
                                    this.bookMksLength = bookmks.length;                                    
                                } else {
                                    this.$swal.fire("Top of List");
                                }                             
                            } 
                        } catch( err ) { 
                            this.$swal({ title: "Shortcut Problem!", text: "Link Issue: [Move] Failed.", icon: "error", footer: err });
                        }
                    }            
                })  
            },         
            /*******************************************************************************************
             * mobile only -> called from Tile Home Page Menu to move bookmark down
             *******************************************************************************************/
             moveBookmarkDown( item ) {
                this.$swal({
                    position         : 'center',
                    allowOutsideClick: true,
                    allowEscapeKey   : true,
                    showConfirmButton: true,
                    confirmButtonText: "Yes",
                    showDenyButton   : true,
                    denyButtonText   : 'No',
                    icon:   'question',
                    title:  "MOVE DOWN </br>[ "+item.title+" ]",
                    text:   "Move Shortcut Down ?",
                    footer: "Move Down 1 Position from ["+this.getBookmarkPositionInArray( item )+"]",
                    customClass: { actions: 'bookmk-actions', confirmButton: 'order-1', denyButton: 'order-2' },
                }).then((result) => {
                    if( result.isConfirmed ) {
                        // get stored bookmarks for search 
                        //var bookmarks = JSON.parse( localStorage.getItem("bookmarks") || "[]" );  
                        try {  
                             var bookmarks = JSON.parse( this.bookmarks );                                      
                             if( bookmarks && bookmarks.find( o => o.route.name === item.route.name ) ) {
                                // get position in JSON array
                                var pos  = this.getBookmarkPositionInArray( item );
                                // check position is not past array length
                                if( pos+1 < bookmarks.length ) {
                                    var newBookmarks = this.moveBookmark( bookmarks, pos, (pos + 1) );
                                    // update bookmarks
                                    localStorage.setItem( "bookmarks", JSON.stringify( newBookmarks ));
                                    // update data block - to mirror local storage bookmarks
                                    this.bookmarks     = localStorage.getItem( "bookmarks" );  
                                    // track length to enable/disable move buttons
                                    var bookmks        = JSON.parse( localStorage.getItem("bookmarks") || "[]" );      
                                    this.bookMksLength = bookmks.length;                                    
                                } else {
                                    this.$swal.fire("Bottom of List");
                                }                                                 
                            } 
                        } catch( err ) { 
                            this.$swal({ title: "Shortcut Problem!", text: "Link Issue: [Move] Failed.", icon: "error", footer: err });
                        }
                    }            
                })  
            }, 
            // ****** drag - drop support section -------------------------------*
            allowDrop( evt ) { evt.preventDefault() },
            overItem( evt, item ) {
                // record underneath bookmark - inorder to replace it
                var pos          = this.getBookmarkPositionInArray( item );
                // important - update current position in data store on drag over item to move from to ...
                this.dragOverPos = pos;
                //console.log( "over item pos: "+pos+" item id:" +item.id );
            },
            // - important to move dragged card to over 
            onDrop( evt, item ) {
                // record old position of item before move 
                var fmPos = this.getBookmarkPositionInArray( item );
                //console.log( "move to =>indx: "+this.dragOverPos+" <<< from Indx: "+fmPos );
                // get current bookmarks before move operation
                var bookmarks = JSON.parse( localStorage.getItem("bookmarks") || "[]" );   
                // perform move operation
                if( this.dragOverPos <= bookmarks.length ) {
                    // replaces cards ... maybe should be inserted ... to push up or down ?
                    var newBookmarks = this.moveBookmark( bookmarks, fmPos, this.dragOverPos );
                    // update bookmarks
                    localStorage.setItem( "bookmarks", JSON.stringify( newBookmarks ));
                    // update data block - to mirror local storage bookmarks
                    this.bookmarks     = localStorage.getItem( "bookmarks" );  
                    // track length to enable/disable move buttons
                    var bookmks        = JSON.parse( localStorage.getItem("bookmarks") || "[]" );      
                    this.bookMksLength = bookmks.length;                                    
                } else {
                    this.$swal.fire("Bottom of List");
                }                                          
            }, 
            /**************************************************************************************
                 check for updated 'default' bookmark list from S3 bucket
            ***************************************************************************************/               
            getDefaultBookmarksFromS3() {
                // request report from back-end to retrieve selected Rss Feed
                this.axios.get( '/rs/utils/document?file=tiles/DefaultBookmarks.txt' ).then((response) => {
                //--------------------------------------------------------*
                // check response object
                var bookMarks = JSON.stringify( response.data );
                    //console.log("Axios Response: "+bookMarks );
                    try {
                        // check number of objects in the json array
                        let bookMks = JSON.parse( bookMarks );
                        if( bookMks && bookMks.length > 5 ) {
                            try {
                                // update localStorage bookmarks list
                                localStorage.setItem( "bookmarks", bookMarks );
                                // update data block - with strified version for data block
                                this.bookmarks      = bookMks;                        
                                // disable end of list button moving
                                this.bookMksLength  = bookMks.length;                        
                                console.log( 'Using S3 Shortcuts #.... ['+this.bookMksLength+' ]' );  
                            } catch(e1) { throw e1 }
                        } else {
                            try {
                                // bookmarks with static list - fallback if none in S3
                                var bookmarks = JSON.stringify( [{"id":"1vd","title":"Bloomberg Clips","group":"Latest Videos","icon":"mdi-alpha-b-circle","route":{"name":"BloombergYTVideos"},"tile":"/rs/utils/document?file=tiles/BloombergYoutube.png"},{"id":"2vd","title":"CNBC Clips","group":"Latest Videos","icon":"mdi-alpha-c-circle","route":{"name":"CNBCYTVideos"},"tile":"/rs/utils/document?file=tiles/CnbcYoutube.png"},{"id":"6vd","title":"Yahoo Clips","group":"Latest Videos","icon":"mdi-alpha-y-circle","route":{"name":"YahooYTVideos"},"tile":"/rs/utils/document?file=tiles/YahooYoutube.png"},{"id":"7vd","title":"Zacks Clips","group":"Latest Videos","icon":"mdi-alpha-z-circle","route":{"name":"ZacksYTVideos"},"tile":"/rs/utils/document?file=tiles/ZacksYoutube.png"},{"id":"3ans","title":"Investing.com","group":"Latest News","icon":"mdi-alpha-i-circle","route":{"name":"InvestingDC"},"tile":"/rs/utils/document?file=tiles/InvestingDotComRss.png"},{"id":"3tl","title":"Fundamentals","group":"Investing Tools","icon":"mdi-alpha-f-circle","route":{"name":"Fundamentals"},"tile":"/rs/utils/document?file=tiles/FinancialAnalysis.png"},{"id":"3ch","title":"Foreign Exchange","group":"Live Data Charts","icon":"mdi-alpha-f-circle","route":{"name":"forexRates"},"tile":"/rs/utils/document?file=tiles/ForeignExchangeChart.png"}] );
                                // update localStorage bookmark list - from data block
                                localStorage.setItem( "bookmarks", bookmarks );
                                // record number of bookmarks by breaking into objects
                                var bookmks         = JSON.parse( bookmarks );
                                // update data block
                                this.bookmarks      = bookmks;
                                // check position in list to disable bottom list button
                                this.bookMksLength  = bookmks.length;                              
                                console.log( 'Using default list of shortcuts - No S3 List. [ '+this.bookMksLength+' ]' ); 
                            } catch(e2) { throw e2 }
                        }  
                    } catch( err ) { console.log( err ) } 
                }).catch( err => { 
                    // set default bookmarks
                    setDefaultBookmarks()
                }) 
            }, 
            // called from tile menu and on start up - to check for valid bookmarks
            compareBookmarks() {
                /*********************************************************
                 * very important to retrieve local storage json string
                 * of bookmarks and re-parse for javascript to count
                 * correctly the number of actual bookmarks and not the 
                 * enclosed array items of those bookmarks ...
                 *********************************************************/
                try {
                    // get latest bookmark listing
                    var bookmarks = JSON.parse( localStorage.getItem("bookmarks") || "[]" );
                    if( bookmarks && bookmarks.length <= 0 ) {
                        // no bookmarks - no localStorage copy - get default list from S3 documents/tiles folder
                        this.getDefaultBookmarksFromS3()
                    } else {
                        // *** check for bookmarks with 'tile' property - old bookmarks do not have tiles ***
                        var tileFound = false;
                        for( var key in bookmarks ) {
                            if( bookmarks.hasOwnProperty( key ) ) {
                                // if tile element exists - assume new structure - exit
                                if( bookmarks[key].tile ) {
                                    tileFound = true;
                                    //console.log("checking keys: "+ bookmarks[key].tile  )
                                    break
                                }
                            }
                        }
                        if( tileFound !== true ) {
                            // old bookmarks or none in local - get default list from S3 documents/tiles folder
                            this.getDefaultBookmarksFromS3()                          
                        } else {
                            // update data block stats
                            this.bookmarks     = JSON.stringify( bookmarks );
                            this.bookMksLength = bookmarks.length
                        }
                    }
                } catch( err ) {
                    // call js/utils.js method to set defaults on error
                    setDefaultBookmarks();
                    console.log("TileMenu: checkBookmarks() "+ err )
                }
            }                                  
         },
      
 }
</script>

<style scoped>
.drop-zone {
  background-color: #eee;
  /*margin-bottom: 10px;
  padding: 10px;*/
}

.drag-el {
  background-color: #f02323;
  padding: 1px;
}
    /* -- saved for later ... in case 
        resizeAllGridItems() {
            //calculate the grid container with
            let gridWidth = document.getElementsByClassName("grid-container")[0].offsetWidth;
            /*calculate the grid item width (the width should be the same for all grid items
            because we used `repeat( auto-fit, minmax(22em , 1fr))` to generate our responsive
            columns)
            let gridItemWidth = document.getElementsByClassName("grid-item")[0].offsetWidth;
            /*devide the with of the grid container by the with of the grid item item to get
            the number of generated columns
            let columnsNumber = ~~(gridWidth / gridItemWidth);
            /*the second part of the algorithm with loop through all the generated grid items.
            Starting with the second row, the grid item in that row will be given a `margin
            -top` value that equals the height of the grid item situated right above it, minus
            the value of `grid-auto-rows`. This way whenever there's an extra space, the grid
            item below will have it's `margin-top` value adjusted to take the extra space.
            let x       = columnsNumber;
            let colIdx  = 0;
            let columnsHeights     = [0, 0, 0];
            let tempColumnsHeights = [];
            let allItems = document.getElementsByClassName("grid-item");
            for( x; x < allItems.length; x++ ) {
              let topItemHeight = columnsHeights[colIdx] + allItems[x - columnsNumber].offsetHeight;
              allItems[x].style.marginTop = (topItemHeight - 300) + 'px';
              tempColumnsHeights.push(topItemHeight - 300);
              colIdx++;
              //move to the next row of grid items to adjust them if all the items of the  previous row are adjusted
              if( colIdx === columnsNumber ) {
                colIdx             = 0;
                columnsHeights     = tempColumnsHeights;
                tempColumnsHeights = [];
              }
            }
          }   
     */     
</style>