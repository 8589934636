<!--

   Stock Percentage - Stock Price Calculator
    - Main view component with sub Stock tab components

-->
<template>
  <v-container class="fill-height printable" > 
    <v-layout justify-center align-center>   
          <v-card class="mx-auto my-auto" min-width="225" max-width="750" hover >
              <v-img class="white--text align-center" width="auto" height="75px" src="@/assets/mathmatics_1280.jpg" cover >      
                  <v-card-title class="justify-center" >
                      <v-row dense>
                          <v-col cols="1">
                              <!-- user help button -->
                              <v-btn class="mx-1" fab small dark color="green" @click.prevent.stop="showHelp" >
                                  <v-icon dark>fa-solid fa-question</v-icon>
                              </v-btn>
                          </v-col>
                          <v-col class="pt-2 d-flex justify-center" cols="9">
                            <span class="text-h6 font-weight-bold">&nbsp;&nbsp;{{$t("stock_prct_prce_page.prct_prce_title")}}</span>
                          </v-col>
                          <v-col cols="2">
                            <!-- track bookmarks -->
                            <Bookmark link="stockCalculators"/>
                          </v-col>                    
                      </v-row>
                      <!-- create help wiki here -->
                      <ToolWiki title="'Price/Percentage' Demystified" titleColor="green" wikiDocument="AboutPriceTools" i18_fileKey="tools_percentage_about_txt" />              
                  </v-card-title>     
              </v-img>
              <v-card-text class="text--primary">
                  <v-container fill-height >
                      <v-flex fill-height >  
                        <!-- -->        
                        <v-card >
                          <v-tabs v-model="tab" color="white" background-color="#99C2C1" >
                              <v-tab v-for="item in items" :key="item.tab">
                                <v-icon left>{{item.icon}}</v-icon>
                                {{ item.tab }}
                              </v-tab>
                          </v-tabs>
                          <v-tabs-items v-model="tab">
                              <v-tab-item v-for="item in items" :key="item.tab">
                                <v-card flat >
                                  <v-card-text class="ma-0 pa-0">
                                    <component v-bind:is="item.content"></component>
                                  </v-card-text>
                                </v-card>
                              </v-tab-item>
                          </v-tabs-items>
                        </v-card>
                          <v-row dense justify="center">
                            <v-col cols="12" >
                                <ToolsBottomAds/>          
                            </v-col>
                          </v-row>   
                          <v-row dense justify="center">
                            <v-col cols="11" >
                                <SideDisclaimerNotice/>
                            </v-col>
                          </v-row>                                                            
                      </v-flex>                 
                  </v-container>
              </v-card-text>
            </v-card>
      </v-layout>    
  </v-container>            
</template>

<script>
    // listen for and allow broadcasts to components
    import { eventBus } from '@/main'  // event bus communication for registered components

    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"      

    // import tab component cards
    import StockPercentageCalculator from "@/components/tools/StockPercentageCalculatorTab.vue";  // tab 1
    import StockPriceCalculator      from "@/components/tools/StockPriceCalculatorTab.vue";       // tab 2
    import SideDisclaimerNotice from '@/components/SideDisclaimerNotice.vue'    
    import ToolsBottomAds from '@/components/ads/ToolsBottomAds.vue'; 
    // wiki tool document used when user presses 'help' button
    import ToolWiki from '@/components/ToolWiki.vue'   
    // user bookmarking component
    import Bookmark      from '@/components/AddToFavorites.vue'                      

    export default {
      components: { StockPercentageCalculator, StockPriceCalculator, SideDisclaimerNotice, ToolsBottomAds, ToolWiki, Bookmark },
      data: () => ({
        valid: false,
        tab: null,
        locale: i18n.locale,
        // determines on object creation if running on mobile device true or false
        isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))), 
        items: [
          { tab: i18n.t('stock_prct_prce_page.prct_tab_lbl'), icon:'mdi-percent-circle', content: 'StockPercentageCalculator' },
          { tab: i18n.t('stock_prct_prce_page.prce_tab_lbl'), icon:'mdi-cash',content: 'StockPriceCalculator' }
        ],
        value: null,
        errors: {},
      }),
      // user defined methods
      methods: {
        disablePastDates(val) {
           return val >= new Date( this.startDate ).toISOString().substr(0, 10)
        },
        showHelp() {
          // emits global event for component - to open help document from button
          eventBus.$emit("show-wiki-tool", "showToolWiki");
        },            
      },      
      // life-cycle - looks for mount
      beforeMount() {
        // show loading spinner - just before component is mounted to app
        this.$loading(true) 
      },      
      mounted() {
        // .5 sec delay - then remove loading indicater       
        setTimeout( ()=> this.$loading(false), 500 );

        // show ads - if enabled in .env file - defined in BottomSheetAds component
        //setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), 2500 ); 
      },
      metaInfo() {
        return {
            title: "Stock-Insight's Price/Percentage Tools",
            meta: [
              { name: 'robots', content: 'index,follow'},  
              { name: 'description', content: 'Stock Options Percentage Calculator' },
              { name: 'keywords', content: 'options, percentage, stock calculator' },                                  
              { property: 'og:type', content: 'website'}, 
              { property: 'og:site_name', content: 'www.Stock-Insight.net'},
              { property: 'og:title', content: "Stock-Insight's Percentage/Price Calculator "},                             
              { vmid: "description", name: "description",
                  content: "Stock-Insight.net, Stock Investing - Percentage/Price Calculator"
              }
            ],
            link: [
              {
                rel: 'canonical',
                href: '<https://www.stock-insight.net/>'
              }
            ]
        }
      },                  
    }
  </script>

  <!-- added to highlight tab icon when active - like the AppBar.vue icons -->
  <style scoped>
    .v-tab {
      font-size: 0.7em;
    }
    .v-tab .v-tab--active {
      color: rgb(239, 240, 252);
    }
    .v-tab--active .v-icon, .v-tab--active .v-btn.v-btn--flat {
      color: red;
    }
  </style>