<!-- 
    desktop favorites menu replacement ( button ) used instead of tabs - checks every click for 
    updated list in localStorage for bookmarks
-->
<template>
    <v-card class="mx-auto" max-width="300" tile >
        <v-menu offset-y @click="getBookMarks" >
            <template v-slot:activator="{ on, attrs }">  
               <!-- check every click redraw bookmarks from localStore for list --> 
               <v-btn color="primary" dark v-bind="attrs" v-on="on" v-on:click.prevent="getBookMarks">
                 <v-icon left >fa-solid fa-heart-circle-check</v-icon>
                 Favorites
               </v-btn>
            </template>
              <v-list class="text-left" dense color="grey">
                <v-list-item-group v-model="selectedItem" >
                <v-list-item v-for="(item) in items" :key="item.id" :to="item.route" >
                    <v-list-item-icon class="pl-0 pr-1 ml-0 mr-1">
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="pl-0 pr-0 ml-0 mr-0">
                    <v-list-item-title v-text="item.title" ></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                </v-list-item-group>
              </v-list>
        </v-menu>              
    </v-card>
</template>

<script>
  export default {
      data: () => ({
          selectedItem: 0,
          items: []
      }),
      methods: {
         getBookMarks() {
            // get localStorage Bookmark list - if any
            var bookmarks = JSON.parse( localStorage.getItem("bookmarks") || "[]" ); 
            this.items    = bookmarks;
         },
      },
      beforeMount() {
         this.getBookMarks();
      }
   }
</script>

<style scoped>
  .v-button {
     opacity: 0.5;
  }
  .v-menu__content {
     background: lightgray;
  }
  .v-list-item {
     background: lightgray;
     color: darkblue;
  }
  .v-list-item--active .v-icon {
     background-color: lightgreen;
  }
  .v-list-item--active .v-list-item__title {
     color: black;
     font-weight: bold;
  }  
  .v-card--link:focus {
      background: lightgray;
   }
   .v-btn {
      z-index: 99 !important;
   }
</style>