import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VLayout,{staticClass:"mt-1 ma-0 pa-0",attrs:{"justify-center":"","align-center":""}},[_c(VFlex,{attrs:{"row":"","align-center":""}},[_c(VCard,{staticClass:"mx-auto mb-15 flexcard outlined",attrs:{"color":'#26c6da',"min-width":this.cardMinWidth,"width":this.cardWidth,"height":this.cardHeight,"hover":""}},[_c(VCardTitle,{staticClass:"justify-center"},[_c(VSpacer),_c('span',{staticClass:"text-h5 black--text font-weight-black"},[_vm._v("Darqube News")]),_c(VSpacer),_c('Bookmark',{attrs:{"link":"DarqubeNews"}})],1),_c(VCardText,[_c('iframe',{attrs:{"data-widget-name":"","name":"NewsWidget","src":"https://darqube.com/external-embedding/news-widget?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3aWRnZXQiOnsibmV3cyI6WyJTZWVraW5nQWxwaGEiLCJUaGUgRWNvbm9taXN0IiwiRmluYW5jaWFsIFRpbWVzIiwiRGFpbHlmeCIsIlJldXRlcnMiXSwidHdfdWlkcyI6WyI2OTYyMDcxMyIsIjkyODc1OTIyNDU5OTA0MDAwMSIsIjYyNDQxMyIsIjE1MTEwMzU3IiwiMjAyNDM3NDQiXX0sIndfdHlwZSI6Ik5ld3NXaWRnZXQiLCJmZV9jZmciOnsiY21vZGUiOjAsImZjbHIiOiJyZ2JhKDI1NSwgMjU1LCAyNTUsIDEpIiwiYmciOiJyZ2JhKDIxLCAyNSwgMzAsIDEpIiwiaCI6NzY1LCJ3IjozMzAsImFzeiI6dHJ1ZSwiZnQiOiJkZWZhdWx0IiwiaHQiOiJub25lIiwidHJoIjpbXSwiY2hjIjoicmdiYSgyMzcsIDUwLCA5OCwgMSkiLCJjbiI6IiIsImxuZyI6ImVuIiwiaGRpY24iOmZhbHNlLCJ3dG1WIjp7InR5cGUiOiJEYXJxdWJlIiwiZW5hYmxlZCI6dHJ1ZX19LCJleHAiOjE2NzY3NjAyMjEsInN1YiI6ImFjY2VzcyJ9.97T4WPB6DLWMx-3Akt5Th2iybJc5C0igp2eVlaQJSqY","id":"NewsWidget","width":this.widgetWidth,"height":this.widgetHeight}})])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }