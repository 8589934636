<!--
   Economic Calendar 
    removed 2 separate widget instances - hidden class statements to support mobile and desktop
    ie: class="mx-auto mb-15 hidden-sm-and-down" for desktop view widget block
    ie: class="mx-auto mb-15 hidden-md-and-up" for mobile view block
    *** duplicate code ***      
-->
<template>
 <v-container fluid class="fill-height" > 
    <v-layout justify-center align-center>  
      <!-- Widget Card Block -->  
      <v-card class="mx-auto my-auto flexcard" :width="this.cardWidth" height="100%" hover >     
          <v-img class="white--text align-center" width="auto" height="75px" src="@/assets/calendar_100.jpg" cover >
              <v-card-title class="justify-center">
                  <v-row dense>
                    <v-col cols="1"></v-col>
                        <v-col class="pt-2" cols="9">
                          <span class="text-h5 font-weight-bold">Economic Calendar</span>
                    </v-col>
                    <v-col cols="2">
                      <!-- track bookmarks -->
                        <Bookmark link="calendar"/>                          
                    </v-col>                    
                  </v-row>
              </v-card-title>
          </v-img>
          <v-card-text ref="tvParent" class="text--primary text-center">
                <!-- TradingView Widget BEGIN 
                [ https://s3.tradingview.com/external-embedding/embed-widget-events.js  ]
                -->
                <div class="tradingview-widget-container">
                  <div class="tradingview-widget-container__widget"></div>
                  <div class="tradingview-widget-copyright"><span class="blue-text">Economic Calendar</span> by TradingView</div>
                  <script type="application/javascript" defer src="https://s3.tradingview.com/external-embedding/embed-widget-events.js" async>
                        {
                            "width": "{{this.widgetWidth}}",
                            "height": "{{this.widgetHeight}}",
                            "colorTheme": "light",
                            "isTransparent": false,
                            "locale": "{{this.locale}}",
                            "importanceFilter": "-1,0,1",
                            "currencyFilter": "USD,EUR,ITL,NZD,CHF,AUD,FRF,JPY,ZAR,TRL,CAD,DEM,MXN,ESP,GBP"
                        }
                  </script>
                </div>
                <!-- TradingView Widget END -->
          </v-card-text>
        </v-card>
        <!-- END DESKTOP CARD -->  
    </v-layout>
  </v-container>          
</template>
  
<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"  
    
    // listen for and allow broadcasts to components
    import { eventBus } from '@/main'  // event bus communication for registered components

    // user bookmarking component
    import Bookmark     from '@/components/AddToFavorites.vue'            

    export default {
      components: { Bookmark },
      data: () => ({
        valid: false,
        locale: i18n.locale,
        // determines on object creation if running on mobile device true or false
        isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))),
        // *** following settings are designed for desktop - flipped if 'mobile' before mount ***              
        cardWidth: "95%",
        widgetWidth: "100%",
        widgetHeight: 1000,  
        showAdsID   : 0,
        hideAdsID   : 0,           
        time_delay: Number( process.env.VUE_APP_ADS_DELAY ),  
      }),
      methods: {
        hideAds() {
           // stop any timeout events by assigned ID - before creating any new ones
           clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );
           // hide any previous side Ad - if any
           eventBus.$emit("hide-side-ads","hideSideAds");          
        },
        showAds() {
          this.hideAds();
          // set variable delay
          var delay = this.time_delay;
          // show ads - if enabled in .env file - defined in BottomSheetAds component - record id for clearing
          this.showAdsID = setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), delay );   // record timeout ID -  for clearing
          delay = delay * 3;
          // send event to parent (AppBar.vue) to close Ads right drawer x3 delay
          this.hideAdsID = setTimeout( ()=> eventBus.$emit("hide-side-ads","hideSideAds"), delay );   // record timeout ID - for clearing      
         }
      },      
      beforeMount() {
          // show loading spinner - just before component is mounted to app
          this.$loading(true);

          //*** re-set card/widget measurements if running on mobile device before mount ***
          if( this.isMobile ) { this.cardWidth="100%";  }
          //*****************************************************/

          // hide any previous side Ad - if any
          this.hideAds();     
      },
      mounted() {
          // 1 sec delay - then remove loading indicater       
          setTimeout( ()=> this.$loading(false), 1500 ); 

          // check for setting - time out is set in .env file for ads 
          if( this.time_delay <= 0 ) { this.time_delay = 2500; }

          // set Ads - if enabled in .env file
          this.showAds();
      },
      beforeUnmount() {
          // send event to parent (AppBar.vue) to close this drawer
          this.hideAds();
      },
      metaInfo() {
        return {
          title: "Stock-Insight's TradingView Widget - Economic Calendar",
          meta: [
            { name: 'robots', content: 'index,follow'},
            { name: 'description', content: 'TradingView Economic Calendar' },
            { name: 'keywords', content: 'TradingView, NYSE, market, stock, chart, financial, calendar, data' },              
            { property: 'og:type', content: 'website'}, 
            { property: 'og:site_name', content: 'www.Stock-Insight.net'},
            { property: 'og:title', content: "Stock-Insight's TradingView Widget - Economic Calendar"},                             
            {
              vmid: "description",
              name: "description",
              content: "TradingView Widget - Economic Calendar"
            }
          ],
          link: [
            {
              rel: 'canonical',
              href: '<https://www.stock-insight.net/>'
            }
          ]
        }
      }             
    }

</script>
