import axios from 'axios'

const apiClient = axios.create({
        baseURL: `/rs/utils/`,
        withCredentials: false, // This is the default,
        timeout: 300000,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=utf-8',
            'Access-Control-Allow-Origin': '*'
        }
})
//const postConfig = { headers: { 'Content-Type': 'multipart/form-data' } };

export default {

    sendContactInfo( pkg ) {
        return apiClient.post( 'contactUsEmail', pkg );
    }

}