<!--
   Watch list dialog reads localstorage watchlist and creates a checkbox list users can selectively remove tickers from their
   stored watchlist

     available from the Watch List Tab only
-->
<template>
    <v-row justify="center">
      <v-dialog retain-focus v-model="dialog" persistent scrollable width="auto" eager>
        <v-card elevation="5" >
          <v-card-title class="blue white--text">
            <h3>Stock Watch List</h3>
            <v-spacer></v-spacer>
            <v-btn small rounded fab class="pink lighten-4" variant="text" 
            @click.prevent.stop="closeDialog" >X</v-btn>
          </v-card-title>
          <v-divider class="ma-0 pa-0"></v-divider>
          <v-card-text style="height:350px;width:300px">
              <v-row dense >
                  <v-col cols="3"></v-col>
                  <v-col cols="5">
                    <v-list class="black--text">
                      <v-list-item-group  multiple>
                          <v-list-item v-for="( item, i ) in watchlist" :key="i" >
                              <v-checkbox class="black--text font-weight-bold" v-model="checked" :label="item" :value="item" ></v-checkbox>
                          </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                  <v-col cols="4"></v-col>
              </v-row>
          </v-card-text>
          <v-divider class="ma-0 pa-0"></v-divider>
          <v-card-actions class="blue accent-1">
            <v-btn :disabled="(this.checked.length <= 0)" rounded color="red accent-1" class="font-weight-bold" variant="text" @click.prevent.stop="deleteConfirm" >Delete Selected</v-btn>
            <v-spacer></v-spacer>                      
            <v-btn rounded class="red accent-1 font-weight-bold" variant="text" @click.prevent.stop="closeDialog" >Exit</v-btn>  
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template> 
<script>
// listen for and allow broadcasts to components  /v-model="selectedTickers"

import { eventBus }  from '@/main'  // event bus communication for registered components

export default {
  data () {
    return {
      valid: false,
      dialog: false,
      symbol: "AAPL",
      newTicker: '',
      addNew:  false,
      checked: [],
      watchlist: ["AAPL"], 
      selectedTickers: '["AAPL"]',
    }
  },
  methods: {
     showDialog() {
        // important parse list in localstorage to match component expectations
        this.checked   = [];  // start with empty list
        this.watchlist = JSON.parse( localStorage.getItem("watchlist") || "[]" );
        this.dialog    = true;
        //setTimeout( ()=> this.$refs['form'].reset(), this.$refs['form'].resetValidation() , 500 );
     },
     closeDialog() {
        this.dialog    = false;
        this.newTicker ='';
        this.addNew    = false;  
         //  localStorage.setItem("bookmarks", JSON.stringify( newBookmarks ));
     },
     deleteConfirm() {
      if( this.checked.length > 0 ) {
          // ask if add to WatchList
          this.$swal({
            title: "Remove Selected Symbols",
            text:  "Checked Tickers Will Be Removed From WatchList?",
            icon:  "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Remove!",
            footer:"( "+this.checked.length+" ) Ticker(s) Will Be Removed From WatchList"
          }).then((result) => {
            if( result.isConfirmed ) {
              // send event to remove selected tickers to WatchList Component
              // step 1 *** update localstorage watchlist *** re-stamp values
              this.watchlist  = JSON.parse( localStorage.getItem("watchlist") );

              // step 2 get current working copy of watchlist
              var wList = this.watchlist;

              // step 3 filter out checked tickers
              // const xList = wList.filter((ticker) => !this.checked.includes(ticker));
              const xList = [];
              // send back unchecked
              for( const tkr of wList ) {
                  if( this.checked.includes( tkr )  ) {
                     // ignore checked symbols
                  } else {
                     xList.push( tkr );
                  }
              }

              // *** if all tickers are removed - add in default 'AAPL' ***
              if( xList && xList.length <=0 ) xList.push( "AAPL" );

              // step 4 update localstorage watchlist with new 
              localStorage.setItem( 'watchlist', JSON.stringify( xList ) );
              //localStorage.setItem( 'lastSeachedSymbol', JSON.stringify( xList[0] ) );

              // notify user
              this.$swal({
                title: "WatchList Updated!",
                text:  "Selected Tickers Removed from WatchList",
                icon:  "success",
                footer: "Tickers Removed: [ "+ this.checked +"]"
              }).then((result) => {
                  // refresh window - reload to display new watchlist
                  // ask registered components (charts) to refresh themselves for the update
                  // send event to registered components - after 1/2 sec delay   

                  // tell listener object to update itself 
                  eventBus.$emit("ticker-deletes", this.checked );  
                  setTimeout( ( ( )=> this.closeDialog()  ), 250 );
                                    
              });
            }
          });
        }
     },
     getWatchListCount() {
        return( this.watchlist.length );
     },
     getWatchListValue( pos ) {
        return( this.watchList.pos );
     },
  },
  beforeMount() {
    // show loading spinner - just before component is mounted to app
    this.$loading(true);

    // important parse list in localstorage to match component expectations
    this.watchlist = JSON.parse( localStorage.getItem("watchlist") || "[]" );
    console.log( "Tickers: "+this.watchlist );
  },
  mounted() {
    // 1 sec delay - then remove loading indicater       
    setTimeout( ()=> this.$loading(false), 1500 ); 
    eventBus.$on( "watchlist-update", this.showDialog );  
       
  },  
}
</script>

<style scoped>
   .v-input--checkbox .v-label {
     font-size: 14px; font-weight: bold;
  }
</style>