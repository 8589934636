import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"300","tile":""}},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.checkBookmarks.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-chart-multiple")]),_vm._v(" Live Data Charts ")],1)]}}])},[_c(VList,{staticClass:"text-left",attrs:{"dense":"","color":"grey"}},[_c(VListItemGroup,{attrs:{"color":"red"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.id,attrs:{"to":item.route,"id":item.id}},[_c(VListItemIcon,{staticClass:"pl-0 pr-1 ml-0 mr-1"},[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,{staticClass:"pl-0 pr-0 ml-0 mr-0"},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.title)}})],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }