<!-- default landing page - puts calculator component -->
<template>
  <v-container class="fill-height printable" > 
    <v-layout justify-center align-center>      
            <v-card class="mx-auto my-auto" min-width="225" max-width="850" hover >
              <!-- src="https://cdn.vuetifyjs.com/images/cards/docks.jpg" -->
              <v-img class="white--text align-center" width="auto" height="75px" cover src="@/assets/gears-header_100.jpg">
                      <v-card-title >
                          <v-row dense>
                              <v-col cols="1">
                                  <!-- user help button -->
                                  <v-btn class="mx-1" fab small dark color="green" @click.prevent.stop="showPutsHelp" >
                                      <v-icon dark>fa-solid fa-question</v-icon>
                                  </v-btn>
                              </v-col>
                              <v-col class="pt-2 d-flex justify-center" cols="9">
                                <span class="text-h5 font-weight-bold">&nbsp;&nbsp;{{$t("puts_page.title")}}</span>
                              </v-col>
                                <v-col cols="2">
                                  <!-- track bookmarks -->
                                  <Bookmark link="puts"/>
                              </v-col>                    
                          </v-row>
                          <!-- create help wiki here - Remember the 'locale' is prepended to the file name -->
                          <PutsHelpWiki />
                      </v-card-title>
              </v-img>
              <!-- <v-card-subtitle class="pb-0">{{$t("puts_page.subtitle")}}</v-card-subtitle> -->
              <v-btn ref="putsBuyBtn" @click="update_puts_mode('B')" class="mt-5 mb-0 mr-5" rounded value="B">{{$t("puts_page.button_buy")}}</v-btn>
              <v-btn ref="putsSellBtn" @click="update_puts_mode('S')" class="mt-5 mb-0 ml-5" rounded value="S">{{$t("puts_page.button_sell")}}</v-btn>   
              <v-card-text class="text--primary">
                <v-form ref="queryForm" @submit.prevent.stop v-model="valid" >
                    <v-container >
                      <!-- ticker block removed: :rules="call_tickerRules" -->
            <!-- PM removed 4.19.23 - Yahoo Ticker Url 403 - until further notice ---              
                      <v-row dense justify="center" class="mb-0 pb-0">
                        <v-col cols="10" sm="10" md="8" lg="6">
                            <v-text-field v-model="ticker"
                              label="Ticker Symbol"
                              prepend-icon="mdi-symbol"
                              :maxlength="7"
                              :counter="10"
                              @input="ticker= ((ticker != null)?ticker.toUpperCase():null)" 
                              @change="symbolLookup"
                              clearable 
                              @click:clear="clearTickerPrice" 
                              hint="Optional - Not Required" 
                              persistent-hint                                                                       
                              dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="10" sm="10" md="8" lg="6">
                            <v-text-field v-model="tickerPrice"
                              readonly
                              prepend-icon="mdi-currency-usd"                    
                              label="Market Price"
                              hint="Non Editable - Info Only"
                              persistent-hint                    
                              dense
                            ></v-text-field>
                        </v-col>               
                      </v-row>
            -->              
                      <!-- ticker information panel - pops up when a price is found -->
                      <v-row v-show="this.tickerPrice" class="ma-0 pa-0">
                        <v-col cols="12">
                            <!-- boolean passed to update data in the component -->
                            <TickerInfoDisplay :jsonData="this.jsonData" />
                        </v-col>
                      </v-row>  
                      <v-row dense justify="center" class="ma-0 pa-0">
                          <v-col cols="12"></v-col>
                      </v-row>  
                      <!-- PUTS MAIN SECTION -->                
                      <v-row dense justify="center">
                        <v-col cols="10" sm="10" md="8" lg="6" >
                          <v-currency-field
                            :valueAsInteger="true" 
                            :decimalLength=0                
                            name="puts_expiration"
                            prepend-icon="mdi-calendar-multiselect"
                            v-model="puts_expiration"
                            :rules="puts_expirationRules"
                            :label="$t('puts_page.puts_expiration_label')"
                            @blur="update_puts_expiration($event)"            
                            dense
                            clearable
                            required />
                        </v-col>
                        <v-col cols="10" sm="10" md="8" lg="6" > 
                          <v-currency-field 
                            :label="$t('puts_page.puts_strikePrice_label')" 
                            dense
                            clearable                  
                            :decimalLength=2
                            v-model="puts_strikePrice"
                            name="puts_strikePrice"                  
                            :rules="puts_strikePriceRules"
                            prepend-icon="mdi-bullseye-arrow"
                            @blur="update_puts_strikePrice($event)"                  
                            required />
                        </v-col>
                      </v-row>
                      <v-row dense justify="center">
                        <v-col cols="10" sm="10" md="8" lg="6" >
                          <v-currency-field 
                            :label="$t('puts_page.puts_currentPrice_label')"  
                            dense
                            clearable                  
                            :decimalLength=2
                            :rules="puts_currentPriceRules"
                            prepend-icon="mdi-currency-usd"
                            v-model="puts_currentPrice"
                            name="puts_currentPrice"
                            @blur="update_puts_currentPrice($event)"                    
                            required
                          />
                        </v-col>       
                        <v-col cols="10" sm="10" md="8" lg="6" >
                          <v-currency-field 
                            :label="$t('puts_page.puts_expectedStockPrice_label')"
                            prepend-icon="mdi-hand-heart-outline"                  
                            :error-messages="errors.rate"
                            :rules=puts_expectedStockPriceRules
                            dense
                            clearable                  
                            :decimalLength=2
                            required
                            name="puts_expectedStockPrice"
                            @blur="update_puts_expectedStockPrice($event)"                   
                            v-model="puts_expectedStockPrice"/>         
                        </v-col>              
                      </v-row>
                      <v-row dense justify="center">
                        <v-col cols="10" sm="10" md="8" lg="6" >
                          <v-currency-field 
                            :valueAsInteger="true" 
                            :decimalLength=0                
                            prepend-icon="mdi-file-sign"
                            name="puts_contracts"
                            v-model="puts_contracts"
                            :rules="puts_contractsRules"
                            :label="$t('puts_page.puts_contracts_label')"  
                            @blur="update_puts_contracts($event)"                  
                            dense
                            clearable                  
                            required />
                        </v-col>
                        <v-col cols="10" sm="10" md="8" lg="6" >
                          <v-currency-field 
                            :label="$t('puts_page.puts_optionPrice_label')" 
                            dense
                            clearable                  
                            :decimalLength=2
                            :rules="puts_optionPriceRules"
                            prepend-icon="mdi-target"
                            v-model="puts_optionPrice"
                            name="puts_optionPrice"
                            @blur="update_puts_optionPrice($event)"                    
                            required />               
                        </v-col>              
                      </v-row>
                    </v-container>
                  </v-form>
                  <!-- calculated bottom sheet info -->
                  <div v-if="displayInfo">
                      <v-layout align-center>
                      <!-- <v-flex xs12 sm12 md8 lg6 offset-md2> -->
                          <v-card class="mx-auto" min-width="200" >
                            <v-toolbar dense color="green lighten-1">
                              <v-app-bar-nav-icon></v-app-bar-nav-icon>
                              <v-toolbar-title class="white--text resultsTitle">{{ (puts_mode === 'B' ? $t("puts_page.button_buy"):$t("puts_page.button_sell"))+results_title }}</v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-btn @click="displayInfo=false" icon>
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-data-table disable-sort disable-pagination
                              :headers="headers"
                              :items="fields"
                              :hide-default-header="true"
                              :hide-default-footer="true"
                              class="elevation-1"
                              no-data-text="No data"                    
                            ></v-data-table>
                          </v-card>
                        <!-- </v-flex> -->
                      </v-layout>
                    </div>
                  <!-- end bottom sheet info-->  
                  <v-row dense justify="center">
                    <v-col cols="12" >
                        <ToolsBottomAds/>          
                    </v-col>
                  </v-row> 
                  <v-row dense justify="center">
                    <v-col cols="11" >
                       <SideDisclaimerNotice/>
                    </v-col>
                  </v-row>                                                                                                   
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                  <v-btn small :disabled="!valid" color="primary" text @click="updateCalculatedResultData">
                    <v-icon left dark>fa-solid fa-upload</v-icon>
                    <span style="font-size: 8px">{{$t("puts_page.button_send")}}</span>
                  </v-btn>
                  <v-btn small color="orange" text class="ml-5 pl-5" @click.prevent.stop="resetQueryOptions">
                    <v-icon left dark>fa-solid fa-rotate-right</v-icon>          
                    <span style="font-size: 8px">{{$t("puts_page.button_reset")}}</span>  
                  </v-btn>
                  <v-spacer></v-spacer>   
                  <v-btn color="green" small text @click.prevent.stop="showPutsHelp"  >
                      <v-icon left dark>fa-solid fa-question</v-icon>
                      <span style="font-size: 8px">Help</span>          
                  </v-btn>                        
              </v-card-actions>
            </v-card>
    </v-layout>
  </v-container>
</template>

<script>
  // listen for and allow broadcasts to components
  import { eventBus } from '@/main'  // event bus communication for registered components

  // use NY Date info for unigue key to sync with server for validation purposes
  var tNYDate = new Date(new Date().toLocaleString('en', {timeZone: 'America/New_York'}));    

  // ads bottom sheet - uses broadcast message to show ad
  //import BottomSheetAds from '@/components/BottomSheetAds.vue'
  import TickerInfoDisplay    from '@/components/tools/TickerInfoDisplay.vue' 
  import SideDisclaimerNotice from '@/components/SideDisclaimerNotice.vue' 
  import ToolsBottomAds       from '@/components/ads/ToolsBottomAds.vue';   
  // wiki tool document used when user presses 'help' button
  import PutsHelpWiki         from '@/components/AboutPutsWiki.vue'
  // user bookmarking component
  import Bookmark             from '@/components/AddToFavorites.vue'

  // i18n international language support for labels - should use browser locale default settings
  // all labels are defined in src/locals folder in json files - ie: en.json for English settings
  import i18n from "@/i18n"      

  export default {
    components: { TickerInfoDisplay, SideDisclaimerNotice, ToolsBottomAds, PutsHelpWiki, Bookmark },
    data: () => ({
      valid: false,
      // ticker loolup fields
      ticker: '',
      tickerPrice: null,
      jsonData: '',            
      tickerInfo: '',
      quoteSourceName: '',
      longName: '',
      sharesOutstanding: '', 
      //---------- SECURITY CHECKS SENT to SERVER -------------------------------*
         xKey: tNYDate.getFullYear()+(tNYDate.getMonth()+1)+(tNYDate.getDate()),
        x2Key: tNYDate.getFullYear()*(tNYDate.getMonth()+1)*(tNYDate.getDate()),          
      //-------------------------------------------------------------------------*  
      displayInfo: false,
      puts_expiration: '',
      puts_strikePrice: '',
      puts_contracts: '',
      puts_optionPrice: '',
      puts_currentPrice: '',  
      puts_expectedStockPrice: '',
      puts_mode: 'B',
      results_title: i18n.t('puts_page.rpt_results_title'),  
      isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))),  
      // reference current language locale
      locale: i18n.locale,                  
      // array to build data table - fields are updated before display is performed
      headers: [ { text: 'Calculated Fields', align: 'start', sortable: false, value: 'name', width: '50%' },
                 { text: 'Amount', value: 'value', width: '50%' },
        ],
        // default values are 0 updated later calling method 'updateCalculatedResultData'
        fields: [{}],  // *** empty structure for data table construction when initialized - empty to start
        // replaced when user selects 'SELL' button
        fieldsSell: [ { name: i18n.t('puts_page.sell_rpt_exp_chg_dollar'),  value: 0, },
                      { name: i18n.t('puts_page.sell_rpt_exp_chg_percent'), value: 0, },
                      { name: i18n.t('puts_page.sell_rpt_ttl_prem_earned'), value: 0, },
                      { name: i18n.t('puts_page.sell_rpt_ptl_gain_lost'),   value: 0, },
        ],
        // replaced when user selects 'BUY' button
        fieldsBuy: [ { name: i18n.t('puts_page.buy_rpt_exp_chg_dollar'),    value: 0, },
                     { name: i18n.t('puts_page.buy_rpt_exp_chg_percent'),   value: 0, },
                     { name: i18n.t('puts_page.buy_rpt_ttl_option_dollar'), value: 0, }, 
                     { name: i18n.t('puts_page.buy_rpt_ttl_option_percent'),value: 0, },
                     { name: i18n.t('puts_page.buy_rpt_ttl_cost'), value: 0, },          
        ],    
      errors: {},
          // FORM RULES SECTION - called by form fields 
          puts_expirationRules: [
            v => !!v || i18n.t('puts_page.puts_expiration_reqMsg'),
            v => (v && parseInt(v.replace(/,/g,'')) > 0 && parseInt(v.replace(/,/g,'')) <= 1095 ) 
                ||  i18n.t('puts_page.puts_expiration_minMaxMsg'),
          ],
          puts_contractsRules: [
            v => !!v || i18n.t('puts_page.puts_contracts_reqMsg'),
            v => (v && parseInt(v.replace(/,/g,'')) > 0 && parseInt(v.replace(/,/g,'')) <= 1000000) 
                || i18n.t('puts_page.puts_contracts_minMaxMsg'),
          ], 
          puts_strikePriceRules: [
            v => !!v || i18n.t('puts_page.puts_strikePrice_reqMsg'), 
            v => (v && parseFloat(v.replace(/,/g,'')) > 0.00 && parseFloat(v.replace(/,/g,'')) <= 1000000 ) || i18n.t('puts_page.puts_strikePrice_minMaxMsg'),
          ],        
          puts_optionPriceRules: [
            v => !!v ||  i18n.t('puts_page.puts_optionPrice_reqMsg'),
            v => (v && parseFloat(v.replace(/,/g,'')) > 0.00 && parseFloat(v.replace(/,/g,'')) <= 1000000 ) ||  i18n.t('puts_page.puts_optionPrice_minMaxMsg'),
          ],  
          puts_currentPriceRules: [
            v => !!v ||  i18n.t('puts_page.puts_currentPrice_reqMsg'), 
            v => (v && parseFloat(v.replace(/,/g,'')) > 0.00 && parseFloat(v.replace(/,/g,'')) <= 1000000 ) ||  i18n.t('puts_page.puts_currentPrice_minMaxMsg'),
          ],
          puts_expectedStockPriceRules: [
            v => !!v ||  i18n.t('puts_page.puts_expectedStockPrice_reqMsg'), 
            v => (v && parseFloat(v.replace(/,/g,'')) > 0.00 && parseFloat(v.replace(/,/g,'')) <= 1000000) ||  i18n.t('puts_page.puts_expectedStockPrice_minMaxMsg'),
          ], 
    }),
    methods: {
         /*************************************************************** 
           *  calls servlet to retrieve data '/ticker?symbol=' relative 
           **************************************************************/
           symbolLookup() {
            if( this.ticker ) {
              this.$loading(true);  
              // clear any previous data stored in session - also called on error
                   this.clearTickerPrice();                                  
              //make request to back-end for latest ticker info  
              this.axios.get('/ticker?symbol='+this.ticker,{headers:{'api-ref':this.xKey,'sc-ch-nx':this.x2Key}}).then((response) => {
                    //console.log( response.data  );
                    var res = response.data;
                    // store for passing to ticker display expansion box
                    this.jsonData = response.data;
                    // **** throw error to popup message - invalid symbol ****
                    if( Number.parseFloat(res[0].regularMarketPrice) <= 0 ) {
                        throw new Error('Invalid Symbol; Not Found');
                    } 
                    // *** trigger to show ticker info dialog ***
                    this.tickerPrice = res[0].regularMarketPrice; 
              }).catch( err => { 
                  this.$loading(false);    
                  //show message error - clear price to hide previous dialog which is the trigger
                  this.tickerPrice = ''; 
                  // display message box
                  this.$swal.fire({
                    icon: 'error',
                    title: i18n.t('puts_page.ticker_srch_err_title'),
                    text: this.ticker+i18n.t('puts_page.ticker_srch_err_msg'),
                    footer: i18n.t('puts_page.ticker_srch_footer_msg'),
                  }).then((result) => {
                      if( result ) {
                        // clear data on error
                        this.clearTickerPrice();                                                                 
                      }
                  }) 
                  console.log(err);   
              });
              // *** update screen from session storage after the promise ***
              setTimeout( ()=> { 
                  this.$loading(false);                                               
              }, 500 );                     
            }
          },
          // called when the 'x' is clicked on the 'ticker' lookup field
          // to clear the price which will hide the ticker stock information panel
          clearTickerPrice() { 
             this.tickerPrice='';
          },                
          // submit button - update default data table array with entered values before showing results
          updateCalculatedResultData() {
            try {            
                // display 1 sec spinner - start
                this.$loading(true)  // show loading spinner 
                // CLEAR REPORT DATA - first
                this.fields.length = 0;  // *** first clear fields array - clear
                // *** first get all form field values - individually used in BUY & SELL sections***
                var expiration    = Number.parseInt(this.puts_expiration.toLocaleString(this.$i18n.locale).replace(/,/g,''));
                var contracts     = Number.parseInt(this.puts_contracts.toLocaleString(this.$i18n.locale).replace(/,/g,''));
                var strikePrice   = Number.parseFloat(this.puts_strikePrice.toLocaleString(this.$i18n.locale).replace(/,/g,''));
                var optionPrice   = Number.parseFloat(this.puts_optionPrice.toLocaleString(this.$i18n.locale).replace(/,/g,''));
                var currentPrice  = Number.parseFloat(this.puts_currentPrice.toLocaleString(this.$i18n.locale).replace(/,/g,''));
                var expectedPrice = Number.parseFloat(this.puts_expectedStockPrice.toLocaleString(this.$i18n.locale).replace(/,/g,''));
                //  ------------------------ BUY FORMULA SECTION ---------------------------------*
                if ( this.puts_mode === 'B' ) {
                  this.fields.splice(0, this.fields.length, ...this.fieldsBuy  ); // re-define based on 'puts_mode' 
                  //------- BUY - PUTS CALC SECTION -------------------------------------------------

                  // Report - Expected Change Dollar column  [ = (C6-C5) ] round to 2 decimal
                  this.fields[0].value = this.numberWithCommas( (expectedPrice - currentPrice).toFixed(2) ); 

                  // Report - Expected Change Percent column [ = (C6/C5) -1 ] - round to 2 decimal
                  this.fields[1].value = this.numberWithCommas( ((( expectedPrice / currentPrice )-1) *100).toFixed(2) );                                  

                  //--- ROD block to determine individual calc parts of formula 
                  // complete formula block [ =((C4-C6)*100)*(C7))-((C7*100)*C8)-(ABS((C8)-(C6-C4))/C3) ]
                    // ( ( C4 - C6 ) *100 )
                    var trod_1 = ( (( strikePrice - expectedPrice ) *100 )) * ((contracts));
                    // ( ( C7 *100 ) *C8 )
                    var trod_2 = ( ( contracts *100 ) * optionPrice );
                    // (  ABS( C8 -(C6-C4) ) /C3 )
                    var trod_3 = ( Math.abs( (optionPrice - (expectedPrice - strikePrice)) ) / expiration );

                  //  Report - Ttl return on Option Dollar - round to 2 decimal places
                  this.fields[2].value = this.numberWithCommas( (trod_1 -trod_2 -trod_3).toFixed(2) );

                  //------ END ROD BLOCK -----------------------------------------------
                  // Ttl Return on Option Percent [ = C13 / ( (C7*100)*C8 ) ]
                  this.fields[3].value = this.numberWithCommas( ((trod_1 -trod_2 -trod_3)
                         / ( ( ( contracts *100 ) * optionPrice ) )*100).toFixed(2) );  

                  // TOTAL COST [ = (C8*C7) * 100  ]
                  this.fields[4].value = this.numberWithCommas( (( optionPrice * contracts) *100 ).toFixed(2) );

                  // <<<*** CHECK if 'Expected Stock Price' is less than 'Strike Price' OVERWRITE 2 Colmns ***>>>
                  if( expectedPrice >= strikePrice ) {
                      // Total Return on Option Dollar will = [ Total Cost  ]
                      this.fields[4].value = this.numberWithCommas( - ( ( contracts *100 ) * optionPrice ).toFixed(2) );
                      // Total Return on Option Percent = -100%
                      this.fields[3].value = this.numberWithCommas(-100.00);
                      // Total Return on Option Dollar  = -Cost
                      this.fields[2].value = this.numberWithCommas( - ( ( contracts *100 ) * optionPrice ).toFixed(2) );     
                  } else {
                      // Total Return on Option Dollar [   ]
                      this.fields[2].value = this.numberWithCommas(  ((trod_1 -trod_2 -trod_3).toFixed(2)) );
                      // Total Return on Option Percent  [ =(C13/C15) ]
                      this.fields[3].value = this.numberWithCommas( ((trod_1 -trod_2 -trod_3)
                            / ( ( ( contracts *100 ) * optionPrice ) )*100).toFixed(2) );  
                  }
                } else {
                  // ***  USER SELECTED 'SELL' BUTTON FORMULA ******************************
                  // ------------------------- SELL FORMULA SECTION ------------------------
                  this.fields.splice(0, this.fields.length, ...this.fieldsSell ); // re-define based on 'puts_mode'
                  // ---------------------------------------------------------------------------------------------
                                                            
                  // SELL PUTS - Second check against user values ****                                                                       
                  if( expectedPrice === strikePrice ) {
                      this.fields[0].value = 0;
                      // Expected Change Percent [  =(C6/C5)-1  ]   
                      this.fields[1].value = 0;
                      // Total Premium Earned    [  =(C7*100)*(C8)  ]
                      this.fields[2].value = this.numberWithCommas( (( contracts *100 ) *  optionPrice ).toFixed(2) );
                      // Potential Gains Lost    [  =((C7*100)*(C6))-((C7*100)*(C4))+C13  ]
                      this.fields[3].value = 0;
                  } else if( expectedPrice > strikePrice ) {
                      this.fields[0].value = this.numberWithCommas( ( expectedPrice - currentPrice ).toFixed(2) );
                      // Expected Change Percent [  =(C6/C5)-1  ]   
                      this.fields[1].value = this.numberWithCommas( ( (( expectedPrice / currentPrice ) -1) *100 ).toFixed(2) );
                      // Total Premium Earned    [  =(C7*100)*(C8)  ]
                      this.fields[2].value = this.numberWithCommas( (( contracts *100 ) *  optionPrice ).toFixed(2) );
                      //Potential Gains Lost US $	
                      this.fields[3].name = 'Potential Gains Lost US $	';
                      // Potential Gains Lost    [  =((C7*100)*(C6))-((C7*100)*(C4))+C13  ]
                      this.fields[3].value = this.numberWithCommas( ((( contracts *100 ) * ( expectedPrice ) )
                                                                      - (( contracts *100 ) * strikePrice ) 
                                                                        + (( contracts *100) * optionPrice)).toFixed(2) );
                  } else { 
                      this.fields[0].value = this.numberWithCommas( ( expectedPrice - currentPrice ).toFixed(2) );
                      // Expected Change Percent [  =(C6/C5)-1  ]   
                      this.fields[1].value = this.numberWithCommas( ( (( expectedPrice / currentPrice ) -1) *100 ).toFixed(2) );
                      // Total Premium Earned    [  =(C7*100)*(C8)  ]
                      this.fields[2].value = this.numberWithCommas( (( contracts *100 ) *  optionPrice ).toFixed(2) );
                      // Stock Value Lost Name Change
                      this.fields[3].name  = 'Stock Value Lost $';
                      // Potential Gains Lost    [  =((C7*100)*(C6))-((C7*100)*(C4))+C13  ]
                      this.fields[3].value = this.numberWithCommas( ((( contracts *100 ) * ( expectedPrice ) )
                                                                      - (( contracts *100 ) * strikePrice ) 
                                                                        + (( contracts *100) * optionPrice)).toFixed(2) );
                  }
                }
                // *** store query data to cookie for later when app restarts 
                this.setPutsCookie();

            } catch(err) { console.log(err)
            } finally {   
               // remove spinner & redisplay bottom data table for user's review
               setTimeout( ()=> { this.$loading(false), this.displayInfo=true }, 1000 );
            }
          },      
          // form item calls on 'blur' to save form data to store
          update_puts_expiration(event) {
            this.$store.commit( 'PUTS_EXPIRATION', event.target.value )
              //console.log('stored expiration: '+event.target.value);
          },
          update_puts_contracts(event){
            this.$store.commit( 'PUTS_CONTRACTS', event.target.value )
          },
          update_puts_strikePrice(event){
            this.$store.commit( 'PUTS_STRIKEPRICE', event.target.value )
          },     
          update_puts_optionPrice(event){
            this.$store.commit( 'PUTS_OPTIONPRICE', event.target.value )
          },
          update_puts_currentPrice(event){
            this.$store.commit( 'PUTS_CURRENTPRICE', event.target.value )
          },  
          update_puts_expectedStockPrice(event){
            this.$store.commit( 'PUTS_EXPECTEDSTOCKPRICE', event.target.value )
          }, 
          // --- user selects 'BUY' or 'SELL' before submitting query - default is 'BUY'
          update_puts_mode( mode ) {
            if ( mode === 'B') {
                this.$refs["putsBuyBtn"].$el.style.color="white"
                this.$refs["putsBuyBtn"].$el.style.background="green"
                this.$refs["putsSellBtn"].$el.style.color="black"                
                this.$refs["putsSellBtn"].$el.style.background="lightgray"                
            } else {
                this.$refs["putsSellBtn"].$el.style.color="white" 
                this.$refs["putsSellBtn"].$el.style.background="green"
                this.$refs["putsBuyBtn"].$el.style.color="black"
                this.$refs["putsBuyBtn"].$el.style.background="lightgray"                
            }
            // update data to match button click - close result table info
            this.puts_mode = mode; this.displayInfo = false;
          }, 
          resetOrig() {
            this.puts_expiration= ''
            this.puts_strikePrice= ''
            this.puts_contracts = ''
            this.puts_optionPrice = ''
            this.puts_currentPrice = ''  
            this.puts_expectedStockPrice =''
          },
          resetQueryOptions() {
            this.$swal({
                title: i18n.t('puts_page.reset_title'),
                text: i18n.t('puts_page.reset_txt'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: i18n.t('puts_page.reset_confirmButtonTxt'),
                cancelButtonText: i18n.t('puts_page.reset_cancelButtonTxt'),
                showCloseButton: false,
                showLoaderOnConfirm: false
              }).then((result) => {
                if(result.value) {
                  this.$refs.queryForm.reset(); 
                  this.$refs.queryForm.resetValidation()
                  this.resetOrig(); this.displayInfo=false;
                  // <v-currency-field bug does not clear on form reset - call reset again
                  setTimeout( ()=> this.$refs.queryForm.reset(), 150 );
                  // reset form - remove any persistent store call data 
                  this.$store.commit('CLEAR_PUTS_DATA'); // clear data store values                  
                  // clear calculated results fields
                  this.fields.length = 0;  // *** first clear fields array - clear
                }
              });
          },
          // formats numbers with commas, negative number wrapped with params
          numberWithCommas(num) {
             //return num < 0 ? '( '+num.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',').replace(/-/g,'')+' )' : num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
             var retVal;
             if( num < 0 ) {
                // remove - negative sign and surround with parans ()
                retVal = '( '+new Intl.NumberFormat( this.$i18n.locale ).format(num).replace(/-/g,'')+' )'
             } else {
                retVal = new Intl.NumberFormat( this.$i18n.locale ).format(num)
             }
             return retVal ;             
          }, 
          // called on each query - cookie overwritten everytime by query
          setPutsCookie() {
             var putsInfo = {
                  puts_expiration:   this.$store.getters.puts_expiration,
                  puts_strikePrice:  this.$store.getters.puts_strikePrice,
                  puts_contracts:    this.$store.getters.puts_contracts,
                  puts_optionPrice:  this.$store.getters.puts_optionPrice,
                  puts_currentPrice: this.$store.getters.puts_currentPrice,  
                  puts_expectedStockPrice: this.$store.getters.puts_expectedStockPrice              
             }
             // *** store cookie - for later retrieval when app starts again - it's always overwritten on each query
             this.$cookies.set('putsInfo', JSON.stringify(putsInfo)); 
          },
          showPutsHelp() {
            // emits global event for component - to open help document from button
            eventBus.$emit("show-puts-wiki", "showHelpWiki");
        },                                                        
    }, 
    // life cycle methods - called when component is mounted - required since buttons are modified
    beforeMount() {
      // get data from store on page switches - tab clicks - if any
      this.puts_expiration     = this.$store.getters.puts_expiration;
      this.puts_contracts      = this.$store.getters.puts_contracts;
      this.puts_strikePrice    = this.$store.getters.puts_strikePrice;
      this.puts_optionPrice    = this.$store.getters.puts_optionPrice;
      this.puts_currentPrice   = this.$store.getters.puts_currentPrice;
      this.puts_expectedStockPrice= this.$store.getters.puts_expectedStockPrice;
      // show loading spinner - just before component is mounted to app
      this.$loading(true);          
    },
    mounted() {
      // set default 'buy' and 'sell' button color once component is mounted for display
      this.$refs["putsBuyBtn"].$el.style.color="white"
      this.$refs["putsBuyBtn"].$el.style.background="green"
      // .5 sec delay - then remove loading indicater       
      setTimeout( ()=> this.$loading(false), 500 );

      // show ads - if enabled in .env file - defined in BottomSheetAds component
      //setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), 2500 );       
    },
    metaInfo() {
      return {
        title: "Stock-Insight's Option Puts Calculator",
        meta: [
          { name: 'robots', content: 'index,follow'},   
          { name: 'description', content: 'Stock Puts Options Calculator' },
          { name: 'keywords', content: 'options, puts, calculator, investments' },                         
          { property: 'og:type', content: 'website'}, 
          { property: 'og:site_name', content: 'www.Stock-Insight.net'},
          { property: 'og:title', content: "Stock-Insight's Option Puts Calculator"},                             
          {
            vmid: "description",
            name: "description",
            content: "A Stock Option 'Puts' Calculator for those 'what if' evaluations"
          }
        ],
        link: [
          {
            rel: 'canonical',
            href: '<https://www.stock-insight.net/>'
          }
        ]
      }
    }             
  }
</script>

<style scoped>
  .resultsTitle {
      font-size: 1.0rem;
  }
</style>
