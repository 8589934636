/*
   External File that is imported into the main desktop and left App drawer menu items

   and changes here will change the desktop as well as mobile menu items since they both share these data elements

    tile: added to support new home page defined by bookmarks - display image of bookmark topic

*/

//import i18n from "@/i18n"
    const baseUrl   = "/rs/utils/document?file=tiles/";
    const menuItems = [
        { id: '1vd', title: "Bloomberg Clips", group: 'Latest Videos', icon: 'mdi-alpha-b-circle', route:({name: 'BloombergYTVideos'}), tile: baseUrl+"BloombergYoutube.png" }, 
        { id: '2vd', title: "CNBC Clips",      group: 'Latest Videos', icon: 'mdi-alpha-c-circle', route:({name: 'CNBCYTVideos'}),      tile: baseUrl+"CnbcYoutube.png" },  
        { id: '3vd', title: "IBD Clips",       group: 'Latest Videos', icon: 'mdi-alpha-i-circle', route:({name: 'IBDYTVideos'}),       tile: baseUrl+"IBDYoutube.png" }, 
        { id: '4vd', title: "Ark Invest Clips",group: 'Latest Videos', icon: 'mdi-alpha-k-circle', route:({name: 'ArkUTVideos'}),       tile: baseUrl+"ArkYoutube.png"  },                              
        { id: '5vd', title: "Reuters Clips",   group: 'Latest Videos', icon: 'mdi-alpha-r-circle', route:({name: 'ReutersYTVideos'}),   tile: baseUrl+"ReutersYoutube.png" },   
        { id: '6vd', title: "Yahoo Clips",     group: 'Latest Videos', icon: 'mdi-alpha-y-circle', route:({name: 'YahooYTVideos'}),     tile: baseUrl+"YahooYoutube.png" },   
        { id: '7vd', title: "Zacks Clips",     group: 'Latest Videos', icon: 'mdi-alpha-z-circle', route:({name: 'ZacksYTVideos'}),     tile: baseUrl+"ZacksYoutube.png" },                 
   ];

export default menuItems