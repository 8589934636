
<!----------------------------------------------------------------------------------------------------
    TAB 4 on Popup Home dialog - also included in AppBarMarketClock

    removed from Home Page dialog 4.15.24 since it will be destroyed whenever a user closes the home
    page. 

    This part from now on is imported into the 'appBarMarketClock.vue' file as it's main functioning
    logic. Could potentially used other places as well in the future.

    Stock Market Analog Clock - NYT   preserveAspectRatio="xMaxYMid meet"
    desktop:  width="400px" height="400px" viewBox="100 25 400 625"
    :style="{ backgroundColor: isMarketTrading ? 'lightcoral' : 'lightgray' }"  fill-height
------------------------------------------------------------------------------------------------------->
<template>
    <!-- determine color of clock if market is trading - color by data block item setting -->
    <!--  :style="{ backgroundColor: marketTrading ? '#C8E6C9' : '#BDBDBD' }" :class=" marketTrading ? 'marketOpen' : 'marketClosed' ">  -->
    <!-- <v-layout fill-height align-content-center :style="{ backgroundColor: '#000' }" > 
        <v-container fluid :class="marketTrading ? 'marketOpen' :  marketHoliday ? 'marketHoliday' : 'marketClosed' ">    
    -->
    <v-container id="clockHousing" fluid class="marketClosed" :style="{ width:'100%', height:'100%' }" >    
            <div v-if="this.isMobile || this.screenWidth <= 1180" >
                <div class="clockbox"> 
                    <!--  width="300px" height="300px" viewBox="35 35 500 650" -->
                    <svg id="clock" width="150px" viewBox="-200 -50 1000 950" xmlns="http://www.w3.org/2000/svg" >
                        <g id="face">
                            <circle class="circle" :style="{ stroke: clockCircleColor }" cx="300" cy="300" r="253.9"/>
                            <path class="hour-marks" :style="{ stroke: clockHourMarksColor }"  d="M300.5 94V61M506 300.5h32M300.5 506v33M94 300.5H60M411.3 107.8l7.9-13.8M493 190.2l13-7.4M492.1 411.4l16.5 9.5M411 492.3l8.9 15.3M189 492.3l-9.2 15.9M107.7 411L93 419.5M107.5 189.3l-17.1-9.9M188.1 108.2l-9-15.6"/>
                            <circle class="mid-circle" cx="300" cy="300" r="16.2"/>
                        </g>
                        <g id="hour">
                            <path class="hour-arm" :style="{ stroke: clockHourArmColor }" d="M300.5 298V142"/>
                            <circle class="sizing-box" cx="300" cy="300" r="253.9"/>
                        </g>
                        <g id="minute">
                            <path class="minute-arm" :style="{ stroke: clockMinuteArmColor }" d="M300.5 298V67"/>
                            <circle class="sizing-box" cx="300" cy="300" r="253.9"/>
                        </g>
                        <g id="second">
                            <path class="second-arm" :style="{ stroke: clockSecondArmColor }" d="M300.5 350V55"/>
                            <circle class="sizing-box" cx="300" cy="300" r="253.9"/>
                        </g>
                    </svg>
                </div>
            </div>
            <div v-else>
                <v-row dense>
                    <v-col cols="2"></v-col>
                    <v-col cols="8">
                        <div class="clockbox"> 
                            <svg id="clock"  width="400px" height="400px" viewBox="100 25 400 550" xmlns="http://www.w3.org/2000/svg" >
                                <g id="face">
                                    <circle class="circle" :style="{ stroke: clockCircleColor }" cx="300" cy="300" r="253.9"/>
                                    <path class="hour-marks" :style="{ stroke: clockHourMarksColor }"  d="M300.5 94V61M506 300.5h32M300.5 506v33M94 300.5H60M411.3 107.8l7.9-13.8M493 190.2l13-7.4M492.1 411.4l16.5 9.5M411 492.3l8.9 15.3M189 492.3l-9.2 15.9M107.7 411L93 419.5M107.5 189.3l-17.1-9.9M188.1 108.2l-9-15.6"/>
                                    <circle class="mid-circle" cx="300" cy="300" r="16.2"/>
                                </g>
                                <g id="hour">
                                    <path class="hour-arm" :style="{ stroke: clockHourArmColor }" d="M300.5 298V142"/>
                                    <circle class="sizing-box" cx="300" cy="300" r="253.9"/>
                                </g>
                                <g id="minute">
                                    <path class="minute-arm" :style="{ stroke: clockMinuteArmColor }" d="M300.5 298V67"/>
                                    <circle class="sizing-box" cx="300" cy="300" r="253.9"/>
                                </g>
                                <g id="second">
                                    <path class="second-arm" :style="{ stroke: clockSecondArmColor }" d="M300.5 350V55"/>
                                    <circle class="sizing-box" cx="300" cy="300" r="253.9"/>
                                </g>
                            </svg>
                        </div>
                    </v-col>
                    <v-col cols="2"></v-col>                    
                </v-row>
            </div>
            <v-row dense class="mt-1 pt-1">
                <v-layout justify-center >
                   <div v-if="alarm == null || alarm == ''">
                        <v-row>
                            <v-col cols="5">                    
                               <v-btn rounded class="white--text font-weight-bold" color="#1976D2" elevation="10" small @click.prevent.stop="alarmTimeSetup" >Set Alarm</v-btn>
                            </v-col>
                            <v-col cols="7">
                                <v-menu offset-y >
                                    <template v-slot:activator="{ on }">
                                        <v-btn block class="white--text font-weight-bold" color="#1976D2" elevation="10" small v-on="on" >Clock Mode: {{format}}</v-btn>
                                    </template>
                                    <v-list> <!-- walk clock mode list allow user to change -->
                                        <v-list-item v-for="(item, index) in modes" :key="index" @click.prevent="selectClockMode(item.id)" >
                                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                        </v-row>
                   </div>
                   <div v-else>
                      <!-- button bar for mobile and desktop -->
                       <div v-if="this.isMobile || this.screenWidth <= 1180" >
                          <v-row>
                            <v-col cols="12">
                                <v-btn fab x-small rounded elevation="10" class="white--text font-weight-bold ml-1 mr-1"  color="#1976D2" @click.prevent.stop="alarmTimeSetup" ><v-icon>fa-solid fa-rotate-right</v-icon></v-btn>                                           
                                <v-btn v-if="alarmRinging == true" fab x-small rounded elevation="10" class="white--text font-weight-bold ml-1 mr-1 " color="#1976D2" @click.prevent.stop="stopRinger" ><v-icon>fa-solid fa-ban</v-icon></v-btn>                                           
                                <v-btn small rounded elevation="10" class="white--text font-weight-bold ml-1 mr-1"        color="#FF1744" @click.prevent.stop="clearAlarm" >Clear Alarm: [ {{ alarmLabel }} ]</v-btn>                    
                            </v-col>
                          </v-row>                        
                       </div>
                       <div v-else>
                          <v-row>
                            <v-col cols="3">
                                <v-btn small rounded elevation="10" class="white--text font-weight-bold" color="#1976D2" @click.prevent.stop="alarmTimeSetup" >Reset</v-btn>                                           
                            </v-col>    
                            <v-col cols="3" v-if="alarmRinging == true">
                                <v-btn small rounded elevation="10" class="white--text font-weight-bold" color="#1976D2" @click.prevent.stop="stopRinger" >Stop</v-btn>                                           
                            </v-col>                                                                                                          
                            <v-col cols="5" v-if="alarmLabel && alarmLabel != null" >
                                <v-btn small rounded elevation="10" class="white--text font-weight-bold" color="#FF1744" @click.prevent.stop="clearAlarm" >Clear Alarm: [ {{ alarmLabel }} ]</v-btn>                    
                            </v-col>
                          </v-row>
                       </div>
                   </div>
                </v-layout>
            </v-row>
            <!-- time picker dialog closed by default 'Set' and 'Cancel' buttons -->
            <v-dialog v-model="timeDialog" persistent width="auto" >
                <v-card title="Time Select"  max-width="auto" prepend-icon="mdi-update" >
                    <v-time-picker  v-model="alarm" :landscape="landscape" scrollable :format="format"></v-time-picker>
                    <v-card-actions>
                        <v-row dense no-gutters>
                            <v-col>
                                <v-btn small class="ms-auto" elevation="5" color="#81D4FA" @click.prevent.stop="chooseAlarmTime" >Set Time</v-btn>
                            </v-col>
                            <v-col>
                                <v-btn small class="ms-auto" elevation="5" color="#EA80FC" @click.prevent.stop="cancelAlarmSetting" >Cancel</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>            
    </v-container>   
</template>

<script> 
    // event bus communication for registered components
    import { eventBus } from '@/main'  
    // jquery needed for sweetalert form
    import $ from 'jquery'
    // setup
    var HOURHAND;
    var MINUTEHAND;
    var SECONDHAND;

    //let beatUrl = new url('@/assets/alarm-clock-short.mp3');
    //let beat = new Audio( beatUrl );
    let beat = new Audio( 'https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/theme_01.mp3' );

    export default {
        name: 'MarketClock',
        data() {
            return {
                isMobile: ( (/Mobile/i.test(navigator.userAgent)) ), 
                timeZone: 'America/New_York',
                screenHeight: window.screen.height,
                screenWidth:  window.screen.width,
                nydate:  new Date( new Date().toLocaleString('en',{ timeZone: this.timeZone }) ),
                hours:   0,  // 24 hour format
                minutes: 0,
                seconds: 0,
                marketTrading: false,
                marketHoliday: false,
                clockCircleColor:    '#455A64',
                clockHourArmColor:   '#455A64',
                clockMinuteArmColor: '#455A64',
                clockSecondArmColor: '#455A64',
                clockHourMarksColor: '#000',  
                alarm: null,  
                alarmRinging: false,
                pauseAlarm: false,
                alarmLabel: null,  
                timeDialog: false,
                format: '24hr',
                //picker: null,
                landscape: false,
                xKey: 0,
                //alarmSound: new Audio( url('@/assets/alarm-clock-short.mp3') ),          
                // 2026 default starting calendar - changes or updates in S3 bucket
                holidays: {
                        "1,1"   : { holiday: "New Year's Day", value: 0 }, 
                        "1,19"  : { holiday: "Martin Luther King Jr", value: 0 },
                        "2,16"  : { holiday: "President's Birthday", value: 0 },
                    //    "4,6"   : { holiday: "Pre-Good Friday", value: .5 },                
                        "4,3"   : { holiday: "Good Friday", value: 0 },
                        "5,25"  : { holiday: "Memorial Day", value: 0 },
                        "6,19"  : { holiday: "Juneteenth NID", value: 0 },
                        "7,3"   : { holiday: "Pre-Independence Day", value: .5 },                
                        "7,4"   : { holiday: "Independence Day", value: 0 },
                        "9,7"   : { holiday: "Labor Day", value: 0 },
                        "11,26" : { holiday: "Thanksgiving Day", value: 0 },
                        "11,27" : { holiday: "Post-Thanksgiving Day", value: .5 },                           
                        "12,24" : { holiday: "Christmas Eve", value: .5 },                     
                        "12,25" : { holiday: "Christmas Day", value: 0 }               
                },
                modes: [ { id: 0, title: 'AM / PM', format: 'ampm' }, { id: 1, title: '24 Hour', format: '24hr' } ]                
            }
        },
        beforeMount() {
            // process updates to calendar, and timer alarm and backgrounds if defined in /documents S3 folder
            this.$loading(true)
 
            // get server/s3 current year json Market calendar from S3 documents 
            this.getMarketCalendar();
            
            // get defined alarm mp3 file url in documents / TimerAlarm.txt - contains Url
            this.getTimerAlarmMP3();

            // option to replace Market Open background - from S3 bucket - if available - otherwise default image
            //this.getTimerMarketOpenBackground();

            // option to replace Market Closed background - from S3 bucket - if available - otherwise default image
            //this.getTimerMarketClosedBackground();    
            
            // option to replace Market Holiday/Weekend background - from S3 bucket - if available - otherwise default image
            //this.getTimerMarketHolidayBackground();            

        },        
        mounted() {
           HOURHAND   = document.querySelector("#hour");
           MINUTEHAND = document.querySelector("#minute");
           SECONDHAND = document.querySelector("#second");
           // get stored alarm time - if any
           var alarmTime = localStorage.getItem("alarm");
           if( alarmTime && alarmTime != '' ) {
              this.alarm = alarmTime;
              // set button label for user
              this.setAlarmLabel();
           }
           // start clock - check every second
           setInterval( this.run_the_clock, 1000 );
           // init - *** store clock active state = showing 'true' ***
           //sessionStorage.setItem( 'timer', true );
           // clear loading symbol after 1 sec
           setTimeout( () => { this.$loading(false); }, 1000 );
        },
        unmounted() {
           // on destroy - remove session flag 
           sessionStorage.removeItem( 'timerShowing' );
        },
        methods: {
            // retrieve from server/s3 bucket Market json calendar
            getMarketCalendar() {
                // check for new calendar or date changes posted to S3 bucket
                var today = new Date();
                // this.showCheckToast();   // calendar check message
                this.axios.get('/rs/utils/document?file='+'calendar_'+today.getFullYear()+'.json',{headers:{'api-ref':this.xKey}}).then((response) => {
                    // console.log( response.data  ); // <- later comment out
                    this.holidays = response.data;    // overwrite default 2026 calendar
                    // **** just display default vaule from locales/en.json file ****
                    //this.showUpdateToast();    
                    console.log("NYSE Calendar Up-To-Date With Market Calendar.");          
                }).catch( err => { 
                    // show message - system using old calendar 2023 - not found in S3 bucket
                    //this.showErrorToast();            
                    console.log( err );
                });
            },
            // retrieve from server/s3 bucket Timer Alarm - else uses default
            getTimerAlarmMP3() {
                this.axios.get('/rs/utils/document?file=TimerAlarm.txt',{headers:{'api-ref':this.xKey}}).then((response) => {
                    //console.log( response.data  ); // <- later comment out           https://pixabay.com/sound-effects/search/alarm/
                    // get mp3 file name from S3 bucket file called 'TimerAlarm.txt'   https://cdn.pixabay.com/audio/2022/03/09/audio_fb0098c6da.mp3
                    beat = new Audio( response.data );  // actual MP3 file bytes - TimerAlarm.mp3
                    //console.log( "FYI: Using S3 Bucket Timer Alarm: "+response.data );          
                }).catch( err => { 
                    // show message - system using old calendar 2023 - not found in S3 bucket
                    // this.showErrorToast();  on error will use 'default' beat timer        
                    console.log( err );
                });                
            },
            getTimerMarketOpenBackground() {
                this.axios.get('/rs/utils/document?file=TimerMarketOpenBackground.txt',{headers:{'api-ref':this.xKey}}).then((response) => {
                    // get reference to background image class
                    let moImage = document.querySelector('.marketOpen');
                        moImage.style.backgroundImage = `url( response.data )`;
                    // console.log( "FYI: Using S3 Bucket background image Url: "+response.data );          
                }).catch( err => { 
                    // show message - system using original background - since file not found in S3 bucket /documents - 404
                    console.log( err );
                });                
            },        
            getTimerMarketClosedBackground() {
                this.axios.get('/rs/utils/document?file=TimerMarketClosedBackground.txt',{headers:{'api-ref':this.xKey}}).then((response) => {
                    // get reference to background image class
                    let mcImage = document.querySelector('.marketClosed');
                        mcImage.style.backgroundImage = `url( response.data )`;
                    // console.log( "FYI: Using S3 Bucket background image Url: "+response.data );       
                }).catch( err => { 
                    // show message - system using original background - since file not found in S3 bucket /documents - 404
                    console.log( err );
                });                
            },
            getTimerMarketHolidayBackground() {
                this.axios.get('/rs/utils/document?file=TimerMarketHolidayBackground.txt',{headers:{'api-ref':this.xKey}}).then((response) => {
                    // get reference to background image class
                    let mhImage = document.querySelector('.marketHoliday');
                        mhImage.style.backgroundImage = `url( response.data )`;
                    // console.log( "FYI: Using S3 Bucket background image Url: "+response.data );       
                }).catch( err => { 
                    // show message - system using original background - since file not found in S3 bucket /documents - 404
                    console.log( err );
                });                
            },                                            
            // this method checks the clock time - every second
            run_the_clock() {
              // get current date from NY time zone
              this.nydate  = new Date( new Date().toLocaleString('en',{ timeZone: this.timeZone }) );
              this.hours   = this.nydate.getHours();    // 24 hour format
              this.minutes = this.nydate.getMinutes();
              this.seconds = this.nydate.getSeconds();

              //console.log("Hour: "+hr+ " Minute: "+ min + " Second: "+ sec);
              let hrPosition  = this.hours *360/12 + ( ( this.minutes * 360/60 ) /12 );
              let minPosition = ( this.minutes * 360/60 ) + ( this.seconds * 360/60 ) /60;
              let secPosition = this.seconds * 360/60;

              //Then we need to apply these numbers as degrees in the inline styles for transform on each of the objects.
              HOURHAND.style.transform   = "rotate(" + hrPosition  + "deg)";
              MINUTEHAND.style.transform = "rotate(" + minPosition + "deg)";
              SECONDHAND.style.transform = "rotate(" + secPosition + "deg)";

              // *** alarm compare current time with alarm setting ***
              var hrs = this.hours;     // *** calculate as 24 hour clock by multiplying 100 to hours
              var min = this.minutes;   // same minutes - which was just updated                                 

              // change color of clock and background
              if( this.isWeekendOrPublicHoliday() ) {
                  this.marketTrading = false;
                  this.setNonTradingDayClockColor();
                  this.changeBackground( 'marketHoliday' );
                  //console.log("weekend & holiday check");
              } else {
                    //---- regular trading day checks ----*
                    if( (( ((hrs*100)+min) > 930 && ((hrs*100)+min) <= 1599 ) ) ) {
                       // check for weekend or holiday - special circumstances in holiday array
                       if( this.isPublicHoliday() === true ) {
                            this.marketHoliday = true;
                            // special case holiday check - look for partial trading day  .5
                            let tradeDay = this.holidays[(this.nydate.getMonth()+1)+','+this.nydate.getDate()].value;
                            // if partial trading day - usually closes @ 1:00 pm - mark with .5 in holiday array
                            if( (tradeDay === .5 && ((hrs*100)+min <= 1300) ) ) {
                               // Trading this holiday - half day trades 
                               this.marketTrading = true;
                               this.setTradingDayClockColor();
                               this.changeBackground( 'marketOpen' );
                            } else {
                               // *** Otherwise always return 'FALSE' since it's a tracked Holiday ***
                               this.marketTrading = false;                               
                               this.setNonTradingDayClockColor();
                               this.changeBackground( 'marketClosed' );
                            }
                            //console.log("public hoiday check block");
                        } else {
                            // within valid trading times - and not a weekend or holiday
                            this.marketTrading = true;                            
                            this.setTradingDayClockColor();
                            this.changeBackground( 'marketOpen' );
                            //console.log("NOT ----> public hoiday check block");                            
                        }
                    } else {
                        //  this.setTradingDayClockColor();
                        //  this.changeBackground( 'marketOpen' ); 
                        //  this.marketTrading = true;  
                        this.marketTrading = false;                                                   
                        this.setNonTradingDayClockColor();
                        this.changeBackground( 'marketClosed' );
                    }
                }
                // *** check alarm settings - label and sound - for trading days only --*
                this.alarmCheck( hrs, min );                    
            },
            setNonTradingDayClockColor() {
                this.clockCircleColor    = '#616161';
                this.clockHourArmColor   = '#FF6D00';
                this.clockHourMarksColor = '#78909C';
                this.clockMinuteArmColor = '#FF9100';
                this.clockSecondArmColor = '#CFD8DC';
            },
            setTradingDayClockColor() {
                this.clockCircleColor    = '#004D40';
                this.clockHourArmColor   = '#2E7D32';
                this.clockHourMarksColor = '#2979FF';
                this.clockMinuteArmColor = '#76FF03';
                this.clockSecondArmColor = '#B71C1C';
            },
            isWeekend() {
                let dayOfWeek = this.nydate.getDay();
                //console.log( "Day of week: "+dayOfWeek );
                return( dayOfWeek == 0 || dayOfWeek == 6 )
            },
            isPublicHoliday() {
                // need to add one day since first day is technically -> zero
                var month = ( this.nydate.getMonth()+1 ); 
                // look for match in holiday array list - by key
                return( !! this.holidays[(month) + ',' + this.nydate.getDate() ] );
             },
            isWeekendOrPublicHoliday() {
                return( this.isWeekend() || this.isPublicHoliday() );
            },          
            // change background of clock dynamically - when Market Opens/Closes etc...
            changeBackground( marketState ) {
                let element = document.getElementById("clockHousing");
                // Remove existing classes
                element.classList.remove( 'marketClosed' );
                element.classList.remove( 'marketOpen' );
                element.classList.remove( 'marketHoliday' );        
                element.classList.add( marketState );
                //- console.log('class added: '+ marketState );             
            },  
            alarmCheck( hr, min ) {
                // check alarm time setting to sound alarm
                if( this.alarm && this.alarm != '' ) {
                    // define alarm label for button
                    // this.setAlarmLabel();
                    // ************ check time set every second ************
                    var hrs  = this.alarm.substring(0,2);
                    var mins = this.alarm.substring(3);

                    if( beat ) {
                        // *** check if hours and minute within defined alarm time ***
                        if( hr == hrs && min == mins ) {
                           // alarm actively ringing - check for pause - set if 'false'
                           if( this.pauseAlarm == false ) this.alarmRinging = true;
                           // *** check if timer is visible showing or not - when alarm is sounded ***
                           var appBarTimerShowing = sessionStorage.getItem( 'timerShowing' );
                           // check if minimized - show dialog if state if 'false'
                           if( ( appBarTimerShowing && appBarTimerShowing === 'false' ) && this.pauseAlarm === false ) {
                               // pass event to parent object to re-show timer dialog - so user can cancel it or change it
                               eventBus.$emit( "show-market-clock", "showMarketClock" );
                           } 
                           // play ringer --- since time matches - every second for 1 minute - unless paused
                           if( this.pauseAlarm == false ) { beat.play(); }
                        } else  {
                           // stop ringer
                           beat.pause();
                           // alarm not active - reset flags
                           this.alarmRinging = false;
                           this.pauseAlarm   = false
                        }
                    } else {
                        console.log("Problem: No Alarm MP3 Sound File available");
                    }
                    //console.log( "checking alarm Setting -> "+this.alarm+" ==> calc "+hrs+" Min: "+mins );                        
                }               
            },            
            clearAlarm() { 
                this.$swal({
                    position: 'center',
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Clear",
                    icon:  'warning',
                    title: "Clear Alarm Setting ?",
                    text:  "Current Alarm Time Will Be Removed",
                    footer:"Use ( Reset ) to Change Time",
                }).then((result) => {
                    if( result.isConfirmed ) {
                        this.alarm = ''; 
                        this.alarmLabel   = null;
                        this.alarmRinging = false;
                        this.pauseAlarm   = false;
                        // stop - pause beat mp3
                        if( beat ) { beat.pause(); }
                        // remove from local storage 
                        localStorage.removeItem("alarm"); 
                    }
                })
            },
            alarmTimeSetup() {
                // get last alarm setting from local storage - if any
                var alarm  = localStorage.getItem("alarm");
                if( alarm ) {
                  this.alarm = alarm;
                }
                // show dialog to change alarm settings
                this.timeDialog=true;
            },
            stopRinger() {
                this.$swal({
                    position: 'center',
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Stop",
                    icon:  'question',
                    title: "Stop Ringing Alarm ?",
                    text:  "Please Confirm to Stop/Pause Alarm",
                    footer: "Or Use ( Reset ) to Change Time",
                }).then((result) => {
                    if( result.isConfirmed ) {
                        // set flag to skip ringer during alarm
                        this.pauseAlarm   = true;
                        this.alarmRinging = false;
                        // stop - pause beat mp3
                        if( beat ) { beat.pause(); }
                    }
                })                
            },
            chooseAlarmTime() {
                // close time picker - update local storage 
                localStorage.setItem( "alarm", this.alarm );
                //console.log( "Alarm Time: "+ this.alarm );
                // define user lable for button
                this.setAlarmLabel();
                // close time dialog
                this.timeDialog=false;
            },
            cancelAlarmSetting() {
                // close time chooser dialog
                this.timeDialog=false;
            },
            // user select clock mode from array
            selectClockMode( id ) {
                this.format = this.modes[id].format;
            },
            setAlarmLabel() {
                var hrs  = this.alarm.substring(0,2);
                var mins = this.alarm.substring(3);
                var timeLabel; 
                // define alarm label for user
                if(  hrs > 12 ) {
                     timeLabel = (hrs-12)+":"+mins+" PM";
                } else {
                     timeLabel = hrs+":"+mins+" AM";
                }
                // update setting display 
                this.alarmLabel = timeLabel;                
            }
        }
    }
</script>

<style scoped>
    .marketOpen {
        /* 
           background-image: url('@/assets/Open_Sign.jpg');
           https://cdn.pixabay.com/photo/2019/12/01/05/01/entrepreneur-4664726_640.jpg
           https://media.tenor.com/qNwXVOp3YAYAAAAM/option-sweeps-tradeui.gif
           https://media.tenor.com/MeXxoUFWX2cAAAAM/3d-stock-market.gif
           https://cdn.pixabay.com/photo/2016/12/11/05/09/new-york-1898805_640.jpg    
           background: url('https://ohlaladani.com.br/wp-content/uploads/wallpaper-OHLALADANI_DESKTOP_WALLPAPERS_AVENTURA-2.jpg') no-repeat center center fixed !important;
        */
        background-size: 100% 100%;
        background-color: #059b9b;
        background-image: url('https://media.tenor.com/T-zDRVK4XdQAAAAM/trading.gif'); 
    }
    .marketClosed {
        /* background-image: url('@/assets/closed-sign_640.png');  */
        background-size: 100% 100%;
        background-color: #031b27;
        background-image: url('https://images.pexels.com/photos/1239162/pexels-photo-1239162.jpeg?auto=compress&cs=tinysrgb&w=600'); 
    }
    .marketHoliday {
        /* background-image: url('@/assets/closed-sign_640.png');  */
        background-size: 100% 100%;
        background-color: #031b27;
        background-image: url('https://cdn.pixabay.com/photo/2016/12/11/05/09/new-york-1898805_640.jpg'); 
    }
    img { opacity: 0.5; }
    img:hover { opacity: 1.0; }
    .clockbox, #clock { width: 100%; height: 100%; }
    /* Clock styles */
    .circle {
        fill: none;
        stroke: #000;
        stroke-width: 9;
        stroke-miterlimit: 10;
    }
    .mid-circle {
        fill: #000;
    }
    .hour-marks {
        fill: none;
        stroke: #000;
        stroke-width: 9;
        stroke-miterlimit: 10;
    }
    .hour-arm {
        fill: none;
        stroke: #000;
        stroke-width: 17;
        stroke-miterlimit: 10;
    }
    .minute-arm {
        fill: none;
        stroke: #000;
        stroke-width: 11;
        stroke-miterlimit: 10;
    }
    .second-arm {
        fill: none;
        stroke: #000;
        stroke-width: 4;
        stroke-miterlimit: 10;
    }

    /* Transparent box ensuring arms center properly. */
    .sizing-box {
        fill: none;
    }

    /* Make all arms rotate around the same center point. */
    /* Optional: Use transition for animation. */
    #hour,
    #minute,
    #second {
        transform-origin: 300px 300px;
        /*transition: transform .5s ease-in-out;*/
    }
</style>

<!-------------------- SWEET ALERT TIME DIALOG - NOT USED iPhone ISSUE ----------------------------------------------------------
    REPLACED WITH Vuetify  v-timepicker component - 04.17.24 - PM
            setSweetAlertAlarm() {
                this.$swal({
                    title: "Trading Alarm",
                    type:  "warning",
                    text:  "Set Time: ",
                    confirmButtonText: 'Set Alarm',
                    showCancelButton: true,                    
                    html: `<input type="time" id="time" name="time" placeholder="00:00" class="swal2-input" min="08:00" max="14:00" required />`,
                    /*******  important section for validation purposes using sweetalert 2 form                   
                    preConfirm: () => {
                        //let time = this.$swal.getPopup().querySelector('#time').value;
                        //console.log( time );
                        //if( time === '' ) {
                        //    this.$swal.showValidationMessage("Invalid Time Input");
                        //    return;
                        //}
                        return new Promise( function ( resolve, reject ) {
                            //DO whatever you need to do with your variables, ajax etc
                            //this.value =  time;
                            resolve({
                                time = $('input[placeholder="time"]').val(),
                            }); //when success

                        });
                    }****************************************/
                }).then((result) => {
                    if( result.isConfirmed ) {
                        // check user selected time is not after current time
                        let time = $('#time').val();
                        if( time ) {
                            // check if not before current time
                            let userHrs  = parseInt( time.substring(0,2) ); // 0-12 : 13-24
                            let userMins = parseInt( time.substring(3) );                        
                                //console.log( "Clock Hrs: "+ hrs + " mins: "+mins );                        
                            // define alarm label for user
                            if( userHrs < this.hours || userHrs+userMins < this.hours + this.getMinutes ) {
                                this.$swal({
                                    title: 'Invalid Time Selection',
                                    text:  'Please Select a Valid Alarm Time',
                                    footer: 'Alarm setting must be after current NY EST time.',
                                    icon:  'error',
                                    showCloseButton: false,
                                    showLoaderOnConfirm: false
                                }).then(() => { this.alarm = '', this.alarmLabel = '' });
                            } else {
                                // set timer - if time valid after current time
                                this.alarm = time; this.alarmLabel = time;
                            }
                        } else {
                            // clear settings
                            this.clearAlarm();
                        }
                    }
                }); 
            }
------------------------------------------------------------------------------------------------------------------->
