<!--
   Financial Markets
    removed 2 separate widget instances - hidden class statements to support mobile and desktop
    ie: class="mx-auto mb-15 hidden-sm-and-down" for desktop view widget block
    ie: class="mx-auto mb-15 hidden-md-and-up" for mobile view block
    *** duplicate code ***      
-->
<template>
  <v-container fluid class="fill-height" > 
    <v-layout justify-center align-center>  
      <!-- Widget Card Block -->  
      <v-card class="mx-auto my-auto flexcard" :width="this.cardWidth" height="100%" hover >     
          <v-img class="white--text align-center" width="auto" height="75px" src="@/assets/data_100.jpg" cover >
              <v-card-title class="justify-center">
                <v-row dense>
                    <v-col cols="1"></v-col>
                        <v-col class="pt-2" cols="9">
                          <span class="text-h5 font-weight-bold">Financial Markets</span>
                    </v-col>
                    <v-col cols="2">
                      <!-- track bookmarks -->
                        <Bookmark link="datawatch"/>                          
                    </v-col>                    
                  </v-row>                       
              </v-card-title>
          </v-img>
          <v-card-text class="text--primary justify-center">
              <!-- TradingView Widget BEGIN -->
              <div class="tradingview-widget-container">
                <div class="tradingview-widget-container__widget"></div>
                <div class="tradingview-widget-copyright"><span class="blue-text">Financial Markets</span> by TradingView</div>
                <script type="application/javascript" defer src="https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js" async>
                      {
                      "width": "{{this.widgetWidth}}",
                      "height": "{{this.widgetHeight}}",
                      "symbolsGroups": [
                        {
                          "name": "Indices",
                          "originalName": "Indices",
                          "symbols": [
                            {
                              "name": "FOREXCOM:SPXUSD",
                              "displayName": "S&P 500"
                            },
                            {
                              "name": "FOREXCOM:NSXUSD",
                              "displayName": "US 100"
                            },
                            {
                              "name": "FOREXCOM:DJI",
                              "displayName": "Dow 30"
                            },
                            {
                              "name": "INDEX:NKY",
                              "displayName": "Nikkei 225"
                            },
                            {
                              "name": "INDEX:DEU40",
                              "displayName": "DAX Index"
                            },
                            {
                              "name": "FOREXCOM:UKXGBP",
                              "displayName": "UK 100"
                            }
                          ]
                        },
                        {
                          "name": "Futures",
                          "originalName": "Futures",
                          "symbols": [
                            {
                              "name": "CME_MINI:ES1!",
                              "displayName": "S&P 500"
                            },
                            {
                              "name": "CME:6E1!",
                              "displayName": "Euro"
                            },
                            {
                              "name": "COMEX:GC1!",
                              "displayName": "Gold"
                            },
                            {
                              "name": "NYMEX:CL1!",
                              "displayName": "Crude Oil"
                            },
                            {
                              "name": "NYMEX:NG1!",
                              "displayName": "Natural Gas"
                            },
                            {
                              "name": "CBOT:ZC1!",
                              "displayName": "Corn"
                            }
                          ]
                        },
                        {
                          "name": "Bonds",
                          "originalName": "Bonds",
                          "symbols": [
                            {
                              "name": "CME:GE1!",
                              "displayName": "Eurodollar"
                            },
                            {
                              "name": "CBOT:ZB1!",
                              "displayName": "T-Bond"
                            },
                            {
                              "name": "CBOT:UB1!",
                              "displayName": "Ultra T-Bond"
                            },
                            {
                              "name": "EUREX:FGBL1!",
                              "displayName": "Euro Bund"
                            },
                            {
                              "name": "EUREX:FBTP1!",
                              "displayName": "Euro BTP"
                            },
                            {
                              "name": "EUREX:FGBM1!",
                              "displayName": "Euro BOBL"
                            }
                          ]
                        },
                        {
                          "name": "Forex",
                          "originalName": "Forex",
                          "symbols": [
                            {
                              "name": "FX:EURUSD",
                              "displayName": "EUR/USD"
                            },
                            {
                              "name": "FX:GBPUSD",
                              "displayName": "GBP/USD"
                            },
                            {
                              "name": "FX:USDJPY",
                              "displayName": "USD/JPY"
                            },
                            {
                              "name": "FX:USDCAD",
                              "displayName": "USD/CAD"
                            },                            
                            {
                              "name": "FX:USDMXN",
                              "displayName": "USD/MXN"
                            },                                 
                            {
                              "name": "FX:USDCHF",
                              "displayName": "USD/CHF"
                            },
                            {
                              "name": "FX:AUDUSD",
                              "displayName": "AUD/USD"
                            }
                          ]
                        }
                      ],
                      "showSymbolLogo": true,
                      "colorTheme": "light",
                      "isTransparent": false,
                      "locale": "{{this.locale}}"
                    }
                </script>
              </div>
              <!-- TradingView Widget END -->
          </v-card-text>
        </v-card>
        <!-- END DESKTOP CARD -->
    </v-layout>
  </v-container>
</template>
  
<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n" 

    // listen for and allow broadcasts to components
    import { eventBus } from '@/main'  // event bus communication for registered components

    // user bookmarking component
    import Bookmark      from '@/components/AddToFavorites.vue'       
    
    export default {
      components: { Bookmark },      
      data: () => ({
        valid: false,
        locale: i18n.locale, 
        // determines on object creation if running on mobile device true or false
        isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))), 
        // *** following settings are designed for desktop - flipped if 'mobile' before mount ***              
        cardWidth: "95%",
        widgetWidth: "100%",
        widgetHeight: 1000,  
        showAdsID   : 0,
        hideAdsID   : 0,            
        time_delay: Number( process.env.VUE_APP_ADS_DELAY ),  // retrieve setting from .env file                                              
      }),
      methods: {
        hideAds() {
           // stop any timeout events by assigned ID - before creating any new ones
           clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );
           // hide any previous side Ad - if any
           eventBus.$emit("hide-side-ads","hideSideAds");          
        },
        showAds() {
            this.hideAds();
            // set variable delay
            var delay = this.time_delay;
            // show ads - if enabled in .env file - defined in BottomSheetAds component - record id for clearing
            this.showAdsID = setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), delay );   // record timeout ID -  for clearing
            delay = delay * 3;
            // send event to parent (AppBar.vue) to close Ads right drawer x3 delay
            this.hideAdsID = setTimeout( ()=> eventBus.$emit("hide-side-ads","hideSideAds"), delay );   // record timeout ID - for clearing      
         }
      },           
      beforeMount() {
          // show loading spinner - just before component is mounted to app
          this.$loading(true) 

          //*** re-set card/widget measurements if running on mobile device before mount ***
          if( this.isMobile ) { this.cardWidth="100%"; }
          //*****************************************************/ 
              
          // hide any previous side Ad - if any
          this.hideAds();                         
      },
      mounted() {
        // 1 sec delay - then remove loading indicater       
        setTimeout( ()=> this.$loading(false), 1000 );  

        // check for setting - time out is set in .env file for ads 
        if( this.time_delay <= 0 ) { this.time_delay = 2500; }

        // ads here after checking online status
        // show ads - if enabled in .env file - defined in BottomSheetAds component
        this.showAds(); 

      },
      beforeUnmount() {
          // send event to parent (AppBar.vue) to close Ads right drawer
          this.hideAds();
      },
      metaInfo() {
        return {
          title: "Stock-Insight's TradingView Widget - Financial Markets",
          meta: [
            { name: 'robots', content: 'index,follow'},
            { name: 'description', content: 'TradingView Financial Markets Chart' },
            { name: 'keywords', content: 'TradingView, NYSE, market, stock, chart, financial, news, data' },              
            { property: 'og:type', content: 'website'}, 
            { property: 'og:site_name', content: 'www.Stock-Insight.net'},
            { property: 'og:title', content: "Stock-Insight's TradingView Widget - Financial Markets"},                             
            {
              vmid: "description",
              name: "description",
              content: "TradingView Widget - Financial Markets"
            }
          ],
          link: [
            {
              rel: 'canonical',
              href: '<https://www.stock-insight.net/>'
            }
          ]
        }
      }                   
    }
</script>
