<!-- 
    desktop tools menu replacement
     @click.stop.prevent="highliteBookmarks"
-->
<template>
    <v-card class="mx-auto" max-width="300" tile>
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">   
               <v-btn @click="checkBookmarks" color="primary" dark v-bind="attrs" v-on="on">
                  <v-icon left >mdi-tools</v-icon>Investment Tools
               </v-btn>
            </template>
              <v-list class="text-left" dense color="grey">
                <v-list-item-group v-model="selectedItem" >
                <!-- very important - added element 'id' to apply CSS class for bookmarks -->
                <v-list-item v-for="(item) in items" :key="item.id" :to="item.route" :id=item.id >
                    <v-list-item-icon class="pl-0 pr-1 ml-0 mr-1">
                       <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="pl-0 pr-0 ml-0 mr-0">
                       <v-list-item-title v-text="item.title" ></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                </v-list-item-group>
              </v-list>
        </v-menu>              
    </v-card>
</template>

<script>
    //import i18n from "@/i18n"
    import toolsMenuItems  from '@/components/menus/Data_Arrays/tools_menu'
    import $ from 'jquery';
    // filter by group defined in data arrays tools menu
    const tGroup = "Investing Tools";

    export default {
      head: {
          // important here to import a data array object to setup the menu
          script: [ { src: '@/components/menus/Data_Arrays/tools_menu.js', async: true, defer: true } ],
      },
      data: () => ({
          selectedItem: 0,
          items: toolsMenuItems,
      }),
      mounted() {
          // check for saved bookmarks before init to setup splash pages - default and tiles
          this.checkBookmarks()
      },  
      methods: {
          checkBookmarks() {
            //------------------------------------------------------------------------*       
            var userBookmarks = JSON.parse( localStorage.getItem("bookmarks") || "[]" );
            if( userBookmarks && userBookmarks.length > 0 ) {
                setTimeout(function() {
                    userBookmarks.forEach((mark) => {
                        // filter only check - Investing Tools group here
                        if( mark.group == tGroup ) {
                        // filter through the keys to re-mark bookmark
                        Object.entries(mark).forEach(([ key, value ]) => {
                            if( key === 'id' ) { //v-list-item v-list-item--link //element.classList.toggle(className)
                                $("#"+value ).addClass("bookmarked");
                            }
                        });  
                        }
                    })
                }, 145 );
            }
          }
      }
    }
</script>

<style scoped>
  .bookmarked {
     border-right: 5px solid rgb(76, 226, 76);
     z-index: 99999;
  }
  .unmarked {
     border-left: 5px solid grey;
     z-index: 99999;
  }  
  .v-button {
     opacity: 0.5;
  }
  .v-menu__content {
     background: lightgray;
  }
  .v-list-item {
     background: lightgray;
     color: darkblue;
  }
  .v-list-item--active .v-icon {
     background-color: lightgreen;
  }
  .v-list-item--active .v-list-item__title {
     color: black;
     font-weight: bold;
  }  
  .v-card--link:focus {
      background: lightgray;
   }

</style>