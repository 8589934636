<!--
       TERMS DIALOG WINDOW - popup if 'dialog' flag is set to true
-->
<template>
  <div class="text-center mx-auto">
    <v-dialog persistent v-model="dialog" :width="(this.isMobile? 350: 550)">
      <v-card height="550px" >
        <v-card-title class="ma-0 pa-0" >  
          <v-container fluid >           
              <v-toolbar color="#26c6da" dark flat width="auto" height="45px">
                  <!-- toggle button list1 only appear on desktop  -->
                  <v-row dense no-gutters class="ma-0 pa-0"> 
                    <!-- desktop button bar options list 1 -->
                    <!-- loop through both feed lists to generate menu for top menu -->
                    <v-col cols="12"  >
                        <v-btn-toggle class="ma-0 pa-0 mt-1" v-model="toggle_list1" borderless dark rounded background-color="blue">
                          <v-btn color="ml-0 pl-2" active-class="aa" id="tBtn" elevation="3" small v-for="item in items" :key="item.id" @click.prevent="getContent(item.id)" rounded >
                              <v-icon left>{{item.icon}}</v-icon>{{ item.title }}
                          </v-btn>
                        </v-btn-toggle>
                    </v-col>
                  </v-row>
              </v-toolbar>
            </v-container>
          </v-card-title>
          <v-card-text class="ma-0 pa-0">
            <v-container fill-height >
              <v-flex fill-height >
                <v-row>  
                  <v-col cols="12">
                    <!-- *** terms display section - all service cookies ... data will be displayed in the termsViewer *** -->  
                    <!-- height adjusted to fit within the host parent card; desktop or mobile -->                
                    <!--<div ref="termsViewer" v-html="this.items[0].content"></div>-->
                    <div ref="termsViewer" v-html="this.terms"></div>
                 </v-col>
                </v-row>                
              </v-flex>            
            </v-container>
          </v-card-text>
        <v-divider class="ma-0 pa-0"></v-divider>
        <v-card-actions class="mt-2 pt-0">
          <v-btn outlined color="primary" text @click.prevent="closeDialog">
            <span style="font-size: 14px; color:green">{{$t("terms_btn_label_agree")}}</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" text @click.prevent="disagree">
            <span style="font-size: 14px; color: darkred">{{$t("terms_btn_label_disagree")}}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  // listen for global event calls called from FooterBar - or where ever
  import { eventBus } from '@/main'  // event bus communication for registered components
  import i18n from "@/i18n"          // i18n international language support for labels

  export default {
    name: "TermsOfUse",
    data: () => ({
        dialog: false,
        scrollInvoked: 0,
        toggle_list1: 0,    
        dialog_title: i18n.t('services_n_cookie_title'),
        value: 1,
        key: 111,
        terms: '',
        isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))), 
        items: [ // loads default content from locales  en.json file
          { id:0, title: i18n.t('services_n_tools_title'), icon:'fa-solid fa-handshake-simple', content: i18n.t('services_n_tools') },
          { id:1, title: i18n.t('cookie_usage_title'), icon:'mdi-cookie', content: i18n.t('cookie_usage') },
        ],        
    }),
    // life cycle methods
    // when this component is added to page - it listens for a specific event
    mounted: function() {
       // setup popup dialog listener when component is added to footer template
       eventBus.$on("show-terms", this.showTerms);
    },
    // user defined methods go here
    methods: {
      disagree() {
          // show confirmation
          this.$swal({
                position: 'center',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showCancelButton: true,  
                cancelButtonText: i18n.t('contact_dialog.send_cancel_button'),              
                showConfirmButton: true,
                confirmButtonText: i18n.t('terms_of_use.confirmButtonText'),
                icon: 'question',
                title: i18n.t('terms_of_use.title'),
                text: i18n.t('terms_of_use.text'),
                footer: i18n.t('terms_of_use.footer'),
            }).then((result) => {
                if( result.isConfirmed ) {
                  // user confirmed non acceptance of site terms ...
                  // remove any previous 'agree' confirmation
                  window.localStorage.removeItem("acceptTerms"); 
                  // get the referring website ... if any
                  //var referrer = Document.referrer;
                  // if referred from another site - return to that site 
                  // or redirect to Google ...
                  //if( referrer === null || referrer === 'undefined'  ) {
                     window.location.replace( "https://www.google.com" ); 
                  //} else {
                  //   window.location.replace( referrer );
                  //}
                }                
            });          
      },
      // load content depending on which toggle but is clicked. 
      // defaults to the first item id=0
      getContent( id ) {
         //this.$refs["termsViewer"].innerHTML= this.items[id].content;
         if( id == 0) {
            this.showTerms();
         } else {
            this.showCookieNotice();
         }
         // highlight / re-highlight toggle button pressed
         setTimeout( ()=>{ this.toggle_list1=id; }, 150 );         
      },
      showTerms() {
        // basically just sets show = true to show dialog  
          this.$loading(true);
            this.terms = '';  // remove content to reset scroll
            // request document from back-end to retrieve selected docs
            this.axios.get('/rs/utils/document?file='+'TermsNConditions.html',{headers:{'api-ref':this.xKey}}).then((response) => {
                  // clear any existing data - reset scroll window position
                  //console.log( response.data  ); // <- later comment out
                  var terms = response.data;
                  // **** just display default vaule from locales/en.json file ****
              if( terms === null || terms.length <= 10 ) {
                  throw new Error('TermsNConditions Document Not Found - using default i18n !');
              } else {
                  // store feed info to data element 'text' for display - which
                  // is created under a tab section ... reference back to that tab first
                  //   this.$refs[rptName];
                  // assign report to the RssFeedViewer - here
                  this.terms = terms;
                  // stop loading spinner
                  this.$loading(false);
              }
            }).catch( err => { 
                // show user default Services in en.json file - but this is old
                this.terms = i18n.t('services_n_tools'); 
                // stop loading spinner
                this.$loading(false);                               
                console.log( err );
            });
           // show popup dialog with Services and Cookie documents
           this.dialog=true;
           //console.log( i18n.t("footer.terms")  );
      },
      showCookieNotice() {
          // basically just sets show = true to show dialog 
          this.$loading(true); 
            this.terms = '';  // remove content to reset scroll          
            // request report from back-end to retrieve selected Rss Feed
            this.axios.get('/rs/utils/document?file='+'CookieUsage.html',{headers:{'api-ref':this.xKey}}).then((response) => {
                  // clear any existing data - reset scroll window position
                  //console.log( response.data  ); // <- later comment out
                  var terms = response.data;
                  // **** just display default vaule from locales/en.json file ****
              if( terms === null || terms.length <= 10 ) {
                  throw new Error('Cookie Usage Document Not Found - using default i18n !');
              } else {
                  // store feed info to data element 'text' for display - which
                  // is created under a tab section ... reference back to that tab first
                  //   this.$refs[rptName];
                  // assign report to the RssFeedViewer - here
                  this.terms = terms;
                  // set scroll back to top
                  // stop loading spinner
                  this.$loading(false);                  
              }
            }).catch( err => { 
                // show user Cookie Notice from en.json file - but this is old
                this.terms = i18n.t('cookie_usage'); 
                // stop loading spinner
                this.$loading(false);                               
                console.log( err );
            });
          // show popup dialog with Services and Cookie documents
          this.dialog=true;
          //console.log( i18n.t("footer.terms")  );
      },
      closeDialog() {
        // remove init from localStorage to re-start ads
        localStorage.removeItem('init');  
        // user clicked <I Agree> button stamp local storage
          window.localStorage.setItem("acceptTerms","true");
        // Removes Event Bus listener upon removal of component
        // eventBus.$off("show-terms");
        // close dialog window
        this.dialog = false
      },
      onScroll () {
          // to track scrolling of card window
          this.scrollInvoked++
      },      
    }
  }
</script>

<style scoped>
  .aa {
    background: rgb(25, 0, 255) !important;
  } 
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow-y: scroll;
}  

#tBtn {
  text-align: center;
  font-size: 8px;
}
</style>