<!-- 
   digital clock - used on desktop and on mobile draw menu
   notice the style background change depending on marketTrading flag
   which is checked every second against NY time zone and date
 -->
<template>
    <!-- chip clickable to switch displayed formats -->
    <v-chip @click.prevent="toggleTimeFormat" class="ma-0 pa-0" :style="{'background-color': marketTrading ? 'green':'darkred'}"      
                                     text-color="white" label >
        <div v-if="marketTrading" >
            <v-icon left color="green lighten-5" class="ma-0 pa-0">mdi-google-circles-extended</v-icon>
        </div>
        <div v-else >
            <v-icon left class="ma-0 pa-0">mdi-close-octagon-outline</v-icon>
        </div>
        <!-- time format for display -->
        <div v-if="timeFormat == 24">
          <div :class="[isMobile? mobileClass: '', desktopClass]">
              <div>&nbsp;{{ date }}&nbsp;</div>
              <div class="hours">[&nbsp;{{ padDigits(hours,2) }}</div>
              <div class="divider">:</div>
              <div class="minutes">{{ padDigits(minutes,2) }}</div>
              <div class="divider">:</div>
              <div class="seconds">{{ padDigits(seconds,2) }}&nbsp;]&nbsp;</div>
              <div>EST</div>
          </div>
        </div>
        <div v-else>
          <div :class="[isMobile? mobileClass: '', desktopClass]">
              <div>&nbsp;{{ date }}&nbsp;</div>
              <!--   here display hours as either am or pm standard   -->
              <div class="hours">[&nbsp;{{ padDigits( ((hours>12)? hours-12 : hours) ,2) }}</div>
              <div class="divider">:</div>
              <div class="minutes">{{ padDigits(minutes,2) }}</div>
              <div class="divider">:</div>
              <div class="seconds">{{ padDigits(seconds,2) }}&nbsp;{{ sAMPM }}&nbsp;]&nbsp;</div>
              <div v-if="!isMobile"><div>EST&nbsp;</div></div>        
          </div>
        </div>
    </v-chip>
</template>

<script>
  export default {
    name: "DigitalClock",
    data() {
      return {
        time: '',
        date: '',
        hours: 0,
        minutes: 0,
        seconds: 0,
        timeFormat: 12,
        sAMPM: "AM",
        xKey: 0,
        // determines on object creation if running on mobile device true or false - although hard coded init
        isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),
        mobileClass:  'mobile',
        desktopClass: 'desktop',
        marketTrading: false,
        // get first instant of time ... will calc on each second once mounted
        nydate: new Date(new Date().toLocaleString('en',{timeZone: 'America/New_York'})),
        week: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
        // 2026 default starting calendar - changes or updates in S3 bucket
        holidays: {
                "1,1"   : { holiday: "New Year's Day", value: 0 }, 
                "1,19"  : { holiday: "Martin Luther King Jr", value: 0 },
                "2,16"  : { holiday: "President's Birthday", value: 0 },
            //    "4,6"   : { holiday: "Pre-Good Friday", value: .5 },                
                "4,3"   : { holiday: "Good Friday", value: 0 },
                "5,25"  : { holiday: "Memorial Day", value: 0 },
                "6,19"  : { holiday: "Juneteenth NID", value: 0 },
                "7,3"   : { holiday: "Pre-Independence Day", value: .5 },                
                "7,4"   : { holiday: "Independence Day", value: 0 },
                "9,7"   : { holiday: "Labor Day", value: 0 },
                "11,26" : { holiday: "Thanksgiving Day", value: 0 },
                "11,27" : { holiday: "Post-Thanksgiving Day", value: .5 },                           
                "12,24" : { holiday: "Christmas Eve", value: .5 },                     
                "12,25" : { holiday: "Christmas Day", value: 0 }               
        } 
      }
    },
    beforeMount() {
       // check for new calendar or date changes posted to S3 bucket
       var today = new Date();
       //this.showCheckToast();   // calendar check message
       this.axios.get('/rs/utils/document?file='+'calendar_'+today.getFullYear()+'.json',{headers:{'api-ref':this.xKey}}).then((response) => {
              //console.log( response.data  ); // <- later comment out
              this.holidays = response.data;
              // **** just display default vaule from locales/en.json file ****
              //this.showUpdateToast();    
              console.log("NYSE Calendar Up-To-Date With Market Calendar.");          
        }).catch( err => { 
            // show message - system using old calendar 2023 - not found in S3 bucket
            //this.showErrorToast();            
            console.log( err );
        });
    },
    mounted() {
        // updates clock every second - to check trading time and dates
        setInterval( () => { this.updateClock() }, 1000 );
    },
    methods: {
      // used to switch time formats to change display from 24 to 12 and back
      toggleTimeFormat() {
        ( this.timeFormat === 12 ? this.timeFormat = 24 : this.timeFormat = 12 );
      },
      padDigits(number, digits) {
         return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
      },      
      updateClock() {
        // get current date from NY time zone
        this.nydate  = new Date( new Date().toLocaleString('en',{timeZone: 'America/New_York'}) );
        this.hours   = this.nydate.getHours();  // 24 hour format
        this.minutes = this.nydate.getMinutes();
        this.seconds = this.nydate.getSeconds();
        // clock display - *
        if( this.timeFormat === 24 ) {
          this.date  = 'NYSE: '+this.zeroPadding(this.nydate.getFullYear(),4)+'-'+this.zeroPadding(this.nydate.getMonth()+1,2)+'-'
                                  +this.zeroPadding(this.nydate.getDate(),2)+' '+this.week[this.nydate.getDay()]+" ";
        } else {
          // check if AM or PM by getting current hour in day
          var iHourCheck = parseInt( this.hours );
          ( (iHourCheck >= 12)? this.sAMPM = "PM" : this.sAMPM = "AM" );
          // display format 12 format
          this.date  = 'NYSE: '+this.week[this.nydate.getDay()]+" "+this.zeroPadding(this.nydate.getMonth()+1,2)+'-'
                                  +this.zeroPadding(this.nydate.getDate(),2)+"-"+this.zeroPadding(this.nydate.getFullYear(),4) 
                                    +" ";
        }
        // main check of current times and is NY time a holiday or weekend to change color of clock
        this.marketTrading = this.isMarketTrading();
      },
      zeroPadding(num, digit) {
        var zero = '';
        for(var i = 0; i < digit; i++) {
            zero += '0';
        }
        return (zero + num).slice(-digit);
      },
      isWeekend() {
         let dayOfWeek = this.nydate.getDay();
         //console.log( "Day of week: "+dayOfWeek );
         return( dayOfWeek == 0 || dayOfWeek == 6 )
      },
      isPublicHoliday() {
        // need to add one day since first day is technically -> zero
        var month = (this.nydate.getMonth()+1); 
        // look for match in holiday array list - by key
        return( !!this.holidays[(month) + ',' + this.nydate.getDate()] );
      },
      isWeekendOrPublicHoliday() {
         return( this.isPublicHoliday() || this.isWeekend() );
      },
      isMarketTrading() {
          var tradeDay; // calculate full or partial trade day if holiday
          if( this.isWeekend() === true ) {
            //console.log('Weekend - No trading ...');
            return false;
          } else {
            // check if between valid trading hours - first
            var hrs = this.hours*100;  // *** calculate as 24 hour clock by multiplying 100 to hours
            var min = this.minutes;    // same minutes - which was just updated
            if( (( hrs+min > 930 ) && ( hrs+min <= 1599 )) ) {
                // check for weekend or holiday - special circumstances in holiday array
                if( this.isPublicHoliday() === true ) {
                    // special case holiday check - look for partial trading day  .5
                    tradeDay = this.holidays[(this.nydate.getMonth()+1)+','+this.nydate.getDate()].value;
                    // if partial trading day - usually closes @ 1:00 pm - mark with .5 in holiday array
                    if( (tradeDay === .5 && (hrs+min <= 1300) ) ) {
                       // Trading this holiday - half day trades 
                       return true;
                    } else {
                       // *** Otherwise always return 'FALSE' since it's a tracked Holiday ***
                       return false;
                    }
                } else {
                    // within valid trading times - and not a weekend or holiday
                    return true;
                }
            } else {
                // outside normal trading hours of NYSE - return false
                return false;
            }
          }
      },
      showCheckToast() {
        // only show on desktop 
        if( ! this.isMobile  ) {        
            this.$swal.fire({
              icon: 'question',
              title : 'NYSE Calendar Check',
              text  : 'Checking for Updates...',
              toast : true,
              position : 'top-right',
              showCancelButton : false,
              showConfirmButton : false,
              timer: 1500,
              timerProgressBar: true,
            });
        }
      },
      showUpdateToast() {
        // only show on desktop 
        if( ! this.isMobile  ) {
          this.$swal.fire({
            icon: 'success',
            title : 'NYSE Calendar Updated',
            text  : 'Calendar Updated!',
            toast : true,
            position : 'top-right',
            showCancelButton : false,
            showConfirmButton : false,
            timer: 1500,
            timerProgressBar: true,
          });
        }
      },      
      showErrorToast() {
        // only show on desktop 
        if( ! this.isMobile  ) {
          this.$swal.fire({
            icon: 'info',
            title : 'NYSE Calendar Notice',
            text  : 'No updates found using current Calendar',
            toast : true,
            position : 'top-right',
            showCancelButton : false,
            showConfirmButton : false,
            timer: 1500,
            timerProgressBar: true,
          });
        }
      }      
    }
  }
</script>

<style scoped>
    .desktop {
      display: flex;
      font-family: 'Share Tech Mono';     
      font-size: 14px;
      color:white;
      background-color: transparent;      
    }
    .mobile {
      display: flex;
      font-family: 'Share Tech Mono';     
      font-size: 12px;
      color: white;
      background-color: transparent;      
    } 
</style>
