import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mx-auto"},[_c(VDialog,{attrs:{"transition":"dialog-top-transition","persistent":"","width":(this.isMobile? 350: 550)},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"height":"550px"}},[_c(VCardTitle,{staticClass:"ma-0 pa-0"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VToolbar,{staticClass:"ma-0 pa-0",attrs:{"color":"lightblue","dark":"","flat":"","height":"45px"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.dialog_title))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeMarketClock}},[_c(VIcon,[_vm._v("fa-solid fa-eye-slash")])],1)],1)],1)],1),_c(VCardText,{staticClass:"ma-0 pa-0"},[_c(VContainer,{attrs:{"fill-height":""}},[_c(VFlex,{attrs:{"fill-height":""}},[_c('marketClock')],1),(this.isMobile || this.screenWidth <= 1180)?_c(VRow,{staticClass:"mt-1"},[_c(VCol,{attrs:{"cols":"12"}},[_c('DigitalClock')],1)],1):_vm._e()],1)],1),_c(VCardActions,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"3"}},[_c(VBtn,{staticClass:"ml-0",attrs:{"small":"","rounded":"","elevation":"5"},on:{"click":_vm.closeMarketClock}},[_c(VIcon,{staticClass:"m1-2"},[_vm._v("fa-solid fa-eye-slash")]),_vm._v("Close")],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }