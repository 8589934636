<!----------------------------------------------------------------------------------------------------  
     Live Ticker Tape from Tradingview widgets
    [ https://www.tradingview.com/widget/ticker-tape/ ]
    [ https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js  ]
    src="js/embed-widget-ticker-tape.js"

    <%= BASE_URL%>
----------------------------------------------------------------------------------------------------->
<template>
  <div>
    <div v-if="isMobile !== true" >
        <v-container class="mt-5 mb-2 ml-0 mr-0 pa-0" fluid >
            <!-- Widget Card Block -->  
            <v-card class="mx-auto my-auto flexcard" width="100%" height="25px">
                <!-- TradingView Widget BEGIN -->
                <div class="tradingview-widget-container">
                    <div class="tradingview-widget-container__widget"></div>
                    <div class="tradingview-widget-copyright"></div>
                        <script ref="tvScript" type="application/javascript" src="js/embed-widget-ticker-tape.js" async>
                            {
                                "symbols": {{ this.symbols }},
                                "showSymbolLogo": true,
                                "colorTheme": "dark",
                                "isTransparent": false,
                                "displayMode": "regular",
                                "largeChartUrl": "https://www.stock-insight.net/symbolchart",                                
                                "locale": {{ this.locale }}
                            }
                        </script>
                    </div>
                    <!-- TradingView Widget END -->
            </v-card>
            <!-- END Widget Block-->
        </v-container>
    </div>
    <div v-else >
        <!-- MOBILE TICKER FEED -->
        <v-container class="mt-1 mb-11 ml-0 mr-0 pa-0" fluid >
            <!-- Widget Card Block -->  
            <v-card class="mx-auto my-auto flexcard" width="100%" height="25px">
                <!-- TradingView Widget BEGIN -->
                <div class="tradingview-widget-container">
                    <div class="tradingview-widget-container__widget"></div>
                    <div class="tradingview-widget-copyright"></div>
                        <script type="application/javascript" src="js/embed-widget-ticker-tape.js" async>
                            {
                                "symbols": {{ this.symbols }},
                                "showSymbolLogo": true,
                                "colorTheme": "dark",
                                "isTransparent": false,
                                "displayMode": "compact",
                                "largeChartUrl": "https://www.stock-insight.net/symbolchart",                                
                                "locale": {{ this.locale }}
                            }
                        </script>
                    </div>
                    <!-- TradingView Widget END -->
            </v-card>
            <!-- END Widget Block-->
        </v-container>
    </div>
</div>
</template>

<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n" 

    export default {
      data: () => ({
        valid: false,
        // *** ticker feed breaks easily. wrapped locale with double quotes for TV widget accepts only valid Json
        locale: '"'+i18n.locale+'"',
        // determines on object creation if running on mobile device true or false
        isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),
        // *** following settings are designed for desktop - flipped if 'mobile' before mount ***   650           
        cardWidth: "100%",
        widgetWidth: "100%",
        widgetHeight: 50, 
        // *** list limited by TV to only 23 entries ****
        symbols: [
                    { "proName": "FOREXCOM:SPXUSD", "title": "S&P 500" },
                    { "proName": "BITSTAMP:BTCUSD", "title": "Bitcoin" },
                    { "description": "Tesla", "proName": "TSLA" },
                    { "description": "Pfizer", "proName": "PFE" },
                    { "description": "Apple", "proName": "AAPL" },
                    { "description": "Disney", "proName": "DIS" },
                    { "description": "Nvida", "proName": "NVDA" },
                    { "description": "Occidental", "proName": "OXY" },
                    { "description": "PayPal", "proName": "PYPL" },
                    { "description": "Amazon","proName": "AMZN" },
                    { "description": "Bank America", "proName": "BAC" },
                    { "description": "Sunrun", "proName": "RUN" }, 
                    { "description": "Palantir", "proName": "PLTR" }, 
                    { "description": "Rivian", "proName": "RIVN" },                     
                    { "description": "CitiGroup", "proName": "C" },
                    { "description": "Advance Micro", "proName": "AMD" },  
        ]
        //time_delay: Number( process.env.VUE_APP_ADS_DELAY ),  // retrieve setting from .env file  
        /*
                    { "description": "Tesla", "proName": "NASDAQ:TSLA" },
                    { "description": "Pfizer", "proName": "NYSE:PFE" },
                    { "description": "Apple", "proName": "NASDAQ:AAPL" },
                    { "description": "Disney", "proName": "NYSE:DIS" },
                    { "description": "Nvida", "proName": "NASDAQ:NVDA" },
                    { "description": "Occidental", "proName": "NYSE:OXY" },
                    { "description": "PayPal", "proName": "NASDAQ:PYPL" },
                    { "description": "Amazon","proName": "NASDAQ:AMZN" },
                    { "description": "Bank America", "proName": "NYSE:BAC" },
                    { "description": "Sunrun", "proName": "RUN" }, 
                    { "description": "Palantir", "proName": "NYSE:PLTR" }, 
                    { "description": "Rivin", "proName": "RIVN" },                     
                    { "description": "CitiGroup", "proName": "NYSE:C" },
                    { "description": "Advance Micro", "proName": "NASDAQ:AMD" },  
                    { "description": "Ford", "proName": "NYSE:F" }, 
                    { "description": "United", "proName": "NASDAQ:UAL" }, 
                    { "description": "General Motors", "proName": "NYSE:GM" },                       
                    { "description": "Meta", "proName": "NASDAQ:META" }, 
                    { "description": "Exxon", "proName": "NYSE:XOM" }, 
                    { "description": "MicroSoft", "proName": "NASDAQ:MSFT" },                  
                    { "description": "AT&T", "proName": "NYSE:T" },                                                                       
                    { "description": "Alphabet", "proName": "NASDAQ:GOOG" }     
                     
                    { "description": "Ford", "proName": "F" }, 
                    { "description": "United", "proName": "UAL" }, 
                    { "description": "General Motors", "proName": "GM" },                       
                    { "description": "Meta", "proName": "META" }, 
                    { "description": "Exxon", "proName": "XOM" }, 
                    { "description": "MicroSoft", "proName": "MSFT" },                  
                    { "description": "AT&T", "proName": "T" },                                                                       
                    { "description": "Alphabet", "proName": "GOOG" },
                    { "description": "Coca-Cola", "proName": "KO" },
                    { "description": "Intel", "proName": "INTC" },
                    { "description": "Verizon", "proName": "VZ" },
                    { "description": "Warner Bros.", "proName": "WBD" },
                    { "description": "Plug", "proName": "PLUG" },
                    { "description": "American", "proName": "AAL" },
                    { "description": "Walgreens", "proName": "WBA" },
                    { "description": "Boston Sci", "proName": "BSX" },
                    { "description": "Wells Fargo", "proName": "WFC" },
                    { "description": "Costco", "proName": "COST" },
                    { "description": "Uber", "proName": "UBER" },
                    { "description": "Chevron", "proName": "CVX" },
                    { "description": "HP Inc", "proName": "HPQ" },
                    { "description": "Micron", "proName": "MU" },
                    { "description": "Shopify", "proName": "SHOP" },
                    { "description": "Neflix", "proName": "NFLX" },
                    { "description": "Boeing", "proName": "BA" },   
                    { "description": "Taiwan Semi", "proName": "TSM" },  
                    { "description": "Shell", "proName": "SHEL" }, 
                    { "description": "NIKE", "proName": "NKE" },
                    { "description": "J&J", "proName": "JNJ" }, 
                    { "description": "PepsiCo", "proName": "PEP" },  
                    { "description": "Dell Tech", "proName": "DELL" }, 
                    { "description": "Eli Lilly", "proName": "LLY" },
                    { "description": "Walmart", "proName": "WMT" }, 
                    { "description": "IBM", "proName": "IBM" },                          
        */
      }),
      beforeMount() {
        //*** re-set card/widget measurements if running on mobile device before mount ***
        if( this.isMobile ) {
            this.cardWidth="100%";
        }
      },
      /**
       * Note: eventually - add dynamic list of symbols from Rapid API - get most active .. for example 
       * 
       * could just dump out the list from a backend servlet or web service.
       * 
       */
      beforeMount() {
          // get updated ticker list from S3
          this.getLatestTickerList();
      },
      mounted() {
        
      },
      methods: {
             // get running ticker list from S3 bucket - if available 
             getLatestTickerList() {
                // check for new calendar or date changes posted to S3 bucket
                var today = new Date();
                // this.showCheckToast();   // calendar check message
                this.axios.get('/rs/utils/document?file=liveTickerList.txt').then((response) => {
                    // console.log( response.data  ); // <- later comment out
                    this.symbols = response.data;    // overwrite default ticker list
                    console.log("Live Ticker List Updated");          
                }).catch( err => { 
                    // show message - system using old calendar 2023 - not found in S3 bucket
                    //this.showErrorToast();            
                    console.log( err );
                });
            },         

      }
    }
</script>
