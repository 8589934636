
<!----------------------------------------------------------------------------
    App Bar Market Clock - uses homePageMarketClock hosted in a dialog
   this allows the Market Alarm Clock to be available all the time not just from
   the HomePage dialog which can be closed and replaced with next component selecte
   by the user.
----------------------------------------------------------------------------------->
<template>
    <div class="text-center mx-auto">
        <v-dialog transition="dialog-top-transition" persistent v-model="dialog" :width="(this.isMobile? 350: 550)">
            <v-card height="550px" >
                <v-card-title class="ma-0 pa-0" >   
                    <v-container fluid >          
                        <v-toolbar color="lightblue" dark flat class="ma-0 pa-0" height="45px">
                            <v-toolbar-title>{{dialog_title}}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn @click="closeMarketClock" icon> <v-icon>fa-solid fa-eye-slash</v-icon> </v-btn>
                        </v-toolbar>
                    </v-container>
                </v-card-title>
                <v-card-text class="ma-0 pa-0">
                    <v-container fill-height >
                        <v-flex fill-height >
                           <marketClock/>
                        </v-flex>
                        <!-- display digital clock for mobile only - screen too busy -->
                        <v-row v-if="this.isMobile || this.screenWidth <= 1180" class="mt-1">
                            <v-col cols="12">  <DigitalClock/> </v-col>
                        </v-row>                        
                    </v-container>
                </v-card-text>
                <v-card-actions >
                    <v-row dense>
                        <v-col cols="3">
                            <v-btn class="ml-0" small rounded elevation="5" @click="closeMarketClock"> <v-icon class="m1-2">fa-solid fa-eye-slash</v-icon>Close</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>                
            </v-card>
        </v-dialog>
    </div>        
</template>

<script>
    import { eventBus } from '@/main'  // event bus communication for registered components
    // import the homePageMarketClock code here - removed from home page dialog 4.15.24
    // so the component is always available from the top App Bar
    import marketClock  from '@/components/views/homePageMarketClock.vue'
    // digital clock - in AppBar top position  url('@/assets/Open_Sign.jpg'); - mobile only
    import DigitalClock   from "@/components/DigitalClock.vue"

    export default {
        name: "Market-Clock",
        components: { marketClock, DigitalClock },
        data: () => ({
            dialog: false,
            dialog_title: "NYSE Market Clock",
            alarm: "01:01",
            screenHeight: window.screen.height,
            screenWidth:  window.screen.width,            
            isMobile: ( (/Mobile/i.test(navigator.userAgent)) ), 
        }),
        // life cycle methods
        beforeMount() {
            // make sure sessionStorage object does not exist just before mount
            sessionStorage.removeItem( 'timerShowing' );            
        },    
        // when this component is added to page - it listens for a specific event
        mounted() {
            // setup popup dialog listener when component is added to footer template
            eventBus.$on( "show-market-clock", this.showMarketClock )
            //console.log("Market Clock component listening ...");
        },
        unmounted() {
            // remove sessionStorage object
            sessionStorage.removeItem( 'timerShowing' );
        },
        methods: {
            showMarketClock() {
                //this.$loading(true);
                //console.log( "showMarketClock method recieved from AppBar icon ... to show" );
                this.dialog = true
                // -- set as active to ignore 'show-market-clock' event from homePageMarketClock.vue
                sessionStorage.setItem( 'timerShowing', 'true' );
            },
            closeMarketClock() {
                // close dialog window
                this.dialog = false
                sessionStorage.setItem( 'timerShowing', 'false' );
            }             
        }
        
    }
</script>