<!-- 
    desktop market trends menu replacement ( button ) used instead of tabs
-->
<template>
    <v-card class="mx-auto" max-width="300" tile>
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">   
               <v-btn @click.stop.prevent="checkBookmarks" color="primary" dark v-bind="attrs" v-on="on">
                 <v-icon left >mdi-podcast</v-icon>
                     Podcasts
               </v-btn>
            </template>
              <v-list class="text-left" dense color="grey">
                <v-list-item-group v-model="selectedItem" >
                  <!-- very important - added element 'id' to apply CSS class for bookmarks -->                  
                  <v-list-item v-for="(item) in items" :key="item.id" :to="item.route" :id=item.id >
                      <v-list-item-icon class="pl-0 pr-1 ml-0 mr-1">
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="pl-0 pr-0 ml-0 mr-0">
                        <v-list-item-title v-text="item.title" ></v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
        </v-menu>              
    </v-card>
</template>

<script>
  /* very important to load menu items from javascript external data file    */
  import menuItems from '@/components/menus/Data_Arrays/podcasts_menu'

  import $ from 'jquery';
  // filter by associate group settings in bookmarks - source data array menus
  const pGroup = "Investing Podcasts";  

    export default {
      head: {
        script: [
           { src: '@/components/menus/Data_Arrays/podcasts_menu.js', async: true, defer: true }
        ],
     },      
      data: () => ({
          selectedItem: 0,
          items: menuItems
      }),
      mounted() {
         // check for saved bookmarks before init to setup splash pages - default and tiles
         this.checkBookmarks()
      },        
      methods: {
        checkBookmarks() {
            var bookMarks = JSON.parse( localStorage.getItem('bookmarks') );
            if( bookMarks && bookMarks.length > 0 ) {             
              setTimeout(function() {            
                  // loop through bookmarks - check associated group label
                  bookMarks.forEach((mark) => {
                      // filter only check - Investing Tools group here
                      if( mark.group === pGroup ) {
                          Object.entries(mark).forEach(([ key, value ]) => {
                              if( key === 'id' ) { //v-list-item v-list-item--link //element.classList.toggle(className)
                                  $("#"+value ).addClass("bookmarked");
                                  //$("#"+value ).children('v-list-item__content v-list-item__title').addClass("bookmarked");
                                  //console.log("Added Class => "+ key +" value: "+value );
                              }
                          });  
                      }
                  })
              }, 135 );
            }
        }              
      }
    }
</script>

<style scoped>
 .bookmarked {
  border-right: 5px solid rgb(76, 226, 76);
    z-index: 99999;
  }
  .no-bookmark {
    border-left: 5px solid;
    z-index: 99999;
  }  
.v-button {
     opacity: 0.5;
  }
  .v-menu__content {
     background: lightgray;
  }
  .v-list-item {
     background: lightgray;
     color: darkblue;
  }
  .v-list-item--active .v-icon {
     background-color: lightgreen;
  }
  .v-list-item--active .v-list-item__title {
     color: black;
     font-weight: bold;
  }  
  .v-card--link:focus {
      background: lightgray;
   }
</style>