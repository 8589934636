
<!----------------------------------------------------------------------------------------------------
    TAB 3 on Popup dialog

         Greed Home Page Chart - using vue-google-charts
         [ https://www.npmjs.com/package/vue-google-charts ]

         :settings="{ packages: ['corechart','gauge'] }"

          * note: had to include these 2 lines at the bottom of index.html
    <script type = "text/javascript" src="https://www.gstatic.com/charts/loader.js"></script>  
    <script type = "text/javascript">google.charts.load('current',{packages:['corechart']});</script>            
------------------------------------------------------------------------------------------------------->
<template>
    <v-container fluid class="fill-height"> 
        <!-- width: 550px; height: 400px;
        <GChart id="glgChart" :resizeDebounce="500" :type="chartType" 
                          :data="chartData" :options="chartOptions" /> -->
        <div id="glgChart" style ="margin: 0 auto"></div>
         <v-row dense align-content="center" justify="space-around" class="mt-2 mb-1 pa-0" >
           <v-col cols="12">
               <v-btn class="ml-1 mr-1" v-for="item in items1" :key="item.id" elevation="5" x-small rounded :to="item.route" >
                    {{ item.title }}
                </v-btn>
            </v-col>
        </v-row>   
        <!-- *** Removed 3-20-24 Rapid API Key Issue - No Data since 1-20-24  ***          
            <v-divider class="ma-0 pa-0" ></v-divider>                         
            <v-row dense align-content="center" justify="space-around" class="mt-1 pa-0" >
            <v-col cols="12">
                <v-btn class="ml-1 mr-1" v-for="item in items2" :key="item.id" elevation="5" x-small rounded :to="item.route" >
                        {{ item.title }}
                    </v-btn>
                </v-col>
            </v-row>
        -->
    </v-container>
</template>

<script> 
/** seems only good for static data - could not update after generated  */
//import { GChart } from 'vue-google-charts/legacy'
import i18n from "@/i18n"

export default {
    name: 'GoogleGreedChart',
     // -- data passed from parent --*
    props: {
       dataPoints: { type: Array }
    },    
    data() {
        return {
            chartType: 'PieChart', 
            isMobile: ( (/Mobile/i.test(navigator.userAgent)) ), 
            width:  window.innerWidth,
            height: window.innerHeight,
            // 1st item 'task' element seems to be required ...
            chartData: this.dataPoints,  
/*
[['Task','Levels of Fear'],['(Now) Fear',37],['(Prv Cls) Fear',29],['(Prv Wk) Extreme Fear',19],['(Prv Mn) Greed',60],['(Prv Yr) Fear',40]]
  testing locally within component 
            chartData: [
                ['Task', 'Levels of Fear'],
                ['Extreme Fear', 24],
                ['Fear', 34],
                ['Neutral', 53],
                ['Greed', 69],
                ['Extreme Greed', 17],
            ],
*/          
            chartOptions: {
                title: i18n.t("fear_greed_meter.meter_title"),
                // exploded labels only for desktop
                //legend: { position: 'labeled', maxLines:1, textStyle:{color:'black',fontSize:10} },
                legend: { position:'bottom', alignment:'center', maxLines:2, textStyle:{fontSize:10} }, 
                chartArea:{ top: 25, bottom:25, right:15, left:15, width:'auto', height:'auto' },               
                pieHole: 0.4,
                //is3D: true,          //'does not work with pieHole on Pie Charts'
                width_units: '%',
                width:  this.width,
                height: this.height,
                responsive: true,
                pieSliceText: 'label', // default 'label' or 'none'
                pieSliceTextStyle: {fontSize:10},
                colors: [],
            },
            // bottom button list and defined routes to social trends tables
            items1: [
                { id: 1, title: 'Stocks',     icon: 'mdi-alpha-s-circle', route:({name: 'stockHeatmap'}) },  
                { id: 2, title: 'S.E.C.',     icon: 'mdi-alpha-b-circle', route:({name: 'SEC_News'})},  
                { id: 3, title: 'Currency',   icon: 'mdi-alpha-c-circle', route:({name: 'forexHeatMap'}) }, 
                { id: 4, title: 'IBD',        icon: 'mdi-alpha-n-circle', route:({name: 'IBDYTVideos'}) },     
                { id: 5, title: 'F.E.D.',     icon: 'mdi-alpha-f-circle', route:({name: 'FED_News'}) },                        
            ],   
            items2: [
                { id: 1, title: i18n.t("nav_trends.social_btn"),       icon: 'mdi-alpha-s-circle', route:({name: 'SocialSentiment'}) },  
                { id: 2, title: i18n.t("nav_trends.top_trend_btn"),    icon: 'mdi-alpha-t-circle', route:({name: 'TopTrending'}) },  
                { id: 3, title: i18n.t("nav_trends.most_changed_btn"), icon: 'mdi-alpha-m-circle', route:({name: 'MostChanged'}) },    
                { id: 4, title: i18n.t("nav_trends.social_stats_btn"), icon: 'mdi-alpha-c-circle', route:({name: 'SocialStats'}) },                                      
          ],           
        }
    },
    mounted() {
        this.onResize();
    },
    methods: {
        updateColors() {
           let newColors = new Array();
           // start @ pos 1 to skip 'Task' data entry;           
           for( let i = 1; i < this.chartData.length ; i++ ) {
              //console.log( 'color was ='+ this.colors[ i-1 ] );
              newColors[ i-1 ] = this.getSliceColor( this.chartData[i][0] );
              //console.log( 'color now is ='+ newColors[ i-1 ] );              
           }
           this.chartOptions.colors= newColors;
        },
        onResize() {
            this.width  = window.innerWidth;
            this.height = window.innerHeight;
            this.updateColors();                
            // re-draw chart for display
            this.drawChart();
        },
        getSliceColor( val ) {
            var color = '#212F3C';
            //console.log("**** Checking slice Task: "+val);
            if( val.includes('Extreme Fear') ) {
                color = "#784212";
            } else if( val.includes('Extreme Greed') ) {
                color = "#145A32";
            } else if( val.includes('Neutral') ) {
                color = "#85C1E9";
            } else if( val.includes('Greed') ) {
                color = "#7DCEA0";
            } else if( val.includes('Fear') ) {
                color = "#F1C40F";
            }
            return( color );
        },
        drawChart() {
            // Define the chart to be drawn.
            // eslint-disable-next-line no-undef
            var data = google.visualization.arrayToDataTable(this.chartData);
            // eslint-disable-next-line no-undef                
            var chart = new google.visualization.PieChart( document.getElementById( 'glgChart' )); 
            this.$loading(true);             
            // Set chart options
            setTimeout(() => {
                chart.draw( data, this.chartOptions ); this.$loading(false);  
            }, 500);            

         }
    },
}
</script>

<style scoped>
    #glgChart {
        width: 100%; 
        min-height: 400px;
    }
</style>
