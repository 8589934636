
<!--
  Base template for Youtube videos - from Reuters playlists
-->
<template>
  <v-row class="mt-3 mb-5" dense justify="center">
      <!-- START DESKTOP CARD - Toolbar and Tabs for report selection -->   
      <!-- <v-card class="mx-auto mb-15" :min-width="this.cardMinWidth" :width="this.cardWidth" :height="this.cardHeight" hover> -->         
      <v-card class="mt-0 mb-5" dense justify="center" :min-width="this.cardMinWidth" :width="this.cardWidth" hover>
        <div v-if="this.isMobile">
              <!-- appears on mobile only devices -->
              <v-toolbar color="#F8F6EA" dark flat width="auto" height="75px" >              
                <v-toolbar-title class="text-left text-h4 mt-1 black--text" background >Reuters</v-toolbar-title>  
                <div style="margin-left: auto; display: block;">
                    <Bookmark link="ReutersYTVideos"/>
                </div> 
                <!-- TABS Section displayed at bottom of toolbar -->
                <template v-slot:extension >
                    <!-- mobile report bar -->
                    <v-btn block v-if="isMobile === true" @click.stop="drawer = !drawer" >
                       <v-icon left >{{ listIcon }}</v-icon> {{ mobileRptBar }}
                    </v-btn>              
                </template>        
              </v-toolbar> 
              <div class="text-left">               
                <v-chip style="margin-left: 15px" x-small text-color="white" color="blue" >{{ this.getformattedDate() }}</v-chip>  
              </div>                       
          </div>
          <div v-else>   
             <v-toolbar color="#F8F6EA" dark flat width="auto" height="75px" >  
                <v-toolbar-title class="text-left text-h3 mt-1 mb-1 black--text" >Reuters</v-toolbar-title>          
                <div style="margin-left: auto; margin-top: 5px; display: block;">
                    <Bookmark link="ReutersYTVideos"/>
                </div>   
                <!-- TABS Section displayed at bottom of toolbar -->
                <template v-slot:extension >
                      <!-- toggle button list1 only appear on desktop  -->
                      <v-row dense no-gutters class="ma-0 pa-0"> 
                        <!-- desktop button bar options list 1 -->
                        <!-- loop through both feed lists to generate menu for top menu -->
                        <v-col cols="12" class="ma-0 pa-0" >
                          <v-btn-toggle dense v-model="toggle_list" borderless  rounded background-color="blue">
                            <v-btn active-class="aa" v-for="item in items" :key="item.id" @click.prevent="getYoutubeVideos( item.id, item.rpt )" elevation="3" rounded small >
                              <v-icon left >{{ item.icon }}</v-icon> {{ item.name }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                      </v-row>
                </template>                                                           
             </v-toolbar> 
             <div class="text-left">
                 <v-chip style="margin-left: 20px;" x-small text-color="white" color="blue" >{{ this.getformattedDate() }}</v-chip>    
             </div>                         
        </div>
        <!-- feed viewing component - where feeds are available for viewing on desktop -->
        <v-card-text>
              <v-container fluid>
                  <v-layout row wrap>
                    <v-flex align-self-start >
                          <!-- tied to user selection calling 'videoChange' function -->
                          <v-select v-if="videos"
                            :items="videos"
                            v-model="selectedVideo"
                            :item-text="'title'"
                            :item-value="'id'"
                            name="selectedVideo"
                            label="Select a Video"
                            v-on:change="videoChanged"
                            solo
                            dark
                            return-object >
                            <template v-slot:selection="{ item }">
                              <span class="d-flex self-align-start" style="width: 100%; align: left;">
                                  {{ item.title }}
                              </span>
                            </template>                    
                          </v-select>
                        </v-flex>
                  </v-layout>
              </v-container>
              <v-row no-gutters class="ma-0 pa-0">
                  <v-col class="ma-0 pa-0" cols="12">
                       <!-- height adjusted to fit within the host parent card; desktop or mobile -->  
                        <!-- W3.css block -->  
                        <v-row no-gutters class="ma-0 pa-0" >
                          <iframe :id=iframe_id type="text/html" width="100%" :height="350" :src=iframe_src frameborder="0" allowfullscreen></iframe>
                          {{ this.iframe_subtitle }}
                        </v-row>
                        <v-row no-gutters class="ma-0 pa-0">
                            <!-- W3.css block -->  
                            <div class="w3-panel w3-padding-24 w3-large w3-left-align w-3-border w3-pale-blue w3-leftbar w3-border-blue">
                              <p class="pword-wrap">{{ this.iframe_desc }}</p>
                            </div>
                        </v-row>
                        <v-row no-gutters class="ma-0 pa-0">
                          <v-col cols="12" class="ma-0 pa-0">
                              <div class="w3-panel w3-padding-16 w3-border w3-light-grey w3-leftbar" >
                                  User Rating: <v-rating v-model="rating" background-color="indigo lighten-3" dense
                                          color="red" half-increments hover size="18" readonly
                                  ></v-rating>
                                <p> ( {{ rating }} ) average based on [ {{ views }} ] reviews</p>
                              </div>  
                          </v-col>
                        </v-row>                          
                 </v-col>
              </v-row>
        </v-card-text>
      </v-card>
      <!-- *** START Left Drawer for small screens - uses tab v-card section to display report *** -->
      <v-navigation-drawer app v-model="drawer" color="blue" theme="dark" class="mt-0 pt-0 pb-15 mb-15" absolute temporary >
          <v-row dense no-gutters class="mb-0 pb-0">
              <v-col class="text-md-center" cols="12">               
                  <h4 class="ml-auto mb-0 pb-0 white--text">Reuters Clips</h4>  
              </v-col>
          </v-row>
          <v-divider dark style="height:3px;background-color:aqua" class="ma-0 pa-0" ></v-divider>             
          <v-list nav dense class="ma-0 pa-0">
            <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
              <!-- loop through both feed lists to generate menu for drawer -->
              <v-list-item v-for="item in items" :key="item.id"  @click.prevent="getYoutubeVideos(item.id,item.rpt)" >
                  <!-- highlight first report id: 0 as 'active' link -->
                  <div v-if="item.id === 0">
                      <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">                
                         <v-icon id="fIcon" left class="initHighlighted" >{{ item.icon }}</v-icon>
                      </v-list-item-icon>     
                  </div>
                  <div v-else>
                      <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">                
                        <v-icon left >{{ item.icon }}</v-icon> 
                      </v-list-item-icon>     
                  </div>                                    
                  <v-list-item-content :style="{'align-items':'left'}">
                      <v-list-item-title id="listFont" class="ListItemClassInActive">{{ item.name }}</v-list-item-title>
                  </v-list-item-content>                      
              </v-list-item> 
            </v-list-item-group>
          </v-list>
      </v-navigation-drawer>  
      <!-- *** END Left Drawer Section *** -->  
    </v-row>
</template>

<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"  

    // date js utility for formatting current NYSE date
    import dayjs from "dayjs";    
    
    // listen for and allow broadcasts to components
    import { eventBus } from '@/main'  // event bus communication for registered components

    // user bookmarking component
    import Bookmark      from '@/components/AddToFavorites.vue'              

    // use NY Date info for unigue key to sync with server - basic security
    var tNYDate = new Date(new Date().toLocaleString('en', {timeZone: 'America/New_York'}));  

    export default {
      components: { Bookmark },
      data: () => ({
        scrollInvoked: 0,
        timestamp: new Date(new Date().toLocaleString('en',{timeZone:'America/New_York'})),        
        toggle_list: 0,    
        valid: false,
        drawer: false, 
        group: null,               
        // grabs locale from environment setup in start of app
        locale: i18n.locale,        
        // determines on object creation if running on mobile device true or false
        isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))),
        // *** following settings are designed for desktop - flipped if 'mobile' before mount ***              
        cardMinWidth: 650,
        cardWidth: "75%",
        cardMaxWidth: "80%",
        cardMinHeight:850,
        cardHeight: 1000,
        rating: 0,
        tab: null,
        show: false,
        listIcon: 'fa-solid fa-list',
        mobileRptBar: i18n.t('video_mobile_reportbar'),        
        //----------SECURITY CHECKS SENT to SERVER --------------------------------*
           xKey: tNYDate.getFullYear()+(tNYDate.getMonth()+1)+(tNYDate.getDate()),
          x2Key: tNYDate.getFullYear()*(tNYDate.getMonth()+1)*(tNYDate.getDate()),          
        //-------------------------------------------------------------------------*  
        reportData      : 'Video Viewer Section', 
        iframe_title    : 'test',
        iframe_subtitle : 'sub',
        iframe_src      : 'https://www.youtube.com/embed/f4pXmHmqE7Y',
        iframe_id       : '0',
        iframe_height   : 450,
        iframe_desc     : '',
        //--- ratings -- data from parse
        rateCount       : 1, 
        rateAvg         : 0.00,
        rateMin         : 1, 
        rateMax         : 5,
        views           : 1,  
        videos: [ ],
        selectedVideo: [ ],
        showAdsID       : 0,
        hideAdsID       : 0,        
        time_delay: Number( process.env.VUE_APP_ADS_DELAY ),  // retrieve setting from .env file      
        // menu items for tabs and left drawer component on mobile  
        /* Java back-end hashmap -> VideoReutersQuartzJob
            // define list of playlists 
            Reuters_VideoPlaylist.put( "Reuters_WorldNews",  reutersPLUrl+"PLZhRxE9191zMpjpVZRF_Mp4loH9o6771G" );
            Reuters_VideoPlaylist.put( "Reuters_USNews",     reutersPLUrl+"PLZhRxE9191zMhTEG_W0eDJcVviNwSDwjS" );    	
            Reuters_VideoPlaylist.put( "Reuters_Tech",       reutersPLUrl+"PLZhRxE9191zO1fep_Ao7sC7NEk_P3YHZn" );    
            Reuters_VideoPlaylist.put( "Reuters_Business",   reutersPLUrl+"PLZhRxE9191zMnamWuZGzRvLEsbWUDD8JT" );      
            Reuters_VideoPlaylist.put( "Reuters_Asia",       reutersPLUrl+"PLZhRxE9191zNaZml2o9sFdEJc9uwLrWsV" );     	
            Reuters_VideoPlaylist.put( "Reuters_Europe",     reutersPLUrl+"PLZhRxE9191zMM9w9V77ZOEZIkVhg-EZH3" );   
            Reuters_VideoPlaylist.put( "Reuters_Energy",     reutersPLUrl+"PLZhRxE9191zMomKUyQ_si262cieE0IJvW" );    
        */
        items: [
              { id:0,name:'World News',rpt:'Reuters_WorldNews',title:'World News',icon:'fa-solid fa-globe'}, 
              { id:1,name:'US News',rpt:'Reuters_USNews',title:'US News',icon:'fa-solid fa-flag-usa'},    
              { id:2,name:'Tech',rpt:'Reuters_Tech',title:'Tech',icon:'fa-solid fa-computer'},
              { id:3,name:'Business',rpt:'Reuters_Business',title:'Business',icon:'fa-solid fa-magnifying-glass-chart'}, 
              { id:4,name:'Asia',rpt:'Reuters_Asia',title:'Asia Pacific',icon:'fa-solid fa-earth-asia'},    
              { id:5,name:'Europe',rpt:'Reuters_Europe',title:'Europe',icon:'fa-solid fa-earth-europe'},  
              { id:6,name:'Energy',rpt:'Reuters_Energy',title:'Energy',icon:'fa-solid fa-charging-station'},                                                                                
        ],                     
      }),
      beforeMount() {
        // show loading spinner - just before component is mounted to app
        this.$loading(true);

        // send event to parent (AppBar.vue) to close Ads right drawer
        this.adCleanUp()            

        //*** re-set card/widget measurements if running on mobile device before mount ***
        if( this.isMobile ) {
            this.cardWidth="90%";
            this.cardMinWidth=325;
            this.cardMaxWidth="99%";
            this.cardHeight=1000;
        }
        //*****************************************************/            
      },
      mounted() {

        // 2.5 sec delay - then remove loading indicater       
        setTimeout( ()=> this.$loading(false), 2500 ); 

        // clear any previous data - to start on first story
        this.reportData = '';  

        // check for setting - time out is set in .env file for ads 
        if( this.time_delay <= 0 ) { this.time_delay = 2500; }             
        
        // *** get first report on mount - Commodities is the first button ***
        this.getYoutubeVideos( 0, this.items[0].rpt );

        // *** set selected video to first video in array - move to axios call ***
        this.selectedVideo = this.videos[0];
      },
      beforeUnmount() {
         // send event to parent (AppBar.vue) to close Ads right drawer
         this.adCleanUp()    
      },            
      // called when component is removed
      unmounted() {
         // clear any 'refresh' data attempts before exiting component
         sessionStorage.removeItem("refresh");
      },           
      methods: {
        // updates video 
        videoChanged() {
            //if( this.selectedVideo != null && this.selectedVideo.length >= 0 ) {
                // close any visible Ad
                this.adCleanUp();               
                this.iframe_title    = this.selectedVideo.title;
                this.iframe_subtitle = this.selectedVideo.published;
                this.iframe_id       = this.selectedVideo.id;
              //  this.iframe_height   = this.selectedVideo.height;
                this.iframe_src      = this.selectedVideo.src;
                this.iframe_desc     = this.selectedVideo.desc;
                //--- rating section convert from String to Number ---*
                this.rateCount       = Number( this.selectedVideo.rateCount );
                this.views           = Number( this.selectedVideo.views );
                //---- calc rating for bottom component
                this.rating          = Number( ((this.rateCount / this.views) *100) ).toFixed(2);
                // redisplay Ads - and their clearing timeouts
                this.showHideAds();
                //console.log("Current Video Title = >"+this.iframe_title );
                //console.log("Current Video Id: = >"+this.iframe_id );                
            //} else {
            //  console.log( 'Default Video Not Set.' );
            //}
        },
        // method attaches listerners to all audio components in the feed
        // [  https://stackoverflow.com/questions/7443578/youtube-iframe-api-how-do-i-control-an-iframe-player-thats-already-in-the-html]
        onScroll () {
           // to track scrolling of card window
           this.scrollInvoked++
        },
        getformattedDate() {
           var day = dayjs( this.timestamp );
           return( "Current as of: "+day.format( 'MM-DD-YYYY HH:mm' ) );           
        },
        adCleanUp() {
          // close right drawer
          eventBus.$emit("hide-side-ads","hideSideAds");  
          // stop any timeout events by assigned ID - before creating any new ones
          clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );
        },
        showHideAds() {
          this.adCleanUp();
          var delay = this.time_delay;
          // show ads - if enabled in .env file - defined in BottomSheetAds component - record id for clearing
          this.showAdsID = setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), delay );  // record timeout ID -  for clearing
          //console.log("showAdsID = "+this.showAdsID);    
          delay = delay * 3;
          // send event to parent (AppBar.vue) to close Ads right drawer x3 delay
          this.hideAdsID = setTimeout( ()=> eventBus.$emit("hide-side-ads","hideSideAds"), delay );   // record timeout ID - for clearing  
          //console.log("shideAdsID = "+this.hideAdsID);    
        },             
        refreshMsg() {
            this.$swal({
                title: 'New Videos Available',
                text:  'Refreshing Data List, Please Wait ...',
                icon:  'info',
                showCloseButton: false,
                showLoaderOnConfirm: false
              }).then(() => {  });
          },            
        /**************************************************************************************
         * this method is assigned to each button generated by looping through List1 and List2
         * when clicked it will make an axios call to retrieve the requested Rss feed from
         * the back end servlet which will pull it from the S3 bucket.
         * 
         * ListNo is passed to clear other button list so it is not highlighted/selected
         * no button group is cleared if zero 0 is passed
         *************************************************************************************/      
        getYoutubeVideos( btnId, rptName ) {
              this.$loading(true);  
              // close any visible Ad
              this.adCleanUp()                  
              // clear any previous data - to start on first story
              //   this.reportData = '';   
              // add timeout to reselect button if clicked multiple times
              //setTimeout( ()=> { this.toggle_list = btnId; }, 150 );                 
              //console.log('call to get videos ...');
              // request report from back-end to retrieve selected Rss Feed - added 'rtype' to request JS Array 
              this.axios.get('/video?report='+rptName,{timeout:5000,headers:{'api-ref':this.xKey,'sc-ch-nx':this.x2Key,'rtype':"JS"}}).then((response) => {
                    // clear any existing data - reset scroll window position
                    this.scrollInvoked=0;                    
                    //console.log( response.data  ); // <- later comment out
                    var videoData = response.data;
                    // **** throw error to popup message - invalid symbol ****
                    if( videoData === null || videoData.length <= 10 ) {
                          throw new Error('Reuters Video Error');
                    } else {
                        // store feed info to data element 'text' for display - which
                        // is created under a tab section ... reference back to that tab first
                        // VERY IMPORTANT to CALL Javascript EVAL Function on String data from server
                        this.videos =  eval( videoData );
                        // select first element as default
                        this.selectedVideo = this.videos[0];                           
                        // debugging - console.log("Set Default Video => "+this.selectedVideo+" " );
                        // set scroll back to top
                        this.scrollInvoked=0;
                        // clear loading, then show wait popup message after 250 mil delay
                        setTimeout( ()=> { this.videoChanged(); this.$loading(false); sessionStorage.removeItem("refresh"); }, 1000 ); 
                        // debugging - console.log("returned: "+ videoData ) ;
                        // redisplay Ads - if enable in .env file during build
                        this.showHideAds();                        
                    }
              }).catch( err => { 
                  this.$loading(false);
                  var xErr = "<div class='w3-row w3-margin-bottom'>"+    
                              "<div class='w3-card-4' style='width:100%'>"+
                              "<header class='w3-container w3-red w3-left-align'>"+
                              "<h3>Reuters Video "+i18n.t('rss_err_msg.title')+"</h3>"+
                              "<h6>"+i18n.t('rss_err_msg.topic')+"</h6></header>"+
                              "<div class='w3-container w3-left-align'>"+
                              "<p></p><p>"+i18n.t('rss_err_msg.action') +"</p>"+
                              "</div></div></div>";
                              console.log( err );
                  // show user by udating - report data for the view
                  this.reportData = xErr;
                  // reload application - probably due to connection timeout or stale data list
                  // *** check for previous try attempt to refresh in session storage ***
                  var refresh = sessionStorage.getItem("refresh");
                  // loader
                  // setTimeout( ()=> { this.$loading(true); }, 2500 );    
                  // after previous attempt - to reload the list - direct to 'home'
                  if( refresh != null ) {
                      // first remove 'reload flag' from sessionStorage - redirect 'home'
                      sessionStorage.removeItem("refresh");
                      setTimeout( ()=> { this.$loading(true); }, 2500 );   
                      setTimeout( ()=> { window.location.href="/"; }, 3500 ); 
                      //console.log( "refresh found & removed - redirected HOME ... " );
                  } else {
                      // **** try refreshing this page data on a reload  1x only ****
                      this.refreshMsg();
                      // set storage item - 'refresh' before reloading this page
                      sessionStorage.setItem( "refresh", 'true' );
                      //console.log( "refresh SET - reload page .. " );                          
                      setTimeout( ()=> { window.location.reload(); }, 1500 );  
                  }
              });
              // remove highlighed default mobile report icon - must be a better way ...
              if( this.isMobile && btnId != 0 ) {
                  var icon = document.getElementById("fIcon");
                  icon.classList.remove("initHighlighted");
              } 
                     
          },
      },
      watch: {
          group () {
             this.drawer = false
          },
      },
      metaInfo() {
        return {
          title: "Stock-Insight's Latest Video Clips From Reuters",
          meta: [
            { name: 'robots', content: 'index,follow'},
            { name: 'description', content: 'Financial Youtube Videos from Reuters' },
            { name: 'keywords', content: 'financial Youtube investing, advisor, Reuters' },            
            { property: 'og:type', content: 'website'}, 
            { property: 'og:site_name', content: 'www.Stock-Insight.net'},
            { property: 'og:title', content: "Stock-Insight's Latest Videos From Reuters"},                             
            {
              vmid: "description",
              name: "description",
              content: "Financial Video Clips from Youtube for Reuters"
            }
          ],
          link: [
            {
              rel: 'canonical',
              href: '<https://www.stock-insight.net/>'
            }
          ]
        }
      }                      
    }
</script>

<style scoped>
  .v-tab--active {
      background-color:rgba(22, 174, 22, 0.3); 
  }
  .v-list-item__content {
      text-align: left;
      color: white; 
      font-weight: bold;     
  }
  .v-list-item__title {
      padding-left: 10px;
      font-weight: bold;        
  }
   .aa {
    background: rgb(25, 0, 255) !important;
  }
  .ListItemClassActive {
    color: #0556f9;
  }
  .ListItemClassInActive {
    color: #f1f8fa;
   }
  .v-list-item__icon v-list-group__header__prepend-icon {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
  } 
  .v-list-item--active .subListIcon .v-icon {
     background-color: lightgreen;
  }
  .v-list-item--active .v-list-item__title {
     color: rgb(253, 250, 250);
     font-weight: bold;
  } 
  .subListIcon {
    font-size: 16px;
    width: 40px;    
  }  
  .subListItem {
    font-size: 18px;
  }   
  .initHighlighted {
    background-color: lightgreen;
  }   
  #listFont {
     font-size: 16px;
     padding-top: 5px;
  }     

</style>