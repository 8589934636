<!---------------------------------------------------------------
         Default Search Splash Home Screen Component - part of
         HomePage.vue which also hosts 'HomePageTileMenu' - 
         which uses localstorage bookmarks - if any  class="mt-5"

         from original, start button creates default bookmarks 
         for starting 'tile menu' view of application if selected
         class="logoMain mx-auto my-auto"
----------------------------------------------------------------->
<template>
    <v-container fluid fill-height>
        <v-layout justify-center align-center>
            <v-card elevation="7" class="d-flex align-center justify-center" min-width="225" min-height="505" max-height="565">
              <v-container class="ma- pa-0"> 
                <v-layout class="ml-0 pl-0 blue" >
                  <div v-if="this.isMobile || this.screenWidth <= 1180" >
                      <v-tabs v-model="tab" background-color="blue" dark>
                        <v-tab class="homeTabFont ml-0 pl-0" v-for="item in items" :key="item.id"><v-icon left >{{ item.icon }}</v-icon></v-tab>
                      </v-tabs>
                  </div>
                  <div v-else>
                      <v-tabs v-model="tab" background-color="blue" dark >
                        <v-tab class="homeTabFont mx-0" v-for="item in items" :key="item.id"><v-icon left >{{ item.icon }}</v-icon>{{ item.tabTitle }}</v-tab>
                      </v-tabs>
                  </div>
                </v-layout>              
                <v-tabs-items v-model="tab" >
                  <!--  Graphic with Grid containing rotating points  -->
                  <v-tab-item >
                      <v-row dense class="ma-0 pa-0" >
                        <v-col class="d-flex ma-0 pa-0" >
                          <v-card class="logoImg mx-auto" height="500" width="649" :img="require('@/assets/success-header.jpg')">
                              <v-card-title class="text-center white--text text-h4 font-weight-bold">{{ this.title }}</v-card-title>
                              <v-card-text>
                                <!--<canvas id='gridwormCanvas' width='650' height='500' style='background-color: white;' ></canvas>-->
                                <canvas id="container" width="200" height="200"></canvas>                                   
                              </v-card-text>
                              <v-footer absolute :height="50" color="rgb(150, 0, 90, 0.2)" class="py-25 pl-2 white--text">
                                <v-btn class="font-weight-bold white--text" elevation="12" rounded color="red" @click.prevent="getRecommendedBookmarks">
                                    <v-icon dark>fa-solid fa-person-running fa-bounce</v-icon> <i><b>{{ this.subTitle }} </b></i>
                                </v-btn>
                              </v-footer>
                          </v-card>
                        </v-col>
                      </v-row>
                  </v-tab-item>
                  <!--Greed and Fear Meter Tab 2 -->
                  <v-tab-item>
                    <v-row dense class="ma-0 pa-0" >
                        <v-col class="d-flex ma-0 pa-0" >
                          <v-card class="mx-auto ma-0 pa-0" height="500" width="649" >
                              <v-card-text class="ma-0 pa-0" >
                                <greedMeter class="ma-0 pa-0" :dataPoints=this.fearGreedPoints  />
                              </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                  </v-tab-item> 
                  <!--Greed and Fear PieChart Tab 3 -->
                  <v-tab-item>
                    <v-row dense class="ma-0 pa-0" >
                        <v-col class="d-flex ma-0 pa-0" >
                          <v-card class="mx-auto ma-0 pa-0" height="500" width="649" >
                              <v-card-text class="ma-0 pa-0" >
                                 <greedChart class="ma-0 pa-0" :dataPoints=this.fearGreedPoints  />
                              </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-container>
            </v-card>
       </v-layout>
    </v-container>
</template>

<script>
  // use NY Date info for unigue key to sync with server - simple security check
  var tNYDate = new Date( new Date().toLocaleString('en', {timeZone: 'America/New_York'}) ); 
  import { eventBus } from '@/main'  // event bus communication for registered components   
  import i18n         from "@/i18n"
  // setup both charts
  import greedChart   from '@/components/views/homePageGreedPieChart.vue'
  import greedMeter   from '@/components/views/homePageGreedMetorChart.vue'

  export default {
        components: { greedMeter, greedChart },
        data() {
            return {
                    //------ TAB 2 chart data - pass values to graph component - default settings ----------*
                    /* Google Chart uses this data block to display - passed from Servlet [http://192.168.0.185:8085/fgi?Indx]
                        fearGreedPoints: [['Task','Levels of Fear'],['(Now) Extreme Fear',24],['(Prv Cls) Fear',28],['(1 WK) Extreme Fear',20],['(1 MN) Greed',65],['(1 YR) Fear',33]],          
                    ***********************************************/
                    // *** defaults with no data points until mounted ***
                    fearGreedPoints: [],
                    //--------------- TAB 1 - moving points section on tab 1 ------- *
                    points: [],
                    velocity2:  5,
                    canvas: null,
                    context: null,
                    radius: 5,
                    boundaryX: 200,
                    boundaryY: 200,
                    numberOfPoints: 15,
                    title: i18n.t("home_page.title"),
                    //subTitle: i18n.t("home_page.subtitle"),
                    subTitle: "Let's Get Started -> [ Click Here ]",
                    //---------------------------------------------------------------*
                    tab: null,
                    //----------2 SECURITY CHECKS SENT to SERVER --------------------------------*
                        xKey:  tNYDate.getFullYear()+(tNYDate.getMonth()+1)+(tNYDate.getDate()),
                        x2Key: tNYDate.getFullYear()*(tNYDate.getMonth()+1)*(tNYDate.getDate()),          
                    //---------------------------------------------------------------------------*  
                    nydate: new Date(new Date().toLocaleString('en',{timeZone: 'America/New_York'})),          
                    items: [
                        { id:0, tabTitle: 'Home',         icon:'fa-solid fa-map-location-dot', content: 'Tab 1 Graphic' },
                        { id:1, tabTitle: 'Indicator',    icon:'fa-solid fa-compass' ,         content: 'Tab 2 Greed Meter' },                    
                        { id:2, tabTitle: 'Mood',         icon:'fa-solid fa-chart-pie' ,       content: 'Tab 3 Pie Chart' },
                    ],  
                    // determines on object creation if running on mobile device true or false
                    isMobile: (/Mobile/i.test(navigator.userAgent)),                  
                    screenHeight: window.screen.height,
                    screenWidth:  window.screen.width,
                    introShortcutsTxt: ''
                }
            },
            beforeMount() {
                //*** re-set chart/widget measurements if running on mobile device before mount ***
                if( this.isMobile ) { this.chartHeight=450 }  
            },  
            mounted() {
                // both splash pages load - just hidden depending on bookmarks
                // start animation after mount after slight delay
                setTimeout(()=>{ this.init() }, 250 );
                // check for partial html document in S3 tiles folder
                //this.getS3IntroShortcutsTxt();                
            },  
            methods: {
                showAboutSite() {
                    // emits global event for component - to open about site document
                    eventBus.$emit("show-about-site", "showAboutSite");
                },
                /*************************************************************
                 *    recommended bookmarks by passing intro message
                 *************************************************************/
                getRecommendedBookmarks() {
                      const url = new URL( '/', window.location.origin );         
                      // show message getting bookmarks - before page refresh - in case of delay
                      this.$swal({ icon: "info", text: "Getting Recommended Shortcuts ...", showConfirmButton: false, });
                      // request report from back-end to retrieve selected Rss Feed
                      this.axios.get( '/rs/utils/document?file=tiles/DefaultBookmarks.txt' ).then((response) => {
                        // check response object from request
                        var bookMarks = JSON.stringify( response.data );
                          //console.log("Axios Response: "+bookMarks );
                          try {
                              // check number of objects in the json array
                              let bookMks = JSON.parse( bookMarks );
                              if( bookMks && bookMks.length > 5 ) {
                                  try {
                                      // update localStorage bookmarks list
                                      localStorage.setItem( "bookmarks", bookMarks );
                                      // update data block - with strified version for data block
                                      this.bookmarks      = bookMks;                        
                                      // disable end of list button moving
                                      this.bookMksLength  = bookMks.length;                        
                                      console.log( 'Using S3 Shortcuts #.... ['+this.bookMksLength+' ]' );  
                                  } catch(e1) { throw e1 }
                              } else {
                                  try {
                                      // bookmarks with static list - fallback if none in S3
                                      var bookmarks = JSON.stringify( [{"id":"1vd","title":"Bloomberg Clips","group":"Latest Videos","icon":"mdi-alpha-b-circle","route":{"name":"BloombergYTVideos"},"tile":"/rs/utils/document?file=tiles/BloombergYoutube.png"},{"id":"2vd","title":"CNBC Clips","group":"Latest Videos","icon":"mdi-alpha-c-circle","route":{"name":"CNBCYTVideos"},"tile":"/rs/utils/document?file=tiles/CnbcYoutube.png"},{"id":"6vd","title":"Yahoo Clips","group":"Latest Videos","icon":"mdi-alpha-y-circle","route":{"name":"YahooYTVideos"},"tile":"/rs/utils/document?file=tiles/YahooYoutube.png"},{"id":"7vd","title":"Zacks Clips","group":"Latest Videos","icon":"mdi-alpha-z-circle","route":{"name":"ZacksYTVideos"},"tile":"/rs/utils/document?file=tiles/ZacksYoutube.png"},{"id":"3ans","title":"Investing.com","group":"Latest News","icon":"mdi-alpha-i-circle","route":{"name":"InvestingDC"},"tile":"/rs/utils/document?file=tiles/InvestingDotComRss.png"},{"id":"3tl","title":"Fundamentals","group":"Investing Tools","icon":"mdi-alpha-f-circle","route":{"name":"Fundamentals"},"tile":"/rs/utils/document?file=tiles/FinancialAnalysis.png"},{"id":"3ch","title":"Foreign Exchange","group":"Live Data Charts","icon":"mdi-alpha-f-circle","route":{"name":"forexRates"},"tile":"/rs/utils/document?file=tiles/ForeignExchangeChart.png"}] );
                                      // update localStorage bookmark list - from data block
                                      localStorage.setItem( "bookmarks", bookmarks );
                                      // record number of bookmarks by breaking into objects
                                      var bookmks         = JSON.parse( bookmarks );
                                      // update data block
                                      this.bookmarks      = bookmks;
                                      // check position in list to disable bottom list button
                                      this.bookMksLength  = bookmks.length;                              
                                      console.log( 'Using default list of bookmarks - No S3 List. [ '+this.bookMksLength+' ]' ); 
                                  } catch(e2) { throw e2 }
                              }  
                          } catch( err ) { console.log( err ) } 
                          // force re-load of application - to display bookmarks / shortcuts
                          window.location.href = url.toString();  
                      }).catch( err => { 
                          // set default bookmarks - method in js/utils.js file
                          setDefaultBookmarks();
                          // refresh screen - reload
                          window.location.href = url.toString();  
                      })
                },
                getChartData() {
                    // check for new chart data - changes posted to S3 bucket
                    this.axios.get('/fgi?Indx',{headers:{'api-ref':this.xKey,'sc-ch-nx':this.x2Key}}).then((response) => {
                        //console.log( response.data  ); // <- later comment out
                        let chtData  = response.data;
                        if( chtData && chtData.length > 25 ) {
                        // update chart from S3 bucket - IMPORTANT: has to use 'eval()' function here
                        this.fearGreedPoints=eval(chtData);
                        // console.log("S3 Chart data Updated: "+this.fearGreedPoints);
                        }
                    }).catch( err => { 
                        // show message - system using old Chart Data - not found in S3 bucket
                        this.fearGreedPoints = [['Task','Levels of Fear'],['(Now) Extreme Fear',100]];
                        console.log( err );                  
                    });
                },
                init() {
                    // Setup default splash page
                    // Main Home Splash page - if bookmarks are less than 2
                    //-- Tab 2 Greed-Fear Chart object -----*
                    // check for new chart data before display
                    this.getChartData();                      
                    //==============================================*
                    //----------Tab 1 - rolling points section -----*
                    this.canvas  = document.getElementById('container');
                    this.context = this.canvas.getContext('2d');  
                    // create points
                    for ( var i = 0; i<this.numberOfPoints; i++ ) {
                        this.createPoint();
                    }
                    // create connections
                    for (var p = 0, l=this.points.length; p<l; p++) {
                      // var point = points[i];
                      if( p === 0 ) {
                          this.points[p].buddy = this.points[this.points.length-1];
                      } else {
                          this.points[p].buddy = this.points[i-1];
                      }
                    }
                    // start animation of points 
                    this.animate();
                },
                createPoint() {
                  var point = {}, vx2, vy2;
                  point.x = Math.random()*this.boundaryX;
                  point.y = Math.random()*this.boundaryY;
                  // random vx 
                  point.vx = (Math.floor(Math.random())*2-1)*Math.random();
                  vx2 = Math.pow(point.vx, 2);
                  // vy^2 = velocity^2 - vx^2
                  vy2 = this.velocity2 - vx2;
                  point.vy = Math.sqrt(vy2) * (Math.random()*2-1);
                  this.points.push(point);
                },
                resetVelocity(point, axis, dir) {
                var vx2, vy2;
                if(axis === 'x') {
                    point.vx = dir*Math.random();  
                    vx2 = Math.pow(point.vx, 2);
                    // vy^2 = velocity^2 - vx^2
                    vy2 = this.velocity2 - vx2;
                    point.vy = Math.sqrt(vy2) * (Math.random()*2-1);
                } else {
                    point.vy = dir*Math.random();  
                    vy2 = Math.pow(point.vy, 2);
                    // vy^2 = velocity^2 - vx^2
                    vx2 = this.velocity2 - vy2;
                    point.vx = Math.sqrt(vx2) * (Math.random()*2-1);
                }
                },
                drawCircle(x, y) {
                    this.context.beginPath();
                    this.context.arc(x, y, this.radius, 0, 2 * Math.PI, false);
                    this.context.fillStyle = '#97badc';
                    this.context.fill();  
                },
                drawLine(x1, y1, x2, y2) {
                    this.context.beginPath();
                    this.context.moveTo(x1, y1);
                    this.context.lineTo(x2, y2);
                    this.context.strokeStyle = '#8ab2d8'
                    this.context.stroke();
                },  
                draw() {
                    for(var i =0, l=this.points.length; i<l; i++) {
                        // circles
                        var point = this.points[i];
                        point.x += point.vx;
                        point.y += point.vy;
                        this.drawCircle(point.x, point.y);
                        // lines
                        this.drawLine(point.x, point.y, point.buddy.x, point.buddy.y);
                        // check for edge
                        if(point.x < 0+this.radius) {
                           this.resetVelocity(point, 'x', 1);
                        } else if(point.x > this.boundaryX-this.radius) {
                           this.resetVelocity(point, 'x', -1);
                        } else if(point.y < 0+this.radius) {
                           this.resetVelocity(point, 'y', 1);
                        } else if(point.y > this.boundaryY-this.radius) {
                           this.resetVelocity(point, 'y', -1);
                        } 
                    }
                },
                animate() {
                    this.context.clearRect ( 0 , 0 , 200 , 200 );
                    this.draw();
                    requestAnimationFrame( this.animate );
                }
            }
    }
</script>

<style scoped>
    .bounce-enter-active {
      animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
      animation: bounce-in 0.5s reverse;
    }
    @keyframes bounce-in {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1.25);
      }
      100% {
        transform: scale(1);
      }
    }
  .logoImg { 
    background-size: cover;
  } 
  #greedChart {
    max-width: 550px;
    max-height: 450px;
    margin: 5px auto;
    opacity: 0.8;
  } 
  .homeTabFont {
    font-size: 9px;
  } 
  .v-btn::before {
     background-color: transparent;
  }
  .v-btn {
    font-size: .6em;
  }
  .v-tab {
     letter-spacing: normal !important;
     align-items: left !important;
  }  
  .div.ex3 {
    overflow: scroll;
    overflow-y: hidden;
  }
  p { text-align: justify; }
  .wrapper {
    width: fit-content;
    margin: 0 auto;
	  text-align: left;
  }
</style>