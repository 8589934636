import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"fill-height printable"},[_c(VLayout,{attrs:{"justify-center":"","align-center":""}},[_c(VCard,{attrs:{"min-width":"225","width":"650","max-width":"850","hover":"","elevation":"5"}},[_c(VToolbar,{attrs:{"src":require('@/assets/agenda_1280.jpg')},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c(VTabs,{staticClass:"ml-1 mr-0 pa-0",attrs:{"show-arrows":"","slider-color":"red","background-color":"rgba(46, 62, 131, 0.5)","stacked":"","icons-and-text":"","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c(VTab,{key:item.tab,attrs:{"active-class":"active-tab"}},[(_vm.isMobile)?_c('div',[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1):_c('div',[_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(_vm._s(item.tab)+" ")],1)])}),1)]},proxy:true}])},[_c(VToolbarTitle,{staticClass:"text-h4 flex white--text text-xs-center mt-2 pt-0"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"1"}},[_c(VBtn,{staticClass:"mx-1 mt-0 pt-0",attrs:{"fab":"","small":"","dark":"","color":"green"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.getMortgageHelp.apply(null, arguments)}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("fa-solid fa-question")])],1)],1),_c(VCol,{staticClass:"pt-2 d-flex justify-center",attrs:{"cols":"9"}},[_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v("  Mortgage Calculators")])]),_c(VCol,{attrs:{"cols":"2"}},[_c('Bookmark',{staticClass:"mt-0 pt-0",attrs:{"link":"mortgageCalculators"}})],1)],1)],1)],1),_c(VWindow,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c(VWindowItem,{key:item.tab},[_c(item.content,{tag:"component",staticClass:"mt-0",attrs:{"id":"item.name"}})],1)}),1),_c('MortgageWiki')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }