import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mx-auto"},[_c(VDialog,{attrs:{"persistent":"","width":(this.isMobile? 350: 550)},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"height":"575px"}},[_c(VCardTitle,{staticClass:"ma-0 pa-0"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"ma-0 pa-0",attrs:{"dense":"","no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VBanner,{attrs:{"color":this.titleColor,"elevation":"11"}},[_c(VToolbarTitle,{staticClass:"font-weight-bold white--text"},[_vm._v(_vm._s(_vm.dialog_title))])],1)],1)],1)],1)],1),_c(VCardText,{staticClass:"ma-0 pa-0",attrs:{"scrollable":""}},[_c(VContainer,{attrs:{"fill-height":""}},[_c(VFlex,{attrs:{"fill-height":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{ref:"PutsWikiViewer",domProps:{"innerHTML":_vm._s(this.text)}})])],1)],1)],1)],1),_c(VDivider,{staticClass:"ma-0 pa-0"}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"primary","text":""},on:{"click":function($event){$event.preventDefault();return _vm.closeDialog.apply(null, arguments)}}},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.$t("disclaimer_notice.button_txt")))])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }