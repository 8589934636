import { render, staticRenderFns } from "./AboutDateWiki.vue?vue&type=template&id=464fbe15&scoped=true&"
import script from "./AboutDateWiki.vue?vue&type=script&lang=js&"
export * from "./AboutDateWiki.vue?vue&type=script&lang=js&"
import style0 from "./AboutDateWiki.vue?vue&type=style&index=0&id=464fbe15&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "464fbe15",
  null
  
)

export default component.exports