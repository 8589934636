import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showAds && _vm.showAds === 'true')?_c('div',[_c(VCard,{attrs:{"flat":""}},[_c(VDivider),_c(VCardText,{staticClass:"ma-0 pa-0"},[_c(VContainer,{attrs:{"fill-height":""}},[_c(VFlex,{attrs:{"fill-height":""}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column justify-space-between align-center"},[_c(VImg,{attrs:{"width":"85%","max-width":"95%","height":"80%","max-height":"95%","src":_vm.Urls[ _vm.currentAd ].url}})],1)])],1)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }