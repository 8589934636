
<!--

   TradingView Financials
background-color: #21D4FD;
background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);

background-color: #FF3CAC;
background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);

-->
<template>
  <!-- IMPORTANT UPDATE  
      'key' to track back-end symbol changes - allowing user to select new ticket from list to 
       to get updated display of Stock Fundimentals from TV   "fa-solid fa-magnifying-glass"

       [ https://github.com/sweetalert2/sweetalert2/issues/646 ] - popup ?  align-center justify-center
       <v-container class="fill-height  no-print" > 
  -->
  <v-container class="fill-height printable" > 
    <v-layout class="mt-1 mb-5" column>
   <!-- <v-flex row align-center>   -->   
      <!-- min-width="225" width="550" max-width="600" -->
      <!-- DESKTOP SECTION -->
      <div v-if="isMobile === false" >
          <v-card class="mx-auto" height="650" width="65%" hover >
              <v-toolbar color="primary" dark flat >
                <v-row no-gutters dense>
                    <v-col cols="2" class="mt-3">
                        <v-btn @click.prevent="searchStockSymbols"><v-icon left>fa-solid fa-magnifying-glass</v-icon>Search</v-btn>
                    </v-col>
                    <v-col cols="8" class="mt-5 d-flex justify-center">
                        <v-toolbar-title class="pa-0 mx-auto text-h5 font-weight-bold" >Financial Analysis</v-toolbar-title>
                        <!-- commented out 10.22.23 - does not work with iFrames CORS issues -->
                        <!-- <v-btn small icon @click.prevent="print"><v-icon left>fa-solid fa-print</v-icon></v-btn> -->                    
                    </v-col>
                    <v-col cols="2" class="mt-3">
                        <Bookmark link="Fundamentals"/> 
                    </v-col>
                </v-row>
                <template v-slot:extension>
                    <v-tabs v-model="tab" background-color="primary" dark>
                        <v-tab v-for="item in items" :key="item.tab" @click.prevent="updateSelectedTab( item.id )">
                             <v-icon left>{{item.icon}}</v-icon>
                                {{ item.tab }}
                        </v-tab>
                    </v-tabs>
                </template>
              </v-toolbar> 
              <v-tabs-items v-model="tab" id="fTabs" >
                  <v-tab-item v-for="item in items" :key="item.tab" >
                      <v-card flat class="ma-0 pa-0">
                          <v-card-text class="ma-0 pa-0">
                              <!-- pass ticker update props to child to keep all components in sync -->
                              <component id="item.name" :ticker="symbol" :is="item.content" ></component>
                          </v-card-text>
                      </v-card>
                  </v-tab-item>
              </v-tabs-items>
          </v-card>
      </div>
      <div v-else>
          <!-- MOBILE SECTION -->
          <v-card class="mt-1 flexcard" min-width="95%" width="99%" :height="this.cardHeight" hover >
                <v-toolbar height="75px" dark flat color="#4286f4" >
                    <v-row>
                      <v-col cols="2" class="mt-1">
                          <v-btn icon @click.prevent="searchStockSymbols"><v-icon left>fa-solid fa-magnifying-glass</v-icon></v-btn>
                      </v-col>
                      <v-col cols="8" class="mt-2 d-flex justify-center">
                          <v-toolbar-title class="ma-0 pa-0 mx-auto text-h6 font-weight-bold" >{{this.symbol}}&nbsp;Fundamentals</v-toolbar-title>
                      </v-col>
                      <v-col cols="2" class="mt-3 ml-0">
                          <Bookmark link="Fundamentals"/>
                      </v-col>
                    </v-row>
                </v-toolbar>                     
                <v-row>
                    <v-col class="ml-0" cols="12">
                        <!-- mobile report bar 8 -->
                        <v-btn  color="#84FFFF" block v-if="isMobile === true" @click.stop="drawer = !drawer" >
                            <v-icon left >{{ listIcon }}</v-icon> Available Analysis
                        </v-btn>  
                    </v-col>
                    <!-- for mobile printing - not enabled - CORS issues printing iFrames
                      <v-col class="ml-0" cols="3">                    
                        <v-btn color="#84FFFF" block v-if="isMobile === true" @click.prevent="print" >
                            <v-icon left >fa-solid fa-print</v-icon></v-btn>  
                      </v-col>   
                    -->
                      <!-- *** feed display section - all rss data will be displayed in the RssFeedViewer *** -->  
                      <!-- height adjusted to fit within the host parent card; desktop or mobile --> 
                </v-row>
                <v-row>
                  <v-col cols="12">
                       <!-- default load 1st item in array -->
                       <component v-bind:ticker="symbol" v-bind:is="this.mobileComponent" ></component>
                  </v-col>
                </v-row>               
          </v-card>
          <!-- *** START Left Drawer for small screens - uses tab v-card section to display report *** -->
          <v-navigation-drawer absolute app v-model="drawer" color="blue" theme="dark" class="overflow-y-auto mt-0 pt-0 pb-30 mb-30" temporary >
              <v-row dense no-gutters class="mb-0 pb-0">
                  <v-col class="text-md-center" cols="12">               
                      <h4 class="ml-auto mb-0 pb-0 white--text">{{ symbol }}</h4>  
                  </v-col>
              </v-row>
              <v-divider dark style="height:3px;background-color:aqua" class="ma-0 pa-0" ></v-divider>             
              <v-list nav dense class="ma-0 pa-0">
                <v-list-item-group v-model="group" class="text-left" no-action sub-group>
                  <!-- loop through both feed lists to generate menu for drawer -->
                  <v-list-item v-for="item in items" :key="item.id" @click.prevent="updateMobileComponent( item.id  )">
                      <!-- highlight first report id: 0 as 'active' link -->
                      <div v-if="item.id === 0">
                          <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">                
                            <v-icon id="fIcon" left class="initHighlighted" >{{ item.icon }}</v-icon>
                          </v-list-item-icon>     
                      </div>
                      <div v-else>
                          <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">                
                              <v-icon left >{{ item.icon }}</v-icon> 
                          </v-list-item-icon>     
                      </div>                  
                      <v-list-item-content :style="{'align-items':'left'}">
                          <v-list-item-title id="listFont" class="ListItemClassInActive" >{{ item.tab }}</v-list-item-title>
                      </v-list-item-content>                      
                  </v-list-item> 
                </v-list-item-group>
              </v-list>
              <v-divider dark style="height:3px;background-color:aqua" ></v-divider>              
          </v-navigation-drawer>        
          <!-- *** END Left Drawer Section *** -->   
          <!-- <div class="g-recaptcha" data-sitekey="6LfTyoAoAAAAAP2kvZh1db1bQUl5gWnrOQMKAVa6"></div>  -->       
      </div>
      <!-- RECAPTCHA TOOL 
            <recaptcha ref="recaptcha" @verify="submit"></recaptcha>
      -->
  <!--   </v-flex> -->
    </v-layout>
    <!-- <printIframe msg-title="Print Analysis" msg-text="This will print the currently selected analysis!" />-->
  </v-container>
</template>

<script>
    // listen for and allow broadcasts to components
    import { eventBus } from '@/main'  // event bus communication for registered components

    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"  

    // import jquery to enable mask to ticker search
    //import $ from "jquery"
     
    // bottom ads
    //import ToolsBottomAds from '@/components/ads/ToolsBottomAds.vue';

    // user bookmarking component   ToolsBottomAds, Bookmark 
    import Bookmark              from '@/components/AddToFavorites.vue'    

    // tab components
    import FinancialWidget       from '@/components/widgets/FundamentalsWidget.vue'
    import SymbolOverviewWidget  from '@/components/widgets/SymbolOverviewWidget.vue'
    import ProfileWidgetVue      from '@/components/widgets/ProfileWidget.vue'
    import TopStoriesWidget      from '@/components/widgets/TopStoriesWidget.vue'

    // print component
    //import printIframe           from '@/components/printIframe.vue'

    // captcha
    //import Recaptcha              from '@/components/Recaptcha-tool.vue'
    //import VueThreejs             from 'vue-threejs'

    // tradingview console errors
    var expectedOrigin = "https://s3.tradingview.com";
    var iframes;

    export default {
      components: { FinancialWidget, SymbolOverviewWidget, ProfileWidgetVue, TopStoriesWidget, Bookmark },
      data: () => ({
        valid: false,
        tab: null,
        tabName: null,
        group: null,
        drawer: false,         
        locale: i18n.locale,
        symbol: "AAPL",
        isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))),
        mobileRptBar: i18n.t('rss_mobile_reportbar'),
        listIcon: 'fa-solid fa-list',                     
        value: null,
        cardMinWidth: "85%",
        cardWidth: "85%",
        cardMaxWidth: "95%",
        cardMinHeight:850,
        cardHeight: 900, 
        itemSelected: 0,
        showAdsID : 0,
        hideAdsID : 0,               
        time_delay: Number( process.env.VUE_APP_ADS_DELAY ), 
        items: [
            { id: 0, tab: 'Overview',    content: 'SymbolOverviewWidget', name: 'tvOverview',   icon:'fa-solid fa-square-poll-vertical' }, 
            { id: 1, tab: 'Financials',  content: 'FinancialWidget',      name: 'tvFinancials', icon:'fa-solid fa-money-check-dollar' },
            { id: 2, tab: 'Profile',     content: 'ProfileWidgetVue',     name: 'tvProfile',    icon:'fa-solid fa-file-contract' }, 
            { id: 3, tab: 'Top News',    content: 'TopStoriesWidget',     name: 'tvTopStories', icon:'fa-regular fa-newspaper' },                                           
        ],
        // mobile only
        dotMenu: [ { title: 'Bookmark' },{ title: 'Print' } ],
        // allow mobile to switch components viewed from left drawer by selecting array id
        mobileComponent: null,
        errors: {},
      }),
      // user defined methods  <i class="fa-regular fa-newspaper"></i>
      methods: {
        forceRerender() {
           // forces component to reload itself, instead of a complete page reload ... 'cool'
           // tied to 
           this.componentKey = this.symbol;  
        },
        updateSelection( tkr ) {
           this.symbol = tkr;
           // reload component since all the tabs are different widgets           
           this.forceRerender();
        },
        getCurrentTabName() {
           return this.tab.name;
        },
        updateSelectedTab( id ) {
           this.itemSelected = id;
           //console.log( "Current Tab id: "+id );
           // clear any Ads - if set in .env file
           // close right drawer - if open
           //eventBus.$emit("hide-side-ads","hideSideAds");      
           this.showHideAds();           
        },
        adCleanUp() {
          // close right drawer
          eventBus.$emit("hide-side-ads","hideSideAds");  
          // stop any timeout events by assigned ID - before creating any new ones
          clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );
        },
        showHideAds() {
          this.adCleanUp();
          var delay = this.time_delay;
          // show ads - if enabled in .env file - defined in BottomSheetAds component - record id for clearing
          this.showAdsID = setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), delay );  // record timeout ID -  for clearing
          //console.log("showAdsID = "+this.showAdsID);    
          delay = delay * 3;
          // send event to parent (AppBar.vue) to close Ads right drawer x3 delay
          this.hideAdsID = setTimeout( ()=> eventBus.$emit("hide-side-ads","hideSideAds"), delay );   // record timeout ID - for clearing  
          //console.log("shideAdsID = "+this.hideAdsID);    
        },              
        // mobile (only) component selecting --*
        updateMobileComponent( id ) {
          // display loading
          this.$loading(false);
          // set selected id 
          this.itemSelected = id;
          // *** update selected component by passed Id to get actual component ***
          this.mobileComponent = this.items[ this.itemSelected ].content;
          // remove highlighed default mobile selected icon - must be a better way ...
          if( this.isMobile && id != 0 ) {
              var icon = document.getElementById("fIcon");
              icon.classList.remove("initHighlighted");
          }              
          // close drawer after selection --***
          this.drawer = false;  
          // check for Ads
          this.showHideAds();
          setTimeout( ()=> this.$loading(false), 1500 );       
        },
        disablePastDates(val) {
           return val >= new Date( this.startDate ).toISOString().substr(0, 10)
        },
        showDateHelp() {
            // emits global event for component - to open help document from button
            eventBus.$emit("show-date-wiki", "showDateHelp");
        }, 
        searchStockSymbols() {
            //  var selectOptions = [];
            //  for (let i = 0; i < data.length; i++) {
            //      selectOptions[data[i].id_calc] = data[i].surname + " " + data[i].name;
            // clear ads if showing
            this.adCleanUp();
            // search popup - here     
            this.$swal({
                title: 'Stock Search Tool',
                text: 'Enter Any Valid Stock Symbol ie: AAPL',
                id: "tickerSearch",
                inputAutoFocus: true,                
                inputAutoTrim: true,
                input: 'text',
                inputPlaceholder: 'Stock Symbol',
                inputAutoFocus: true,
                inputAttributes: { autocapitalize:"on", minlength: 1, maxlength: 7 },   
                showLoaderOnConfirm: true,
                confirmButtonText: 'Search',
                showCancelButton: true,
                footer: "Exchanges: NYSE NASDAQ AMEX",
              }).then((result) => {
                if( result.value && result.value != "" ) {
                    this.$loading(true);
                    try {
                        // check for any XSS tags inserted in form - from utils.js
                        if( checkForSpecialChar( result.value ) ) {
                          // ignore if any special characters are found in input
                          //console.log('Ignored XSS Attack: '+ result.value );
                          // close first popup search
                          this.$swal.close();
                          this.$swal({ icon: "error", title: "Oops...", text: "Invalid Ticker Symbol!", footer: 'Only Valid Stock Tickers Accepted' });                          
                        } else {
                            var ticker   = result.value.trim().toUpperCase();
                            //console.log("Symbol parsed returned: -> "+ticker);
                            // enter possible ticker submitted by user
                            if( ticker ) {
                                this.symbol = ticker;
                                // *** post update to all listening sub components **********************
                                eventBus.$emit("ticker-update", this.symbol );  
                                // save to localStorage to bring up on a 'refresh' or restart of App
                                localStorage.setItem( "lastSeachedSymbol", this.symbol );
                                // update statistics from TV
                                setTimeout( ()=> this.updateSelection( this.symbol ), 1500 );                                       
                                //***********************************************************************                           
                            }
                            // close first popup search
                            this.$swal.close();                            
                        }
                    } catch(err) { console.log( err ); }
                    // 1 sec delay - then remove loading indicater       
                    setTimeout( ()=> this.$loading(false), 1500 );
                    //console.log( "Selected: "+ result.value   showModal:true, ); 
                }
                // reshow Ads if enabled ..
                this.showHideAds();
             })
        },
      },
      // life-cycle - looks for mount
      beforeMount() {
        // show loading spinner - just before component is mounted to app
        this.$loading(true);
        
        // send event to parent (AppBar.vue) to close Ads right drawer - if any
        eventBus.$emit("hide-side-ads","hideSideAds");  

        // stop any timeout events by assigned ID - before creating any new ones
        clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );   

        // check for last seached ticker to overwrite default AAPL
        var lastSeachedSymbol = localStorage.getItem("lastSeachedSymbol");
        if( lastSeachedSymbol && lastSeachedSymbol != "" && lastSeachedSymbol != null ) {
            this.symbol = lastSeachedSymbol;
        }

      },      
      mounted() {
        // update  selected id 0 component
        this.mobileComponent = this.items[ this.itemSelected ].content;

        // 1.5 sec delay - then remove loading indicater       
        setTimeout( ()=> this.$loading(false), 1500 );

        // check for setting - time out is set in .env file for ads 
        if( this.time_delay <= 0 ) { this.time_delay = 2500; }

        // show ads - if enabled in .env file 
        this.showHideAds();
  
        // *** TradingView Widget Error Handler  ***
        window.addEventListener("message", (e) => {
        if( e.origin !== expectedOrigin ) return;
            const sourceIframe = iframes.find( (iframe) => iframe.contentWindow === e.source, );
            console.log(sourceIframe);
        })          
      },
      beforeUnmount() {
        // send event to parent (AppBar.vue) to close Ads right drawer - if any
        eventBus.$off("hide-side-ads","hideSideAds");  
        // stop any timeout events by assigned ID - before moving on to different component
        clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );       
      }     
    }
</script>

<style scoped>
 .v-tab--active {
    background-color:rgba(116, 219, 185, 0.3); 
 }
 .v-list-item__content {
     text-align: left;
     color: white; 
     font-weight: bold;     
 }
 .v-list-item__title {
     padding-left: 10px;
     font-weight: bold;        
 }
 
  .aa {
    background: rgb(25, 0, 255) !important;
  }
  .ListItemClassActive {
    color: #0556f9;
  }
  .ListItemClassInActive {
    color: #f1f8fa;
   }
  .v-list-item__icon v-list-group__header__prepend-icon {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
  } 
  .v-list-item--active .subListIcon .v-icon {
     background-color: lightgreen;
  }
  .v-list-item--active .v-list-item__title {
     color: rgb(253, 250, 250);
     font-weight: bold;
  } 
  .subListIcon {
    font-size: 16px;
    width: 40px;    
  }  
  .subListItem {
    font-size: 18px;
  }   
  .initHighlighted {
    background-color: lightgreen;
  }   
  #listFont {
     font-size: 16px;
     padding-top: 5px;
  }     
</style>