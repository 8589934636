
<!--
       Margin View Component part of tools menu
-->
 <template>
  <v-container class="fill-height printable" > 
    <v-layout justify-center align-center>      
        <v-card class="mx-auto my-auto" min-width="225" max-width="850" hover >
            <v-img class="white--text align-center" width="auto" height="75px" 
                          src="@/assets/banner-binary_100.jpg" cover >
                <v-card-title >
                    <v-row dense>
                        <v-col cols="1">
                            <!-- user help button -->
                            <v-btn class="mx-1" fab small dark color="green" @click.prevent.stop="showMarginHelp" >
                                <v-icon dark>fa-solid fa-question</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="pt-2 d-flex justify-center" cols="9">
                          <span class="text-h5 font-weight-bold">&nbsp;&nbsp;{{$t("margin_page.title")}}</span>
                        </v-col>
                        <v-col cols="2">
                            <!-- track bookmarks -->
                            <Bookmark link="margin"/>
                        </v-col>                    
                    </v-row>
                    <!-- create help wiki here -->
                    <MarginHelpWiki />
                </v-card-title>          
            </v-img>
            <v-card-subtitle >{{$t("margin_page.subtitle")}}</v-card-subtitle>
            <v-card-text class="text--primary">
              <v-form ref="queryForm" @submit.prevent.stop v-model="valid" >
                  <v-container fill-height >
                    <!-- ticker block removed: :rules="call_tickerRules" -->
          <!-- PM removed 4.19.23 - Yahoo Ticker Url 403 - until further notice ---              
                    <v-row dense justify="center" class="mb-0 pb-0">
                      <v-col cols="10" sm="10" md="8" lg="6" >
                          <v-text-field v-model="ticker"
                            :label="$t('margin_page.ticker_lbl')"
                            prepend-icon="mdi-symbol"
                            :maxlength="7"
                            :counter="10"
                            @input="ticker= ((ticker != null)?ticker.toUpperCase():null)" 
                            @change="symbolLookup"
                            clearable 
                            @click:clear="clearTickerPrice"  
                            hint="Optional - Not Required" 
                            persistent-hint                                                                        
                            dense
                          ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense justify="center" class="mb-0 pb-0">              
                      <v-col cols="10" sm="10" md="8" lg="6">
                          <v-text-field v-model="tickerPrice"
                            readonly
                            prepend-icon="mdi-currency-usd"                    
                            :label="$t('margin_page.market_price_lbl')"
                            hint="Non Editable - Info Only"
                            persistent-hint                      
                            dense
                          ></v-text-field>
                      </v-col>               
                    </v-row>
          -->              
                    <!-- ticker information panel - pops up when a price is found -->
                    <v-row dense v-show="this.tickerPrice" class="ma-0 pa-0">
                      <v-col cols="12">
                        <!-- boolean passed to update data in the component -->
                        <TickerInfoDisplay :jsonData="this.jsonData" />
                      </v-col>
                    </v-row>  
                    <!--Main Margin Fields Section -->
                    <v-row dense justify="center" class="mt-1 pt-1" >
                      <v-col cols="10" sm="10" md="8" lg="6" >
                        <v-currency-field 
                          name="margin_amount_borrowed" 
                          :label="$t('margin_page.margin_amount_brwd')"                    
                          v-model="margin_amount_borrowed"
                          :rules="amount_borrowedRules"                                        
                          :decimalLength=2                
                          prepend-icon="mdi-bank"
                          @blur="update_margin_amount_borrowed($event)"                                  
                          required
                        />
                      </v-col>
                    </v-row>              
                    <v-row dense justify="center">
                      <v-col cols="10" sm="10" md="8" lg="6" >
                        <v-currency-field 
                          :decimalLength=0   
                          :valueAsInteger="true"           
                          name="days_borrowed"                
                          prepend-icon="mdi-calendar-multiselect"
                          :label="$t('margin_page.margin_days')"    
                          v-model="days_borrowed"
                          :rules="days_borrowedRules"  
                          @blur="update_margin_days_borrowed($event)"              
                          required
                        />
                      </v-col>
                    </v-row>                      
                    <v-row dense justify="center">
                      <v-col cols="10" sm="10" md="8" lg="6" >
                        <v-currency-field 
                          name="margin_rate" 
                          :label="$t('margin_page.margin_rate')"                    
                          :decimalLength=2
                          prepend-icon="mdi-percent"
                          v-model="margin_rate"
                          :rules="margin_rateRules" 
                          @blur="update_margin_rate($event)"                                         
                          required
                        />
                      </v-col>       
                    </v-row>
                  </v-container>
                </v-form>
                <!-- ******************* calculated bottom sheet info box ********************************* -->
                  <div v-if="displayInfo">
                    <v-layout align-center>
                    <!-- <v-flex xs12 sm12 md8 lg6 offset-md2> -->
                        <v-card class="mx-auto" min-width="200" >
                          <v-toolbar dense color="green lighten-1">
                            <v-app-bar-nav-icon></v-app-bar-nav-icon>
                            <v-toolbar-title class="white--text resultsTitle">{{this.results_title}}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn @click="displayInfo=false" icon>
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-data-table disable-sort disable-pagination
                            :headers="headers"
                            :items="fields"
                            :hide-default-header="true"
                            :hide-default-footer="true"
                            class="elevation-1"
                            no-data-text="No data"                    
                          ></v-data-table>
                        </v-card>
                      <!-- </v-flex> -->
                    </v-layout>
                  </div>
                <!-- ********************************** end bottom sheet info *************************** -->
                <v-row dense justify="center">
                  <v-col cols="12" >
                      <ToolsBottomAds/>          
                  </v-col>
                </v-row>   
                <v-row dense justify="center">
                  <v-col cols="11" >
                     <SideDisclaimerNotice/>
                  </v-col>
                </v-row>                                     
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <!-- button section at bottom of call card -->
                <v-btn small :disabled="!valid" color="primary" text @click="updateCalculatedResultData">
                  <v-icon left dark>fa-solid fa-upload</v-icon>
                  <span style="font-size: 8px">{{$t("calls_page.button_send")}}</span>
                </v-btn>
                <v-btn color="orange" small class="ml-5 pl-5" text @click.prevent.stop="resetQueryOptions" >
                  <v-icon left dark>fa-solid fa-rotate-right</v-icon>
                  <span style="font-size: 8px">{{$t("calls_page.button_reset")}}</span>          
                </v-btn>
                <v-spacer></v-spacer>   
                <v-btn color="green" small text @click.prevent.stop="showMarginHelp"  >
                  <v-icon left dark>fa-solid fa-question</v-icon>
                  <span style="font-size: 8px">Help</span>          
                </v-btn>                                   
            </v-card-actions>
          </v-card>
     </v-layout>
  </v-container>        
</template>

<script>
    // listen for and allow broadcasts to components
    import { eventBus } from '@/main'  // event bus communication for registered components

    // use NY Date info for unigue key to sync with server
    var tNYDate = new Date(new Date().toLocaleString('en', {timeZone: 'America/New_York'}));  

    // ads bottom sheet - uses broadcast message to show ad
    //import BottomSheetAds from '@/components/BottomSheetAds.vue'
    import TickerInfoDisplay    from '@/components/tools/TickerInfoDisplay.vue' 
    import SideDisclaimerNotice from '@/components/SideDisclaimerNotice.vue' 
    import ToolsBottomAds       from '@/components/ads/ToolsBottomAds.vue'; 
    // wiki tool document used when user presses 'help' button
    import MarginHelpWiki       from '@/components/AboutMarginWiki.vue' 
    // user bookmarking component
    import Bookmark             from '@/components/AddToFavorites.vue'         

    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"      
    //import { AxiosError } from 'axios';

    export default {
      components: { TickerInfoDisplay, SideDisclaimerNotice, ToolsBottomAds, MarginHelpWiki, Bookmark },  
      data: () => ({
        valid: false,
        isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))),
        // reference current language locale
        locale: i18n.locale,            
        // ticker fields
        ticker: '',
        tickerPrice: null,
        jsonData: '',        
        //---------- SECURITY CHECKS SENT to SERVER -------------------------------*
           xKey: tNYDate.getFullYear()+(tNYDate.getMonth()+1)+(tNYDate.getDate()),
          x2Key: tNYDate.getFullYear()*(tNYDate.getMonth()+1)*(tNYDate.getDate()),          
        //-------------------------------------------------------------------------*  
        displayInfo: false,
        agreed_to_terms: false,
        margin_rate: '',
        days_borrowed: '',
        margin_amount_borrowed: '',        
        userConfirmedDates:false,
        results_title: 'Margin Results',
        // array to build data table - fields are updated before display - will be replaced on event call
        headers: [ { text: 'Calculated Fields', align: 'start', sortable: false, value: 'name', width: '50%' },
                   { text: 'Amount', value: 'value', width: '50%' },
        ],
        // ---> replaced block
        fields: [ { name: 'Daily Interest Charges US $', value: 0, },
                  { name: 'Total Interest Charges US $', value: 0, },
        ],
        errors: {},
            // FORM RULES SECTION - called by form fields for field validation
            // FORM RULES SECTION - called by form fields 
            days_borrowedRules: [
              v => !!v || i18n.t('margin_page.rules_no_days_req_msg'), 
              v => (v && parseInt(v.replace(/,/g,'')) > 0) || i18n.t('margin_page.rules_no_days_req_msg'),
              v => (v && parseInt(v.replace(/,/g,'')) <= 3650 ) || i18n.t('margin_page.rules_days_brwd_limit_msg')
            ],                    
            amount_borrowedRules: [
              v => !!v || i18n.t('margin_page.rules_brwd_amt_req_msg'),
              v => (v && parseFloat(v.replace(/,/g,'')) > 0.00) || i18n.t('margin_page.rules_brwd_amt_req_msg'),
              v => (v && parseFloat(v.replace(/,/g,'')) <= 100000000000) || i18n.t('margin_page.rules_brwd_amt_excd_msg')
            ],
            margin_rateRules: [
              v => !!v || i18n.t('margin_page.rules_mrgn_amt_req_msg'),               
              v => (v && parseFloat(v.replace(/,/g,'')) > 0.00) || i18n.t('margin_page.rules_mrgn_amt_req_msg'),
              v => (v && parseFloat(v.replace(/,/g,'')) < 100.00 ) || i18n.t('margin_page.rules_mrgn_amt_excd_msg')
            ], 
      }),
      methods: {
            /********************************************************* 
             *          calls internal servlet to retrieve data
             **************************************************************/
            symbolLookup() {
                if( this.ticker ) {
                  this.$loading(true);   
                  // save and pass onto Ticker display expansion box
                     this.clearTickerPrice();                                        
                  // make request to back-end    
                  this.axios.get('/ticker?symbol='+this.ticker,{headers:{'api-ref':this.xKey,'sc-ch-nx':this.x2Key}}).then((response) => {                        
                    // grab reference to returned json object
                    var res = response.data;
                    // store copy for passing to display box
                    this.jsonData = response.data;   
                    // **** throw error to popup message - invalid symbol ****
                    if( Number.parseFloat(res[0].regularMarketPrice) <= 0 ) {
                        throw new Error('Invalid Symbol; Not Found');
                    }
                    // *** trigger to show ticker info dialog ***
                    this.tickerPrice = res[0].regularMarketPrice; 
                    // store for -> TickerInfoDisplay template data
                  }).catch( err => { 
                      this.$loading(false),   
                      //show message error - clear price to hide previous dialog which is the trigger
                      this.tickerPrice = '',
                      // display message box
                      this.$swal.fire({
                        icon: 'error',
                        title: i18n.t('margin_page.ticker_srch_err_title'),
                        text: this.ticker+i18n.t('margin_page.ticker_srch_err_msg'),                        
                        footer: i18n.t('margin_page.ticker_srch_footer_msg'),
                      }).then((result) => {
                          if( result ) {
                            // clear data on error
                            this.clearTickerPrice();                                                                      
                          }
                      }) 
                      console.log(err);                         
                   });
                  // *** update screen from session storage after the promise ***
                  setTimeout( ()=> { 
                      this.$loading(false);                                               
                  }, 500 );                     
              }
            },
            // called when the 'x' is clicked on the 'ticker' lookup field
            // to clear the price which will hide the ticker stock information panel
            clearTickerPrice() { 
                this.tickerPrice='';
            },        
            // upload button clicked calls this to update default data table array with entered values before showing results
            updateCalculatedResultData() {
              try {
                // display 1 sec spinner - start
                this.$loading(true)  // show loading spinner 
                
                // Daily Interest Charge - [ =B4*(A4/360 ) ]  test block
                // calculate goup individual amounts first, removes embedded commas
                var amount = this.margin_amount_borrowed.toLocaleString(this.$i18n.locale).replace(/,/g,'');
                var days   = this.days_borrowed.toLocaleString(this.$i18n.locale).replace(/,/g,'');
                var margin = this.margin_rate.toLocaleString(this.$i18n.locale).replace(/,/g,'');

                // do final total calculations - formats Intl - then do finally calcs [ =B4*(A4/360) ]
                //Daily Interest Charge
                this.fields[0].value = this.numberWithCommas( (( margin/100) * (amount/360)).toFixed(2) );
                // Total Interest Charge - [ =B4*(A4/360 ) *D4 ]             
                this.fields[1].value = this.numberWithCommas( (((margin/100) * ( amount/360)) * days).toFixed(2) );
  
                // set margin cookie to store query information for screen values when user returns back to tab
                this.setMarginCookie();   

              } catch(err) { console.log(err)
              } finally {
                 //  remove spinner & redisplay bottom data table for user's review
                 setTimeout( ()=> { this.$loading(false), this.displayInfo=true }, 1000 );
              }
            },
            // *** form item calls on 'blur' to save form data to data store by each field
            update_margin_days_borrowed(event) {
              this.$store.commit( 'MARGIN_DAYS_BORROWED', event.target.value )
              //console.log('stored date: '+event.target.value);
            },
            update_margin_amount_borrowed(event){
              this.$store.commit( 'MARGIN_AMOUNT_BORROWED', event.target.value )
            },
            update_margin_rate(event){
              this.$store.commit( 'MARGIN_RATE', event.target.value )
            }, 
            // popup dialog    
            resetQueryOptions() {
              this.$swal({
                  title: i18n.t('calls_page.reset_title'),
                  text: i18n.t('calls_page.reset_txt'),
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: i18n.t('calls_page.reset_confirmButtonTxt'),
                  cancelButtonText: i18n.t('calls_page.reset_cancelButtonTxt'),
                  showCloseButton: false,
                  showLoaderOnConfirm: false
                }).then((result) => {
                  if(result.value) {
                    this.$refs.queryForm.reset();           // ???
                    this.$refs.queryForm.resetValidation();  // ???
                    this.displayInfo = false;
                    // <v-currency-field bug does not clear on form reset - call reset again
                    setTimeout( ()=> this.$refs.queryForm.reset(), 150 );
                    // reset form - remove any persistent store call data 
                    this.$store.commit('CLEAR_MARGIN_DATA');                   
                    // clear calculated results fields on report
                    this.fields[0].value = 0
                    this.fields[1].value = 0
                  }
                });
            },
            // formats numbers with commas, negative number wrapped with params
            numberWithCommas(num) {
              //return num < 0 ? '( '+num.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',').replace(/-/g,'')+' )' : num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              return new Intl.NumberFormat( this.$i18n.locale ).format(num);
            },
            // called on each query - cookie overwritten
            setMarginCookie() {
               var marginInfo = {
                  margin_days_borrowed: this.$store.getters.margin_days_borrowed,
                  margin_amount_borrowed: this.$store.getters.margin_amount_borrowed, 
                  margin_rate: this.$store.getters.margin_rate                                   
               }
               // *** store cookie - for later retrieval when app starts again - it's always overwritten on each query
               this.$cookies.set('marginInfo', JSON.stringify(marginInfo));                
            },
            showMarginHelp() {
               // emits global event for component - to open help document from button
               eventBus.$emit("show-margin-wiki", "showMarginHelp");
            },               
      }, 
      // life-cycle methods defined in this section - * bug found in Vue had to use before mount to load data
      beforeMount() {
        // get data from data store on page switches between tab selection - if any
        this.days_borrowed          = this.$store.getters.margin_days_borrowed;
        this.margin_amount_borrowed = this.$store.getters.margin_amount_borrowed;
        this.margin_rate            = this.$store.getters.margin_rate;  
        // show loading spinner - just before component is mounted to app
        this.$loading(true); 
        
      },
      mounted() {
        // .5 sec delay - then remove loading indicater       
        setTimeout( ()=> this.$loading(false), 500 );
        
        // show ads - if enabled in .env file - defined in BottomSheetAds component
        //setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), 2500 ); 

      },
      metaInfo() {
        return {
          title: "Stock-Insight's Option Margin Calculator",
          meta: [
            { name: 'robots', content: 'index,follow'},   
            { name: 'description', content: 'Stock Margin Options Calculator' },
            { name: 'keywords', content: 'option margin calculator, margins' },                 
            { property: 'og:type', content: 'website'}, 
            { property: 'og:site_name', content: 'www.Stock-Insight.net'},
            { property: 'og:title', content: "Stock-Insight's Margin Calculator"},                             
            {
              vmid: "description",
              name: "description",
              content: "A Stock Option 'Margin' Calculator for those 'what if' evaluations"
            }
          ],
          link: [
            {
              rel: 'canonical',
              href: '<https://www.stock-insight.net/>'
            }
          ]
        }
      }                    
    }
  </script>