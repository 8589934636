<!-- 
    desktop market trends menu replacement ( button ) used instead of tabs
-->
<template>
    <v-card class="mx-auto" max-width="300" tile>
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">   
               <v-btn color="primary" dark v-bind="attrs" v-on="on">
                 <v-icon left >mdi-chart-bar-stacked</v-icon>
                 Market Trends
               </v-btn>
            </template>
              <v-list class="text-left" dense color="grey">
                <v-list-item-group v-model="selectedItem" >
                  <v-list-item v-for="(item) in items" :key="item.id" :to="item.route" >
                      <v-list-item-icon class="pl-0 pr-1 ml-0 mr-1">
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="pl-0 pr-0 ml-0 mr-0">
                        <v-list-item-title v-text="item.title" ></v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
        </v-menu>              
    </v-card>
</template>

<script>
 /* very important to load menu items from javascript external data file    */
 import menuItems from '@/components/menus/Data_Arrays/trends_menu'

  export default {
      head: {
      script: [
        { src: '@/components/menus/Data_Arrays/trends_menu.js', async: true, defer: true }
      ],
    },      
    data: () => ({
        selectedItem: 0,
        items: menuItems
    }),
  }
</script>

<style scoped>
  .v-button {
     opacity: 0.5;
  }
  .v-menu__content {
     background: lightgray;
  }
  .v-list-item {
     background: lightgray;
     color: darkblue;
  }
  .v-list-item--active .v-icon {
     background-color: lightgreen;
  }
  .v-list-item--active .v-list-item__title {
     color: black;
     font-weight: bold;
  }  
  .v-card--link:focus {
      background: lightgray;
   }
</style>