/*
   External File that is imported into the main desktop and left App drawer menu items

   and changes here will change the desktop as well as mobile menu items since they both share these data elements

   tile: added to support new home page defined by bookmarks - display image of bookmark topic

*/

import i18n from "@/i18n"
    const baseUrl   = "/rs/utils/document?file=tiles/";
    const menuItems = [
        { id: '1tn',title: i18n.t("nav_trends.social"),       icon: 'mdi-alpha-s-circle', route:({name: 'SocialSentiment'}), tile: 'https://picsum.photos/id/11/500/300' },    
        { id: '2tn',title: i18n.t("nav_trends.top_trending"), icon: 'mdi-alpha-t-circle', route:({name: 'TopTrending'}),     tile: 'https://picsum.photos/id/11/500/300' },      
        { id: '3tn',title: i18n.t("nav_trends.most_changed"), icon: 'mdi-alpha-m-circle', route:({name: 'MostChanged'}),     tile: 'https://picsum.photos/id/11/500/300' },   
        { id: '4tn',title: i18n.t("nav_trends.social_stats"), icon: 'mdi-alpha-c-circle', route:({name: 'SocialStats'}),     tile: 'https://picsum.photos/id/11/500/300' },         
   ];

export default menuItems