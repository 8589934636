import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VLayout,{attrs:{"justify-center":"","align-center":""}},[_c(VCard,{staticClass:"mx-auto my-auto flexcard",attrs:{"width":this.cardWidth,"height":"100%","hover":""}},[_c(VImg,{staticClass:"white--text align-center",attrs:{"width":"auto","height":"75px","src":require("@/assets/currency_coins_100.jpg"),"cover":""}},[_c(VCardTitle,{staticClass:"justify-center"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"1"}}),_c(VCol,{staticClass:"pt-2",attrs:{"cols":"9"}},[_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Foreign Exchange")])]),_c(VCol,{attrs:{"cols":"2"}},[_c('Bookmark',{attrs:{"link":"forexRates"}})],1)],1)],1)],1),_c(VCardText,{ref:"tvParent",staticClass:"text--primary text-center"},[_c('div',{staticClass:"tradingview-widget-container"},[_c('div',{staticClass:"tradingview-widget-container__widget"}),_c('div',{staticClass:"tradingview-widget-copyright"},[_c('span',{staticClass:"blue-text"},[_vm._v("Exchange Rates")]),_vm._v(" by TradingView")]),_c('script',{attrs:{"type":"application/javascript","defer":"","src":"https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js","async":""}},[_vm._v(" { \"width\": \""+_vm._s(this.widgetWidth)+"\", \"height\": \""+_vm._s(this.widgetHeight)+"\", \"currencies\": [ \"EUR\", \"USD\", \"JPY\", \"GBP\", \"CHF\", \"AUD\", \"CAD\", \"MXN\", \"CNY\", \"NZD\" ], \"isTransparent\": false, \"colorTheme\": \"light\", \"locale\": \""+_vm._s(this.locale)+"\" } ")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }