<!-------------------------------------------------------------------------------
   Darqube NewsFeed [ https://darqube.com/solutions/widgets ] v-flex align-center |  v-flex justify-center align-center>
<iframe data-widget-name="" name="NewsWidget" src="https://darqube.com/external-embedding/news-widget?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3aWRnZXQiOnsibmV3cyI6WyJTZWVraW5nQWxwaGEiLCJUaGUgRWNvbm9taXN0IiwiRmluYW5jaWFsIFRpbWVzIiwiRGFpbHlmeCIsIlJldXRlcnMiXSwidHdfdWlkcyI6WyI2OTYyMDcxMyIsIjkyODc1OTIyNDU5OTA0MDAwMSIsIjYyNDQxMyIsIjE1MTEwMzU3IiwiMjAyNDM3NDQiXX0sIndfdHlwZSI6Ik5ld3NXaWRnZXQiLCJmZV9jZmciOnsiY21vZGUiOjAsImZjbHIiOiJyZ2JhKDI1NSwgMjU1LCAyNTUsIDEpIiwiYmciOiJyZ2JhKDIxLCAyNSwgMzAsIDEpIiwiaCI6NzY1LCJ3IjozMzAsImFzeiI6dHJ1ZSwiZnQiOiJkZWZhdWx0IiwiaHQiOiJub25lIiwidHJoIjpbXSwiY2hjIjoicmdiYSgyMzcsIDUwLCA5OCwgMSkiLCJjbiI6IiIsImxuZyI6ImVuIiwiaGRpY24iOmZhbHNlLCJ3dG1WIjp7InR5cGUiOiJEYXJxdWJlIiwiZW5hYmxlZCI6dHJ1ZX19LCJleHAiOjE2NzY3NjAyMjEsInN1YiI6ImFjY2VzcyJ9.97T4WPB6DLWMx-3Akt5Th2iybJc5C0igp2eVlaQJSqY" id="NewsWidget" :width="this.widgetWidth" height="900"></iframe>
-------------------------------------------------------------------------------->
<template>
  <v-container fluid class="fill-height" > 
    <v-layout class="mt-1 ma-0 pa-0" justify-center align-center>  
      <v-flex row align-center>    
        <v-card :color="'#26c6da'" class="mx-auto mb-15 flexcard outlined" :min-width="this.cardMinWidth" :width="this.cardWidth" :height="this.cardHeight" hover>       
          <v-card-title class="justify-center">
              <v-spacer></v-spacer>
            <span class="text-h5 black--text font-weight-black">Darqube News</span>
              <v-spacer></v-spacer>
            <Bookmark link="DarqubeNews"/>
          </v-card-title>
          <v-card-text >
            <iframe data-widget-name="" name="NewsWidget" src="https://darqube.com/external-embedding/news-widget?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3aWRnZXQiOnsibmV3cyI6WyJTZWVraW5nQWxwaGEiLCJUaGUgRWNvbm9taXN0IiwiRmluYW5jaWFsIFRpbWVzIiwiRGFpbHlmeCIsIlJldXRlcnMiXSwidHdfdWlkcyI6WyI2OTYyMDcxMyIsIjkyODc1OTIyNDU5OTA0MDAwMSIsIjYyNDQxMyIsIjE1MTEwMzU3IiwiMjAyNDM3NDQiXX0sIndfdHlwZSI6Ik5ld3NXaWRnZXQiLCJmZV9jZmciOnsiY21vZGUiOjAsImZjbHIiOiJyZ2JhKDI1NSwgMjU1LCAyNTUsIDEpIiwiYmciOiJyZ2JhKDIxLCAyNSwgMzAsIDEpIiwiaCI6NzY1LCJ3IjozMzAsImFzeiI6dHJ1ZSwiZnQiOiJkZWZhdWx0IiwiaHQiOiJub25lIiwidHJoIjpbXSwiY2hjIjoicmdiYSgyMzcsIDUwLCA5OCwgMSkiLCJjbiI6IiIsImxuZyI6ImVuIiwiaGRpY24iOmZhbHNlLCJ3dG1WIjp7InR5cGUiOiJEYXJxdWJlIiwiZW5hYmxlZCI6dHJ1ZX19LCJleHAiOjE2NzY3NjAyMjEsInN1YiI6ImFjY2VzcyJ9.97T4WPB6DLWMx-3Akt5Th2iybJc5C0igp2eVlaQJSqY" id="NewsWidget" :width="this.widgetWidth" :height="this.widgetHeight"></iframe>
          </v-card-text>
        </v-card>
      </v-flex>
      <!-------------------------------------------------------------->
    </v-layout>     
  </v-container>   
</template>
  
<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"  
    
    // listen for and allow broadcasts to components
    import { eventBus } from '@/main'  // event bus communication for registered components

    // user bookmarking component
    import Bookmark     from '@/components/AddToFavorites.vue'       

    export default {
      components: { Bookmark },
      data: () => ({
        valid: false,
        cardMinWidth: 650,
        cardWidth: "80%",
        cardMaxWidth: "95%",
        cardHeight: 1055,
        widgetWidth: "100%",
        widgetHeight: 975,         
        // determines on object creation if running on mobile device true or false
        isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),
        // grabs locale from environment setup in start of app
        locale: i18n.locale,
        mode: sessionStorage.getItem('mode'),
        showAdsID   : 0,
        hideAdsID   : 0,           
        time_delay: Number( process.env.VUE_APP_ADS_DELAY ),  // retrieve setting from .env file  
      }),
      methods: {
        hideAds() {
            // stop any timeout events by assigned ID - before creating any new ones
            clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );
            // hide any previous side Ad - if any
            eventBus.$emit("hide-side-ads","hideSideAds");          
          },
          showAds() {
            this.hideAds();
            // set variable delay
            var delay = this.time_delay;
            // show ads - if enabled in .env file - defined in BottomSheetAds component - record id for clearing
            this.showAdsID = setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), delay );   // record timeout ID -  for clearing
            delay = delay * 3;
            // send event to parent (AppBar.vue) to close Ads right drawer x3 delay
            this.hideAdsID = setTimeout( ()=> eventBus.$emit("hide-side-ads","hideSideAds"), delay );   // record timeout ID - for clearing      
          }
      },            
      beforeMount() {
        // show loading spinner - just before component is mounted to app
        this.$loading(true);
        this.hideAds();
        if( this.isMobile ) {
          //  this.cardWidth=375;
            this.cardWidth="100%"
            this.cardMinWidth=325;
            this.cardMaxWidth="99%";
            this.cardHeight=1055;
            this.widgetWidth ="100%";
            this.widgetHeight= 975;            
        }        
      },
      mounted() {
        // 1 sec delay - then remove loading indicater       
        setTimeout( ()=> this.$loading(false), 1500 ); 

        // check for setting - time out is set in .env file for ads 
        if( this.time_delay <= 0 ) { this.time_delay = 2500; }
        // show ads - if enabled in .env file - defined in BottomSheetAds component
        this.showAds();
      },
      beforeUnmount() {
          // send event to parent (AppBar.vue) to close Ads right drawer
         this.hideAds();
      },
      metaInfo() {
        return {
          title: "Stock-Insight's Darqube News Widget - World Financial News",
          meta: [
            { name: 'robots', content: 'index,follow'},
            { property: 'og:type', content: 'website'}, 
            { property: 'og:site_name', content: 'www.Stock-Insight.net'},
            { property: 'og:title', content: "Stock-Insight's Darqube Widget - Financial News Live"},                             
            {
              vmid: "description",
              name: "description",
              content: "Darqube Widget - Financial News Live"
            }
          ],
          link: [
            {
              rel: 'canonical',
              href: '<https://www.stock-insight.net/>'
            }
          ]
        }
      }             
    }
</script>

