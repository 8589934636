import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[(_vm.showSearch)?_c('div',[_c(VToolbar,{attrs:{"color":"white","dark":"","flat":"","width":"auto","height":"25px"}},[_c(VToolbarTitle,{staticClass:"text-left text-h6"},[_c(VBtn,{staticClass:"ml-1",attrs:{"rounded":"","small":"","dark":"","color":"blue"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.searchRecords.apply(null, arguments)}}},[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v("fa-solid fa-magnifying-glass-location")]),_vm._v("Search Filings ")],1)],1)],1)],1):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.rssFeed)}})])
}
var staticRenderFns = []

export { render, staticRenderFns }