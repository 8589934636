/*
  provided from [https://medium.com/i18n-and-l10n-resources-for-developers/the-ultimate-guide-to-vue-localization-with-vue-i18n-bf98b1d40c65]
  to detect locale from browser settings

*/
export default function getBrowserLocale(options = {}) {
    const defaultOptions = { countryCodeOnly: false }
  
    const opt = { ...defaultOptions, ...options }
  
    const navigatorLocale =
      navigator.languages !== undefined
        ? navigator.languages[0]
        : navigator.language
  
    if (!navigatorLocale) {
      return undefined
    }
  
    const trimmedLocale = opt.countryCodeOnly
      ? navigatorLocale.trim().split(/-|_/)[0]
      : navigatorLocale.trim()
  
    return trimmedLocale
  }