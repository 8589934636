/***************************************************************************
 
    the router controls access to all the vue components - once defined
  [ https://router.vuejs.org/guide/essentials/history-mode.html  ]

****************************************************************************/
import Vue from 'vue'
import VueRouter from 'vue-router'

//import { createRouter, createWebHistory } from 'vue-router'
//import createWebHashHistory from 'vue-router'
//  import { animals } from 'js/list.js'
//[ https://stackoverflow.com/questions/58001805/how-do-i-loop-through-an-array-in-an-external-js-file-using-vuejs-cdn  ]
// Tools folder - defined routes for application - home defaults to 'Calls' route
import CallsView           from '@/views/tools/CallsView.vue'
import PutsView            from '@/views/tools/PutsView.vue'
import MarginView          from '@/views/tools/MarginView.vue'
// holds multiple calculators for Mortgage Lending
import MortgageCalculators from '@/views/tools/MortgageCalculators.vue'

import DateTimeView        from '@/views/tools/DateTimeView.vue'
import StockCalculatorView from '@/views/tools/StockPercentagePriceView.vue'
import FundamentalsWidget  from '@/views/tools/FinancialsWidget.vue'

// tradingview charts
import MarketOverview      from '@/views/charts/MarketOverview.vue'
import StockMarket         from '@/views/charts/StockMarket.vue'
import ForexRates          from '@/views/charts/ForexRates.vue'
import EconomicCalendar    from '@/views/charts/EconomicCalendar.vue'
import ForexHeatMap        from '@/views/charts/ForexHeatMap.vue'
import DataWatch           from '@/views/charts/MarketDataWatch.vue'
import SymbolChart         from '@/views/charts/ChartWidgetView.vue'
import CryptoMarket        from '@/views/charts/CryptoView.vue'
import StockHeatMap        from '@/views/charts/StockHeatMap.vue'
import CryptoHeatMap       from '@/views/charts/CryptoHeatMap.vue'
// Benzinga Widget Does not work 'as is' even wrapped
//import FinlogixCalendar from '@/components/widgets/FinlogixEarningsCalendar.vue'
//import FinlogixInsight  from '@/components/widgets/FinlogixMarketInsights.vue'

// news RSS Feeds
import TradingViewSnaps    from '@/views/news/TradingViewsSnaps.vue'
import DarqubeNewsFeed     from '@/views/news/DarqubeNewsFeed.vue'
import MarketWatchNews     from '@/views/news/MarketWatchRssFeeds.vue'
import CNBCNews            from '@/views/news/CNBCRssFeeds.vue'
import PoliticoNews        from '@/views/news/PoliticoRssFeeds.vue'
//import TradingEconomicsNews from '@/views/news/TradingEconomicsRss.vue' - removed - now subscription only
import SEC_RssFeedsVue     from '@/views/news/SEC_RssFeeds.vue'
import WSJ_RssFeedsVue     from '@/views/news/WSJ_RssFeeds.vue'
import FED_RssFeedsVue     from '@/views/news/FED_RssFeeds.vue'
import NYT_RssFeedsVue     from '@/views/news/NewYorkTimesRssFeeds.vue'
import Economist_RssFeeds  from '@/views/news/EconomistRssFeeds.vue'
import CryptoRssFeeds      from '@/views/news/CryptoRssFeeds.vue'
import NasdaqRssFeeds      from '@/views/news/NasdaqRssFeeds.vue'
import NasdaqTraderAlerts  from '@/views/news/NasdaqTraderRssFeeds.vue'
import ReutersNews         from '@/views/news/ReutersRssFeeds.vue'
import RedditViews         from '@/views/news/RedditFeeds.vue'
import InvestingDC         from '@/views/news/InvestingDotCom.vue'

//import RssFeedExample from '../views/RssFeeds.vue'

// Market Trends - Rapid API Data
import SocialSentiment     from '@/views/trends/SocialSentiment.vue'
import TopTrending         from '@/views/trends/TopTrending.vue'
import MostChanged         from '@/views/trends/MostChanged.vue'
import SocialStats         from '@/views/trends/SocialStats.vue'

// Podcasts
import Barrons             from '@/views/podcasts/BarronsPodcastRss.vue'
import CNBC_Podcasts       from '@/views/podcasts/CNBCPodcastRss.vue'
import MarketPlace         from '@/views/podcasts/MarketPlacePodcastRss.vue'
import ZacksPodcasts       from '@/views/podcasts/ZACKSPodcastRss.vue'
import WSJPodcasts         from '@/views/podcasts/WSJPodcastRss.vue'

// video clips
import BloombergYTVideos   from '@/views/video/BloombergYTVideos.vue'
import CNBCYTVideos        from '@/views/video/CNBCYTVideos.vue'
import ReutersVideos       from '@/views/video/ReutersYTVideos.vue'
import YahooVideos         from '@/views/video/YahooYTVideos.vue'
import ZacksVideos         from '@/views/video/ZacksYTVideos.vue'
import arkVideos           from '@/views/video/ArkYTVideos.vue'
import ibdVideos           from '@/views/video/IBDVideos.vue'

// default home page 
import HomePage            from '@/views/HomePage.vue'

// setup router - then define the App's routes
Vue.use( VueRouter )

// application defined routes

// router set to 'abstract' to hide url changes - not currently implemented
//const router;

/**************************************************************************************************
 *              create routes data array from links data - associate defined components
 * 
 * 
 * Note: any changes to the WebApp 'routes' (links) here .. need to be added to the 'vue.config.js' file
 *       in the 'root' of the project. It creates the 'sitemap.xml' file required by Google's search engine.
 * 
 *       I did try to have only one javascript file with defined 'routes' that would update this array
 *       and the one for the 'vue.config.js' file ... following the design of the 'Menu' system. Which
 *       uses javascript files under 'src/components/menus/Data_Arrays' to create the main menu system for
 *       the 'desktop' and 'mobile' left drawer component (slide out menu).
 * 
 *       However after several attempts decided to leave this for later upgrades.
 * 
 *       Javascript in Vue (at this point, Vue 2) can only be imported into defined 'templates'.
 *       Without an external library like jQuery or other utility, importing javascript files into other javascript file,
 *       has it's limitations (although ES6 does use 'modules') .. this functionality should be available in later
 *       versions. 
 * 
 *       The only difference is this array, which creates the 'links' and the one in the 'vue.config.js' file
 *       which creates the 'sitemap' links is the  [ priority:  1.0,  changefreq: 'daily' ] elements required
 *       by Google's search engine. Any changes to either file should be replicated with these slight differences
 *       following the same design patterns in each file.
 *       
 *   PM  12.09.23
 *  [ https://github.com/vuejs/vue-router/issues/2913 ]
 *  [ https://stackoverflow.com/questions/72283424/nuxts-vue-router-how-to-differentiate-path-ending-with-and-without ]
 **************************************************************************************************/
 // *** Please Note: the first path element, must match the component name otherwise the bookmark feature will not work.
const routes = [
  {  path: '/',                     name: 'home',                 component: HomePage        ,pathToRegexOptions: { strict: true } }, 
  {  path: '/calls',                name: 'calls',                component: CallsView       ,pathToRegexOptions: { strict: true } },       
  {  path: '/puts',                 name: 'puts',                 component: PutsView             },    
  {  path: '/margin',               name: 'margin',               component: MarginView           }, 
  {  path: '/mortgageCalculators',  name: 'mortgageCalculators',  component: MortgageCalculators  },       
  {  path: '/datetime',             name: 'datetime',             component: DateTimeView         },    
  {  path: '/Fundamentals',         name: 'Fundamentals',         component: FundamentalsWidget   },      
  {  path: '/stockCalculators',     name: 'stockCalculators',     component: StockCalculatorView  },
  {  path: '/overview',             name: 'overview',             component: MarketOverview       }, 
  {  path: '/calendar',             name: 'calendar',             component: EconomicCalendar     },   
  {  path: '/symbolchart',          name: 'symbolchart',          component: SymbolChart          },     
  {  path: '/stockmarket',          name: 'stockmarket',          component: StockMarket          }, 
  {  path: '/stockHeatmap',         name: 'stockHeatmap',         component: StockHeatMap         },           
  {  path: '/forexRates',           name: 'forexRates',           component: ForexRates           }, 
  {  path: '/datawatch',            name: 'datawatch',            component: DataWatch            },      
  {  path: '/forexHeatMap',         name: 'forexHeatMap',         component: ForexHeatMap         }, 
  {  path: '/cryptoMarket',         name: 'cryptoMarket',         component: CryptoMarket         }, 
  {  path: '/cryptoHeatmap',        name: 'cryptoHeatmap',        component: CryptoHeatMap        }, 
  {  path: '/TradingViewSnaps',     name: 'TradingViewSnaps',     component: TradingViewSnaps     },                
  {  path: '/DarqubeNews',          name: 'DarqubeNews',          component: DarqubeNewsFeed      }, 
  {  path: '/marketWatchNews',      name: 'marketWatchNews',      component: MarketWatchNews      }, 
  {  path: '/cnbcNews',             name: 'cnbcNews',             component: CNBCNews             },  
  {  path: '/politicoNews',         name: 'politicoNews',         component: PoliticoNews         },  
//  {  path: '/tradingEconomicsNews', name: 'tradingEconomicsNews', component: TradingEconomicsNews },  
  {  path: '/SEC_News',             name: 'SEC_News',             component: SEC_RssFeedsVue      },  
  {  path: '/WSJ_News',             name: 'WSJ_News',             component: WSJ_RssFeedsVue      },
  {  path: '/FED_News',             name: 'FED_News',             component: FED_RssFeedsVue      }, 
  {  path: '/NYT_News',             name: 'NYT_News',             component: NYT_RssFeedsVue      },
  {  path: '/Economist_News',       name: 'Economist_News',       component: Economist_RssFeeds   }, 
  {  path: '/Crypto_News',          name: 'Crypto_News',          component: CryptoRssFeeds       }, 
  {  path: '/NasdaqRssFeeds',       name: 'NasdaqRssFeeds',       component: NasdaqRssFeeds       },    
  {  path: '/NasdaqTraderAlerts',   name: 'NasdaqTraderAlerts',   component: NasdaqTraderAlerts   }, 
  {  path: '/ReutersNews',          name: 'ReutersNews',          component: ReutersNews          },     
  {  path: '/RedditViews',          name: 'RedditViews',          component: RedditViews          },  
  {  path: '/InvestingDC',          name: 'InvestingDC',          component: InvestingDC          },                                                   
  {  path: '/SocialSentiment',      name: 'SocialSentiment',      component: SocialSentiment      }, 
  {  path: '/TopTrending',          name: 'TopTrending',          component: TopTrending          }, 
  {  path: '/MostChanged',          name: 'MostChanged',          component: MostChanged          },  
  {  path: '/SocialStats',          name: 'SocialStats',          component: SocialStats          }, 
  {  path: '/Barrons',              name: 'Barrons',              component: Barrons              },   
  {  path: '/CNBCPodcasts',         name: 'CNBC_Podcasts',        component: CNBC_Podcasts        },         
  {  path: '/MarketPlace',          name: 'MarketPlace',          component: MarketPlace          },  
  {  path: '/WSJPodcasts',          name: 'WSJPodcasts',          component: WSJPodcasts          }, 
  {  path: '/ZacksPodcasts',        name: 'ZacksPodcasts',        component: ZacksPodcasts        },              
  {  path: '/ArkUTVideos',          name: 'ArkUTVideos',          component: arkVideos            },
  {  path: '/BloombergYT',          name: 'BloombergYTVideos',    component: BloombergYTVideos    },      
  {  path: '/CNBC_Videos',          name: 'CNBCYTVideos',         component: CNBCYTVideos         }, 
  {  path: '/IBDYTVideos',          name: 'IBDYTVideos',          component: ibdVideos            },      
  {  path: '/Reuters_Videos',       name: 'ReutersYTVideos',      component: ReutersVideos        },         
  {  path: '/Yahoo_Videos',         name: 'YahooYTVideos',        component: YahooVideos          },   
  {  path: '/Zacks_Videos',         name: 'ZacksYTVideos',        component: ZacksVideos          },
  {  path: "/:pathMatch(.*)",       redirect: { name: "home" }                                    },
];

// create router from defined routes
const router = new VueRouter({
      //  mode: 'abstract',
      //mode: 'hash',
      mode: 'history',
      // base: process.env.BASE_URL, 
      routes,
      strict: true 
});

router.beforeEach((to, from, next) => {
  try {
    if( to.path === from.path ) {
      next(true) // Prevent the redundant navigation
    } else {
      next()     // Proceed with the navigation
    }

  } catch(err) 
    { console.log( "route Error:"+ err ) }
    finally {
       // make sure always to scroll window to 'top' of screen
      setTimeout( ()=> window.scrollTo( { top: 0, left: 0, behavior: 'smooth'} ), 1500 );  }
})

router.onError(err => {
     //print('<!-- router error: ' + err.message + ' -->')
     console.log( "Route Error: "+err );
});

// removed 'home' as default - so pages can be loaded from link url 07-18-23
// router.replace({ name: "home" }).catch(()=>{});

//const originalPush = router.prototype.push
//                     router.prototype.push = function push(location) {
//  return originalPush.call(this, location).catch(err => err)
//}

//      {
//        path: '/BloombergClips',
//        name: 'BloombergClips',
//        component: BloombergVideo
//      },

/*             
      {
        path: '/ZacksPodcasts',
        name: 'ZacksPodcasts',
        component: ZacksPodcasts
      },   
*/   

/*
router.beforeEach((to, from, next) => {
  try {
    if (to.path === from.path) {
      next(true) // Prevent the redundant navigation
    } else {
      next() // Proceed with the navigation
    }
  } catch(err) { console.log( err ) }
})

router.onError(err => {
  if (err.message.includes('redundant navigation')) {
    // Do nothing, since the navigation was already prevented

  } else {
    console.error(err)
  }
})
*/

//--- FINISH ROUTER SETUP-----*
export default router;

