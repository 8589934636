<!--------------------------------------------------------------------------

         Greed Home Page Gauge Chart - using Github
              'npm install gauge-chart'

         [ https://github.com/greetclock/gauge-chart ]

         * note: no longer supported

---------------------------------------------------------------------------->
<template>
  <div class="ma-0 pa-0">
      <v-row dense class="mt-1 mb-0"><v-col cols="12"><h5><b>{{ this.meter_title }}</b></h5></v-col></v-row>        
      <v-row class="ma-0 pa-0" dense><v-col class="ma-0 pa-0" cols="12"><div id="gaugeArea"></div></v-col></v-row>
      <!-- legend 'home made' using buttons -->
      <!-- <v-badge right :content=this.nowValue  color="#B03A2E" >Dreaded</v-badge>  -->
      <v-row dense align-content="center" justify="space-around" class="ma-0 pa-0" >
          <v-col cols="1"></v-col>
          <v-col cols="2" > 
             <v-badge right :color="this.now_color" :content=this.now_value><small>{{ this.now_label }}</small></v-badge> 
          </v-col>
          <v-col cols="2" >
             <v-badge right :color="this.prv_cls_color" :content=this.prv_cls_value ><small>{{ this.prv_cls_label }}</small></v-badge> 
          </v-col>
          <v-col cols="2" >
             <v-badge right :color="this.prv_wk_color" :content=this.prv_wk_value ><small>{{ this.prv_wk_label }}</small></v-badge> 
          </v-col>
          <v-col cols="2" >
             <v-badge right :color="this.prv_mn_color" :content=this.prv_mn_value ><small>{{ this.prv_mn_label }}</small></v-badge> 
          </v-col>                        
          <v-col cols="2" >            
             <v-badge right :color="this.prv_yr_color" :content=this.prv_yr_value ><small>{{ this.prv_yr_label }}</small></v-badge>
          </v-col>  
          <v-col cols="1"></v-col>                                                      
      </v-row>   
      <v-row dense align-content="center" justify="space-around" class="mt-1" >
         <v-col cols="12">
             <!-- draw sentiment data table buttons -->
             <v-btn class="ml-1 mr-1" v-for="item in items1" :key="item.id" elevation="5" x-small :to="item.route" >
                 {{ item.title }}
             </v-btn>
          </v-col>
      </v-row> 
      <v-row dense align-content="center" justify="space-around" class="mt-1 mb-0" >
         <v-col cols="12">
             <!-- draw sentiment data table buttons -->
             <v-btn class="ml-1 mr-1" v-for="item in items2" :key="item.id" elevation="5" x-small :to="item.route" >
                 {{ item.title }}
             </v-btn>
          </v-col>
      </v-row> 
      <v-divider ></v-divider>              
      <v-row dense align-content="center" justify="space-around" class="mt-0 mb-0" >
         <v-col cols="12">
             <!-- draw sentiment data table buttons -->
             <v-btn class="ml-1 mr-1" v-for="item in items3" :key="item.id" elevation="5" x-small rounded :to="item.route" >
                 {{ item.title }}
              </v-btn>
          </v-col>
      </v-row>                            
  </div>
</template>

<script> 
import i18n from "@/i18n"
/**
 * potential build issue outside PM Laptop
 * this require actually looks for /home/paulm/node_modules/gauge-chart folder and it's files
 * to build this Gauge component
 * 
 * .. need to develop using a different gauge meter from another library
 * 
 */
const GaugeChart = require('gauge-chart')   //  require('gauge-chart')
export default {
  name: 'gauge-chart',
  // -- data passed from parent --*
  props: {
     dataPoints: { type: Array }
  },    
  data() {
      return {
          meter_title: i18n.t("fear_greed_meter.meter_title"),
          gaugeArea: null,
          gaugeHeight: 350,
          gaugeWidth: 350,
          now_label:'NA',
          now_value: '0',
          now_color: '',
          prv_cls_label: 'NA',
          prv_cls_value: '0',
          prv_cls_color: '',
          prv_wk_label: 'NA',
          prv_wk_value: '0',
          prv_wk_color: '',
          prv_mn_label: 'NA',
          prv_mn_value: '0',
          prv_mn_color: '',
          prv_yr_label: 'NA',
          prv_yr_value: '0', 
          prv_yr_color: '',
          isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),              
          width:  window.innerWidth,
          height: window.innerHeight,
          // 1st item 'task' element seems to be required ...
          chartData: this.dataPoints, 
          // Properties of the gauge 
          gaugeOptions:{
              hasNeedle: true,
              needleColor: 'black',
              needleUpdateSpeed: 1000,
              arcLabels: [i18n.t("fear_greed_meter.arch_dreaded"),i18n.t("fear_greed_meter.arch_fear"),'',i18n.t("fear_greed_meter.arch_neutral"), '', '',i18n.t("fear_greed_meter.arch_greed"),i18n.t("fear_greed_meter.arch_avarice")],
              arcLabelFontSize: false,                
              //arcColors: ['rgb(44, 151, 222)', 'lightgray'],
              arcColors: [ 'red','red','#FFD700','lightblue', 'grey','lightblue','lightgreen','#006400', '#006400'],
              arcDelimiters: [ 0.1, 19, 39, 48.5, 50.5, 61, 81, 99.9 ],                
              rangeLabel: ['0', '100'],
              needleStartValue: 0,
              // these next 2 values are set from 'Now' value of the array
              //needleValue: 10,                
              centralLabel: 'NA',
          },
          // bottom button list and defined routes to social trends tables
          items1: [
            { id: 1, title: 'Alerts',     icon: 'mdi-alpha-a-circle', route:({name: 'NasdaqTraderAlerts'}) },  
            { id: 2, title: 'Markets',    icon: 'mdi-alpha-o-circle', route:({name: 'overview'})},  
            { id: 3, title: 'Financials', icon: 'mdi-alpha-f-circle', route:({name: 'datawatch'}) }, 
            { id: 4, title: 'Nasdaq',     icon: 'mdi-alpha-n-circle', route:({name: 'NasdaqRssFeeds'}) },            
          ],   
          items2: [
            { id: 1, title: 'MarketWatch',   icon: 'mdi-alpha-m-circle', route:({name: 'marketWatchNews'}) },  
            { id: 2, title: 'Economist',     icon: 'mdi-alpha-e-circle', route:({name: 'Economist_News'}) },              
            { id: 3, title: 'CNBC',          icon: 'mdi-alpha-c-circle', route:({name: 'CNBCYTVideos'}) },  
            { id: 4, title: 'TradingView',   icon: 'mdi-alpha-t-circle', route:({name: 'TradingViewSnaps'}) },              
          ],  
          items3: [
            { id: 1, title: 'Crypto',       icon: 'mdi-alpha-s-circle', route:({name: 'cryptoMarket'}) },  
            { id: 2, title: 'Crypto News',  icon: 'mdi-alpha-t-circle', route:({name: 'Crypto_News'}) },  
            { id: 3, title: 'Heatmap',      icon: 'mdi-alpha-m-circle', route:({name: 'cryptoHeatmap'}) },    
            { id: 4, title: 'Economics',    icon: 'mdi-alpha-e-circle', route:({name: 'tradingEconomicsNews'}) },                                      
          ],                                                
      }
  },
  mounted() {
      this.onResize();
      // assign pointer once document is vueable 
      this.gaugeArea = document.querySelector('#gaugeArea');
      // find now value from Rapid data passed from 'homePage'
      try {
         // get value from back-end parse
         // legend labels & values - set here
         //------------ NOW --------------------------*     
         this.now_label     = this.chartData[1][0];
         this.now_value     = String( this.chartData[1][1] );
         this.now_color     = this.getSliceColor( this.now_label );  
         //------------ PRV CLS ----------------------*         
         this.prv_cls_label = this.chartData[2][0];
         this.prv_cls_value = String(this.chartData[2][1]);
         this.prv_cls_color = this.getSliceColor( this.prv_cls_label );
        //------------- PRV Week ---------------------*                
         this.prv_wk_label  = this.chartData[3][0];
         this.prv_wk_value  = String(this.chartData[3][1]);
         this.prv_wk_color  = this.getSliceColor( this.prv_wk_label );
        //------------ PRV Month ---------------------*                
         this.prv_mn_label  = this.chartData[4][0];
         this.prv_mn_value  = String(this.chartData[4][1]);
         this.prv_mn_color  = this.getSliceColor( this.prv_mn_label );
        //------------- PRV Year ---------------------*                
         this.prv_yr_label  = this.chartData[5][0];
         this.prv_yr_value  = String(this.chartData[5][1]);  
         this.prv_yr_color  = this.getSliceColor( this.prv_yr_label );
        //--------------------------------------------*                
         //console.log("RCVD: "+ this.chartData  );
         this.gaugeOptions.centralLabel = this.now_value;
         //console.log( 'Current NOW Value:'+ nowValue );
      } catch( err ) { console.log( err ); }
      // Draw and update the chart component
      GaugeChart.gaugeChart( this.gaugeArea, this.gaugeHeight, this.gaugeOptions).updateNeedle( Number(this.now_value) );
  },
  methods: {
      onResize() {
          this.width  = window.innerWidth;
          this.height = window.innerHeight;
          // adjust to mobile or desktop settings
          ( this.isMobile ? this.gaugeHeight=350: this.gaugeHeight=400 )
          //document.getElementById('glgChart').draw( this.chartData, this.chartOptions );
      },
      getSliceColor( title ) {
          //console.log("**** Checking slice Task: "+val);
          if( title.includes('Extreme Fear') ) {
            return( 'red' );
          } else if( title.includes('Extreme Greed') ) {
            return( '#006400' );
          } else if( title.includes('Neutral') ) {
            return( '#ADD8E6' );
          } else if( title.includes('Greed') ) {
            return( '#3CB371' );
          } else if( title.includes('Fear') ) {
            return( '#FFD700' );
          } else {
            return('#C0C0C0');
          }
      },        
  },
}
</script>

<style scoped>
.v-btn__content {
 color: #0366d6;
}
.v-label {
  font-size: 7px;
}
* {
font-family: Roboto, Helvetica Neue, sans-serif;
}

a {
text-decoration: none;
}

.header {
border-bottom: 1px solid lightgray;
margin-bottom: 10px;
display: flex;
}

.subheader {
font-size: 23px;
color: #939298;
margin-top: 24px;
}

.gauge-row {
display: flex;
flex-direction: row;
flex: 1;
margin-bottom: 10px;
min-height: calc(97vh - 100px);
}

.edit {
height: 25px;
width: 70%;
text-align: center;
font-size: 20px;
border: 1px solid lightgray;
cursor: pointer;
margin: 0 auto;
}

.edit:hover {
background-color: #f8f8f8;
}

.edit-leftside {
display: flex;
flex-direction: column;
}

.edit-container {
display: flex;
flex-direction: row;
flex: 1;
margin-bottom: 10px;
min-height: calc(97vh - 100px);
}

.edit-box {
width: 260px;
height: 455px;
border: 1px solid lightgray;
padding: 15px;
}

.edit-box__input {
width: 100px;
margin: 4px;
padding: 3px;
}

.link {
text-decoration: none;
color: #0366d6;
font-size: 42px;
}

.button-wrap {
display: flex;
flex-direction: row;
float: right;
width: 292px;
justify-content: flex-end;
}

.button {
font-size: 12px;
font-weight: 800;
cursor: pointer;
padding: 3px;
background-color: #444;
color: white;
margin-left: 4px;
}

#code {
opacity: 0;
}

.link:hover {
text-decoration: underline;
}

.fork-me {
position: absolute;
top: 0;
right: 0;
border: 0;
}

#input-chartWidth,
#input-needleValue,
#input-needleStartValue,
#input-needleUpdateSpeed {
width: 60px;
}

#input-hasNeedle,
#input-outerNeedle {
margin: 4px;
padding: 3px;
background-color: white;
}

footer {
font-size: 12px;
text-align: center;
position: relative;
}
</style>