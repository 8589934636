import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mt-0 pt-0",attrs:{"outlined":"","color":"rgb(237, 240, 245, 0.3)"}},[_c(VCardText,[_c(VRow,{staticClass:"mt-1 pt-1",attrs:{"dense":"","align":"start"}},[_c(VCol,{staticClass:"ml-7",attrs:{"cols":"2"}},[_c(VBtn,{attrs:{"fab":"","big":"","color":"white"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.hideSideAds.apply(null, arguments)}}},[_c(VIcon,{attrs:{"color":"red","dark":""}},[_vm._v("mdi-close")])],1)],1),_c(VCol,{attrs:{"cols":"10"}},[_vm._v(" ")])],1),_c(VRow,{staticClass:"mt-1 pt-1",attrs:{"dense":"","align":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"align-center",attrs:{"outlined":"","color":"transparent"}},[_c(VCardText,[(this.isMobile || this.screenWidth <= 1180)?_c('div',[_c(VImg,{attrs:{"src":_vm.Urls[ _vm.currentAd ].url,"width":"250px","height":"85%"}})],1):_c('div',[_c(VImg,{attrs:{"src":_vm.Urls[ _vm.currentAd ].url,"width":"350px","height":"85%"}})],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }