<!-- 
    TOP TRENDS Component 
-->
<template>
  <v-layout class="mt-5" align-center justify-center column>
    <!--<v-flex row align-center>  -->
      <!-- START DESKTOP CARD - Toolbar and Tabs for report selection -->      
      <v-card class="mx-auto mb-15 flexcard" :min-width="this.cardMinWidth" :width="this.cardWidth" :height="this.cardHeight" hover>
        <div v-if="this.isMobile">
              <!-- appears on mobile only devices -->
              <v-toolbar color="#F8F6EA" dark flat width="auto" height="55px" src="@/assets/cnbc_title_background.png">              
                <v-toolbar-title class="text-left text-h5 mt-1 white--text" background >Top Trendings</v-toolbar-title>  
                <div style="margin-left: auto; display: block;">
                    <Bookmark link="SocialStats"/>
                </div> 
                <!-- TABS Section displayed at bottom of toolbar -->
                <template v-slot:extension >
                      <!-- mobile report bar -->
                      <v-btn block v-if="isMobile === true" @click.stop="drawer = !drawer" >
                          <v-icon left >{{ listIcon }}</v-icon> {{ mobileRptBar }}
                      </v-btn>              
                </template>        
              </v-toolbar> 
              <div class="text-left">               
                <v-chip style="margin-left: 15px" x-small text-color="white" color="blue" >{{ this.getformattedDate() }}</v-chip>  
              </div>                       
          </div>
          <div v-else>   
             <v-toolbar color="#F8F6EA" dark flat width="auto" height="75px" src="@/assets/cnbc_title_background.png">  
                <v-toolbar-title class="text-left text-h3 mt-1 mb-1 white--text" >Top Trending</v-toolbar-title>          
                <div style="margin-left: auto; margin-top: 5px; display: block;">
                    <Bookmark link="SocialStats"/>
                </div>    
                <!-- TABS Section displayed at bottom of toolbar -->
                <template v-slot:extension >
                      <!-- toggle button list1 only appear on desktop  -->
                      <v-row dense no-gutters class="ma-0 pa-0" v-if="isMobile === false"> 
                        <!-- desktop button bar options list 1 -->
                        <!-- loop through both feed lists to generate menu for top menu -->
                        <v-col cols="12" class="ma-0 pa-0" >
                          <v-btn-toggle v-model="toggle_list1" borderless dark rounded background-color="blue">
                            <v-btn active-class="aa" elevation="3" small v-for="item in items" :key="item.id" rounded @click.prevent="getTrendsReport(item.id,item.rpt)">
                              <v-icon left >{{ item.icon }}</v-icon> {{ item.name }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                      </v-row>
                </template>                        
             </v-toolbar> 
        </div>
        <!-- Trends viewing component - where Trends are available for viewing on desktop -->
        <v-container fluid>
            <!-- report section -->
            <v-row dense>  
              <v-col cols="12">
                  <!-- *** Trends display section - table data will be displayed in the TrendsViewer *** -->  
                  <!-- height adjusted to fit within the host parent card; desktop or mobile -->                
                  <v-card v-scroll.self="onScroll" class="overflow-y-auto" ref="trendsViewer" :height="this.cardHeight-(this.isMobile?160:170)" width="100%" >    
                     <TrendsViewer :trends="reportData" />                          
                  </v-card>                  
              </v-col>
            </v-row>
        </v-container>
      </v-card>
      <!-- *** START Left Drawer for small screens - uses tab v-card section to display report *** -->
      <v-navigation-drawer app v-model="drawer" color="blue" theme="dark" class="mt-0 pt-0 pb-15 mb-15" absolute temporary >
          <v-row dense no-gutters class="mb-0 pb-0">
              <v-col class="text-md-center" cols="12">               
                  <h5 class="ml-auto mb-1 pb-1 white--text">Top Trending</h5>  
              </v-col>
          </v-row>
          <v-divider dark style="height:3px;background-color:aqua" class="ma-1 pa-0" ></v-divider>             
          <v-list nav dense class="ma-0 pa-0">
            <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
              <!-- loop through both Trends lists to generate menu for drawer -->
              <v-list-item v-for="item in items" :key="item.id" @click.prevent="getTrendsReport(item.id,item.rpt)">
                  <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">
                      <!-- highlight first report icon id = 0 -->
                      <div v-if="item.id === 0">
                          <v-icon id="fIcon" left class="initHighlighted" >{{ item.icon }}</v-icon>                    
                      </div>
                      <div v-else>
                          <!-- default no highlite until clicked -->
                          <v-icon left >{{ item.icon }}</v-icon>
                      </div>
                  </v-list-item-icon>      
                  <v-list-item-content :style="{'align-items':'left'}">
                      <v-list-item-title id="listFont" class="ListItemClassInActive" >{{ item.name }}</v-list-item-title>
                  </v-list-item-content>                      
              </v-list-item> 
            </v-list-item-group>
          </v-list>
      </v-navigation-drawer>  
      <!-- *** END Left Drawer Section *** -->                
  <!-- </v-flex> -->
  </v-layout>       
</template>
  
<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"  

    // date js utility for formatting current NYSE date
    import dayjs from "dayjs";    
    
    // listen for and allow broadcasts to components
    import { eventBus } from '@/main'  // event bus communication for registered components

    // user bookmarking component
    import Bookmark     from '@/components/AddToFavorites.vue'         

    // add embedded Trends viewer for customizing Trends Data Tables
    import TrendsViewer from '@/components/views/TrendsViewer.vue'

    // use NY Date info for unigue key to sync with server - basic security
    var tNYDate = new Date(new Date().toLocaleString('en', {timeZone: 'America/New_York'}));  

    export default {
      components: { TrendsViewer, Bookmark },
      data: () => ({
        scrollInvoked: 0,
        //----
          toggle_list1: 0,    
        //----
        valid: false,
        drawer: false, 
        group: null,               
        // grabs locale from environment setup in start of app
        locale: i18n.locale,        
        // determines on object creation if running on mobile device true or false
        isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))),
        // *** following settings are designed for desktop - flipped if 'mobile' before mount ***              
        cardMinWidth: 650,
        cardWidth: "90%",
        cardMaxWidth: "99%",
        cardMinHeight:850,
        cardHeight: 900,
        tab: null,
        show: false,
        listIcon: 'fa-solid fa-list',
        mobileRptBar: i18n.t('rss_mobile_reportbar'), 
        showAdsID       : 0,
        hideAdsID       : 0,        
        time_delay: Number( process.env.VUE_APP_ADS_DELAY ),  // retrieve setting from .env file        
        //----------SECURITY CHECKS SENT to SERVER --------------------------------*
           xKey: tNYDate.getFullYear()+(tNYDate.getMonth()+1)+(tNYDate.getDate()),
          x2Key: tNYDate.getFullYear()*(tNYDate.getMonth()+1)*(tNYDate.getDate()),          
        //-------------------------------------------------------------------------*  
        reportData: 'Report Viewer Section', 
        // menu items for tabs and left drawer component on mobile  
        // { id:1,name:'News',rpt:'Nasdaq_news',title:'General News',icon:'mdi-newspaper-variant-multiple'},
        items: [
              { id:0,name:'Posts',rpt:'top-trending-posts.html',title:'Trending Posts',icon:'fa-solid fa-envelopes-bulk'}, 
              { id:1,name:'Likes',rpt:'top-trending-likes.html',title:'Trending Likes',icon:'mdi-heart-pulse'},                            
              { id:2,name:'Comments',rpt:'top-trending-comments.html',title:'Trending Comments',icon:'fa-solid fa-comment'},   
              { id:3,name:'Impressions',rpt:'top-trending-impressions.html',title:'Trending Impressions',icon:'fa-solid fa-brain'},                                                                                                                                                                                 
        ],                     
      }),
      beforeMount() {
        // show loading spinner - just before component is mounted to app
        this.$loading(true);
        //*** re-set card/widget measurements if running on mobile device before mount ***
        if( this.isMobile ) {
            this.cardWidth="90%";
            this.cardMinWidth=325;
            this.cardMaxWidth="99%";
            this.cardHeight=1000;
        }
      },
      mounted() {
        // 1/2 sec delay - then remove loading indicater       
        //setTimeout( ()=> this.$loading(false), 500 ); 

        // clear any previous data - to start on first story
        this.reportData = '';        

        // check for setting - time out is set in .env file for ads 
        if( this.time_delay <= 0 ) { this.time_delay = 2500; }        

        // *** get first report on mount - Commodities is the first button ***
        this.getTrendsReport( this.items[0].id, this.items[0].rpt );

      },
      beforeUnmount() {
          // send event to parent (AppBar.vue) to close Ads right drawer
          eventBus.$emit("hide-side-ads","hideSideAds");
      },      
      methods: {
        onScroll () {
           // to track scrolling of card window
           this.scrollInvoked++
        },
        getformattedDate() {
           var day = dayjs( this.timestamp );
           return( "Current as of: "+day.format( 'MM-DD-YYYY HH:mm' ) );           
        }, 
        showHideAds() {
          // stop any timeout events by assigned ID - before creating any new ones
          clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );
          var delay = this.time_delay;
          // show ads - if enabled in .env file - defined in BottomSheetAds component - record id for clearing
          this.showAdsID = setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), delay );   // record timeout ID -  for clearing
          delay = delay * 3;
          // send event to parent (AppBar.vue) to close Ads right drawer x3 delay
          this.hideAdsID = setTimeout( ()=> eventBus.$emit("hide-side-ads","hideSideAds"), delay );   // record timeout ID - for clearing      
        },      
        /**************************************************************************************
         * this method is assigned to each button generated by looping through List1 and List2
         * when clicked it will make an axios call to retrieve the requested Trend from
         * the back end servlet which will pull it from the S3 bucket.
         * 
         * ListNo is passed to clear other button list so it is not highlighted/selected
         * no button group is cleared if zero 0 is passed
         *************************************************************************************/      
        getTrendsReport( btnId, rptName ) {
              this.$loading(true);  
              // close any visible Ad
              eventBus.$emit("hide-side-ads","hideSideAds");                          
              // add timeout to reselect button if clicked multiple times
              setTimeout( ()=> { this.toggle_list1 = btnId; }, 150 );                    
              // clear any previous data - to start on first story
                 this.reportData = '';              
              // request report from back-end to retrieve selected Trends
              this.axios.get('/trends?report='+rptName,{headers:{'api-ref':this.xKey,'sc-ch-nx':this.x2Key}}).then((response) => {
                    // clear any existing data - reset scroll window position
                    this.scrollInvoked=0;                    
                    //console.log( response.data  ); // <- later comment out
                    var trendsData = response.data;
                    // **** throw error to popup message - invalid symbol ****
                    if( trendsData === null || trendsData.length <= 10 ) {
                        throw new Error('Social Trends Error');
                    } else {
                          // store Trend info to data element 'text' for display - which
                          // is created under a tab section ... reference back to that tab first
                          //   this.$refs[rptName];
                          // assign report to the TrendsViewer - here
                          this.reportData = trendsData;
                          // set scroll back to top
                          this.scrollInvoked=0;
                          setTimeout( ()=> { this.$loading(false); }, 1000 ); 
                          // show new set of ads
                          this.showHideAds();                                  
                    }
              }).catch( err => { 
                  this.$loading(false);
                  var xErr = "<div class='w3-row w3-margin-bottom'>"+    
                              "<div class='w3-card-4' style='width:100%'>"+
                              "<header class='w3-container w3-red w3-left-align'>"+
                              "<h3>Top Trends Stats "+i18n.t('rss_err_msg.title')+"</h3>"+
                              "<h6>"+i18n.t('rss_err_msg.topic')+"</h6></header>"+
                              "<div class='w3-container w3-left-align'>"+
                              "<p></p><p>"+i18n.t('rss_err_msg.action') +"</p>"+                                
                              "</div></div></div>";
                               console.log( err );
                    // show user by udating - report data for the view
                    this.reportData = xErr;
                    // reload application - probably due to connection timeout
                    setTimeout( ()=> { this.$loading(true); }, 2500 );                     
                    setTimeout( ()=> { window.location.reload(); }, 3500 );                               
              });
              // remove highlighed default mobile report icon - must be a better way ...
              if( this.isMobile && btnId != 0 ) {
                  var icon = document.getElementById("fIcon");
                  icon.classList.remove("initHighlighted");
              }                    
          },
      },
      watch: {
          group () {
             this.drawer = false
          },
      },
      metaInfo() {
        return {
          title: "Stock-Insight's Social Sentiment - Top Trending Posts",
          meta: [
            { name: 'robots', content: 'index,follow'},
            { property: 'og:type', content: 'website'}, 
            { property: 'og:site_name', content: 'www.Stock-Insight.net'},
            { property: 'og:title', content: "Stock-Insight's Social Sentiment - Top Trending Posts"},                             
            {
              vmid: "description",
              name: "description",
              content: "Stock-Insight's Social Sentiment - Top Trending Posts"
            }
          ],
          link: [
            {
              rel: 'canonical',
              href: '<https://www.stock-insight.net/>'
            }
          ]
        }
      }                           
    }
</script>

<style scoped>
  .v-tab--active {
      background-color:rgba(22, 174, 22, 0.3); 
  }
  .v-list-item__content {
      text-align: left;
      color: white; 
      font-weight: bold;     
  }
  .v-list-item__title {
      padding-left: 15px;
      font-weight: bold;        
  }
   .aa {
    background: rgb(25, 0, 255) !important;
  }
  .ListItemClassActive {
    color: #0556f9;
  }
  .ListItemClassInActive {
    color: #f1f8fa;
   }
  .v-list-item__icon v-list-group__header__prepend-icon {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
  } 
  .v-list-item--active .subListIcon .v-icon {
     background-color: lightgreen;
  }
  .v-list-item--active .v-list-item__title {
     color: rgb(253, 250, 250);
     font-weight: bold;
  } 
  .subListIcon {
    font-size: 16px;
    width: 40px;    
  }  
  .subListItem {
    font-size: 18px;
  }    
  .initHighlighted {
    background-color: lightgreen;
  }   
  #listFont {
     font-size: 16px;
     padding-top: 5px;
  }        
</style>

