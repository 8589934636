import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VLayout,{attrs:{"justify-center":"","align-center":""}},[_c(VCard,{staticClass:"mx-auto my-auto flexcard",attrs:{"width":this.cardWidth,"height":"100%","hover":""}},[_c(VImg,{staticClass:"white--text align-center",attrs:{"width":"auto","height":"75px","src":require("@/assets/stock-market_100.jpg"),"cover":""}},[_c(VCardTitle,{staticClass:"justify-center"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"1"}}),_c(VCol,{staticClass:"pt-2",attrs:{"cols":"9"}},[_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Stock Heatmap")])]),_c(VCol,{attrs:{"cols":"2"}},[_c('Bookmark',{attrs:{"link":"stockHeatmap"}})],1)],1)],1)],1),_c(VCardText,{ref:"tvParent",staticClass:"text--primary text-center"},[_c('div',{staticClass:"tradingview-widget-container"},[_c('div',{staticClass:"tradingview-widget-container__widget"}),_c('div',{staticClass:"tradingview-widget-copyright"},[_c('span',{staticClass:"blue-text"},[_vm._v("Track all markets on TradingView")])]),_c('script',{attrs:{"type":"application/javascript","defer":"","src":"https://s3.tradingview.com/external-embedding/embed-widget-stock-heatmap.js","async":""}},[_vm._v(" { \"exchanges\": [], \"dataSource\": \"AllUSA\", \"grouping\": \"sector\", \"blockSize\": \"market_cap_basic\", \"blockColor\": \"change\", \"locale\": \"en\", \"symbolUrl\": \"\", \"colorTheme\": \"light\", \"hasTopBar\": true, \"isDataSetEnabled\": false, \"isZoomEnabled\": true, \"hasSymbolTooltip\": true, \"width\":\"100%\", \"height\":\"1000\" } ")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }