<!--   -------------------------------------------------------------------------------------------
     this is the main application app bar which has tabs which switch between 'calls' and 'puts'
---------------------------------------------------------------------------------------------  -->
<template>
 <v-container class="ma-0 pa-0" >     
    <!--start of application navigation top bar-->
    <!-- [ src="https://picsum.photos/1920/1080?random" ]  :to="{name: 'home'}" -->
    <div v-if="this.isMobile || this.screenWidth <= 1180" >
      <!-- ***** mobile app bar menu system ******* -->         
        <v-app-bar app flat dark dense src="@/assets/pulse-trace_100.jpg" 
            width="auto" class="flex-grow-0" cover clipped-left short >
            <!-- app bar individual components - removed -> class="hidden-md-and-up" -->
            <v-app-bar-nav-icon @click="drawer = !drawer" ></v-app-bar-nav-icon>
            <!-- logo -->  
            <v-btn :to="{name: 'home'}" plain >
                <v-img class="mx-1" src="@/assets/Apps-Home.png" max-height="32" max-width="32" contain></v-img>
            </v-btn>            
            <v-toolbar-title class="white--text">{{ this.app_title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="showMarketClock" class="mx-2" fab dark x-small color="primary">
               <!-- fa-regular fa-clock -->
                <v-icon dark>mdi-alarm</v-icon>
            </v-btn>
            <marketClock/>            
        </v-app-bar> 
        <!-- start of drawer navigation element - walks data item list - hidden on medium and above screens --> 
        <v-card elevation="12"> 
          <v-layout class="ma-0 pa-0">  
            <v-navigation-drawer app floating v-model="drawer" class="indigo darken-1 mt-0 pt-0" :width="300" temporary >                             
                <AppBarLeftDrawer/>
            </v-navigation-drawer> 
          </v-layout>
        </v-card>
        <!-- end of drawer navigation element -->          
    </div>
    <div v-else >
      <!-- ***** desktop app bar menu system ******* -->
      <v-app-bar app flat dark dense src="@/assets/pulse-trace_100.jpg" width="auto" class="flex-grow-0" cover clipped-left short  >
            <!-- app bar individual components -->
            <!-- logo -->  
              <v-btn :to="{name: 'home'}" plain >
                <v-img class="mx-2" src="@/assets/Apps-Home.png" max-height="32" max-width="32" contain></v-img>
              </v-btn>
              <v-toolbar-title class="white--text">{{ this.app_title }}</v-toolbar-title>
              <v-spacer></v-spacer>
                <DigitalClock/> 
                <v-btn @click="showMarketClock" class="mx-2" fab dark x-small color="primary">
                    <v-icon dark>mdi-alarm</v-icon>
                </v-btn>
                <marketClock/>
              <v-spacer></v-spacer>         
              <!-- *** only show 'Local Switcher if in DEV mode' *** -->
              <!-- *** setting from .env file located in project root folder ***--> 
              <!-- <div v-if="pgm_mode == 'DEV'" >Language:&nbsp;<LocaleSwitcher /></div> -->                        
              <template v-slot:extension>
                <v-tabs id="appToolbarBtns" center-active show-arrows hide-slider>
                  <v-tab><AppBarToolsMenu/></v-tab>
                  <v-tab><AppBarChartsMenu/></v-tab>
                  <v-tab><AppBarNewsMenu/></v-tab>  
                  <v-tab><AppBarVideoClips/></v-tab>
                  <v-tab><AppBarPodcasts/></v-tab>                              
                  <!-- <v-tab><AppBarTrendsMenu/></v-tab> -->
                  <v-tab><AppBarFavorites/></v-tab>                         
                </v-tabs>
              </template>
      </v-app-bar>  
    </div>          
    <!-- end desktop button tabs menu -->
 </v-container>
</template>

<script>
  import { eventBus } from '@/main'  // event bus communication for registered components
  /* using i18n to pull from locale settings to load 'src/locals/xx.json' file for label settings */
  // { id: 13,title: 'RssFeeds', icon: 'mdi-alpha-c-circle', route: '/rssfeeds' }, 
  import i18n from "@/i18n"

  // import main tab menu components from components/menus folder
  import AppBarLeftDrawer  from "@/components/menus/AppBarLeftDrawerMenu.vue"
  // *** right drawer for vertical ADs ***
  //import AppBarRightDrawer from "@/components/ads/AppBarRightDrawerMenu.vue" 
  //----------------------------------------------------------------------* 
  import AppBarToolsMenu   from "@/components/menus/AppBar_ToolsMenu.vue"
  import AppBarChartsMenu  from "@/components/menus/AppBar_ChartsMenu.vue"
  import AppBarNewsMenu    from "@/components/menus/AppBar_NewsMenu.vue"
  import AppBarTrendsMenu  from "@/components/menus/AppBar_TrendsMenu.vue"
  import AppBarPodcasts    from '@/components/menus/AppBar_PodcastsMenu.vue'
  import AppBarVideoClips  from '@/components/menus/AppBar_VideoClipsMenu.vue'
  //-------- -------* 
  import AppBarFavorites   from '@/components/menus/AppBar_Favorites.vue'

  // digital clock - in AppBar top position
  import DigitalClock      from "@/components/DigitalClock.vue"
  import marketClock       from '@/components/views/appBarMarketClock.vue'
  // testing language switcher for development and debugging purposes
  //import LocaleSwitcher from "@/components/LocalSwitcher.vue"  AppBarRightDrawer

  export default {
    components: { AppBarToolsMenu, AppBarChartsMenu, AppBarNewsMenu, AppBarTrendsMenu, AppBarPodcasts, AppBarVideoClips,
                      AppBarLeftDrawer, DigitalClock, AppBarFavorites, marketClock },
    name: "AppBar",
    data: () => ({
          drawer: false,
          group: null,
          tabs: null,
          app_title: process.env.VUE_APP_TITLE,
          pgm_mode: process.env.VUE_APP_MODE, 
          // look for previous agreement to site terms in local storage
          acceptTerms: window.localStorage.getItem('acceptTerms'),         
          // determines on object creation if running on mobile device true or false - although hard coded init
          //isMobile: window.matchMedia("(max-width: 700px)").matches, [ && !(/ipad/i.test(navigator.userAgent))  ] 
          isMobile: ( (/Mobile/i.test(navigator.userAgent) ) ),
          screenHeight: screen.height,
          screenWidth:  screen.width,          
        }),
        methods: {
          // **** Ask user to accept terms of usage -- ****
          showTermsMessage() {
              // **** remove loading spinner before dialog popup *** allow 'bots' to search site ***
              this.$loading(false)
              // check for previous agreement to the 'terms' - show terms if flag not set - let bots search site
              if( ! this.isBot( navigator.userAgent ) && ( this.acceptTerms == null || this.acceptTerms !== 'true' ) ) {
                  // *** show initial terms notice method after a slight delay ***
                  this.$swal({
                    position: 'center',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showDenyButton: true,
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: i18n.t('terms_init_popup_notice.confirmBtnTxt'),
                    denyButtonText: i18n.t('terms_init_popup_notice.denyBtnTxt'),
                    icon: 'warning',
                    title: i18n.t('terms_init_popup_notice.title'),
                    text: i18n.t('terms_init_popup_notice.text'),
                    footer: i18n.t('terms_init_popup_notice.footer'),
                  }).then((result) => {
                      if( result.isConfirmed ) {
                        // continue as normal since user agreed to popup message - restart ads
                        // console.log('call to remove init storage item');                  
                        localStorage.removeItem('init');
                        
                        // *** stamp local storage to skip this popup next time ... user agreed to site terms
                        window.localStorage.setItem("acceptTerms","true");

                      } else if( result.isDenied ) {
                        // remove previous agreed stamp - if any then show complete 'terms' doc
                        localStorage.removeItem('acceptTerms');
                        
                        // show complete terms document in popup by emitting an event to the 'bus' 
                        eventBus.$emit("show-terms", "showTerms");
                      }
                  })
              }
          },
          showMarketClock() {
             // send message event to show market clock dialog
             eventBus.$emit( "show-market-clock", "showMarketClock" );
             //console.log( "show market clock from AppBar called .." );
          },
          isBot( userAgent ) {
            /**********************************************************************************
             * special method to allow 'bots' to crawl this website - without having to verify 
             * acknowledgement of 'terms of service'
             * check is page is being crawled by 'bot' by checking navigator.useragent
             *********************************************************************************/
                const robots = new RegExp([
                  /bot/,/spider/,/crawl/,                            // GENERAL TERMS
                  /APIs-Google/,/AdsBot/,/Googlebot/,                // GOOGLE ROBOTS
                  /mediapartners/,/Google Favicon/,
                  /FeedFetcher/,/Google-Read-Aloud/,
                  /DuplexWeb-Google/,/googleweblight/,
                  /bing/,/yandex/,/baidu/,/duckduck/,/yahoo/,        // OTHER ENGINES
                  /ecosia/,/ia_archiver/,
                  /facebook/,/instagram/,/pinterest/,/reddit/,       // SOCIAL MEDIA
                  /slack/,/twitter/,/whatsapp/,/youtube/,
                  /semrush/,                                         // OTHER
                ].map((r) => r.source).join("|"),"i");               // BUILD REGEXP + "i" FLAG
                //console.log('Is Robot: '+ robots.test( userAgent)  );
              return( robots.test( userAgent) );
          }
        },
        watch: {
          drawer: {
              handler(val) {
                  //console.log( "drawer state = ..."+val ); 
                  // call 'closeFavorites' since it is detached from reset of menu system
                  if( val ) {
                     eventBus.$emit("open-drawer", "closeFavorites" );
                  }
              },
              //deep: true
          }
    },           
  }
</script>

<!-- style section that is specific to this component by specifing 'scoped' -->
<style scoped>
  .v-navigation-drawer {
      /*background: transparent !important*/
      margin-top: 5;
      margin-left: 3;
      margin-right: 3;
      padding-left: 0;
      padding-right: 0;
  }
  .theme--dark.v-tabs-items {
     background-color: transparent !important;
  }
  .theme--light.v-tabs-items {
     background-color: transparent !important;
  }  
</style>