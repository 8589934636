<!-- GOOGLE ADS -----
     App Bar Right Drawer Menu Options - used for future ADs

     Basically just a shell to show ADs if configured in application root .env file

     class="fill-height"
-->
<template> 
    <!--  <container grow d-flex flex-column flex-nowrap fluid >  -->
        <!-- <v-container class="mt-0 pt-0" fill-height fluid color="transparent"> -->
        <!-- d-flex align-center justify-center -->
        <v-card class="mt-0 pt-0" outlined color="rgb(237, 240, 245, 0.3)" > 
            <v-card-text> 
                <v-row dense align="start" class="mt-1 pt-1">
                    <v-col cols="2" class="ml-7">
                        <v-btn fab big color="white" @click.prevent.stop="hideSideAds" >
                            <v-icon color="red" dark>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="10">&nbsp;</v-col>    
                </v-row>
                <v-row dense align="center" class="mt-1 pt-1" >
                    <v-col cols="12">
                        <!-- card class="d-flex flex-column align-center justify-center" color="transparent" -->
                        <v-card class="align-center" outlined color="transparent" > 
                            <v-card-text> 
                                <div v-if="this.isMobile || this.screenWidth <= 1180" >
                                    <v-img :src="Urls[ currentAd ].url" width="250px" height="85%"></v-img>                                       
                                </div> 
                                <div v-else>
                                    <v-img :src="Urls[ currentAd ].url" width="350px" height="85%"></v-img>                                 
                                </div>
                                <!--     
                                    <v-img src="@/assets/verticleAd.png" class="adsResponsive"></v-img>                        
                                    <Adsense
                                        data-ad-client="pub-9537720061722515"
                                        data-ad-slot="1234567890">
                                    </Adsense>-->
                                    <!-- <script async type="application/javascript" src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9537720061722515"
                                        crossorigin="anonymous" ></script>
                                -->
                                        <!-- StockInsightVirticalAdDisplay 
                                        <ins class="adsbygoogle"
                                            style="display:block"
                                            data-ad-client="ca-pub-9537720061722515"
                                            data-ad-slot="5737716294"
                                            data-ad-format="auto"
                                            data-full-width-responsive="true"></ins>
                                        <script type="application/javascript" async>(adsbygoogle = window.adsbygoogle || []).push({});</script>
                                        -->
                            </v-card-text>
                        </v-card> 
                    </v-col>
                </v-row> 
            </v-card-text>
        </v-card>            
    <!-- end of right drawer AD element --> 
</template>
<script>
  /* using i18n to pull from locale settings to load 'src/locals/xx.json' file for label settings */
  //import i18n from "@/i18n"

  var adsbygoogle;

  // event bus communication for registered components
  import { eventBus } from '@/main'  

  // check for 'demo' mode - passed /?demo query string to show what ads 'might' look like
  var mode = sessionStorage.getItem('mode');     

  export default {
    name: "AppBarRightDrawer",
    data: () => ({
           // determines on object creation if running on mobile device true or false - although hard coded init
           isMobile: ( (/Mobile/i.test(navigator.userAgent)) ), 
           width:  200,
           height: 300,
           currentAd: 0,
           screenWidth: screen.width,
           screenHeight: screen.height,
           Urls: [
                {id: 0, url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnxusIKX9hyUM1OPg4jJ5TjUdH2vgtu5UBvQ&usqp=CAU" },
                {id: 1, url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGOjdGzKT29AitkuCTc5VFOHckCVu8lYEk4w&usqp=CAU"},
                {id: 2, url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9_SOiDLmAf4ajc1wZFRJrGcYFNDWuCcvcLg&usqp=CAU"},
                {id: 3, url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGmE6bjiKQfKl4E7fY74_nN4RX9QqPDztCaA&usqp=CAU"},
                {id: 4, url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzMZ7a2GlWULwm88OIlnRJDBC5terrWR6DnWN1D2Nja0skze9JL05XWW5viujuPwyvxT4&usqp=CAU"},
                {id: 5, url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtIQN21587iqZIV6VQLvy3orRERAvJqGM6nA&usqp=CAU"},
                {id: 6, url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxniYdvM_t-nScxRh9La2tYuPD0I7HI4zXVQ&usqp=CAU"},
                {id: 7, url: "https://assets-global.website-files.com/63a9fb94e473f36dbe99c1b1/651bc8bc625e350932484b38_hYMjqoCsTx6elJfeGEHr.png"},
                {id: 8, url: "https://assets-global.website-files.com/63a9fb94e473f36dbe99c1b1/651bc8bc625e350932484b31_iyk0yCP5QcixIrnhVEuM.png"},
                {id: 9, url: "https://assets-global.website-files.com/63a9fb94e473f36dbe99c1b1/651bc8bc625e350932484b35_mJmMV8YCRViyfWNw03hw.png"}
            ]           
           // this array is used only on the left drawer menu for mobile devices
    }),
    // close side ads - calls AppBar since it is the host to close the right side drawer
    methods: {
        hideSideAds() {
           // send event to parent (AppBar.vue) to close this drawer
           eventBus.$emit("hide-side-ads","hideSideAds");
           // get next url
           this.currentAd = this.getRandomInteger( 0, this.Urls.length -1 );  
        },
        getRandomInteger( min, max ) {
           var num = Math.round(Math.random() * max + min )
           //console.log("Random # = "+ num )
           return( num )
        }          
    },
    mounted() {
        // call google refresh method  - not approved by AdSense - 12.10.23      
        //(adsbygoogle = window.adsbygoogle || []).push({});
        this.currentAd = this.getRandomInteger( 0, this.Urls.length -1 );       
    },
 }
</script>

<style scoped>
  #menuBtnHidden {
    visibility: hidden;
  }
  #menuBtnVisible {
    visibility: visible;
  } 
  .adsDrawer {
    /* height: 100vh; */
    background: rgba(200, 201, 202, 0.3);
    margin-left: 0px;
  } 
 
</style>