<!-----------------------------------------------------------------------------
   Realtime Charts Parent Page - this component hosts 4 sub-component Charts 
   
    Tabs constructed for desktop -> possible query string to change chart symbol [ tvwidgetsymbol=GM ]

      Tab 1  AnalyticsAdvChart from '@/components/widgets/analyticsAdvChart.vue'
      Tab 2  TechnicalAdvChart from '@/components/widgets/technicalAdvChart.vue'
      Tab 3  WatchListAdvChart from '@/components/widgets/watchListAdvChart.vue'
      Tab 4  AreaAdvChart      from '@/components/widgets/areaAdvChart.vue'

      Note: [ https://stackoverflow.com/questions/71194974/how-can-i-load-the-content-from-scratch-on-each-tab-click-when-using-v-tabs-in-v ]
      updating components without refreshing page
      [ https://michaelnthiessen.com/force-re-render ]
----------------------------------------------------------------------------->
<template>
 <v-container fluid fill-height :key="this.symbol" > 
  <v-row justify="center" align="center"> 
    <v-col cols="12">
      <!-- Widget Card Block  mx-auto my-auto flexcard -->  
      <v-card class="mx-auto my-auto flexcard" :width="this.cardWidth" height="100%" hover >
          <v-img class="white--text align-center" width="auto" height="75px" src="@/assets/currency_coins_100.jpg" cover >
              <v-row dense>
                  <v-col cols="1"></v-col>
                    <v-col class="pt-2" cols="9">
                       <span class="text-h5 font-weight-bold">Real-Time Charts</span>
                    </v-col>
                    <v-col cols="2">
                        <!-- track bookmarks -->
                        <Bookmark link="symbolchart"/>                          
                    </v-col>                    
              </v-row>
          </v-img>
          <!-- <v-card-subtitle >Ticker</v-card-subtitle> -->
          <!-- **** DESKTOP ONLY TAB Titles SECTION **** -->
          <div v-if="this.isMobile === false">
                <v-toolbar dense color="gray" dark flat >
                    <v-tabs v-model="tab" align-with-title >
                        <v-tabs-slider color="yellow"></v-tabs-slider>
                        <v-tab @click="showHideAds" href="#Analytics">Analytics</v-tab>
                        <v-tab @click="showHideAds" href="#Technical">Technical</v-tab>   
                        <v-tab @click="showHideAds" href="#WatchList">Watch List</v-tab>                   
                        <v-tab @click="showHideAds" href="#Area">Area</v-tab>                   
                    </v-tabs>           
                </v-toolbar>
          </div>
          <v-card-text ref="chrtWidget" class="text--primary text-center printable">
              <!-- **** MOBILE show simpler real-time-chart skip multiple not enough screen space ***** -->
              <div v-if="this.isMobile === true">
                    <!-- MOBILE VIEW -->
                    <RealtimeChart />
              </div>
              <div v-else>
                  <!---------------------------------------
                    ***  DESKTOP TOOLBAR SECTION ***
                  ---------------------------------------->
                  <v-toolbar class="mt-0 mb-0" color="grey">
                    <v-toolbar-title class="white--text"></v-toolbar-title>
                    <v-btn color="lightprimary" @click.prevent.stop="searchStockSymbols">
                        <v-icon left>search</v-icon>&nbsp;Search
                    </v-btn>
                    <v-spacer></v-spacer>
                    <!-- check for last search ticker - display button to update watchlist-->
                    <div v-show="showUpdateWatchListBtn">
                        <!-- check for last search ticker and in localstorage first -->
                        <div v-if="hasLastSearchSymbol" >
                          <v-btn v-if="this.tab==='WatchList'" color="lightprimary" @click.prevent.stop="chkLastSearchOnWatchList">
                            <v-icon left>fa-solid fa-pen-to-square</v-icon>&nbsp;WatchList Add -> [ {{this.lastSeachedSymbol}} ] ?
                          </v-btn>
                        </div>
                    </div>
                    <v-spacer></v-spacer>                    
                    <v-btn v-if="this.tab==='WatchList'" color="lightprimary" @click.prevent.stop="watchList">
                        <v-icon left>list</v-icon>&nbsp;WatchList
                    </v-btn>
                  </v-toolbar>                  
                  <!-- *** Desktop TradingView Widget Charts TABS section *** --> 
                  <v-tabs-items v-model="tab">
                    <v-tab-item :key="1" value="Analytics">
                      <!--  set specific height for desktop to stop flickering load -->                      
                      <v-card flat height="700px" class="ma-0 pa-0"> 
                        <v-card-text class="ma-0 pa-0">
                             <!-- import Advanced Analytics Chart for this tab -->
                             <AnalyticsAdvChart :key="tab" />
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Chart 2 Section -->
                    <v-tab-item :key="2" value="Technical">
                      <v-card flat height="700px" class="ma-0 pa-0">
                          <v-card-text class="ma-0 pa-0">
                             <!-- import Advanced Technical Chart for this tab -->  
                             <TechnicalAdvChart :key="tab"/>
                          </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Chart 3 Section -->
                    <v-tab-item :key="3" value="WatchList">
                      <v-card flat height="700px" class="ma-0 pa-0">
                          <v-card-text class="ma-0 pa-0">
                             <!-- import Advanced Watch List Chart for this tab -->
                             <WatchListAdvChart :key="tab"/>
                          </v-card-text>
                      </v-card>
                    </v-tab-item>     
                    <!-- Chart 3 Section -->                       
                    <v-tab-item :key="4" value="Area">
                        <v-card flat height="700px" class="ma-0 pa-0">
                          <v-card-text class="ma-0 pa-0">
                            <!-- import Advanced AREA Chart for this tab  -->
                            <AreaAdvChart :key="tab"/>
                          </v-card-text>
                        </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
            <!-- END DESTOP TAB SECTION Widgets -->
        </v-card-text>
      </v-card>
      <WatchListDialog/>
      <!-- END Widget Block-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n              from "@/i18n" 

    // listen for and allow broadcasts to components
    import { eventBus }      from '@/main'  // event bus communication for registered components

    // user bookmarking component
    import Bookmark          from '@/components/AddToFavorites.vue'

    // import Adv Charts for Tabs on desktop version for each tab
    import AnalyticsAdvChart from '@/components/widgets/analyticsAdvChart.vue'
    import TechnicalAdvChart from '@/components/widgets/technicalAdvChart.vue'
    import WatchListAdvChart from '@/components/widgets/watchListAdvChart.vue'
    import AreaAdvChart      from '@/components/widgets/areaAdvChart.vue'
    // mobile Real-Time Chart
    import RealtimeChart     from '@/components/widgets/realtimeChart_Mobile.vue'    
    // watch list editor
    import WatchListDialog   from '@/components/widgets/watchListDialog.vue'

    // ads bottom sheet - uses broadcast message to show ad
    //import BottomSheetAds from '@/components/BottomSheetAds.vue'  
    var parentChart;
    export default {
      components: { Bookmark, AnalyticsAdvChart, TechnicalAdvChart, WatchListAdvChart, AreaAdvChart, RealtimeChart, WatchListDialog },        
      data: () => ({
          tab: "Analytics",
          valid: false,
          locale: i18n.locale,
          // determines on object creation if running on mobile device true or false
          isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))),
          // *** following settings are designed for desktop - flipped if 'mobile' before mount ***   650           
          cardWidth: "95%",
          widgetWidth: "100%",
          widgetHeight: 800, 
          symbol: "AAPL", // ticker feed passes 'tvwidgetsymbol' params to this component
          watchlist: ["AAPL"],
          lastSeachedSymbol: 'AAPL',
          showUpdateWatchListBtn: false,  // toggle for update watchlist button
          showAdsID : 0,
          hideAdsID : 0,    
          time_delay: Number( process.env.VUE_APP_ADS_DELAY ),  // retrieve setting from .env file  
      }),
      methods: {
        hideAds() {
           // stop any timeout events by assigned ID - before creating any new ones
           clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );
           // hide any previous side Ad - if any
           eventBus.$emit("hide-side-ads","hideSideAds");          
        },
        forceRerender() {
           // forces component to reload itself, instead of a complete page reload ... 'cool'
           // tied to 
           this.componentKey = this.symbol;  
        },        
        updateSelection( tkr ) {
           // update component symbol from 'emitted' event call from parent
           this.symbol = tkr;
           // reload component since all the tabs are different widgets           
           this.forceRerender();
        },        
        adCleanUp() {
          // close right drawer
          eventBus.$emit("hide-side-ads","hideSideAds");  
          // stop any timeout events by assigned ID - before creating any new ones
          clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );
        },   
        showHideAds() {
          this.adCleanUp();
          var delay = this.time_delay;
          // show ads - if enabled in .env file - defined in BottomSheetAds component - record id for clearing
          this.showAdsID = setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), delay );  // record timeout ID -  for clearing
          //console.log("showAdsID = "+this.showAdsID);    
          delay = delay * 3;
          // send event to parent (AppBar.vue) to close Ads right drawer x3 delay
          this.hideAdsID = setTimeout( ()=> eventBus.$emit("hide-side-ads","hideSideAds"), delay );   // record timeout ID - for clearing  
          //console.log("TAB ID = "+this.tab);  
          
          // *** check each tab click to update last searched symbol - since a search can be performed on each chart section
          this.lastSeachedSymbol = localStorage.getItem("lastSeachedSymbol");
          //********* UPDATE Current WatchList from each tab click event ***********/   
              this.watchlist = JSON.parse( localStorage.getItem("watchlist") );
          //************************************************************************/

          // *** toggle Update WatchList button Show/No Show by checking watchList of last searched 
          this.showUpdateWatchListBtn = this.notInWatchList( this.lastSeachedSymbol );          

        },  
        // hide update button on WatchList chart - depending on Last Search Ticker
        notInWatchList( ticker ) {
           var notInList = true;  // default NOT in list
           // ticker search
           var watchList = JSON.parse( localStorage.getItem("watchlist") );

           // if found in list return false - first check if ticker and watchlist is not null
           if( ticker && watchList ) {
              if ( watchList.includes( ticker ) ) {
                  notInList = false;  // ticker found in list
              }
            }
          return( notInList )  //
        },
        hasLastSearchSymbol() {
          // get last recorded search symbol from localstorage - update data block of parent
          this.lastSeachedSymbol = localStorage.getItem( "lastSeachedSymbol");
          if( this.lastSeachedSymbol && this.lastSeachedSymbol.length > 0 ) { return true } else { return( false ) }
        },
        /*****************************************************************
         * TICKER SEARCH DIALOG - Desktop Only
         * available from parent for all 4 charts
         * Note: updates 'lastSearchedsymbol' in localStorage -
         *    then notifies each child chart to reload with that ticker
         *****************************************************************/
        searchStockSymbols() {
            // remove any ads if visible
            this.adCleanUp();
            // search popup - here               
            this.$swal({
                title: 'Stock Search Tool',
                text: 'Enter Any Valid Stock Symbol ie: AAPL',
                inputAutoFocus: true,                
                inputAutoTrim: true,
                inputPlaceholder: 'Stock Symbol',
                inputAttributes: {minLength:1,maxlength:7,autocapitalize:'on'},                                 
                input: 'text',
                confirmButtonText: 'Search',
                showCancelButton: true,
                footer: 'Exchanges: NYSE NASDAQ AMEX'
             }).then((result) => {
                if( result.value != null && result.value != "" ) {
                    this.$loading(true);
                    try {
                        // check for any XSS tags inserted in form - from utils.js
                        if( checkForSpecialChar( result.value ) ) {
                          // ignore if any special characters are found in input
                          //console.log('Ignored XSS Attack: '+ result.value );
                          // close first popup search
                          this.$swal.close();
                          this.$swal({ icon: "error", title: "Oops...", text: "Invalid Ticker Symbol!", footer: 'Only Valid Stock Tickers Accepted' });                          
                        } else {
                            var ticker = result.value.trim().toUpperCase();
                            //console.log("Symbol parsed returned: -> "+ticker);
                            // enter possible ticker submitted by user
                            if( ticker ) {
                                this.symbol = ticker;
                                // *** post update to all listening sub components **********************
                                eventBus.$emit("ticker-update", this.symbol );  
                                console.log( "Sent Event to update Watchlist : "+this.symbol );
                                // save to localStorage to bring up on a 'refresh' or restart of App
                                localStorage.setItem( "lastSeachedSymbol", this.symbol );
                                // update statistics from TV
                                setTimeout( ()=> this.updateSelection( this.symbol ), 1000 );                                       
                                //***********************************************************************                           
                            }
                            // close first popup search
                            this.$swal.close();                            
                        }
                    } catch(err) { console.log( err ); }
                    // *** toggle Update WatchList Show/No Show by checking watchList of last searched
                    this.showUpdateWatchListBtn = this.notInWatchList( this.lastSeachedSymbol );
                    // 1 sec delay - then remove loading indicater       
                    setTimeout( ()=> this.$loading(false), 1000 );
                    //console.log( "Selected: "+ result.value   showModal:true, ); 
                }
                // reshow Ads if enabled ..
                this.showHideAds();
             })
        },
        watchList() {
            // remove any ads if visible
            this.adCleanUp();
            // search popup - here      
            
            eventBus.$emit("watchlist-update", this.showDialog );  
            //console.log("called show watchlist dialog");

            // reshow Ads if enabled ..
            //this.showHideAds();
        },
        // user can add the last searched Ticker to Watchlist here
        /*****************************************************************
         * WATCHLIST UPDATE Dialog
         *         - available for Desktop only - 
         * allows user to update watchlist chart from last searched ticker
         *****************************************************************/
        chkLastSearchOnWatchList() {
            // check for last seached ticker to overwrite default AAPL from Fundamentals tool setting
            this.lastSeachedSymbol = localStorage.getItem("lastSeachedSymbol");
            this.watchlist         = JSON.parse( localStorage.getItem("watchlist") );
            //-------------------------*
            if( (this.watchlist && this.lastSeachedSymbol) && this.watchlist.includes( this.lastSeachedSymbol ) ) {
                // ticker already in watchlist - ignore - possible double add - clear both data references
                console.log('FYI: Last Searched ['+this.lastSeachedSymbol+'] is already in WatchList.');
            } else {
              // check for both existing - then check for watchlist count
              if( this.lastSeachedSymbol && this.watchlist ) {
                  if( this.watchlist.length < 16 ) {
                      // ask if add to WatchList
                      this.$swal({
                        title: "Update WatchList",
                        text: "Add Last Searched Ticker [ "+this.lastSeachedSymbol+" ] to WatchList?",
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Add it!",
                        footer:"[ "+this.lastSeachedSymbol+" ] Not Currently on WatchList"
                      }).then((result) => {
                        if( result.isConfirmed ) {
                          // *** toggle Update WatchList Show/No Show by checking watchList of last searched
                          this.showUpdateWatchListBtn = false;  
                          this.symbol = this.lastSeachedSymbol;
                          // emit event to add symbol to watchlist
                          eventBus.$emit("ticker-update", this.symbol );  
                          // display message 
                          this.$swal({
                            title: "WatchList Updated!",
                            text:  "Latest Ticker Search [ "+this.lastSeachedSymbol+" ] Added to WatchList",
                            icon:  "success"
                          }).then((result) => {
                              // refresh window - reload to display new watchlist
                              // ask registered components (charts) to refresh themselves for the update
                              // send event to registered components - after 1/4 sec delay  - 1x only
                              //setTimeout( ()=> eventBus.$emit("ticker-update", this.symbol ), 250 );  
                              console.log( "Sent Ticker to be updated ... " );                  
                          });
                        }
                      });
                  } else {
                      this.$swal({
                          title: "WatchList MaxLimit Reached!",
                          text:  "Notice: List Limited to 15 Stock Symbols",
                          icon:  "info",
                          footer: "Remove Unwanted Symbols Using the 'WatchList' Button"
                      });                  
                  }
                }
            }
        },        
      },      
      /*******************************************************************
       * before the main parent component is loaded procs are called
       *******************************************************************/
      beforeMount() {
        // show loading spinner - just before component is mounted to app
        this.$loading(true);

        //*** re-set card/widget measurements if running on mobile device before mount ***
        if( this.isMobile ) { this.cardWidth="100%"; }

        //******* WATCHLIST SETUP from LocalStorage ***********/   
        var wList = localStorage.getItem("watchlist");
        if( wList ) {
           this.watchlist = JSON.parse( wList );
        } else {
           // create default localstorage watchlist - if none - from data default value
           localStorage.setItem('watchlist', JSON.stringify( this.watchlist ) );
        }
        //*****************************************************/        

        //******** LAST SEARCHED SYMBOL SETUP *****************/
        // check for last seached ticker to overwrite default AAPL from Fundamentals tool setting        
        var lastSearched = localStorage.getItem("lastSeachedSymbol");
        if( lastSearched ) {
           this.lastSeachedSymbol = lastSearched;
        } else {
           // create default last searched from component default data setting 'AAPL'
           localStorage.setItem('lastSeachedSymbol', this.lastSeachedSymbol );
        }

        //** QUERY PARAM ** OVER-RIDE LAST SEARCHED SYMBOL BLOCK ***/
          //*** check for specific query string to TradingView Component
          //*** tvwidgetsymbol=GM ?? for example passed by rolling ticker feed setting
          let queryString = window.location.search;
          let urlParams   = new URLSearchParams( queryString );
          // look for specific symbol passed to chart
          if( urlParams && urlParams.has('tvwidgetsymbol') ) {
              let queryTicker = urlParams.get('tvwidgetsymbol');
              if( queryTicker ) {
                 // override any previous 'lastSearchedSymbol' search before mount
                 this.lastSeachedSymbol = queryTicker;                    // update data item
                 localStorage.setItem('lastSeachedSymbol', queryTicker ); // update localStorage
              }
          }
        //*************** END QUERY PARAM OVERRIDE ****************/
 
        // *** toggle Update WatchList Show/No Show by checking watchList of last searched - before setup
        this.showUpdateWatchListBtn = this.notInWatchList( this.lastSeachedSymbol );
 
        // clear any previous ads
        this.hideAds();

      },
      /************************************************************
       * component is fully displayed and mounted in card
       ************************************************************/
      mounted() {
          // 1 sec delay - then remove loading indicater       
          setTimeout( ()=> this.$loading(false), 1500 ); 

          // check for setting - time out is set in .env file for ads 
          if( this.time_delay <= 0 ) { this.time_delay = 2500; }

          // if enabled in .env file of root
          this.showHideAds();

      },
      beforeUnmount() {
          // send event to parent (AppBar.vue) to close this drawer
          this.hideAds();
      },
      metaInfo() {
        return {
          title: "Stock-Insight's TradingView Widget - Real-Time Charts",
          meta: [
            { name: 'robots', content: 'index,follow'},
            { name: 'description', content: 'TradingView Real-Time Charts' },
            { name: 'keywords', content: 'TradingView, NYSE, market, stock, chart, financial, news, data' },               
            { property: 'og:type', content: 'website'}, 
            { property: 'og:site_name', content: 'www.Stock-Insight.net'},
            { property: 'og:title', content: "Stock-Insight's TradingView Widget - Real-Time Charts"},                             
            {
              vmid: "description",
              name: "description",
              content: "TradingView Widget - Real-Time Charts"
            }
          ],
          link: [
            {
              rel: 'canonical',
              href: '<https://www.stock-insight.net/>'
            }
          ]
        }
      }           
    }
</script>

