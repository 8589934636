/*****************************************************
           Vue main setup file 
******************************************************/
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.css'

//--- vue setup sequence -----------------*
import Vue     from 'vue'
import App     from './App.vue'
import vuetify from './plugins/vuetify'  // vuetify css library
import router  from './router'
import store   from './store'

//*** i18n for international support installed from vue ui dependencies list ****
// -- added from terminal -> vue add i18n
// outlined here: [ https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n/ ]
import i18n from "./i18n";

/****************************************************************************
 * npm terminal install instructions for fontawesome libraries before using
 * 
 *  npm install @fortawesome/fontawesome-free -D
 *  npm i --save @fortawesome/fontawesome-svg-core
 *  npm i --save @fortawesome/free-solid-svg-icons
 *  npm i --save @fortawesome/vue-fontawesome@latest-2
 * 
 **************************************************************************/

/* import the fontawesome libraries */
import { library, dom }    from "@fortawesome/fontawesome-svg-core";
    import { faCode }      from "@fortawesome/free-solid-svg-icons";
    library.add(faCode)
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas }             from '@fortawesome/free-solid-svg-icons'
import { faVuejs }         from '@fortawesome/free-brands-svg-icons';
import { far }             from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, faVuejs)
dom.watch();

/* register - add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

/*********************************************************
 * global event bus using 'mitt' ----> for vue 3 only !!!!
 * 
 * [ https://medium.com/@certosinolab/using-event-bus-in-vue-js-3-425aae8c21a6 ]
 ********************************************************/
 //import { createApp } from 'vue'
 //import mitt from 'mitt'
 //const emitter = mitt()
 //const app = createApp(App)
 //app.config.globalProperties.emitter = emitter
 //Vue.use( emitter );  // reference to call from code

 /*********************************************************************
 * event bus for vue 2 - allows communication between components
 * 
 *    global communications bus
 */
export const eventBus = new Vue();

/**********************************************************************
 * https://phiny1.github.io/v-currency-field/started.html#vue-cli
 * [ https://phiny1.github.io/v-currency-field/config.html#component-props  ]
 **********************************************************************/
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'  //Globally import VTextField

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, { 
    locale: 'en-US',
    decimalLength: 2,
    autoDecimalMode: true,
    allowBlank: true,
    min: null,
    max: null,
    defaultValue: 0,
    valueAsInteger: false,
    allowNegative: false,
    iconfont: "faSvg",
})

/**
 * vue-cookies - install [ npm install vue-cookies --save ]
 * url info [ https://www.npmjs.com/package/vue-cookies  ]
 * 
 */
 Vue.use(require('vue-cookies'));
   // 30 day after, expire
   Vue.$cookies.config('30d');

/******* allows formatting of numeric numbers ***********
 * Format js - install from console [ npm i -S vue-intl ]
 * 
 * url info [ https://formatjs.io/ ] 
 * 
 *     Only works with Vue 3.2.23 
 * 
 ********************************************************/

/**************************************************************************
 *  load external scripts plugin - specifically for trading view components ? 
 * 
 *  Not currently used ... probably not necessary 
 * 
 *  [ https://www.npmjs.com/package/vue-plugin-load-script ]
 *  used older version 1.3.2 - 
 *   from terminal npm install    --save vue-plugin-load-script@^1.3.2
 * 
 */
 // import LoadScript from "vue-plugin-load-script";
 // Vue.use(LoadScript);

/************************************************************************
 *  installed v-mask for text fields
 *  from terminal in folder, [ npm install v-mask ]
 */
 import { VueMaskDirective } from "v-mask";
 Vue.directive("mask", VueMaskDirective);
 
/************************************************************************
    SweetAlert dialog library - pretty popups
    info: [ https://www.npmjs.com/package/vue-sweetalert2 ] also
  installed SweetAlert and supported themes from terminal command line
    [npm install -S vue-sweetalert2] and [npm install @sweetalert2/themes]    
************************************************************************/
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// add popup model dialog library globaly
Vue.use(VueSweetalert2);
Vue.config.productionTip = false

/**
 * Vue Calendar plugin - instead of vuetify version - supports i18n better
 * [ https://vcalendar.io/ ]
 * 
 * install from terminal @ project folder
 *   npm install v-calendar
 */
 //import VCalendar from 'v-calendar';  NOT CURRENTLY IN USE
 //Vue.use(VCalendar,{ componentPrefix: 'vc'})

/**
 *    DAY.JS
 * 
 * [ https://www.npmjs.com/package/dayjs ] 
 * [ https://day.js.org/docs/en/plugin/relative-time ]
 * 
 * npm install vue-dayjs-plugin or npm install dayjs --save
 * 
 */
 import VueDayjs from 'vue-dayjs-plugin'
 Vue.use(VueDayjs) 

 /************************************************************
  *  axious communications wrapper
  *  from project terminal -> [ npm install --save axios vue-axios  ]
  *  info: [ https://www.npmjs.com/package/vue-axios  ]
  */
  import axios from 'axios' 
  import VueAxios from 'vue-axios' 
  Vue.use(VueAxios, axios)   

  /**
   * loading / spinner plugin - install instructions
   * [ https://www.npmjs.com/package/vuejs-loading-plugin ]
   * 
   */
  import VueLoading from 'vuejs-loading-plugin'
    // using default options
    Vue.use(VueLoading)
 
/************************************************************************************************************
 * Google Analytics Plugin - replaced with javascript block in index.html for new GA code
 * [ https://medium.com/js-dojo/how-to-use-google-analytics-in-your-vue-app-with-vue-analytics-9cdb913301d1 ]
 ************************************************************************************************************/
   import VueAnalytics from 'vue-analytics';
  // replace by adding javascript block to index.html 6.4.23
  // GA4 ( 369275658 ) - starting July 1, 2023
  // old number: id: 'UA-264459442-1'
  // and then use it in main.js
   Vue.use( VueAnalytics, {
     id: 'G-1WHZTVDPT9',
     router
   })

 /********************************************************
  * vue-meta library - Google search tags
  * info: [ https://levelup.gitconnected.com/how-to-add-metadata-in-vue-using-vue-meta-55c593f4bbf5 ]
  * [ https://www.digitalocean.com/community/tutorials/vuejs-vue-meta ]
  * [ https://medium.com/js-dojo/how-to-add-dynamic-meta-tags-to-your-vue-js-app-for-google-seo-b827e8c84ee9 ]
  *******************************************************/
 import Meta from "vue-meta";
 //Vue.use(Meta,{ refreshOnceOnNavigation: true});
 Vue.use(Meta)

/*********************************************************
 * Google Adsence plugin ( installed 01.09.23 )
 * Info Url [ https://www.npmjs.com/package/vue-google-adsense ]
 * from terminal in project folder installed -> [ npm install vue-script2 vue-google-adsense --save ]
 ****************************************************************/
 import Ads from 'vue-google-adsense'
 Vue.use(require('vue-script2'))

 Vue.use(Ads.Adsense)
 Vue.use(Ads.InArticleAdsense)
 Vue.use(Ads.InFeedAdsense)
  /* ---------[ place these in Tools Ads, Right Slide Out; and RSS - server side ]-------- as needed
      VueAdsense Template example :

      <Adsense
          data-ad-client="ca-pub-XXXXXXXXXXXXXXXX"
          data-ad-slot="1234567890">
      </Adsense>
      VueInArticleAdsense Template :

      <InArticleAdsense
          data-ad-client="ca-pub-XXXXXXXXXXXXXXXX"
          data-ad-slot="1234567890">
      </InArticleAdsense>
******************************************************************/
// -- init application --*
new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

/*****************************************
  load home page as default - replace('/')
  by redirecting initial load to root
  very import in router 'abstract' mode
 
  need to investigate  vue-router-webcache for possible use
    [ https://www.npmjs.com/package/vue-router-webcache ]

  //  router.replace('/');

******************************************/

/*****************************************
 *  later need to investigate JWT library for handling webtokens
 *  npm install --save axios jwt-decode
 *  info: [ https://chrismroberts.com/2019/01/03/authentication-and-protected-routes-in-vuejs/  ]
 * 
 ***********************************************************************************/
