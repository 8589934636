<!--
    component to display ticker info in Calls, Puts, and Margin
-->
<template>
    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header class="justify-center" color="#e6f2ff">{{this.exchange}}&nbsp;:&nbsp;<b>{{this.shortName}}</b></v-expansion-panel-header>
            <v-expansion-panel-content color="#cce6ff">
            <v-card class="mx-auto" min-width="225" outlined >
                <v-card-title class="justify-center" >{{this.shortName}}</v-card-title>
                <v-card-subtitle>Analyst Rating:<b>{{this.averageAnalystRating}}</b></v-card-subtitle>
                <v-card-text>
                    <v-container>
                        <v-row no-gutters dense class="fill-height" >
                            <v-col md="4" align-start>
                                <v-chip label outlined class="standard" >
                                    Mrkt Open: {{ this.numberWithCommas((this.regularMarketOpen)) }}
                                </v-chip>
                            </v-col>
                            <v-col md="4" align-start>
                                <v-chip label outlined class="standard">
                                   Prv Cls: {{ this.numberWithCommas((this.regularMarketPreviousClose)) }}
                                </v-chip>
                            </v-col>
                            <v-col md="4" align-start>
                                <v-chip label outlined class="standard">
                                  52-Wk H: {{ this.numberWithCommas((this.fiftyTwoWeekHigh)) }}
                                </v-chip>
                            </v-col>
                        </v-row>                         
                        <v-row no-gutters dense class="fill-height" >
                            <v-col md="4" align-start>
                                <v-chip label outlined class="standard">
                                   Ask Prc: {{ this.numberWithCommas((this.ask)) }}
                                </v-chip>
                            </v-col>
                            <v-col md="4" align-start>
                                <v-chip label outlined class="standard">
                                   Bid Prc: {{ this.numberWithCommas((this.bid)) }}
                                </v-chip>
                            </v-col>
                            <v-col md="4" align-start>
                                <v-chip label outlined class="standard">
                                  Prc 2 Bk: {{ this.numberWithCommas((this.priceToBook)) }}
                                </v-chip>
                            </v-col>
                        </v-row> 
                        <v-row no-gutters dense class="fill-height" >
                            <v-col md="4" align-start>
                                <v-chip label outlined class="standard">
                                  Day High: {{ this.numberWithCommas((this.regularMarketDayHigh )) }}
                                </v-chip>
                            </v-col>
                            <v-col md="4" align-start>
                                <v-chip label outlined class="standard">
                                  P/E Rt : {{ this.numberWithCommas((this.priceEpsCurrentYear)) }}
                                </v-chip>
                            </v-col>
                            <v-col md="4" align-start>
                                <v-chip label outlined class="standard">
                                   52-Wk L: {{ this.numberWithCommas((this.fiftyTwoWeekLow)) }}
                                </v-chip>
                            </v-col>                        
                        </v-row> 
                        <v-row no-gutters dense class="fill-height" >
                            <v-col md="4" align-start>
                                <v-chip label outlined class="standard">
                                  Day Low: {{ this.numberWithCommas((this.regularMarketDayLow)) }}
                                </v-chip>
                            </v-col>
                            <v-col md="4" align-start>
                                <v-chip label outlined class="standard">
                                  Div Yd: {{ this.numberWithCommas((this.trailingAnnualDividendRate)) }}
                                </v-chip>
                            </v-col>
                            <v-col md="4" align-start>
                                <v-chip label outlined class="standard">
                                  Type: {{ this.quoteType }}
                                </v-chip>
                            </v-col>                        
                        </v-row>  
                        <v-row>
                           <v-col>
                               Date: {{ new Date(this.created * 1000).toLocaleString("en-US", {timeZone: "America/New_York"}) }}
                           </v-col>
                        </v-row>
                        <v-row>
                           <v-col>
                              <v-icon color="yellow" left>mdi-alert-circle</v-icon>{{tickerNotice}} 
                           </v-col>
                        </v-row>                        
                    </v-container>                                       
                </v-card-text>
            </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>                    
</template>

<script>
// all labels are defined in src/locals folder in json files - ie: en.json for English settings
import i18n from "@/i18n"   

export default {
    name: "TickerInfoDisplay",
    // listen from parent to update data
    props: [ 'jsonData' ],
    watch: { 
         // watch props passed - then parse into data elements
         jsonData: function() {
            // display expansion box after a slight delay
            setTimeout( ()=> { this.updateDisplay(); }, 250 );            
        }
    },   
    data() {
      return {
        // ticker related data fields
        ticker: '',
        tickerInfo: '',
        fiftyDayAverage: '',
        fiftyTwoWeekHigh: '',
        fiftyTwoWeekLow: '',
        regularMarketPrice: '',            
        quoteSourceName: '',
        longName: '',
        shortName: '',
        sharesOutstanding: '', 
        quoteType: '', 
        averageAnalystRating: '', 
        regularMarketOpen: '',
        regularMarketDayHigh: '',
        regularMarketDayLow: '',
        trailingPE: '',
        marketCap: '',
        priceEpsCurrentYear: '',
        bid: '',
        ask: '',
        messageBoardId: '',
        trailingAnnualDividendRate: '',
        priceToBook: '',
        regularMarketPreviousClose: '',
        exchange:'',
        created:'',
        // determines on object creation if running on mobile device true or false - although hard coded init
        isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),
        mobileClass:  'mobile',
        desktopClass: 'desktop',
        tickerNotice: i18n.t('ticker_info_btm_notice'),        
      }
    },
    methods: {
        /*
           update method uses json data passed to assign ticker data in component for
           display in the expandable dialog box
        */
        updateDisplay() {
            this.quoteSourceName      = this.jsonData[0].quoteSourceName; 
            this.longName             = this.jsonData[0].longName;  
            this.shortName            = this.jsonData[0].shortName;                                         
            this.fiftyDayAverage      = this.jsonData[0].fiftyDayAverage;
            this.fiftyTwoWeekHigh     = this.jsonData[0].fiftyTwoWeekHigh;
            this.fiftyTwoWeekLow      = this.jsonData[0].fiftyTwoWeekLow;
            this.regularMarketPrice   = this.jsonData[0].regularMarketPrice;
            this.quoteType            = this.jsonData[0].quoteType;          
            this.averageAnalystRating = this.jsonData[0].averageAnalystRating; 
            this.regularMarketOpen    = this.jsonData[0].regularMarketOpen;   
            this.regularMarketDayHigh = this.jsonData[0].regularMarketDayHigh;  
            this.regularMarketDayLow  = this.jsonData[0].regularMarketDayLow;                        
            this.trailingPE           = this.jsonData[0].trailingPE;  
            this.marketCap            = this.jsonData[0].marketCap;          
            this.bid                  = this.jsonData[0].bid;
            this.ask                  = this.jsonData[0].ask; 
            this.priceEpsCurrentYear  = this.jsonData[0].priceEpsCurrentYear;    
            this.trailingAnnualDividendRate  = this.jsonData[0].trailingAnnualDividendRate;  
            this.messageBoardId       = this.jsonData[0].messageBoardId;   
            this.priceToBook          = this.jsonData[0].priceToBook;  
            this.regularMarketPreviousClose = this.jsonData[0].regularMarketPreviousClose; 
            this.exchange             = this.jsonData[0].exchange;
            this.created              = this.jsonData[0].created;
        },
        numberWithCommas(num) {
             //return num < 0 ? '( '+num.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',').replace(/-/g,'')+' )' : num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
             var retVal;
             if( num < 0 ) {
                // remove - negative sign and surround with parans ()
                retVal = '( '+(new Intl.NumberFormat( this.$i18n.locale ).format(num).replace(/-/g,''))+' )'
             } else {
                retVal = new Intl.NumberFormat( this.$i18n.locale ).format(num);
             }
             return retVal ;             
          },         
    }
}
</script>

<style scoped>
   .standard {
      width: 150px;
   }
</style>