<!------------------------------------------------------------------------------------------

    Advanced Techical TradingView Chart - with :key to trigger reload component - refresh
    used on 'Real-Time' Charts component tab  :key="this.symbol"

------------------------------------------------------------------------------------------->
<template>
    <v-container fluid class="fill-height" :key="this.symbol" column > 
        <v-layout justify-center fluid align-center>    <!-- min-width="225" width="550" max-width="600" -->    
            <!-- desktop show analytics version - searchable ticker etc ... -->
            <!-- TradingView Desktop Widget BEGIN -->
            <div class="tradingview-widget-container">
                <div id="technicalWidget" class="tradingview-widget-container__widget" style="height:calc(100% - 32px);width:100%"></div>
                <div class="tradingview-widget-copyright"><span class="blue-text">Technical Analysis Chart</span> by TradingView</div>
                <script type="application/javascript" defer src="https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js" async>
                    {
                    "container_id": "technical-analysis-chart-demo",
                    "width": "{{this.widgetWidth}}",
                    "height": "{{this.widgetHeight}}",
                    "autosize": true,
                    "symbol": "{{this.symbol}}",
                    "interval": "D",
                    "timezone": "exchange",
                    "theme": "light",
                    "style": "1",
                    "withdateranges": true,
                    "hide_side_toolbar": false,
                    "allow_symbol_change": false,
                    "save_image": true,
                    "studies": [
                        "ROC@tv-basicstudies",
                        "StochasticRSI@tv-basicstudies",
                        "MASimple@tv-basicstudies"
                    ],
                    "show_popup_button": true,
                    "popup_width": "1000",
                    "popup_height": "650",
                    "support_host": "https://www.tradingview.com"
                    }
                </script>
            </div>
            <!-- TradingView Widget END -->
        </v-layout>
    </v-container>        
</template>

<script>
        // i18n international language support for labels - should use browser locale default settings
        // all labels are defined in src/locals folder in json files - ie: en.json for English settings
        import i18n from "@/i18n"  

        import { eventBus } from '@/main'  // event bus communication for registered components
        export default {
            // need to pass ticker in props to sync all child components 
            props: { ticker: { type: String, default: '' } },
            data: () => ({
                    locale: i18n.locale,
                    value: null,
                    // determines on object creation if running on mobile device true or false
                    isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))),
                    // *** following settings are designed for desktop - flipped if 'mobile' before mount ***   650           
                    cardWidth: "100%",
                    widgetWidth: "100%",
                    widgetHeight: 695,  
                    symbol: "AAPL", // ticker feed passes 'tvwidgetsymbol' params to this component
                    showAdsID : 0,
                    hideAdsID : 0,    
                    time_delay: Number( process.env.VUE_APP_ADS_DELAY ),  // retrieve setting from .env file   
                    errors: {},                   
                }),
            methods: {
                forceRerender() {
                    // set spinner - to hide screen update
                    this.$loading(true);                
                      this.componentKey = this.symbol; // trigger to reload component only 
                    setTimeout( ()=> { this.$loading(false); }, 1000 );  
                },            
                tickerUpdate( ticker ) {
                    // update component symbol from 'emitted' event call from parent
                    this.symbol = ticker;
                    this.forceRerender();
                    //console.log("Ticker Recvd: "+ticker+" Ticker = "+this.symbol);               
                },
            },
            beforeMount() {
                // set spinner
                this.$loading(true);
            },
            mounted() {
                // listen for Ticker Change - from parent component
                eventBus.$on("ticker-update", this.tickerUpdate, 'ticker' );   
                // 1.5 sec delay - then remove loading indicater       
                //setTimeout( ()=> { this.$loading(false); this.tickerUpdate( this.ticker ) }, 1500 );
                var ticker = localStorage.getItem( "lastSeachedSymbol" );
                // make sure ticker matches last searched
                if( ticker ) { this.symbol = ticker; }
                // clear wait spinner                 
                setTimeout( ()=> { this.$loading(false); } );

            },
        }
</script>

<style scoped>

</style>