<!--
           Stock Percentage Calculator Tab hosted in '/views/StockPercentagePriceView.vue'
-->
<template>
    <v-card color="#C9F9F7" style="position: relative" >
        <v-card-title class="text-h6 justify-center">{{$t("stock_prct_prce_page.prct_tab_subtitle")}}</v-card-title>
            <v-card-text style="height:100%;width:100%">
                <v-row justify="center" >                      
                    <v-col cols="8" sm="8" md="6" lg="4" >
                        <v-currency-field 
                            name="currentPrice" 
                            :label="$t('stock_prct_prce_page.prct_tab_crnt_prce_lbl')"                             
                            :allow-negative="false"                
                            :decimalLength=2
                            :value-as-integer="false"                              
                            prepend-icon="mdi-currency-usd"
                            v-model="currentPrice"
                            clearable
                            @blur="calculateGainLoss"
                            :rules="currentPrice_Rules"
                            required />
                    </v-col>
                    <v-col cols="8" sm="8" md="6" lg="4" >
                        <v-currency-field 
                            name="expectedPrice" 
                            :label="$t('stock_prct_prce_page.prct_tab_expt_prce_lbl')"                            
                            :allow-negative="false"                
                            :decimalLength=2
                            :value-as-integer="false"                              
                            prepend-icon="mdi-hand-heart-outline"
                            v-model="expectedPrice"
                            clearable
                            :rules="expectedPrice_Rules"                            
                            @blur="calculateGainLoss"                            
                            required />

                    </v-col>
                    <v-col cols="8" sm="8" md="6" lg="4" >
                        <v-currency-field 
                            name="percentGainLoss"
                            :label="$t('stock_prct_prce_page.prct_tab_gain_loss_lbl')"                            
                            :allow-negative="true"                
                            :decimalLength=2
                            :value-as-integer="false"                              
                            prepend-icon="mdi-percent-outline"
                            v-model="percentGainLoss"
                            readonly
                            required />
                    </v-col>
                </v-row>                   
            </v-card-text>
    </v-card> 
</template>

<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"   

    export default {
      data: () => ({
        currentPrice: null,
        expectedPrice: null,
        percentGainLoss: null, 
        locale: i18n.locale,
        // determines on object creation if running on mobile device true or false        
        isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))), 
        currentPrice_Rules: [
            v => !!v || i18n.t('stock_prct_prce_page.prct_tab_crnt_prce_req_lbl'), 
            v => (v && parseFloat(v.replace(/,/g,'')) > 0.00 && parseFloat(v.replace(/,/g,'')) <= 900000000000) || i18n.t('stock_prct_prce_page.prct_tab_expt_prce_err_lbl'),
        ],                             
        expectedPrice_Rules: [
            v => !!v || i18n.t('stock_prct_prce_page.prct_tab_expt_prce_req_lbl'), 
            v => (v && parseFloat(v.replace(/,/g,'')) > 0.00 && parseFloat(v.replace(/,/g,'')) <= 90000000000000) || i18n.t('stock_prct_prce_page.prct_tab_expt_prce_err_lbl'),
        ],     
      }),
      errors: {},      
      // set locale when the object is mounted so date is displayed in correct language
      beforeMount: function() {
        //this.currentLocale = this.$i18n.locale;
        //console.log('Before mount try to change language from setting: '+this.currentLocale );                
      },      
      mounted() {
        // make sure library 'days.js' calculations match current locale settings
        //dayjs.locale( this.$i18n.locale );
      },      
      methods: {
        calculateGainLoss() {
            // if not null for both values - calculate gain/loss --*
            if( this.currentPrice && this.expectedPrice ) {
                // *** first get form field values - individually used in calculated sections***
                var currentPrc  = Number.parseFloat(this.currentPrice.toLocaleString(this.$i18n.locale).replace(/,/g,''));
                var expectedPrc = Number.parseFloat(this.expectedPrice.toLocaleString(this.$i18n.locale).replace(/,/g,''));

                if( currentPrc != 0 && expectedPrc != 0 ) {
                    this.percentGainLoss = (((expectedPrc-currentPrc)/currentPrc)*100);
                } else {
                    this.percentGainLoss = 0;
                }
            }
        },
        // formats numbers with commas, negative number wrapped with params
        numberWithCommas(num) {
             //return num < 0 ? '( '+num.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',').replace(/-/g,'')+' )' : num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
             var retVal;
             if( num < 0 ) {
                // remove - negative sign and surround with parans () to signify negative number
                retVal = '( '+new Intl.NumberFormat( this.$i18n.locale ).format(num).replace(/-/g,'')+' )'
             } else {
                retVal = new Intl.NumberFormat( this.$i18n.locale ).format(num)
             }
             return retVal;             
        },                                
      },
    
    }
</script>