import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{staticClass:"mt-5 pa-0",attrs:{"align-center":"","justify-start":"","column":""}},[_c(VFlex,{attrs:{"row":"","align-center":""}},[_c(VCard,{staticClass:"mx-auto mb-15 flexcard",attrs:{"min-width":this.cardMinWidth,"width":this.cardWidth,"height":this.cardHeight,"hover":""}},[_c(VImg,{staticClass:"white--text align-center",attrs:{"width":"auto","height":"75px","src":require("@/assets/news_1280.jpg")}},[_c(VCardTitle,{staticClass:"justify-center"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"1"}}),_c(VCol,{staticClass:"pt-2",attrs:{"cols":"9"}},[_c('span',{staticClass:"text-h4 red--text font-weight-black"},[_vm._v("Trading News")])]),_c(VCol,{attrs:{"cols":"2"}},[_c('Bookmark',{attrs:{"link":"TradingViewSnaps"}})],1)],1)],1)],1),_c(VCardText,{staticClass:"text--primary"},[_c('div',{staticClass:"tradingview-widget-container"},[_c('div',{staticClass:"tradingview-widget-container__widget"}),_c('div',{staticClass:"tradingview-widget-copyright"},[_c('span',{staticClass:"blue-text"},[_vm._v("Daily News Roundup")]),_vm._v(" by TradingView")]),_c('script',{attrs:{"type":"application/javascript","defer":"","src":"https://s3.tradingview.com/external-embedding/embed-widget-timeline.js","async":""}},[_vm._v(" { \"feedMode\": \"all_symbols\", \"colorTheme\": \"light\", \"isTransparent\": \"false\", \"displayMode\": \"regular\", \"width\": \""+_vm._s(this.widgetWidth)+"\", \"height\": \""+_vm._s(this.widgetHeight)+"\", \"locale\": \""+_vm._s(this.locale)+"\", \"largeChartUrl\": \"https://stock-insight.net/symbolchart\" } ")])])]),_c(VDivider),_c(VCardActions)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }