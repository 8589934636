<!--
   ADS - for tool bottom insert - Prototype used for horizontal ads
-->
<template>
  <div v-if="showAds && showAds === 'true'"> 
    <v-card flat >
        <v-divider></v-divider>        
        <v-card-text class="ma-0 pa-0">
            <v-container fill-height >
              <v-flex fill-height >
                <v-row dense>  
                  <v-col cols="12">
                    <div class="d-flex flex-column justify-space-between align-center"> 
                      <!-- START DEMO MODE SECTION -->
                         <v-img width="85%" max-width="95%" height="80%" max-height="95%" :src="Urls[ currentAd ].url"></v-img>
                      <!-- END DEMO MODE SECTION -->

                      <!--- Denied by AdSense 12.10.23 
                        once account is created an verified - update here 
                          also update .env file setting VUE_APP_ADS=true - and rebuild webapp
                          don't forget to remove upper place holder v-img image ... above this block
                          <Adsense
                              data-ad-client="ca-pub-XXXXXXXXXXXXXXXX"
                              data-ad-slot="1234567890">
                          </Adsense>
                          type="application/javascript"
                          <v-img defer src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaFYTzIPiXeB_NHjrfbvN2qx33kWDtwGBZ0A&usqp=CAU">

                         <v-img :width="maxWidth" :height="maxHeight" src="@/assets/ad-placeholder.png"></v-img>    
                    -->
                    <!--
                       <script type="application/javascript" defer src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9537720061722515"
                          crossorigin="anonymous" async></script>
                       <ins class="adsbygoogle" style="display:block; text-align:center; width:300px; height:150px;"
                          data-ad-layout="in-article"
                          data-ad-format="fluid"
                          data-ad-client="ca-pub-9537720061722515"
                          data-ad-slot="1488260346"></ins>
                          <script type="application/javascript" defer >(adsbygoogle = window.adsbygoogle || []).push({});</script>
                    -->
                    </div>
                 </v-col>
                </v-row>                
              </v-flex>            
            </v-container>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
    var adsbygoogle;
    // check for 'demo' mode - passed /?demo query string to show what ads 'might' look like
    var mode = sessionStorage.getItem('mode');      
    export default {
        data: () => ({
          // determines on object creation if running on mobile device true or false
          // isMobile: window.matchMedia("(max-width: 700px)").matches,  
          isMobile: ( (/Mobile/i.test(navigator.userAgent) ) ),
          showAdDelay: Number( process.env.VUE_APP_ADS_DELAY ), // define delay appear of Ad
          // check if ads are setup in .env file - marked as 'true' once available to application
          showAds: process.env.VUE_APP_ADS,
          maxHeight:100,
          maxWidth: 350,
          currentAd: 0,
          Urls: [
               {id: 0, url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaFYTzIPiXeB_NHjrfbvN2qx33kWDtwGBZ0A&usqp=CAU" },
               {id: 1, url: "https://655969.fs1.hubspotusercontent-na1.net/hubfs/655969/Newspaper%20Advertising%20Key%20Takeaways.mp4/medium.jpg"},
               {id: 2, url: "https://blog.releasemyad.com/wp-content/uploads/2015/02/Most-Effective-Newspaper-Ads.jpg"},
               {id: 3, url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4Q1EZzremBUrU7fugkWCBEG_jvuS93UV0Bw&usqp=CAU"},
               {id: 4, url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZg5v_yXe5rFQD13IWR0YpXTqz1O3CCfGwtA&usqp=CAU"},
               {id: 5, url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqa7sh_0dkLVAToIwKm1u7Z2NxtHMnQIZ_-A&usqp=CAU"},
               {id: 6, url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1aeaXZDTPEU2exUtUuVeIOoGjZQ1bVAQUIQ&usqp=CAU"},
               {id: 7, url: "https://www.webfx.com/wp-content/uploads/2021/10/social-media-ad-Examples__01.png"}
          ]
        }),
        // life cycle methods
        beforeMount() {
           // check .env file settings for mobile defined width => [ VUE_APP_MOBILE_MEDIA_QUERY_WIDTH=700px ]
           //if( this.isMobile ) { this.maxHeight = 50; this.maxWidth  = 200; }
           // check for 'demo' mode - passed /?demo query string to show what ads 'might' look like
           mode = sessionStorage.getItem('mode');     
           if( mode && mode === 'demo' ) {
               this.showAds = 'true';
               //console.log("DEMO SHOW ADS BM = "+this.showAds);           
           }
           // debugging
           //console.log("SHOW ADS = "+this.showAds);   
        }, 
        mounted() {
           //setTimeout( (adsbygoogle = window.adsbygoogle || []).push({}), 1500 );
           // set current ad - randomly accordingly to size of Ad array
           this.currentAd = this.getRandomInteger( 0, this.Urls.length -1 );               
        },
        methods: {
            getRandomInteger( min, max ) {
              var num = Math.round(Math.random() * max + min )
              //console.log("Random # = "+ num )
              return( num )
            }          
        }         
    }
</script>

<style scoped>
    .cardColor {
      background-color: rgba(255, 255, 255, 0.9) !important;
      border-color: white !important;
    }
    html {
      overflow-y: auto !important;
    }
</style>