<!----------------------------------------------------------------------------

   mortgage calculators main page host to multiple calculators from array

   @click.prevent="updateSelectedTab( item.id )"  width="{cardWidth}"
----------------------------------------------------------------------------->
<template>
 <v-container class="fill-height printable" > 
    <v-layout justify-center align-center>  
        <v-card min-width="225" width="650" max-width="850" hover elevation="5">
            <!-- mortgage toolbar -->
              <v-toolbar :src="require('@/assets/agenda_1280.jpg')" >
                  <v-toolbar-title  class="text-h4 flex white--text text-xs-center mt-2 pt-0" >
                    <!-- user help button -->
                    <v-row dense>
                        <v-col cols="1">
                            <!-- user help button -->
                            <v-btn class="mx-1 mt-0 pt-0" fab small dark color="green" @click.prevent.stop="getMortgageHelp" >
                                <v-icon dark>fa-solid fa-question</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="pt-2 d-flex justify-center" cols="9">
                          <span class="text-h5 font-weight-bold">&nbsp;&nbsp;Mortgage Calculators</span>
                        </v-col>
                        <v-col cols="2">
                            <!-- track bookmarks -->
                            <Bookmark class="mt-0 pt-0" link="mortgageCalculators"/>
                        </v-col>                    
                    </v-row>
                  </v-toolbar-title>
                  <template v-slot:extension>
                    <v-tabs show-arrows v-model="tab" slider-color="red" background-color="rgba(46, 62, 131, 0.5)" class="ml-1 mr-0 pa-0" stacked icons-and-text dark>
                        <v-tab v-for="item in items" :key="item.tab" active-class="active-tab" >
                            <div v-if="isMobile">
                                <v-icon>{{item.icon}}</v-icon>
                            </div>
                            <div v-else>
                                <v-icon left>{{item.icon}}</v-icon>{{item.tab}}
                            </div>                  
                        </v-tab>
                    </v-tabs>
                  </template>
              </v-toolbar>
              <!-- tab content components items list here  {{ item.tab }}  -->
              <v-window v-model="tab">
                <v-window-item v-for="item in items" :key="item.tab" >
                    <component id="item.name" :is="item.content" class="mt-0 " ></component>
                </v-window-item>                  
              </v-window>
              <!-- create help wiki here - hidden until called by event -->
              <MortgageWiki />
        </v-card>
     </v-layout>
 </v-container>
</template>

<script>
    // event bus communication for registered components
    import { eventBus }      from '@/main'  
    import i18n              from "@/i18n"

    // wiki tool document used when user presses 'help' button
    import MortgageWiki      from '@/components/AboutMortgageCalculatorsWiki.vue'   
    // user bookmarking component
    import Bookmark          from '@/components/AddToFavorites.vue'        

    // first tab component- payments
    import paymentCalculator from '@/components/widgets/tools/mortgagePayment.vue'
    
    export default {
      name: "mortgage-calculators",
      components: { paymentCalculator, MortgageWiki, Bookmark },
      data: () => ({
          tab: 0,
          model: 'Payments',
          mobileRptBar: i18n.t('rss_mobile_reportbar'),
          listIcon: 'fa-solid fa-list',                     
          value: null,
          cardMinWidth: "90%",
          cardWidth: "90%",
          cardMaxWidth: "98%",
          cardMinHeight:850,
          cardHeight: 900, 
          itemSelected: 0,
          showAdsID : 0,
          hideAdsID : 0,               
          time_delay: Number( process.env.VUE_APP_ADS_DELAY ),           
          items: [
            { id: 0, tab: 'Payments', content: 'paymentCalculator', name: 'paymentCaclulator',  icon:'fa-solid fa-calculator' }, 
                            
          ],                    
          // determines on object creation if running on mobile device true or false
          isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),                  
          screenHeight: window.screen.height,
          screenWidth:  window.screen.width,  
        }),
        methods: {
            getMortgageHelp() {
               // emits global event for component - to open help document from button
               eventBus.$emit("show-mortgage-wiki", "showMortgageHelp");
            },  
        },  
        beforeMount() {
            this.$loading(true); 
        }, 
        mounted() {
             // .5 sec delay - then remove loading indicater       
             setTimeout( ()=> this.$loading(false), 500 );            
        },              
        metaInfo: {
              title: "Stock-Insight's Mortgage Calculators",
              meta: [
                { name: 'robots', content: 'index,follow'},
                { property: 'og:type', content: 'website'}, 
                { property: 'og:site_name', content: 'www.Stock-Insight.net'},
                { property: 'og:title', content: "Stock-Insight's Mortgage Calculators"},                             
                {
                  //vmid: "description",
                  name: "description",
                  content: "Stock-Insight.net 'Maybe We Can Help You Connect The Dots' in Stock News"
                }
              ],
              link: [ { rel: 'canonical', href: '<https://stock-insight.net/>' } ]
        }, 
      }
</script>

<style scoped >
  .v-tab:active {
     background-color:black !important;
  }
  .v-tab:hover {
     background-color: black;
     border-color: blue;
  }
  /* sets active tab color */
  .active-tab {
    background-color:black !important;
  }
</style>

<!-- koji sample -------------------------------------------*

<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Mortgage Calculator</title>
  </head>
  <body>
    <h1>Mortgage Calculator</h1>
    <form id="mortgage-form">
      <label for="home-value">Home Value:</label>
      <input type="number" id="home-value" required />
      <br />
      <label for="down-payment">Down Payment:</label>
      <input type="number" id="down-payment" required />
      <br />
      <label for="loan-term">Loan Term (years):</label>
      <select id="loan-term">
        <option value="15">15 years</option>
        <option value="30">30 years</option>
      </select>
      <br />
      <label for="interest-rate">Interest Rate (%):</label>
      <input type="number" id="interest-rate" step="0.01" required />
      <br />
      <label for="property-tax">Property Tax (Yearly):</label>
      <input type="number" id="property-tax" required />
      <br />
      <label for="house-insurance">House Insurance (Yearly):</label>
      <input type="number" id="house-insurance" required />
      <br />
      <label for="private-mortgage-insurance"
        >Private Mortgage Insurance (Monthly):</label
      >
      <input
        type="number"
        id="private-mortgage-insurance"
        step="0.01"
        required
      />
      <br />
      <label for="hoa">HOA (Monthly):</label>
      <input type="number" id="hoa" required />
      <br />
      <button type="button" onclick="calculateMortgage()">Calculate</button>
    </form>
    <p id="result"></p>
    <script>
      function calculateMortgage() {
      const homeValue = parseFloat(document.getElementById('home-value').value);
      const downPayment = parseFloat(document.getElementById('down-payment').value);
      const loanTerm = parseInt(document.getElementById('loan-term').value);
      const interestRate = parseFloat(document.getElementById('interest-rate').value) / 100;
      const propertyTaxYearly = parseFloat(document.getElementById('property-tax').value);
      const houseInsuranceYearly = parseFloat(document.getElementById('house-insurance').value);
      const privateMortgageInsuranceMonthly = parseFloat(document.getElementById('private-mortgage-insurance').value);
      const hoaMonthly = parseFloat(document.getElementById('hoa').value);

      if (isNaN(homeValue) || isNaN(downPayment) || isNaN(loanTerm) || isNaN(interestRate) || isNaN(propertyTaxYearly) || isNaN(houseInsuranceYearly) || isNaN(privateMortgageInsuranceMonthly) || isNaN(hoaMonthly)) {
      document.getElementById('result').innerHTML = 'Please enter valid numbers in all fields.';
      return;
      }

      const loanAmount = homeValue - downPayment;
      const monthlyInterestRate = interestRate / 12;
      const numPayments = loanTerm * 12;

      const monthlyPayment = (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -numPayments));

      const propertyTaxMonthly = propertyTaxYearly / 12;
      const houseInsuranceMonthly = houseInsuranceYearly / 12;
      const totalMonthlyExpenses = propertyTaxMonthly + houseInsuranceMonthly + privateMortgageInsuranceMonthly + hoaMonthly;

      const totalMonthlyPayment = monthlyPayment + totalMonthlyExpenses;

      const downPaymentPercentage = (downPayment / homeValue) * 100;

      document.getElementById('result').innerHTML = `
      Monthly Payment: $${totalMonthlyPayment.toFixed(2)}
      																				<br>
      Down Payment: $${downPayment.toFixed(2)} (${downPaymentPercentage.toFixed(2)}% of home value)
      `;
      }
    </script>
  </body>
</html>


-->
