<!-- 

    Single discalimer notice for all components

-->
<template>
    <div class="mt-2 pt-1 mb-0 pb-0">
        <p class="caption" ><v-icon color="red" left>mdi-alert-circle</v-icon>
            {{$t("side_disclaimer_notice")}}
        </p>
    </div>
</template>

<script>
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n" 

    export default {
        data() { return {
           side_disclaimer_notice: i18n.t('side_disclaimer_notice'),        
        }
      }
    }
</script>

<style>

</style>