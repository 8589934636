/*
   External File that is imported into the main desktop and left App drawer menu items

   and changes here will change the desktop as well as mobile menu items since they both share these data elements

   tile: added to support new home page defined by bookmarks

*/

import i18n from "@/i18n"

// var paramValue = 'NASDAQ:AAPL';
//,params:{'tvwidgetsymbol':paramValue} ?? working without defining it in the route query param...?
/*
   { id: 11, title: "Earnings Calendar", icon: 'mdi-alpha-a-circle', route:({name: 'earningsCalendar'}) }, 
   { id: 12, title: "Market Insights", icon: 'mdi-alpha-i-circle', route:({name: 'marketInsight'}) },          
*/
const baseUrl   = "/rs/utils/document?file=tiles/";
const menuItems = [
   { id: '1ch',  title: i18n.t("nav_charts.real_time"),    group: 'Live Data Charts',  icon: 'mdi-alpha-c-circle', route:({name: 'symbolchart'}),  tile: baseUrl+'RealTimeCharts.png' }, 
   { id: '2ch',  title: i18n.t("nav_charts.eco_calendar"), group: 'Live Data Charts',  icon: 'mdi-alpha-e-circle', route:({name: 'calendar'}),     tile: baseUrl+'EconomicCalendarChart.png' },   
   { id: '3ch',  title: i18n.t("nav_charts.for_exchange"), group: 'Live Data Charts',  icon: 'mdi-alpha-f-circle', route:({name: 'forexRates'}),   tile: baseUrl+'ForeignExchangeChart.png' }, 
   { id: '4ch',  title: i18n.t("nav_charts.ex_heatmap"),   group: 'Live Data Charts',  icon: 'mdi-alpha-h-circle', route:({name: 'forexHeatMap'}), tile: baseUrl+'ForexHeatMapChart.png' }, 
   { id: '5ch',  title: i18n.t("nav_charts.fin_markets"),  group: 'Live Data Charts',  icon: 'mdi-alpha-i-circle', route:({name: 'datawatch'}),    tile: baseUrl+'FinancialMarketsChart.png'}, 
   { id: '6ch',  title: i18n.t("nav_charts.market_oview"), group: 'Live Data Charts',  icon: 'mdi-alpha-m-circle', route:({name: 'overview'}),     tile: baseUrl+'MarketOverviewChart.png' },           
   { id: '7ch',  title: i18n.t("nav_charts.crypto_market"),group: 'Live Data Charts',  icon: 'mdi-alpha-p-circle', route:({name: 'cryptoMarket'}), tile: baseUrl+'CryptoMarketChart.png' },     
   { id: '8ch',  title: i18n.t("nav_charts.stock_market"), group: 'Live Data Charts',  icon: 'mdi-alpha-s-circle', route:({name: 'stockmarket'}),  tile: baseUrl+'StockMarketChart.png' }, 
   { id: '9ch',  title: "Stock Heatmap",                   group: 'Live Data Charts',  icon: 'mdi-alpha-t-circle', route:({name: 'stockHeatmap'}), tile: baseUrl+'StockHeatMapChart.png' },         
   { id: '10ch', title: "Crypto Heatmap",                  group: 'Live Data Charts',  icon: 'mdi-alpha-y-circle', route:({name: 'cryptoHeatmap'}),tile: baseUrl+'CryptoHeatMapChart.png' },
];

export default menuItems

