<!--
  About Puts Wiki DIALOG WINDOW - popup - from button

  note: had to create one wiki for each 'tool', since Vue caches these objects and recall 
        previous pages by using 'props' - there must be a better way ...

  example:  <ToolWiki title="The 'Puts Tool' Demystified" titleColor="green" wikiDocument="AboutCalls.html" /> 
-->
<template>
  <div class="text-center mx-auto">
    <v-dialog persistent v-model="dialog" :width="(this.isMobile? 350: 550)">
      <v-card height="575px" >
        <v-card-title class="ma-0 pa-0" >  
          <v-container fluid >   
            <v-row dense no-gutters class="ma-0 pa-0"> 
                    <!-- desktop button bar options list 1 -->
                    <v-col cols="12"  >
                      <v-banner :color="this.titleColor" elevation="11">
                         <v-toolbar-title class="font-weight-bold white--text">{{dialog_title}}</v-toolbar-title>
                      </v-banner>
                    </v-col>
                  </v-row>            
            </v-container>
          </v-card-title>
          <v-card-text scrollable class="ma-0 pa-0">
            <v-container fill-height >
              <v-flex fill-height >
                <v-row>  
                    <v-col cols="12">
                      <!-- *** terms display section - all service cookies ... data will be displayed in the termsViewer *** -->  
                      <!-- height adjusted to fit within the host parent card; desktop or mobile -->                
                      <!--<div ref="termsViewer" v-html="this.items[0].content"></div>-->
                      <div ref="PutsWikiViewer" v-html="this.text"></div>
                  </v-col>
                </v-row>                
              </v-flex>            
            </v-container>
          </v-card-text>
        <v-divider class="ma-0 pa-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" text @click.prevent="closeDialog">
            <span style="font-size: 18px">{{$t("disclaimer_notice.button_txt")}}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  // listen for global event calls called from FooterBar - or where ever
  import { eventBus } from '@/main'  // event bus communication for registered components
  import i18n from "@/i18n"          // i18n international language support for labels

  export default {
    name: "puts-help-wiki",
    data: () => ({
        dialog: false,
        scrollInvoked: 0,
        titleColor: 'green',
        dialog_title: "'Puts' Demystified",
        value: 1,
        key: 111,
        text: '',
        docUrl: 'AboutPuts.html',
        i18_fileKey: 'tools_puts_about_txt',        
        isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))), 
        locale: i18n.locale,
    }),
    // life cycle methods
    // when this component is added to page - it listens for a specific event
    mounted() {
       // setup popup dialog listener when component is added to footer template - NO Parans
       eventBus.$on( "show-puts-wiki", this.showHelpWiki );
    },
    // user defined methods go here
    methods: {
      showHelpWiki() {
        // basically just sets show = true to show dialog  
          this.$loading(true);
            this.text = '';  // remove any content to reset scroll
                  // request report from back-end to retrieve selected Rss Feed
                  var urlPage = ('/rs/utils/document?file='+this.docUrl);
                  this.axios.get( urlPage,{timeout:2500,headers:{'api-ref':this.xKey}}).then((response) => {
                        // clear any existing data - reset scroll window position
                        //console.log( response.data  ); // <- later comment out
                        var txt = response.data;
                        // **** just display default vaule from locales/en.json file ****
                        if( txt === null || txt.length <= 10 ) {
                            throw new Error('PutsHelpWiki Document Not Found - using passed i18n !');
                        } else {
                            // assign report to the RssFeedViewer - here
                            this.text = txt;
                            // stop loading spinner
                            this.$loading(false);                            
                        }
                  }).catch( err => { 
                      // stop loading spinner
                      this.$loading(false);                    
                      // show user default Services in en.json file - but this is old
                      this.text = i18n.t( this.i18_fileKey );                
                      console.log( err );
                  });
              // show popup dialog with Services and Cookie documents
              this.dialog=true;
              //console.log( i18n.t("footer.terms")  );
      },
      closeDialog() {
        // clear both 
        this.text   = '';
        // close dialog window
        this.dialog = false;
      },
      onScroll () {
        // to track scrolling of card window
        this.scrollInvoked++
      },      
    },
  }
</script>

<style scoped>
  .aa {
    background: rgb(25, 0, 255) !important;
  } 
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow-y: scroll;
}  
</style>