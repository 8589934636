<!-- 
   TradingView Snaps Feed [ https://www.tradingview.com/widget/timeline/ ]
-->
<template>
  <v-layout class="mt-5 pa-0" align-center justify-start column>
    <v-flex row align-center>  
      <!-- START DESKTOP CARD -->      
      <v-card class="mx-auto mb-15 flexcard" :min-width="this.cardMinWidth" :width="this.cardWidth" :height="this.cardHeight" hover>       
        <v-img class="white--text align-center" width="auto" height="75px"
            src="@/assets/news_1280.jpg">
            <v-card-title class="justify-center">
              <v-row dense>
                <v-col cols="1"></v-col>
                <v-col class="pt-2" cols="9">
                    <span class="text-h4 red--text font-weight-black">Trading News</span>
                </v-col>
                <v-col cols="2">
                  <!-- track bookmarks -->
                  <Bookmark link="TradingViewSnaps"/>                          
                </v-col>                    
              </v-row>          
            </v-card-title>
        </v-img>
        <!-- <v-card-subtitle >View subtitle</v-card-subtitle> -->
        <!--  -->
        <v-card-text class="text--primary">
            <!-- TradingView Widget BEGIN -->
            <div class="tradingview-widget-container">
                <div class="tradingview-widget-container__widget"></div>
                <div class="tradingview-widget-copyright"><span class="blue-text">Daily News Roundup</span> by TradingView</div>
                    <script type="application/javascript" defer src="https://s3.tradingview.com/external-embedding/embed-widget-timeline.js" async>
                       {
                          "feedMode": "all_symbols",
                          "colorTheme": "light",
                          "isTransparent": "false",
                          "displayMode": "regular",
                          "width": "{{this.widgetWidth}}",
                          "height": "{{this.widgetHeight}}",
                          "locale": "{{this.locale}}",
                          "largeChartUrl": "https://stock-insight.net/symbolchart"
                        }
                    </script>
            </div>
            <!-- TradingView Widget END -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions></v-card-actions>
      </v-card>
      <!-- END DESKTOP CARD -->
      <!-------------------------------------------------------------->
    </v-flex>
  </v-layout>        
</template>
  
<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"  
    
    // listen for and allow broadcasts to components
    import { eventBus } from '@/main'  // event bus communication for registered components

    // user bookmarking component
    import Bookmark      from '@/components/AddToFavorites.vue'    

    export default {
      components: { Bookmark },
      data: () => ({
        valid: false,
        // grabs locale from environment setup in start of app
        locale: i18n.locale,        
        // determines on object creation if running on mobile device true or false
        isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),
        // *** following settings are designed for desktop - flipped if 'mobile' before mount ***              
        cardMinWidth: 650,
        cardWidth: "80%",
        cardMaxWidth: "95%",
        cardHeight: 1000,
        widgetWidth: "100%",
        widgetHeight: 900, 
        showAdsID   : 0,
        hideAdsID   : 0,           
        time_delay: Number( process.env.VUE_APP_ADS_DELAY ),               
      }),
      methods: {
        hideAds() {
            // stop any timeout events by assigned ID - before creating any new ones
            clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );
            // hide any previous side Ad - if any
            eventBus.$emit("hide-side-ads","hideSideAds");          
          },
          showAds() {
            this.hideAds();
            // set variable delay
            var delay = this.time_delay;
            // show ads - if enabled in .env file - defined in BottomSheetAds component - record id for clearing
            this.showAdsID = setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), delay );   // record timeout ID -  for clearing
            delay = delay * 3;
            // send event to parent (AppBar.vue) to close Ads right drawer x3 delay
            this.hideAdsID = setTimeout( ()=> eventBus.$emit("hide-side-ads","hideSideAds"), delay );   // record timeout ID - for clearing      
          }
      },                  
      beforeMount() {
        // show loading spinner - just before component is mounted to app
        this.$loading(true);
        //*** re-set card/widget measurements if running on mobile device before mount ***
        if( this.isMobile ) {
          //  this.cardWidth=375;
            this.cardWidth="90%"
            this.cardMinWidth=325;
            this.cardMaxWidth="100%";
            this.cardHeight=1000;
            this.widgetWidth ="100%";
            this.widgetHeight= 900;            
        }
        // clear any previous ads 
        this.hideAds();
        //*****************************************************/            
      },
      mounted() {
        // 1 sec delay - then remove loading indicater       
        setTimeout( ()=> this.$loading(false), 1000 ); 

        // check for setting - time out is set in .env file for ads 
        if( this.time_delay <= 0 ) { this.time_delay = 2500; }

        // show ads - if enabled in .env file 
        this.showAds();
      },
      beforeUnmount() {
          // send event to parent (AppBar.vue) to close Ads right drawer
          this.hideAds();
      },
      metaInfo() {
        return {
          title: "Stock-Insight's TradingView Widget - News Snaps",
          meta: [
            { name: 'robots', content: 'index,follow'},
            { name: 'description', content: 'Trading View Snaps News' },
            { name: 'keywords', content: 'Trading View, snaps, NYSE, market, stock, financial, news, feed' },               
            { property: 'og:type', content: 'website'}, 
            { property: 'og:site_name', content: 'www.Stock-Insight.net'},
            { property: 'og:title', content: "Stock-Insight's TradingView Widget - Snaps"},                             
            {
              vmid: "description",
              name: "description",
              content: "TradingView Widget - Snaps"
            }
          ],
          link: [
            {
              rel: 'canonical',
              href: '<https://www.stock-insight.net/>'
            }
          ]
        }
      }              
    }
</script>

<style scoped>
  .v-image__image{
     width:100%;
  }
</style>

