/*
   External File that is imported into the main desktop and left App drawer menu items

   and changes here will change the desktop as well as mobile menu items since they both share these data elements

   route:  *  must point to defined route object to load under /router/index.js -> paths

   tile: added to support new home page defined by bookmarks - display image of bookmark topic

*/

import i18n from "@/i18n"
    //const baseUrl = process.env.DEV_APP_BASE_URL;
    const baseUrl   = "/rs/utils/document?file=tiles/";
    const menuItems = [
        { id: '1tl', title: i18n.t("nav_tools.calls"), group: 'Investing Tools',  icon: 'mdi-alpha-c-circle', route:({name: 'calls'}),            tile: baseUrl+"CallsCalculator.png" },
        { id: '2tl', title: i18n.t('nav_tools.date'),  group: 'Investing Tools',  icon: 'mdi-alpha-d-circle', route:({name: 'datetime'}),         tile: baseUrl+"DateTools.png" }, 
        { id: '3tl', title: "Fundamentals",            group: 'Investing Tools',  icon: 'mdi-alpha-f-circle', route:({name: 'Fundamentals'}),     tile: baseUrl+"FinancialAnalysis.png" }, 
        { id: '4tl', title: i18n.t('nav_tools.margin'),group: 'Investing Tools',  icon: 'mdi-alpha-m-circle', route:({name: 'margin'}),           tile: baseUrl+"MarginCalculator.png" },
//        { id: '5tl', title: "Mortgage Calculators",  group: 'Investing Tools',  icon: 'mdi-alpha-o-circle', route:({name: 'mortgageCalculators'}), tile: 'https://picsum.photos/id/11/500/300' },        
        { id: '6tl', title: i18n.t('nav_tools.puts'),  group: 'Investing Tools',  icon: 'mdi-alpha-p-circle', route:({name: 'puts'}),             tile: baseUrl+"PutsCalculator.png" },
        { id: '7tl', title: i18n.t('nav_tools.price'), group: 'Investing Tools',  icon: 'mdi-alpha-s-circle', route:({name: 'stockCalculators'}), tile: baseUrl+"PercentagePriceTools.png" },  
           
   ];

export default menuItems