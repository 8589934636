<!-- default calls landing page - calculator component
   * fontawsome icons use like this instead of 'prepend-icon' property
   <font-awesome-icon :icon="['font-awesome-icon', 'fas-calendar-clock']" slot="prepend"/>
   or  <font-awesome-icon icon="phone" /> for example
   [ icons from -> https://fontawesome.com/v4/icons/  or -> https://materialdesignicons.com/  ]
 -->
 <template>
  <v-container class="fill-height printable" > 
    <v-layout justify-center align-center>  
       <v-card class="mx-auto my-auto mb-20" min-width="225" max-width="850" hover >
            <v-img class="white--text align-center" width="auto" height="75px" src="@/assets/banner-math_100.jpg" cover >
                  <v-card-title >
                      <v-row dense>
                          <v-col cols="1">
                              <!-- user help button -->
                              <v-btn class="mx-1" fab small dark color="green" @click.prevent.stop="showCallsHelp" >
                                  <v-icon dark>fa-solid fa-question</v-icon>
                              </v-btn>
                          </v-col>
                          <v-col class="pt-2 -flex justify-center" cols="9">
                              <span class="text-h5 font-weight-bold">&nbsp;&nbsp;{{$t("calls_page.title")}}</span>
                          </v-col>
                          <v-col cols="2">
                              <!-- track bookmarks -->
                              <Bookmark link="calls"/>
                          </v-col>                    
                      </v-row>
                      <!-- create help wiki here - Remember the 'locale' is prepended to the file name -->
                      <CallsHelpWiki />                      
                  </v-card-title>
            </v-img>
        <v-card-text class="text--primary">               
            <!-- <v-card-subtitle class="pb-0">{{$t("calls_page.subtitle")}}</v-card-subtitle> -->
            <v-btn ref="callBuyBtn" @click="update_call_mode('B')" class="mt-5 mb-0 mr-5" rounded value="B">{{$t("calls_page.button_buy")}}</v-btn>
            <v-btn ref="callSellBtn" @click="update_call_mode('S')" class="mt-5 mb-0 ml-5" rounded value="S">{{$t("calls_page.button_sell")}}</v-btn>
            <v-form ref="queryForm" @submit.prevent.stop v-model="valid" >
                <v-container >
                  <!-- ticker block removed: :rules="call_tickerRules" -->
    <!-- PM removed 4.19.23 - Yahoo Ticker Url 403 - until further notice ---
                  <v-row dense justify="center" class="mb-0 pb-0">
                    <v-col cols="10" sm="10" md="8" lg="6">
                        <v-text-field v-model="ticker"
                          :label="$t('calls_page.call_ticker')"
                          prepend-icon="mdi-symbol"
                          :maxlength="15"
                          :counter="15"
                          @input="ticker= ((ticker != null)?ticker.toUpperCase():null)" 
                          @change="symbolLookup"
                          clearable 
                          @click:clear="clearTickerPrice" 
                          hint="Optional - Not Required" 
                          persistent-hint                               
                          dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="10" sm="10" md="8" lg="6">
                        <v-text-field v-model="tickerPrice"
                          readonly
                          prepend-icon="mdi-currency-usd"                    
                          :label="$t('calls_page.call_market_price')"
                          hint="Non Editable - Info Only"
                          persistent-hint
                          dense
                        ></v-text-field>
                    </v-col>               
                  </v-row>
    -->
                  <!-- ticker information panel - pops up when a price is found -->
                  <v-row v-show="this.tickerPrice" class="ma-0 pa-0">
                    <v-col cols="12">
                        <!-- boolean passed to update data in the component -->
                        <TickerInfoDisplay :jsonData="this.jsonData" />
                    </v-col>
                  </v-row>  
                  <v-row dense justify="center" class="ma-0 pa-0">
                      <v-col cols="12"></v-col>
                  </v-row>                                  
                  <!-- call calculator block -->
                  <v-row dense justify="center">
                    <v-col cols="10" sm="10" md="8" lg="6">
                        <v-currency-field 
                          :valueAsInteger="true" 
                          :decimalLength=0
                          name="call_expiration"
                          prepend-icon="mdi-calendar-multiselect"                  
                          v-model="call_expiration"
                          :rules="call_expirationRules"
                          :label="$t('calls_page.call_expiration_label')"
                          @blur="update_call_expiration($event)"                
                          dense
                          clearable                  
                          required
                        />
                    </v-col>
                    <v-col cols="10" sm="10" md="8" lg="6" >
                        <v-currency-field 
                          name="call_strikePrice" 
                          :label="$t('calls_page.call_strikePrice_label')"                   
                          dense
                          clearable
                          :rules="call_strikePriceRules"
                          :decimalLength=2
                          prepend-icon="mdi-bullseye-arrow"
                          v-model="call_strikePrice"
                          @blur="update_call_strikePrice($event)"                  
                          required
                        />
                    </v-col>
                  </v-row>
                  <v-row dense justify="center">
                    <v-col cols="10" sm="10" md="8" lg="6" >
                        <v-currency-field 
                          name="call_currentPrice"                
                          :label="$t('calls_page.call_currentPrice_label')"                  
                          dense
                          clearable                  
                          :decimalLength=2
                          :rules="call_currentPriceRules"
                          prepend-icon="mdi-currency-usd"
                          v-model="call_currentPrice"
                          @blur="update_call_currentPrice($event)"                  
                          required
                        />
                    </v-col>       
                    <v-col cols="10" sm="10" md="8" lg="6" >
                        <v-currency-field 
                          name="call_expectedStockPrice"                
                          :label="$t('calls_page.call_expectedStockPrice_label')"                  
                          prepend-icon="mdi-hand-heart-outline"                  
                          :error-messages="errors.rate"
                          :rules=call_expectedStockPriceRules
                          :decimalLength=2
                          dense
                          clearable
                          required
                          @blur="update_call_expectedStockPrice($event)"                  
                          v-model="call_expectedStockPrice"/>                 
                      </v-col>              
                  </v-row>
                  <v-row dense justify="center">
                    <v-col cols="10" sm="10" md="8" lg="6" >
                        <v-currency-field 
                              :valueAsInteger="true" 
                              :decimalLength=0                
                              name="call_contracts"                
                              prepend-icon="mdi-file-sign"
                              :rules="call_contractsRules"
                              :label="$t('calls_page.call_contracts_label')"                  
                              dense
                              clearable
                              v-model="call_contracts"
                              @blur="update_call_contracts($event)"                  
                              required
                        />
                    </v-col>
                    <v-col cols="10" sm="10" md="8" lg="6" >
                        <v-currency-field 
                              name="call_optionPrice"                
                              :label="$t('calls_page.call_optionPrice_label')"                  
                              dense
                              clearable
                              :rules="call_optionPriceRules"
                              :decimalLength=2
                              prepend-icon="mdi-target"
                              v-model="call_optionPrice"
                              @blur="update_call_optionPrice($event)"                  
                              required
                        />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
              <!-- ******************* calculated bottom sheet info box ********************************* -->
                <div v-if="displayInfo">
                  <v-layout align-center>
                  <!-- <v-flex xs12 sm12 md8 lg6 offset-md2> -->
                      <v-card class="mx-auto" min-width="200" >
                        <v-toolbar dense color="green lighten-1">
                          <v-app-bar-nav-icon></v-app-bar-nav-icon>
                          <v-toolbar-title class="white--text resultsTitle">{{(call_mode === 'B' ? $t("calls_page.button_buy"):$t("calls_page.button_sell"))+results_title}}</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn @click="displayInfo=false" icon>
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-toolbar>
                        <v-data-table disable-sort disable-pagination
                          :headers="headers"
                          :items="fields"
                          :hide-default-header="true"
                          :hide-default-footer="true"
                          class="elevation-1"
                          no-data-text="No data"                    
                        ></v-data-table>
                      </v-card>
                    <!-- </v-flex> -->
                  </v-layout>
                </div>
              <!-- ********************************** end bottom sheet info *************************** -->
              <v-row justify="center">
                <v-col cols="12" >
                    <ToolsBottomAds/>          
                </v-col>
              </v-row>      
              <v-row justify="center">
                <v-col cols="11" >
                    <SideDisclaimerNotice/>
                </v-col>
              </v-row>                      
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <!-- button section at bottom of call card -->
              <v-btn small :disabled="!valid" color="primary" text @click="updateCalculatedResultData">
                <v-icon left dark>fa-solid fa-upload</v-icon>
                <span style="font-size: 8px">{{$t("calls_page.button_send")}}</span>
              </v-btn>
              <v-btn color="orange" class="ml-5 pl-5" small text @click.prevent.stop="resetQueryOptions" >
                <v-icon left dark>fa-solid fa-rotate-right</v-icon>
                <span style="font-size: 8px">{{$t("calls_page.button_reset")}}</span>          
              </v-btn>
              <v-spacer></v-spacer>                 
              <v-btn color="green" small text @click.prevent.stop="showCallsHelp"  >
                <v-icon left dark>fa-solid fa-question</v-icon>
                <span style="font-size: 8px">Help</span>          
              </v-btn>                         
          </v-card-actions>
         </v-card>
  </v-layout>
</v-container>
</template>
<script>
  // listen for and allow broadcasts to components
  import { eventBus } from '@/main'  // event bus communication for registered components

  // use NY Date info for unigue key to sync with server
  var tNYDate = new Date(new Date().toLocaleString('en', {timeZone: 'America/New_York'}));

  // ads bottom sheet - uses broadcast message to show ad
  import TickerInfoDisplay    from '@/components/tools/TickerInfoDisplay.vue'
  import SideDisclaimerNotice from '@/components/SideDisclaimerNotice.vue'
  import ToolsBottomAds       from '@/components/ads/ToolsBottomAds.vue';
  // wiki tool document used when user presses 'help' button
  import CallsHelpWiki        from '@/components/AboutCallsWiki.vue'
  // user bookmarking component
  import Bookmark             from '@/components/AddToFavorites.vue'

  // i18n international language support for labels - should use browser locale default settings
  // all labels are defined in src/locals folder in json files - ie: en.json for English settings
  import i18n from "@/i18n"   

  export default {
    components: { TickerInfoDisplay, SideDisclaimerNotice, ToolsBottomAds, CallsHelpWiki, Bookmark },
    data: () => ({
      valid: false,
      // ticker related data fields
      ticker: '',
      tickerPrice: null,
      jsonData: '',  
      showAdsID : 0,
      hideAdsID : 0,           
      //---------- SECURITY CHECKS SENT to SERVER -------------------------------*
        xKey: tNYDate.getFullYear()+(tNYDate.getMonth()+1)+(tNYDate.getDate()),
        x2Key: tNYDate.getFullYear()*(tNYDate.getMonth()+1)*(tNYDate.getDate()),          
      //-------------------------------------------------------------------------*  
      displayInfo: false,
      agreed_to_terms: false,
      call_expiration: '',
      call_strikePrice: '',
      call_contracts: '',
      call_optionPrice: '',
      call_currentPrice: '',  
      call_expectedStockPrice: '',
      // determines on object creation if running on mobile device true or false
      isMobile: ( (/Mobile/i.test(navigator.userAgent)) ), 
      // reference current language locale
      locale: i18n.locale,         
      call_mode: 'B',
      results_title: i18n.t('calls_page.rpt_results_title'),
      // array to build data table - fields are updated before display
      headers: [ { text: 'Calculated Fields', align: 'start', sortable: false, value: 'name', width: '50%' },
                 { text: 'Amount', value: 'value', width: '50%' },
        ],
        // default values are 0 updated later calling method 'updateCalculatedResultData' Stock Value Lost $'
        fields: [{}],  // *** empty structure for data table construction when initialized - empty to start
        // replaced when user selects 'SELL' button
        fieldsSell: [ { name: i18n.t('calls_page.sell_rpt_exp_chg_dollar'), value: 0, },
                      { name: i18n.t('calls_page.sell_rpt_exp_chg_percent'), value: 0, },
                      { name: i18n.t('calls_page.sell_rpt_ttl_prem_earned'), value: 0, },
                      { name: i18n.t('calls_page.sell_rpt_stk_val_lost'), value: 0, },
        ],
        // replaced when user selects 'BUY' button
        fieldsBuy: [ { name: i18n.t('calls_page.buy_rpt_exp_chg_dollar'), value: 0, },
                     { name: i18n.t('calls_page.buy_rpt_exp_chg_percent'), value: 0, },
                     { name: i18n.t('calls_page.buy_rpt_ttl_option_dollar'), value: 0, }, 
                     { name: i18n.t('calls_page.buy_rpt_ttl_option_percent'), value: 0, }, 
                     { name: i18n.t('calls_page.buy_rpt_ttl_cost'), value: 0, },          
        ],        
      errors: {},
          // FORM RULES SECTION - called by form fields 
          call_expirationRules: [
            v => !!v || i18n.t('calls_page.call_expiration_reqMsg'),
            v => (v && parseInt(v.replace(/,/g,'')) > 0 && parseInt(v.replace(/,/g,'')) <= 1095 ) 
                ||  i18n.t('calls_page.call_expiration_minMaxMsg'),
          ],
          call_contractsRules: [
            v => !!v || i18n.t('calls_page.call_contracts_reqMsg'),
            v => (v && parseInt(v.replace(/,/g,'')) > 0 && parseInt(v.replace(/,/g,'')) <= 1000000) 
                || i18n.t('calls_page.call_contracts_minMaxMsg'),
          ], 
          call_strikePriceRules: [
            v => !!v || i18n.t('calls_page.call_strikePrice_reqMsg'), 
            v => (v && parseFloat(v.replace(/,/g,'')) > 0.00 && parseFloat(v.replace(/,/g,'')) <= 1000000 ) || i18n.t('calls_page.call_strikePrice_minMaxMsg'),
          ],        
          call_optionPriceRules: [
            v => !!v ||  i18n.t('calls_page.call_optionPrice_reqMsg'),
            v => (v && parseFloat(v.replace(/,/g,'')) > 0.00 && parseFloat(v.replace(/,/g,'')) <= 1000000 ) ||  i18n.t('calls_page.call_optionPrice_minMaxMsg'),
          ],  
          call_currentPriceRules: [
            v => !!v ||  i18n.t('calls_page.call_currentPrice_reqMsg'), 
            v => (v && parseFloat(v.replace(/,/g,'')) > 0.00 && parseFloat(v.replace(/,/g,'')) <= 1000000 ) ||  i18n.t('calls_page.call_currentPrice_minMaxMsg'),
          ],
          call_expectedStockPriceRules: [
            v => !!v ||  i18n.t('calls_page.call_expectedStockPrice_reqMsg'), 
            v => (v && parseFloat(v.replace(/,/g,'')) > 0.00 && parseFloat(v.replace(/,/g,'')) <= 1000000) ||  i18n.t('calls_page.call_expectedStockPrice_minMaxMsg'),
          ], 
    }),
    methods: {
          /********************************************************* 
           *          calls internal servlet to retrieve data
           **************************************************************/
          symbolLookup() {
            if( this.ticker ) {
              this.$loading(true);                  
              //update NY Time to match with server - reset before call
              this.axios.get('/ticker?symbol='+this.ticker,{headers:{'api-ref':this.xKey,'sc-ch-nx':this.x2Key}}).then((response) => {
                    console.log( response.data  ); // <- later comment out
                    var res = response.data;
                    this.clearTickerPrice();
                    // store copy for passing to display box
                    this.jsonData = response.data;
                    //console.log("longName = "+ res[0].longName );                                        
                    //console.log( "Price = "  + res[0].regularMarketPrice );
                    // **** throw error to popup message - invalid symbol ****
                    if( Number.parseFloat(res[0].regularMarketPrice) <= 0 
                          && Number.parseFloat(res[0].bid) <= 0
                             && Number.parseFloat(res[0].ask) <= 0 ) {
                        throw new Error('Invalid Symbol; Not Found');
                    }
                    // *** trigger to show ticker info dialog once price is set in data element ***
                    this.tickerPrice = res[0].regularMarketPrice; 
                    // store for -> TickerInfoDisplay template data
              }).catch( err => { 
                  this.$loading(false);    
                  //show message error - clear price to hide previous dialog which is the trigger
                  this.tickerPrice = ''; 
                  // display message box
                  this.$swal.fire({
                    icon: 'error',
                    title: i18n.t('calls_page.ticker_srch_err_title'),
                    text: this.ticker+i18n.t('calls_page.ticker_srch_err_msg'),
                    footer: i18n.t('calls_page.ticker_srch_footer_msg'),
                  }).then((result) => {
                      if( result ) {
                        // clear data on error
                        this.clearTickerPrice();                
                      }
                  })  
                  console.log( err );  
              });
              // *** update screen from session storage after the promise ***
              setTimeout( ()=> { 
                  // set price to display pop up ticker info box
                  //this.tickerPrice = sessionStorage.getItem("tickerPrice");
                  this.$loading(false);                                               
              }, 500 );                     
            }
          },
          // called when the 'x' is clicked on the 'ticker' lookup field
          // to clear the price which will hide the ticker stock information panel
          clearTickerPrice() { 
              this.tickerPrice='';
          },
          // upload button clicked calls this to update default data table array with entered values before showing results
          updateCalculatedResultData() {
            try {
              // display 1 sec spinner - start
              this.$loading(true)  // show loading spinner 
              // CLEAR REPORT DATA - first
              this.fields.length = 0;  // *** first clear reports fields array - clear
              this.fields = [{}];
              // *** first get all form field values - individually used in BUY & SELL sections***
                var expiration    = Number.parseInt(this.call_expiration.toLocaleString(this.$i18n.locale).replace(/,/g,''));
                var contracts     = Number.parseInt(this.call_contracts.toLocaleString(this.$i18n.locale).replace(/,/g,''));
                var strikePrice   = Number.parseFloat(this.call_strikePrice.toLocaleString(this.$i18n.locale).replace(/,/g,''));
                var optionPrice   = Number.parseFloat(this.call_optionPrice.toLocaleString(this.$i18n.locale).replace(/,/g,''));
                var currentPrice  = Number.parseFloat(this.call_currentPrice.toLocaleString(this.$i18n.locale).replace(/,/g,''));
                var expectedPrice = Number.parseFloat(this.call_expectedStockPrice.toLocaleString(this.$i18n.locale).replace(/,/g,''));
              // *** USER SELECTED 'BUY' BUTTON ***  -- default value
              if ( this.call_mode === 'B' ) {
                //  ------------------------ BUY FORMULA SECTION ---------------------
                  this.fields = this.fields.splice( 0, this.fields.length );
                  this.fields.splice(0, this.fields.length, ...this.fieldsBuy  ); // re-define based on 'call_mode'

                // Expected Change Dollar    [ =(C6-C5) ]
                this.fields[0].value = this.numberWithCommas( ( expectedPrice - currentPrice ).toFixed(2) );
                // Expected Change Percent   [ =(C6/C5)-1 ]            
                this.fields[1].value = this.numberWithCommas( (( ( expectedPrice / currentPrice ) -1) *100).toFixed(2) );                
                //-------------------------------------------------------------------------------------------------------
                  // complete formula [ =(((C6-C4)*100)*(C7))-((C7*100)*C8)-(ABS((C8)-(C6-C4))/C3)  ]
                  // ((C4-C6)*100)
                  var trod_1 = ( ( expectedPrice - strikePrice ) *100) * ((contracts));
                  // - ((C7*100)*C8)
                  var trod_2 = ( ( contracts * 100) * optionPrice );
                  // -(ABS((C8)-(C6-C4))/C3)
                  var trod_3 = Math.abs( ( ( optionPrice - (expectedPrice - strikePrice)) / expiration ) );
                //-------------------------------------------------------------------------------------------------------
                // Total Return on Option Dollar [   ]
                this.fields[2].value = this.numberWithCommas(  ((trod_1 -trod_2 -trod_3).toFixed(2)) );
                // Total Return on Option Percent  [ =(C13/C15) ]
                this.fields[3].value = this.numberWithCommas( ((trod_1 -trod_2 -trod_3) / ( ( ( contracts *100 ) * optionPrice ) )*100).toFixed(2) );  
                // Total Cost - calc first before next step [ =((C7*100)* C8) ]
                this.fields[4].value = this.numberWithCommas( ( ( contracts *100 ) * optionPrice ).toFixed(2) );
                // <<<*** CHECK if 'Expected Stock Price' is less than 'Strike Price'  ***>>>
                if( expectedPrice <= strikePrice ) {
                   // Total Return on Option Dollar will = [ Total Cost  ]
                   this.fields[4].value = this.numberWithCommas( - ( ( contracts *100 ) * optionPrice ).toFixed(2) );
                   // Total Return on Option Percent = -100%
                   this.fields[3].value = this.numberWithCommas(-100.00);
                   // Total Return on Option Dollar  = -Cost
                   this.fields[2].value = this.numberWithCommas( - ( ( contracts *100 ) * optionPrice ).toFixed(2) );             
                }
              } else {
                // ***  USER SELECTED 'SELL' BUTTON FORMULA ***
                // -------------------------SELL FORMULA SECTION ------------------------
                  this.fields.length = 0;  // clear fields array - first 
                  this.fields = this.fields.splice( 0, this.fields.length );                                 
                  this.fields.splice(0, this.fields.length, ...this.fieldsSell ); // re-define based on 'call_mode'
                                              
                if( expectedPrice === strikePrice  ) {
                  // Expected Change Dollar  [  =(C6-C5)  ]
                  this.fields[0].value = this.numberWithCommas( (expectedPrice - currentPrice).toFixed(2) );
                  // Expected Change Percent [  =(C6/C5)-1  ]                 
                  this.fields[1].value = this.numberWithCommas( ( ( (expectedPrice / currentPrice) -1 ) *100).toFixed(2) );
                  // Total Premium Earned    [  =(C7*100)*(C8)  ]
                  this.fields[2].value = this.numberWithCommas( ( (contracts *100 ) * optionPrice ).toFixed(2));
                  // Potential Gains Lost    [  =((C7*100)*(C4))-((C7*100)*(C6))+C13  ]
                  this.fields[3].value = this.numberWithCommas( ( ((contracts *100) *strikePrice)  - ((contracts *100) *expectedPrice) 
                                                  +  (( contracts *100 ) *optionPrice) ).toFixed(2) ); 
                } else if( expectedPrice > strikePrice )  {
                  // Expected Change Dollar  [  =(C6-C5)  ]
                  this.fields[0].value = this.numberWithCommas( (expectedPrice - currentPrice).toFixed(2) );
                  // Expected Change Percent [  =(C6/C5)-1  ]                 
                  this.fields[1].value = this.numberWithCommas( ( ( (expectedPrice / currentPrice) -1 ) *100).toFixed(2) );
                  // Total Premium Earned    [  =(C7*100)*(C8)  ]
                  this.fields[2].value = this.numberWithCommas( ( (contracts *100 ) * optionPrice ).toFixed(2));
                  //Potential Gains Lost Name Change
                  this.fields[3].name  = 'Potential Gains Lost US $';
                  // Potential Gains Lost    [  =((C7*100)*(C4))-((C7*100)*(C6))+C13  ]
                  this.fields[3].value = this.numberWithCommas( ( ((contracts *100) *strikePrice)  - ((contracts *100) *expectedPrice) 
                                                  +  (( contracts *100 ) *optionPrice) ).toFixed(2) ); 
                } else {
                  // Expected Change Dollar  [  =(C6-C5)  ]
                  this.fields[0].value = this.numberWithCommas( (expectedPrice - currentPrice).toFixed(2) );
                  // Expected Change Percent [  =(C6/C5)-1  ]                 
                  this.fields[1].value = this.numberWithCommas( ( ( (expectedPrice / currentPrice) -1 ) *100).toFixed(2) );
                  // Total Premium Earned    [  =(C7*100)*(C8)  ]
                  this.fields[2].value = this.numberWithCommas( ( (contracts *100 ) * optionPrice ).toFixed(2));
                  // Potential Gains Lost    [  =((C7*100)*(C4))-((C7*100)*(C6))+C13  ]
                  //Field Name Change
                  this.fields[3].name  = 'Stock Value Lost $';
                  // Stock Value Lost $    [  =((C7*100)*(C4))-((C7*100)*(C6))+C13  ]
                  this.fields[3].value = this.numberWithCommas( ( ((contracts *100) *strikePrice)  - ((contracts *100) *expectedPrice) 
                                                  +  (( contracts *100 ) *optionPrice) ).toFixed(2) ); 
                }

              }    
              // set call cookie to store query information for later on tab switches
              this.setCallCookie();               

            } catch(err) { console.log(err)
            } finally {
               //  remove spinner & redisplay bottom data table for user's review
               setTimeout( ()=> { this.$loading(false), this.displayInfo=true }, 1000 );
            }
          },
          // form item calls on 'blur' to save form data to data store
          update_call_expiration(event) {
            this.$store.commit( 'CALL_EXPIRATION', event.target.value )
            //console.log('stored expiration: '+event.target.value);
          },
          update_call_contracts(event){
            this.$store.commit( 'CALL_CONTRACTS', event.target.value )
          },
          update_call_strikePrice(event){
            this.$store.commit( 'CALL_STRIKEPRICE', event.target.value )
          },     
          update_call_optionPrice(event){
            this.$store.commit( 'CALL_OPTIONPRICE', event.target.value )
          },
          update_call_currentPrice(event){
            this.$store.commit( 'CALL_CURRENTPRICE', event.target.value )
          },  
          update_call_expectedStockPrice(event){
            this.$store.commit( 'CALL_EXPECTEDSTOCKPRICE', event.target.value )
          },
          // --- user selects 'BUY' or 'SELL' before submitting query - default is 'BUY'
          update_call_mode( mode ) {
            // set "BUY" and "SELL" button colors depending on selection
            if ( mode === 'B') {
                this.$refs["callBuyBtn"].$el.style.color="white"
                this.$refs["callBuyBtn"].$el.style.background="green"
                this.$refs["callSellBtn"].$el.style.color="black"                
                this.$refs["callSellBtn"].$el.style.background="lightgray"                
            } else {
                this.$refs["callSellBtn"].$el.style.color="white" 
                this.$refs["callSellBtn"].$el.style.background="green"
                this.$refs["callBuyBtn"].$el.style.color="black"
                this.$refs["callBuyBtn"].$el.style.background="lightgray"                
            }
            // update data to match button click - close results table
            this.call_mode = mode; this.displayInfo = false;
          }, 
          // popup message dialog to clear data confirmation
          resetQueryOptions() {
            this.$swal({
                title: i18n.t('calls_page.reset_title'),
                text: i18n.t('calls_page.reset_txt'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: i18n.t('calls_page.reset_confirmButtonTxt'),
                cancelButtonText: i18n.t('calls_page.reset_cancelButtonTxt'),
                showCloseButton: false,
                showLoaderOnConfirm: false
              }).then((result) => {
                if(result.value) {
                  this.$refs.queryForm.reset();            // ???
                  this.$refs.queryForm.resetValidation();  // ???
                  this.displayInfo = false;
                  // <v-currency-field bug does not clear on form reset - call reset again
                  setTimeout( ()=> this.$refs.queryForm.reset(), 150 );
                  // reset form - remove any persistent store call data 
                  this.$store.commit('CLEAR_CALLS_DATA');                   
                  // clear calculated results fields
                  this.fields.length = 0;  // *** first clear fields array - clear                  
                }
              });
          },
          // formats numbers with commas, negative number wrapped with params
          numberWithCommas(num) {
             //return num < 0 ? '( '+num.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',').replace(/-/g,'')+' )' : num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
             var retVal;
             if( num < 0 ) {
                // remove - negative sign and surround with parans () to signify negative number
                retVal = '( '+new Intl.NumberFormat( this.$i18n.locale ).format(num).replace(/-/g,'')+' )'
             } else {
                retVal = new Intl.NumberFormat( this.$i18n.locale ).format(num)
             }
             return retVal;             
          }, 
          // called on each query - cookie overwritten
          setCallCookie() {
             var callInfo = {
                  call_expiration:   this.$store.getters.call_expiration,
                  call_strikePrice:  this.$store.getters.call_strikePrice,
                  call_contracts:    this.$store.getters.call_contracts,
                  call_optionPrice:  this.$store.getters.call_optionPrice,
                  call_currentPrice: this.$store.getters.call_currentPrice,  
                  call_expectedStockPrice: this.$store.getters.call_expectedStockPrice              
             }
             // *** store cookie - for later retrieval when app starts again - it's always overwritten on each query
             this.$cookies.set('callInfo', JSON.stringify(callInfo));                
          },
          showCallsHelp() {
            // emits global event for component - to open help document from button
            eventBus.$emit("show-calls-wiki", "showHelpWiki");
          },                 
          adCleanUp() {
            // close right drawer
            eventBus.$emit("hide-side-ads","hideSideAds");  
            // stop any timeout events by assigned ID - before creating any new ones
            clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );
          },
          showHideAds() {
            this.adCleanUp();
            var delay = this.time_delay;
            // show ads - if enabled in .env file - defined in BottomSheetAds component - record id for clearing
            this.showAdsID = setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), delay );  // record timeout ID -  for clearing
            //console.log("showAdsID = "+this.showAdsID);    
            delay = delay * 3;
            // send event to parent (AppBar.vue) to close Ads right drawer x3 delay
            this.hideAdsID = setTimeout( ()=> eventBus.$emit("hide-side-ads","hideSideAds"), delay );  // record timeout ID - for clearing  
            //console.log("shideAdsID = "+this.hideAdsID);    
          },                        
    }, 
    // life-cycle methods defined in this section
    beforeMount() {
      // get data from data store on page switches between tab selection - if any
      this.call_expiration     = this.$store.getters.call_expiration;
      this.call_contracts      = this.$store.getters.call_contracts;
      this.call_strikePrice    = this.$store.getters.call_strikePrice;
      this.call_optionPrice    = this.$store.getters.call_optionPrice;
      this.call_currentPrice   = this.$store.getters.call_currentPrice;
      this.call_expectedStockPrice= this.$store.getters.call_expectedStockPrice;
      // show loading spinner - just before component is mounted to app
      this.$loading(true); 

    },
    mounted() {
      // set default 'BUY' button color once component is mounted for display
      this.$refs["callBuyBtn"].$el.style.color="white"
      this.$refs["callBuyBtn"].$el.style.background="green" 

      // .5 sec delay - then remove loading indicater       
      setTimeout( ()=> this.$loading(false), 1000 );

      // show ads - if enabled in .env file - defined in BottomSheetAds component
      //setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), 2500 ); 
      //setTimeout( ()=> eventBus.$emit("show-bottom-ads","showBottomAds"), 1000 );
    },
    beforeUnmount() {
      // clear emitter
      eventBus.$off("hide-side-ads","hideSideAds");  
    },
  }
</script>

<style scoped>
   .resultsTitle {
       font-size: 1.0rem;
   }
</style>
